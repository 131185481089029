<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card v-if="is_init" :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left :color="'#9400d3'">mdi-pyramid</v-icon>
          <span class="body-1">
            {{ action }} TMS Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-col style="max-width: 400px;">
          <v-text-field
              outlined
              dense
              v-model="layer_obj.name"
              label="Name"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.url"
              label="URL (required)"
              :hide-details="true"
          ></v-text-field>
          <p>Please make sure {z}/{x}/{y}.png is at the end of the url and is in lower case
          </p>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.api_key_key"
              label="Security key name"
              hint="Field name for security key, such as 'api_key' or 'access_token'."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.api_key_value"
              label="Security key value"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.attribution"
              label="attribution"
              hint="String to be shown in the attribution control, e.g. '© OpenStreetMap contributors'. It describes the layer data and is often a legal obligation towards copyright holders and tile providers."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.minZoom"
              label="minZoom"
              type="number"
              hint="The minimum zoom level down to which this layer will be displayed (inclusive)."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.maxZoom"
              label="maxZoom"
              type="number"
              hint="The maximum zoom level down to which this layer will be displayed (inclusive)."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.tileSize"
              type="number"
              label="tileSize"
              hint="Width and height of tiles in the grid. Use a number if width and height are equal, or L.point(width, height) otherwise."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.opacity"
              type="number"
              label="opacity"
              hint="Opacity of the tiles."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.maxNativeZoom"
              type="number"
              label="maxNativeZoom"
              hint="Maximum zoom number the tile source has available. If it is specified, the tiles on all zoom levels higher than maxNativeZoom will be loaded from maxNativeZoom level and auto-scaled."
              persistent-hint
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_tms.minNativeZoom"
              type="number"
              label="minNativeZoom"
              hint="Minimum zoom number the tile source has available. If it is specified, the tiles on all zoom levels lower than minNativeZoom will be loaded from minNativeZoom level and auto-scaled."
              persistent-hint
          ></v-text-field>
        </v-col>


        <v-divider></v-divider>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="layer_obj.config.show_description_and_comment"
              :hide-details="true"
              dense
              label="Add description and comments"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              rows="1"
              clearable
              dense
              auto-grow
              v-model="layer_obj.config.description"
              label="Description"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              dense
              rows="1"
              clearable
              auto-grow
              v-model="layer_obj.config.comments"
              label="Comments"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="layer_obj.config.stage.show"
              :hide-details="true"
              dense
              label="Show stage"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.stage.show">
          <v-select
              outlined
              dense
              v-model="layer_obj.config.stage.stage"
              :items="stages"
              item-text="stage"
              item-value="stage"
              label="Stage"
              :hide-details="true"
          >
            <template v-slot:selection="{item}">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               @click="submit"
               :disabled="layer_obj.name.length < 1 || is_loading"
        >Submit
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {deepClone} from "@/services/generic";
import {templates} from "@/store";
import {c_qfDocument, u_qfDocument} from "@/services/app_api";
import {get_qfDocument_type_as_dict} from "@/services/app_utils";


export default {
  name: "DriveTileLayerTMSDialog",
  props: {
    show_dialog: {type: Boolean},
    action: {type: String}, // Add, Edit, Duplicate
    map_id: {type: String, default: null},
    layer_id: {type: String, default: null},

    refresh_map_function: {type: Function, default: null},
    refresh_qfdrive_function: {type: Function, default: null},
  },
  components: {},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 600
      },

      is_init: false,
      is_loading: false,
      errors_list: [],

      layer_obj: {},

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],
    }
  },
  methods: {

    async submit() {
      this.is_loading = true
      console.log('in submit')
      console.log(this.layer_obj)
      switch (this.action) {
        case 'Add':
          await c_qfDocument(
              this.layer_obj,
              null,
              false)
          break;
        case 'Edit':
          await u_qfDocument(this.layer_obj, null, null)
          break;
        case 'Duplicate':
          this.layer_obj.id = null
          await c_qfDocument(
              this.layer_obj,
              null,
              false)
          break;
      }

      if (this.refresh_map_function !== null) {
        this.refresh_map_function(this.map_id)
      }

      if (this.refresh_qfdrive_function !== null) {
        this.refresh_qfdrive_function()
      }

      this.is_loading = false

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      let layers
      switch (this.action) {
        case 'Add':
          this.layer_obj = deepClone(templates.qfDocument)
          this.layer_obj.type = 'layer'
          this.layer_obj.name = 'New TMS Layer'

          this.layer_obj.config = deepClone(templates.qfDocument__config__templates.layer)
          this.layer_obj.config.layer_type = 'tile'
          this.layer_obj.config.layer_format = 'leaflet_tms'


          break;
        case 'Edit':
          layers = await get_qfDocument_type_as_dict('layer')
          this.layer_obj = layers[this.layer_id]
          break;
        case 'Duplicate':
          layers = await get_qfDocument_type_as_dict('layer')
          this.layer_obj = layers[this.layer_id]
          this.layer_obj.name += ' - COPY'
          break;

      }

      this.is_init = true


    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>