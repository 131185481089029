<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      scrollable
      hide-overlay
      :no-click-animation="true"
      :retain-focus="false"
      :max-width="dialog_width"
      :fullscreen="fullscreen"
      v-resize="on_resize"
  >
    <v-card v-if="is_ready">
      <v-app-bar dense color="white" max-height="42">
        <v-toolbar-title class="caption" style="padding-left: 0; margin-left: 10px;">
          <v-chip
              v-if="layer_obj.config.feature_type === 'Polygon'"
              label small
              :color="`${map_layer_dict.style.fillColor}${Math.floor(map_layer_dict.style.fillOpacity*100)}`"
              style="padding: 0;"
          >
            <v-icon small :color="map_layer_dict.style.color" v-if="layer_obj.config.feature_type === 'Polygon'">
              mdi-vector-polygon
            </v-icon>
          </v-chip>
          <v-icon small left :color="map_layer_dict.style.color"
                  v-if="layer_obj.config.feature_type === 'Point' && map_layer_dict.style.marker_type === 'marker'">
            mdi-map-marker
          </v-icon>
          <v-icon small left :color="map_layer_dict.style.color"
                  v-if="layer_obj.config.feature_type === 'Point' && map_layer_dict.style.marker_type === 'circleMarker'">
            mdi-circle
          </v-icon>
          <v-icon small left :color="map_layer_dict.style.color" v-if="layer_obj.config.feature_type === 'Line'">
            mdi-vector-polyline
          </v-icon>
          {{ layer_obj.name }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-select
            v-if="!fullscreen"
            height="25px"
            style="max-width: 100px; font-size: 0.9em;"
            :items="[
                    {t: '10%', v: 10},
                    {t: '20%', v: 20},
                    {t: '30%', v: 30},
                    {t: '40%', v: 40},
                    {t: '50%', v: 50},
                    {t: '60%', v: 60},
                    {t: '70%', v: 70},
                    {t: '80%', v: 80},
                    {t: '90%', v: 90},
                    {t: '100%', v: 100},
                    ]"
            v-model="dialog_width_pct"
            @change="on_resize"
            item-text="t"
            item-value="v"
            :hide-details="true"
            prepend-icon="mdi-arrow-expand-horizontal"
            title="Set Width"
            dense>
        </v-select>
        <v-select
            v-if="!fullscreen"
            height="25px"
            style="max-width: 100px; font-size: 0.9em;"
            :items="[
                    {t: '10%', v: 10},
                    {t: '20%', v: 20},
                    {t: '30%', v: 30},
                    {t: '40%', v: 40},
                    {t: '50%', v: 50},
                    {t: '60%', v: 60},
                    {t: '70%', v: 70},
                    {t: '80%', v: 80},
                    {t: '90%', v: 90},
                    {t: '100%', v: 100},
                    ]"
            v-model="dialog_height_pct"
            @change="on_resize"
            item-text="t"
            item-value="v"
            :hide-details="true"
            prepend-icon="mdi-arrow-expand-vertical"
            title="Set Height"
            dense>
        </v-select>
        <v-btn icon v-if="fullscreen">
          <v-icon @click="fullscreen=false; on_resize()">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!fullscreen">
          <v-icon @click="fullscreen=true; on_resize()">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text v-bind:style="{
        'width': dialog_width+'px',
        'max-width': dialog_width+'px',
        'min-height': dialog_height+'px',
        'max-height': dialog_height+'px'
      }">

        <v-row style="margin-top: 10px; margin-bottom: 10px;">

          <v-btn v-if="selected_uid_list.length > 0"
                 @click="clear_selected_features" small text
                 style="text-transform: none;">
            <v-icon left>mdi-select-remove</v-icon>
            Clear Selection
          </v-btn>
          <v-btn v-if="selected_uid_list.length > 0"
                 @click="invert_selected_features" small text
                 style="text-transform: none;">
            <v-icon left>mdi-select-inverse</v-icon>
            Invert Selection
          </v-btn>

          <v-btn v-if="selected_uid_list.length > 0 && bottom_sheet_tab === 'table'"
                 @click="shuffle_show_selected_on_tob" small text
                 style="text-transform: none;">
            <v-icon color="blue" left>mdi-arrow-up-bold-box</v-icon>
            Selected rows on top
          </v-btn>
          <!--          <v-btn v-if="selected_uid_list.length > 0 && show_selected_on_tob"-->
          <!--                 @click="show_selected_on_tob=!show_selected_on_tob" small text-->
          <!--                 style="text-transform: none;">-->
          <!--            <v-icon color="orange" left>mdi-arrow-up-bold-box</v-icon>-->
          <!--            Selected rows mixed in table-->
          <!--          </v-btn>-->

          <v-btn v-if="!table_data_is_editable && bottom_sheet_tab === 'table'"
                 @click="start_edit_table_mode" small text
                 style="text-transform: none;">
            <v-icon left color="green">mdi-table-edit</v-icon>
            Start Edit Table
          </v-btn>

          <v-btn v-if="table_data_is_editable && bottom_sheet_tab === 'table'"
                 @click="set_table_data_is_editable(false)" small text
                 style="text-transform: none;">
            <v-icon left color="purple">mdi-pencil-off</v-icon>
            Stop Edit Table
          </v-btn>

          <v-spacer></v-spacer>

          <v-menu
              transition="slide-y-transition"
              :close-on-content-click="false"
              left

          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense nav>

              <v-list-item @click="setMapsVectorLayerQueryDialog_show">
                <v-list-item-icon>
                  <v-icon :color="'#d2691e'">mdi-database-search</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Query</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="setDriveVectorLayerExportDialog_show">
                <v-list-item-icon>
                  <v-icon color="purple">mdi-file-export</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Export</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-menu>
        </v-row>
        <v-row v-if="selected_uid_list.length > 0" style="margin-bottom: 10px;">
          Selected records: {{ selected_uid_list.length }}
        </v-row>

        <v-tabs
            v-model="bottom_sheet_tab"
            fixed-tabs
            height="24"
        >
          <v-tabs-slider color="purple"></v-tabs-slider>

          <v-tab href="#table">
            <v-btn small text style="text-transform: none;">
              <v-icon small left>mdi-table</v-icon>
              Table
            </v-btn>

          </v-tab>
          <v-tab href="#plots">
            <v-btn small text style="text-transform: none;">
              <v-icon small left>mdi-chart-bar</v-icon>
              Plots
            </v-btn>
          </v-tab>

          <v-tab href="#mewa_plots" v-if="$store.state.auth.isLoggedIn">
            <v-btn small text style="text-transform: none;">
              <v-icon small left>mdi-chart-bar</v-icon>
              MEWA Plots
            </v-btn>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="bottom_sheet_tab" style="height: 100%; min-height: 100%;">
          <v-tab-item value="table" style="height: 100%; min-height: 100%;">
            <v-divider></v-divider>
            <MapsVectorLayerTable
                v-if="bottom_sheet_tab === 'table'"
                :json_arrays.sync="json_arrays"
                :schema.sync="layer_obj.config.schema"
                :highlight_selected_items="highlight_selected_items"
                :update_json_arrays_in_map="update_json_arrays_in_map"
                :selected_uid_list.sync="selected_uid_list"
                :update_selected_uid_list="update_selected_uid_list"
                :doc_obj="layer_obj"
                :table_data_is_editable.sync="table_data_is_editable"
                :show_selected_on_tob.sync="show_selected_on_tob"
                :bottom_sheet_resized.sync="bottom_sheet_resized"
            ></MapsVectorLayerTable>
          </v-tab-item>
          <v-tab-item value="plots" style="height: 100%; min-height: 100%; overflow-y: scroll;">
            <v-divider></v-divider>
            <MapsVectorLayerPlots
                v-if="bottom_sheet_tab === 'plots'"
                :json_arrays.sync="json_arrays"
                :schema.sync="layer_obj.config.schema"
                :selected_uid_list.sync="selected_uid_list"
                :map_layer_dict="map_layer_dict"
            ></MapsVectorLayerPlots>
          </v-tab-item>

          <v-tab-item value="mewa_plots" style="height: 100%; min-height: 100%; overflow-y: scroll;" v-if="$store.state.auth.isLoggedIn">
            <v-divider></v-divider>
            <MewaBottomSheetPlots
                v-if="bottom_sheet_tab === 'mewa_plots'"
                :json_arrays.sync="json_arrays"
                :schema.sync="layer_obj.config.schema"
                :selected_uid_list.sync="selected_uid_list"
                :map_layer_dict.sync="map_layer_dict"
                :set_style="set_style"
                :update_json_arrays_func="update_json_arrays"
                :layer_obj.sync="layer_obj"
                :submit_change_style="submit_change_style"
            ></MewaBottomSheetPlots>
          </v-tab-item>

        </v-tabs-items>

      </v-card-text>
    </v-card>

  </v-dialog>

</template>

<script>

import MapsVectorLayerTable from "@/components/Maps/MapsVectorLayerTable";
import MapsVectorLayerPlots from "@/components/Maps/MapsVectorLayerPlots";
import MewaBottomSheetPlots from "@/components/MEWA/MewaBottomSheetPlots";

export default {
  name: "MapsVectorLayerBottomSheet",
  props: {
    show_dialog: {type: Boolean},
    map_layer_dict: {type: Object},
    layer_obj: {type: Object},
    json_arrays: {type: Object},

    selected_uid_list: {type: Array},
    clear_selected_features: {type: Function},
    invert_selected_features: {type: Function},
    start_edit_table_mode: {type: Function},
    highlight_selected_items: {type: Function},
    update_json_arrays_in_map: {type: Function},

    table_data_is_editable: {type: Boolean},
    MapsVectorLayerQueryDialog_show: {type: Boolean},
    DriveVectorLayerExportDialog_show: {type: Boolean},

    set_style: {type: Function},
    on_update_schema: {type: Function},
    submit_change_style: {type: Function},
  },
  components: {
    MapsVectorLayerTable,
    MapsVectorLayerPlots,
    MewaBottomSheetPlots
  },
  data() {
    return {

      fullscreen: false,

      dialog_width_pct: 80,
      dialog_height_pct: 80,

      dialog_width: 100,
      dialog_height: 100,

      is_ready: false,
      bottom_sheet_tab: 'table',

      bottom_sheet_resized: false,

      show_selected_on_tob: false,

    }
  },
  methods: {

    update_json_arrays(){
      this.$emit('update:map_layer_dict', this.map_layer_dict)
      this.$emit('update:json_arrays', this.json_arrays)
      this.on_update_schema()
    },

    shuffle_show_selected_on_tob() {
      this.show_selected_on_tob = false;
      setTimeout(() => {
        this.show_selected_on_tob = true;
      }, 100)
    },

    async set_table_data_is_editable(val = false) {
      this.$emit('update:table_data_is_editable', val)
    },

    async update_selected_uid_list(selected_uid_list) {
      this.$emit('update:selected_uid_list', selected_uid_list)
    },

    async setMapsVectorLayerQueryDialog_show() {
      this.$emit('update:MapsVectorLayerQueryDialog_show', true)
    },
    async setDriveVectorLayerExportDialog_show() {
      this.$emit('update:DriveVectorLayerExportDialog_show', true)
    },

    async submit() {

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async on_resize() {
      if (this.fullscreen) {
        this.dialog_height = window.innerHeight
        this.dialog_width = window.innerWidth
      } else {
        this.dialog_height = parseInt(window.innerHeight * this.dialog_height_pct / 100)
        this.dialog_width = parseInt(window.innerWidth * this.dialog_width_pct / 100)
      }
      this.$set(this, 'bottom_sheet_resized', true)
      // this.bottom_sheet_resized = true
      console.log(this.bottom_sheet_resized)
    },
    async init() {
      this.is_ready = true
      await this.on_resize()

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

.v-dialog__content {
  align-items: flex-end;
}

.v-tab {
  text-transform: none !important;
}

</style>
