<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      max-width="400px"
  >
<!--    <template v-slot:activator="{ on, attrs }">-->
<!--      <v-btn-->
<!--          @click="reset_form"-->
<!--          icon-->
<!--          v-bind="attrs"-->
<!--          v-on="on"-->
<!--      >-->
<!--        <v-icon>mdi-cog</v-icon>-->
<!--      </v-btn>-->
<!--    </template>-->
    <v-card
        :loading="loading"
    >

      <v-toolbar color="indigo" dense dark>

        <v-toolbar-title>
          User Settings
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>

        <v-col cols="12">
          <v-text-field
              label="First Name"
              v-model="user_profile.first_name"
              :disabled="loading"
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
              label="Last Name"
              v-model="user_profile.last_name"
              :disabled="loading"
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            small
            text
            :disabled="loading"
            @click="cancel"
        >
          Close
        </v-btn>
        <v-btn
            small
            class="primary"
            :disabled="loading"
            @click="submit"
        >
          Submit
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {isEmpty} from 'lodash'
import {c_user_profile, u_user_profile} from "@/services/user_api";
import {deepClone} from "@/services/generic";


export default {
  name: "UserSettings",
  props: {
    show_dialog: {type: Boolean},
  },
  data() {
    return {
      dialog: false,
      loading: false,

      user_profile: {
        version: 0,
        first_name: null,
        last_name: null,
        full_name: null,
        abrv_name: null,
      },
    }
  },
  methods: {
    init_form() {
      this.user_profile = deepClone(this.$store.state.user_settings.user_profile)
      this.loading = false
    },
    async submit() {
      this.loading = true

      this.user_profile.full_name = `${this.user_profile.first_name} ${this.user_profile.last_name}`
      this.user_profile.abrv_name = `${this.user_profile.first_name[0].toUpperCase()}${this.user_profile.last_name[0].toUpperCase()}`

      if ( isEmpty(this.$store.state.user_settings.user_profile)){

        await c_user_profile(this.user_profile)
      } else {
        this.user_profile.version += 1
        await u_user_profile(this.user_profile)
      }
      this.cancel()
    },
    cancel() {
      this.loading = false
      this.$emit('update:show_dialog', false)
    },

  },
  mounted() {
    this.init_form()

  }

}
</script>

<style scoped>

</style>
