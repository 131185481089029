<template>
  <div>
    <v-app-bar
        app dense clipped-left
        color="white"
    >
      <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->

      <v-img class="mr-2" max-width="30" max-height="30" src="@/assets/questfeed/logo.svg"/>

      <v-app-bar-title v-if="!$vuetify.breakpoint.mobile">
        <span class="font-weight-bold">Quest</span>
        <span class="font-weight-light">Feed </span>

<!--        <v-chip-->
<!--            x-small-->
<!--            color="yellow"-->
<!--        >-->
<!--          <span class="caption font-italic">Beta</span>-->
<!--        </v-chip>-->

        <!--      <v-app-bar-title v-if="!$vuetify.breakpoint.mobile">-->
        <!--        <span class="text-capitalize">Quest</span>-->
        <!--        <span class="font-weight-light">Feed </span>-->
        <!--        <span class="font-weight-bold">-->
        <!--          GIS-->
        <!--        </span>-->
        <small style="color: grey; font-size: 0.6em;!important; margin-left: 10px;">v{{ $store.state.version }}</small>
      </v-app-bar-title>


      <v-spacer></v-spacer>
      <h3 v-if="!$store.state.is_online" class="body-1 red--text">You are Offline</h3>
      <v-btn icon v-if="!$store.state.is_online">
        <v-icon color="red">mdi-wifi-off</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <UserSettings v-if="$store.state.auth.isLoggedIn"></UserSettings>
      <v-menu
          bottom
          rounded
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
              v-if="$store.state.auth.isLoggedIn"
              color="green"
              size="36"
              v-bind="attrs"
              v-on="on"
          >
            <span class="white--text body-1">
              {{ $store.state.user_settings.user_profile.abrv_name }}
            </span>
          </v-avatar>
          <v-avatar
              v-else
              color="red"
              size="36"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon dark>
              mdi-account-question
            </v-icon>
          </v-avatar>
        </template>


        <v-list nav dense>
          <v-list-item link v-if="$store.state.auth.isLoggedIn">
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ $store.state.user_settings.user_profile.full_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $store.state.auth.user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link v-else>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Anonymous User
              </v-list-item-title>
              <v-list-item-subtitle>
                Locally stored data only
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-3" v-if="!$store.state.auth.isLoggedIn && $store.state.stage === 'dev'"></v-divider>
          <SignUp v-if="!$store.state.auth.isLoggedIn && $store.state.stage === 'dev'"></SignUp>

          <v-divider class="my-3" v-if="!$store.state.auth.isLoggedIn"></v-divider>
          <LogIn v-if="!$store.state.auth.isLoggedIn"></LogIn>

          <v-divider class="my-3" v-if="$store.state.auth.isLoggedIn"></v-divider>
          <SignOut v-if="$store.state.auth.isLoggedIn"></SignOut>

        </v-list>
      </v-menu>

      <v-menu
          offset-y
          transition="slide-y-transition"
          bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense nav>

          <v-list-item
              v-if="$store.state.stage === 'dev'"
              @click="on_select_tool('show_UserSettings')">
            <v-list-item-icon>
              <v-icon color="purple">mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>User Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              v-for="(item, index) in menu_items"
              @click="on_select_tool(item.action)"
              :key="index">
            <v-list-item-icon>
              <v-icon v-if="item.icon !== null" :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        v-if="drawer"
        permanent clipped absolute hide-overlay app :width="width"
    >

      <v-list dense nav>
        <v-list-item v-if="width===50">
          <v-list-item-icon>
            <v-icon @click="width=200;" color="blue">mdi-chevron-right-box</v-icon>
          </v-list-item-icon>


        </v-list-item>
        <v-list-item v-if="width===200">
          <v-list-item-icon>
            <v-icon @click="width=50;" color="blue">mdi-chevron-left-box</v-icon>
          </v-list-item-icon>
        </v-list-item>


        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            router :to="item.link"
        >
          <v-list-item-icon>
            <v-icon :title="item.title">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


    </v-navigation-drawer>

    <UserSettings
        v-if="show_UserSettings"
        :show_dialog.sync="show_UserSettings"
    ></UserSettings>

  </div>

</template>

<script>

import SignUp from "@/components/Auth/SignUp";
import LogIn from "@/components/Auth/LogIn";
import SignOut from "@/components/Auth/SignOut";
import UserSettings from "@/components/User/UserSettings";

export default {
  name: "TheNavbar",
  components: {
    SignUp,
    LogIn,
    SignOut,
    UserSettings
  },
  data() {
    return {
      drawer: false,
      width: 50,
      items: [
        {title: 'Map', icon: 'mdi-map', link: '/map'},
      ],
      menu_items: [
        // {title: 'User Settings', icon: 'mdi-cog', action: 'show_UserSettings', color: '#D500F9'},
        {title: 'Help', icon: 'mdi-help-circle', action: 'show_help', color: 'orange'},
        {title: `Version ${this.$store.state.version}`, icon: 'mdi-git', action: null, color: 'brown'},
        {title: `QuestFeed GIS`, icon: null, action: null, color: 'green'},
      ],
      show_UserSettings: false,
    }
  },
  methods: {
    on_select_tool(action) {
      switch (action) {
        case 'show_UserSettings':
          this.show_UserSettings = true
          break;
        case 'show_help':
          window.open('https://questfeed.notion.site/qfGIS-Quick-Start-Guide-761c724a50e54a7e8da7c643a079f70b', '_blank')
          break;
        default:
          break;
      }
    },

  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
