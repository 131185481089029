<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="orange">mdi-application-import</v-icon>
          <span class="body-1">
            Import Raster Layer(s)
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-file-input
              outlined
              multiple
              accept=".geotiff, .GeoTIFF, .TIFF, .tif, .tiff"
              show-size
              :clearable="true"
              label="Select files"
              v-model="input_files"
              @change="on_file_select"
              :hide-details="true"
          ></v-file-input>
          <p class="caption">Select GeoTIFF files with coordinates in WGS84 projection.
          </p>
        </v-col>


        <v-col v-if="input_layers_list.length > 0">

          <v-card v-for="(input_layer, idx) in input_layers_list" :key="idx" style="margin-bottom: 10px;" elevation="3">
            <v-system-bar color="blue" class="white--text caption">{{ input_layer.file_info.file_name }}</v-system-bar>
            <v-col style="max-width: 400px;">
              <v-text-field
                  v-model="input_layer.name"
                  label="Name"
                  :hide-details="true"
              ></v-text-field>
            </v-col>

            <v-col style="max-width: 400px;">
              <v-textarea
                  outlined
                  rows="1"
                  clearable
                  auto-grow
                  v-model="input_layer.description"
                  label="Description"
                  :hide-details="true"
              ></v-textarea>
            </v-col>

            <v-col style="max-width: 400px;">
              <v-select
                  outlined
                  dense
                  v-model="input_layer.stage.stage"
                  :items="stages"
                  item-text="stage"
                  item-value="stage"
                  label="Stage"
                  :hide-details="true"
              >
                <template v-slot:selection="{item}">
                  <v-list-item dense>
                    <v-list-item-icon>
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.stage }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" dense>
                    <v-list-item-icon>
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.stage }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

<!--            <v-col>-->
<!--              <v-switch-->
<!--                  :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"-->
<!--                  v-model="input_layer.upload_blob"-->
<!--                  :hide-details="true"-->
<!--                  label="Upload to cloud"-->
<!--              ></v-switch>-->
<!--            </v-col>-->

          </v-card>

        </v-col>

        <v-progress-linear v-if="loading" indeterminate color="blue"></v-progress-linear>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="green" class="white--text"
               :disabled="loading || input_layers_list.length < 1"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
// import GeoTIFF, {fromUrl, fromUrls, fromArrayBuffer, fromBlob} from 'geotiff';
import {deepClone} from "@/services/generic";
import {templates} from "@/store";
import {c_qfDocument, u_qfCollection} from "@/services/app_api";
import {get_qfCollection_type_as_dict} from "@/services/app_utils";

export default {
  name: "DriveRasterLayerImportDialog",
  components: {},
  props: {
    show_dialog: {type: Boolean},
    map_id: {type: String, default: null},
    callback_function: {type: Function},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      import_file_type: '',

      input_files: [],
      input_layers_list: [],

      active_table_data: [],
      active_table_headers: [],
      active_table_name: null,

      loading: false,

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],

    }
  },
  methods: {
    async reset_data() {
      this.input_layers_list = []
      this.table_headers = []
      this.table_data = []
    },

    async on_file_select() {
      console.log(this.input_files)
      this.loading = true
      await this.reset_data()
      for (let input_file of this.input_files) {
        await this.process_raster_file(input_file)
      }

      this.loading = false

    },

    async process_raster_file(input_file) {


      // const tiff = await fromBlob(input_file);
      // console.log(tiff)


      this.input_layers_list.push({
        name: input_file.name,
        description: null,
        comments: null,
        stage: {
          show: true,
          stage: 'Development', // Development, Testing, Production
        },

        upload_blob: true,

        file_info: {
          file_name: input_file.name,
          file_size: input_file.size,
          file_type: input_file.type,
          file_lastModified: input_file.lastModified,
        },

        file_obj: input_file

      })

    },

    async create_layers() {

      for (let layer_item of this.input_layers_list) {

        let layer_obj = deepClone(templates.qfDocument)
        layer_obj.name = layer_item.name
        layer_obj.type = 'layer'
        layer_obj.docBlob.has_blob = true
        layer_obj.docBlob.type = 'tiff'
        layer_obj.docBlob.is_zipped = false

        layer_obj.config = deepClone(templates.qfDocument__config__templates.layer)

        layer_obj.config.description = layer_item.description
        layer_obj.config.comments = layer_item.comments
        layer_obj.config.stage = layer_item.stage

        layer_obj.config.layer_type = 'raster'
        layer_obj.config.layer_format = 'GeoTIFF'

        layer_obj.config.is_imported = true
        layer_obj.config.import_info = deepClone(templates.qfDocument__config__templates.layer.import_info)
        layer_obj.config.import_info.file_name = layer_item.file_info.file_name
        layer_obj.config.import_info.file_size = layer_item.file_info.file_size
        layer_obj.config.import_info.file_type = layer_item.file_info.file_type
        layer_obj.config.import_info.file_lastModified = layer_item.file_info.file_lastModified

        let resp = await c_qfDocument(layer_obj, layer_item.file_obj, layer_item.upload_blob)

        let layer_id = resp.doc_id

        if (this.map_id !== null) {
          let maps = await get_qfCollection_type_as_dict('map')
          let map = maps[this.map_id]

          map.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
          map.layers.dict[layer_id].id = layer_id
          map.layers.list.push(layer_id)

          await u_qfCollection(map)
        }


      }

    },

    async submit() {
      this.loading = true

      await this.create_layers()

      if (this.callback_function !== null) {
        this.callback_function(this.map_id)
      }
      this.loading = false
      this.cancel()


    },
    cancel() {
      this.reset_data()
      this.$emit('update:show_dialog', false)

    },
    async init() {
      await this.reset_data()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
