import {deepClone} from "@/services/generic";
import {v4 as uuidv4} from "uuid";

export async function filter_json_arrays_for_row_uids(json_arrays, selected_uid_list) {

    let cols_list = Object.keys(json_arrays)
    let json_arrays2 = {}
    for (let col_name of cols_list) {
        json_arrays2[col_name] = []
    }
    let row_uid_col = json_arrays.row_uid

    for (let row_uid of selected_uid_list) {
        let idx = row_uid_col.indexOf(row_uid)
        if (idx > -1) {
            for (let col_name of cols_list) {
                json_arrays2[col_name].push(json_arrays[col_name][idx])
            }
        }
    }
    return json_arrays2
}

export async function remove_row_ids_from_json_arrays(json_arrays, remove_uid_list) {
    json_arrays = deepClone(json_arrays)
    let cols_list = Object.keys(json_arrays)

    for (let row_uid of remove_uid_list) {
        let idx = json_arrays.row_uid.indexOf(row_uid)
        if (idx > -1) {
            for (let col_name of cols_list) {
                json_arrays[col_name].splice(idx, 1)
            }
        }
    }
    return json_arrays
}


export async function json_arrays_to_json_list(json_arrays, schema, exclude_col_uids) {

    let col_uids = Object.keys(json_arrays).filter(e => !exclude_col_uids.includes(e))

    let json_list = []
    for (let idx in json_arrays.row_uid) {
        let json_item = {}
        for (let col_uid of col_uids) {
            let col_label = schema[col_uid].label
            json_item[col_label] = json_arrays[col_uid][idx]
        }
        json_list.push(json_item)
    }

    return json_list
}

export async function json_arrays_to_json_list_no_label(json_arrays, schema, exclude_col_uids) {

    let col_uids = Object.keys(json_arrays).filter(e => !exclude_col_uids.includes(e))

    let json_list = []
    for (let idx in json_arrays.row_uid) {
        let json_item = {}
        for (let col_uid of col_uids) {
            json_item[col_uid] = json_arrays[col_uid][idx]
        }
        json_list.push(json_item)
    }

    return json_list
}

export async function json_list_to_json_arrays(json_list) {

    let json_arrays = {}
    for (let key in json_list[0]) {
        json_arrays[key] = []
    }
    for (let row_item of json_list) {

        for (let key in row_item) {
            json_arrays[key].push(row_item[key])
        }
    }
    return json_arrays
}


export async function get_idx_for_row_uid(json_arrays, row_uid) {
    return json_arrays.row_uid.indexOf(row_uid)
}

export async function get_rec_for_row_uid(json_arrays, row_uid, exclude_cols) {
    json_arrays = deepClone(json_arrays)
    let idx = await get_idx_for_row_uid(json_arrays, row_uid)
    let cols_list = Object.keys(json_arrays).filter(e => !exclude_cols.includes(e))
    let rec = {}
    for (let col_name of cols_list) {
        rec[col_name] = json_arrays[col_name][idx]
    }
    return rec
}

export async function get_json_arrays_unique_vals(json_arrays) {

    let exclude_cols = ['row_uid', 'geometry']
    let cols_list = Object.keys(json_arrays).filter(e => !exclude_cols.includes(e))

    let uniques_dict = {}
    for (let col_name of cols_list) {
        uniques_dict[col_name] = [...new Set(json_arrays[col_name])].sort()
    }

    return uniques_dict

}


export async function json_arrays_to_json_dict_with_col_name(json_arrays, schema) {
    console.log(schema)
    let exclude_cols = ['row_uid', 'geometry']
    let cols_list = Object.keys(json_arrays).filter(e => !exclude_cols.includes(e))
    let json_dict = {}
    for (let idx in json_arrays.row_uid) {

        let row_uid = json_arrays.row_uid[idx]

        let item_dict = {}
        for (let col_uid of cols_list) {
            let col_name = schema[col_uid].label
            item_dict[col_name] = json_arrays[col_uid][idx]
        }
        json_dict[row_uid] = item_dict

    }

    return json_dict
}


export async function col_name_2_col_uid_mapper(schema) {

    let col_name_2_col_uid = {}
    for (let col_uid in schema) {
        let col_label = schema[col_uid].label
        col_name_2_col_uid[col_label] = col_uid
    }
    return col_name_2_col_uid

}

export async function add_row_to_json_arrays(json_arrays, new_geometry = null, copy_row_uid = null) {

    let cols_list = Object.keys(json_arrays).filter(e => !['row_uid', 'geometry'].includes(e))
    let copy_item = {}
    if (copy_row_uid !== null) {
        let copy_idx = json_arrays.row_uid.indexOf(copy_row_uid)
        for (let col_uid of cols_list) {
            copy_item[col_uid] = json_arrays[col_uid][copy_idx]
        }
    }

    if (new_geometry !== null) {
        json_arrays.geometry.push(new_geometry)
    }

    json_arrays.row_uid.push(uuidv4().replaceAll('-', ''))

    for (let col_uid of cols_list) {

        if (copy_row_uid !== null) {
            json_arrays[col_uid].push(copy_item[col_uid])
        } else {
            json_arrays[col_uid].push(null)
        }

    }

    return json_arrays

}


export async function json_arrays_to_geojson(json_arrays) {

    let geojson = {
        "type": "FeatureCollection",
        "features": []
    }
    for (let idx in json_arrays.row_uid) {

        geojson.features.push({
            type: 'Feature',
            properties: {row_uid: json_arrays.row_uid[idx]},
            geometry: json_arrays.geometry[idx]
        })

    }

    return geojson

}

export async function json_arrays_to_full_geojson(json_arrays, schema) {
    let exclude_col_names = []
    for (let col_uid in schema) {
        if (schema[col_uid].is_system) {
            exclude_col_names.push(col_uid)
        }
    }

    let col_uids = Object.keys(json_arrays).filter(e => !exclude_col_names.includes(e))

    let geojson = {
        "type": "FeatureCollection",
        "features": []
    }
    for (let idx in json_arrays.row_uid) {

        let properties = {
            row_uid: json_arrays.row_uid[idx]
        }
        for (let col_uid of col_uids) {
            let col_label = schema[col_uid].label
            properties[col_label] = json_arrays[col_uid][idx]
        }

        geojson.features.push({
            type: 'Feature',
            properties: properties,
            geometry: json_arrays.geometry[idx]
        })

    }

    return geojson

}