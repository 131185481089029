<template>
  <v-card style="margin-bottom: 10px;">
    <v-card-text>
      <div v-bind:id="div_id"></div>
    </v-card-text>
  </v-card>
</template>

<script>

import {newPlot} from 'plotly.js-dist-min'
import {v4 as uuidv4} from "uuid";

export default {
  name: "PlotsLineOrBar",
  props: {
    plot_type: {type: String, default: 'lines'},
    plot_data: {type: Array},
  },
  data() {
    return {
      template_plot_data: [{
        x: [],
        y: [],
        color: 'green',
        width: 4,
      }],
      div_id: uuidv4().replaceAll('-', ''),
    }
  },
  methods: {
    async do_plot() {
      let data = []
      for (let item of this.plot_data) {
        let data_item = {
          name: item.name,
        }

        switch (this.plot_type) {
          case 'lines':
            data_item.type = 'scatter'
            data_item.mode = 'lines'
            data_item.line = {
              color: item.color,
              width: item.width
            }
            break;
          case 'bar':
            data_item.type = 'bar'
            data_item.marker = {
              color: item.color,
              width: item.width
            }
            break;
          case 'bar_stack':
            data_item.type = 'bar'
            data_item.marker = {
              color: item.color,
              width: item.width
            }
            break;
          default:
            data_item.type = 'scatter'
            data_item.mode = 'lines'
            data_item.line = {
              color: item.color,
              width: item.width
            }
            break;
        }

        if (item.x !== null) data_item.x = item.x
        if (item.y !== null) data_item.y = item.y

        data.push(data_item)
      }

      let layout = {
        // showlegend: false,
        autosize: true,
        // margin: {
        //   l: 50,
        //   r: 50,
        //   b: 50,
        //   t: 50,
        //   pad: 4
        // },
        xaxis: {
          showgrid: false,
          showline: false,
        },
        yaxis: {
          showgrid: false,
          showline: false,
        },
      }
      if (this.plot_type === 'bar_stack') {
        layout.barmode = 'stack'
      }

      newPlot(this.div_id, data, layout);
    },
    init() {
      this.do_plot()
    },
  },
  watch: {
    plot_data() {
      setTimeout(() => {
        this.do_plot()
      }, 100)
    },
    plot_type() {
      setTimeout(() => {
        this.do_plot()
      }, 100)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
