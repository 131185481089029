<template>

  <div style="margin-bottom: 5px;">
    <v-row>
      <v-spacer></v-spacer>

      <v-menu
          transition="slide-y-transition"
          :close-on-content-click="true"
          right
          v-if="layer_tools_show_mode=== 'all'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense nav>

          <v-list-item @click="on_select_tool('edit_layer')">
            <v-list-item-icon>
              <v-icon color="green">mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('duplicate_layer')">
            <v-list-item-icon>
              <v-icon color="purple">mdi-content-save-all</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Save As</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="on_select_tool('delete')">
            <v-list-item-icon>
              <v-icon :color="'#ff0000'">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Delete Layer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MapsTileLayerTools",
  props: {
    layer_tools_show_mode: {type: String},
    on_select_tool: {type: Function},
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>
