<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="pink" left>mdi-merge</v-icon>
          <span class="body-1">
            Merge with Table(s)
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3">
          <v-system-bar color="green" class="white--text caption">Step 1: Select Layer Column</v-system-bar>
          <v-card-text>
            <v-col style="max-width: 400px;">
              <v-select
                  label="Layer Column Name"
                  v-model="layer_column_uid"
                  :items="layer_columns"
                  item-text="col_name"
                  item-value="col_uid"
                  :hide-details="true"
              ></v-select>
            </v-col>
            <v-btn small @click="show_layer_table">
              <v-icon color="orange" left>mdi-table</v-icon>
              Show Layer Table
            </v-btn>

            <v-btn small @click="show_layer_schema">
              <v-icon color="orange" left>mdi-table-cog</v-icon>
              Show Layer Schema
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3">
          <v-system-bar color="green" class="white--text caption">Step 2: Select Table(s)</v-system-bar>

          <v-col style="max-width: 400px;">
            <v-autocomplete
                v-model="merge_table_ids"
                :items="input_tables_list"
                dense
                small-chips
                deletable-chips
                clearable
                multiple
                label="Select Merge Table(s)"
                item-text="table_name"
                item-value="table_id"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>
        </v-card>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3" v-if="merge_table_ids.length > 0">
          <v-system-bar color="green" class="white--text caption">Step 3: Select Merge Column for Table(s)</v-system-bar>
          <v-card-text>
            <v-card v-for="merge_table_id in merge_table_ids" :key="merge_table_id" style="margin-bottom: 10px;"
                    elevation="3">
              <v-system-bar color="blue" class="white--text caption">{{ tables[merge_table_id].name }}</v-system-bar>

              <v-col style="max-width: 400px;">
                <v-select
                    label="Merge Column Name"
                    v-model="input_tables_info[merge_table_id].merge_col_uid"
                    :items="input_tables_info[merge_table_id].columns"
                    item-text="col_name"
                    item-value="col_uid"
                    :hide-details="true"
                ></v-select>
              </v-col>

              <v-col>
                <v-select
                    label="Included Columns"
                    v-model="input_tables_info[merge_table_id].selected_col_uids"
                    :items="input_tables_info[merge_table_id].columns"
                    @change="is_merge_checked=false; join_json_arrays=null"
                    item-text="col_name"
                    item-value="col_uid"
                    deletable-chips
                    multiple
                    small-chips
                    :hide-details="true"
                ></v-select>
              </v-col>

              <v-col style="max-width: 400px;">
                <v-text-field
                    outlined
                    dense
                    v-model="input_tables_info[merge_table_id].prefix"
                    label="Columns Prefix (optional)"
                    :hide-details="true"
                ></v-text-field>
              </v-col>
              <v-col style="max-width: 400px;">
                <v-text-field
                    outlined
                    dense
                    v-model="input_tables_info[merge_table_id].suffix"
                    label="Columns Suffix (optional)"
                    :hide-details="true"
                ></v-text-field>
              </v-col>

              <v-btn small @click="show_table_table(merge_table_id)">
                <v-icon color="green" left>mdi-table</v-icon>
                Show Table Data
              </v-btn>
              <v-btn small @click="show_table_schema(merge_table_id)">
                <v-icon color="green" left>mdi-table-cog</v-icon>
                Show Table Schema
              </v-btn>

            </v-card>
          </v-card-text>
        </v-card>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3" v-if="merge_table_ids.length > 0">
          <v-system-bar color="green" class="white--text caption">Step 4: Validate and Merge</v-system-bar>
          <v-card-text>
            <v-col>
              <div style="margin-bottom: 5px;">
                <v-btn
                    small
                    :disabled="merge_table_ids.length === 0"
                    @click="check_merge_data"
                    color="orange" class="white--text"
                >
                  <v-icon left>mdi-table-check</v-icon>
                  Validate
                </v-btn>
              </div>

              <div style="margin-bottom: 5px;">
                <v-btn
                    small
                    :disabled="!is_merge_checked || errors_list.length > 0"
                    @click="do_join_operation"
                    color="pink" class="white--text"
                >
                  <v-icon left>mdi-merge</v-icon>
                  Merge Layer and Tables
                </v-btn>
              </div>
              <div style="margin-bottom: 5px;" v-if="join_json_arrays !== null">
                <v-btn small @click="show_join_table">
                  <v-icon color="purple" left>mdi-table</v-icon>
                  Show Merged Table Data
                </v-btn>
              </div>
              <div style="margin-bottom: 5px;" v-if="join_json_arrays !== null">
                <v-btn small @click="show_join_schema">
                  <v-icon color="purple" left>mdi-table-cog</v-icon>
                  Show Merged Table Schema
                </v-btn>
              </div>
            </v-col>

            <p class="caption red--text" v-if="errors_list.length > 0">
              <strong>Errors:</strong><br>
              <b v-for="error in errors_list" :key="error">
                {{ error }}<br>
              </b>

            </p>
          </v-card-text>
        </v-card>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3" v-if="join_json_arrays !== null">
          <v-system-bar color="green" class="white--text caption">Step 5: Save the results</v-system-bar>
          <v-card-text>

            save to current layer or save as a new layer

            <v-radio-group
                v-model="save_option"
                :hide-details="true"
                label="Save Results To:"
            >
              <v-radio class="caption"
                       label="Current Layer"
                       color="green"
                       value="Current Layer"
              ></v-radio>
              <v-radio class="caption"
                       label="New Layer"
                       color="orange"
                       value="New Layer"
              ></v-radio>
            </v-radio-group>

            <v-col v-if="save_option === 'New Layer'" style="max-width: 400px;">

              <v-text-field
                  v-model="new_layer_name"
                  label="New Layer Name"
                  outlined
                  dense
                  :hide-details="true"
              ></v-text-field>

              <!--              <v-switch-->
              <!--                  v-if="map_id !== null"-->
              <!--                  v-model="add_new_layer_to_map"-->
              <!--                  dense-->
              <!--                  :hide-details="true"-->
              <!--                  label="Add new layer to current map"-->
              <!--              ></v-switch>-->
              <v-switch
                  :disabled="!($store.state.auth.isLoggedIn && $store.state.is_online)"
                  v-model="upload_new_layer"
                  dense
                  :hide-details="true"
                  label="Upload new layer to cloud"
              ></v-switch>
            </v-col>

          </v-card-text>
        </v-card>


        <DriveTableViewDialog
            v-if="DriveTableViewDialog_show"
            :show_dialog.sync="DriveTableViewDialog_show"
            :callback_function="null"
            :the_title="active_table_name"
            :json_arrays="active_table_json_arrays"
            :schema="active_table_schema"
        ></DriveTableViewDialog>

        <DriveSchemaDialog
            v-if="DriveSchemaDialog_show"
            :show_dialog.sync="DriveSchemaDialog_show"
            :update_schema_callback="null"
            :schema="active_table_schema"
            :json_arrays="active_table_json_arrays"
            :idx="null"
        ></DriveSchemaDialog>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               :disabled="is_loading || !is_merge_created"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";
import DriveSchemaDialog from "@/components/Drive/DriveSchemaDialog";
import {c_qfDocument_vector_layer, get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import * as aq from 'arquero';
import {deepClone} from "@/services/generic";

export default {
  name: "MapsVectorLayerMergeTableDialog",
  props: {
    show_dialog: {type: Boolean},
    update_current_layer_callback_function: {type: Function},
    layer_obj: {type: Object},
    layer_json_arrays: {type: Object},
    layer_schema: {type: Object},
    map_id: {type: String, default: null},
  },
  components: {DriveTableViewDialog, DriveSchemaDialog},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },
      is_loading: true,

      save_option: 'Current Layer', // 'Current Layer' or 'New Layer'
      new_layer_name: this.layer_obj.name + ' - Merged',
      upload_new_layer: false,
      add_new_layer_to_map: false,

      tables: {},

      layer_columns: [],
      layer_column_uid: null,

      input_tables_list: [],
      input_tables_info: {},
      merge_table_ids: [],

      join_json_arrays: null,
      join_schema: null,

      active_table_json_arrays: {},
      active_table_schema: {},
      active_table_name: null,

      is_merge_checked: false,
      is_merge_created: false,
      errors_list: [],

      DriveTableViewDialog_show: false,
      DriveSchemaDialog_show: false,

    }
  },
  methods: {

    async show_layer_table() {
      this.active_table_json_arrays = this.layer_json_arrays
      this.active_table_schema = this.layer_schema
      this.active_table_name = this.layer_obj.name
      this.DriveTableViewDialog_show = true
    },
    async show_layer_schema() {
      this.active_table_json_arrays = this.layer_json_arrays
      this.active_table_schema = this.layer_schema
      this.active_table_name = this.layer_obj.name
      this.DriveSchemaDialog_show = true
    },
    async show_table_table(table_id) {
      this.active_table_json_arrays = await idb_unzip_blob(table_id)
      this.active_table_schema = this.tables[table_id].config.schema
      this.active_table_name = this.tables[table_id].name
      this.DriveTableViewDialog_show = true
    },
    async show_table_schema(table_id) {
      this.active_table_json_arrays = await idb_unzip_blob(table_id)
      this.active_table_schema = this.tables[table_id].config.schema
      this.active_table_name = this.tables[table_id].name
      this.DriveSchemaDialog_show = true
    },
    async show_join_table() {
      this.active_table_json_arrays = this.join_json_arrays
      this.active_table_schema = this.join_schema
      this.active_table_name = 'Merged Data'
      this.DriveTableViewDialog_show = true
    },
    async show_join_schema() {
      this.active_table_json_arrays = this.join_json_arrays
      this.active_table_schema = this.join_schema
      this.active_table_name = 'Merged Data'
      this.DriveSchemaDialog_show = true
    },

    async check_merge_data() {
      // check if form is correctly filled
      let errors_list = []
      if (this.layer_column_uid === null) errors_list.push(`'Layer Column Name' is empty`)
      if (this.merge_table_ids.length === 0) errors_list.push(`No merge table is selected`)

      for (let merge_table_id of this.merge_table_ids) {
        if (this.input_tables_info[merge_table_id].merge_col_uid === null) {
          errors_list.push(`'Merge Column Name' is empty for ${this.tables[merge_table_id].name} table`)
        }
        if (this.input_tables_info[merge_table_id].selected_col_uids.length === 0) {
          errors_list.push(`'Included Columns' is empty for ${this.tables[merge_table_id].name} table`)
        }
      }
      console.log(errors_list)
      this.errors_list = errors_list
      this.is_merge_checked = true
      this.join_json_arrays = null
      this.join_schema = null
      this.is_merge_created = true
    },

    async do_join_operation() {


      let join_schema = deepClone(this.layer_schema)
      let layer_json_arrays = deepClone(this.layer_json_arrays)
      layer_json_arrays.layer_join_col = deepClone(layer_json_arrays[this.layer_column_uid])
      for (let idx in layer_json_arrays.layer_join_col) {
        layer_json_arrays.layer_join_col[idx] = String(layer_json_arrays.layer_join_col[idx])
      }

      let join_td = aq.table(layer_json_arrays)

      for (let merge_table_id of this.merge_table_ids) {

        let json_arrays = await idb_unzip_blob(merge_table_id)

        json_arrays[`row_uid__${merge_table_id}`] = json_arrays.row_uid
        delete json_arrays.row_uid

        let merge_col_uid = this.input_tables_info[merge_table_id].merge_col_uid
        let table_join_col_name = `${merge_table_id}_join_col`

        json_arrays[table_join_col_name] = deepClone(json_arrays[merge_col_uid])
        for (let idx in json_arrays[table_join_col_name]) {
          json_arrays[table_join_col_name][idx] = String(json_arrays[table_join_col_name][idx])
        }

        join_td = join_td.join_left(aq.table(json_arrays), ['layer_join_col', table_join_col_name])
      }

      let join_json_arrays0 = join_td.data()

      let join_json_arrays = {}
      for (let col_uid in this.layer_schema) {
        if (col_uid in join_json_arrays0) {
          join_json_arrays[col_uid] = join_json_arrays0[col_uid].data
        }
      }
      for (let merge_table_id of this.merge_table_ids) {
        for (let col_uid of this.input_tables_info[merge_table_id].selected_col_uids) {
          if (col_uid in join_json_arrays0) {
            join_schema[col_uid] = this.input_tables_info[merge_table_id].schema[col_uid]

            let col_label = join_schema[col_uid].label
            let prefix = this.input_tables_info[merge_table_id].prefix
            let suffix = this.input_tables_info[merge_table_id].suffix
            if (prefix !== null) col_label = `${prefix}${col_label}`
            if (suffix !== null) col_label = `${col_label}${suffix}`
            join_schema[col_uid].label = col_label

            join_json_arrays[col_uid] = join_json_arrays0[col_uid].data
          }
        }
      }

      this.join_json_arrays = join_json_arrays
      this.join_schema = join_schema
      this.is_merge_created = true
    },

    async submit() {
      this.is_loading = true
      if (this.save_option === 'Current Layer') {
        await this.update_current_layer_callback_function(this.join_json_arrays, this.join_schema)
      }
      if (this.save_option === 'New Layer') {
        let map_id = this.map_id
        if (this.add_new_layer_to_map) map_id = this.map_id

        let layer_config = deepClone(this.layer_obj.config)
        layer_config.schema = this.join_schema

        await c_qfDocument_vector_layer(
            this.new_layer_name,
            this.layer_obj.config.feature_type,
            this.join_json_arrays,
            map_id,
            this.upload_new_layer,
            layer_config)

      }
      this.is_loading = false

      this.cancel()

    },
    cancel() {
      this.is_loading = true
      this.$emit('update:show_dialog', false)
      this.is_loading = false
    },


    async init() {
      this.is_loading = true
      this.tables = await get_qfDocument_type_as_dict('table')

      this.input_tables_list = []
      this.input_tables_info = {}

      for (let table_id in this.tables) {
        this.input_tables_list.push({
          table_id: this.tables[table_id].id,
          table_name: this.tables[table_id].name,
        })


        let table_obj = this.tables[table_id]
        let table_schema = table_obj.config.schema
        let table_columns = []
        let selected_col_uids = []
        for (let col_uid in table_schema) {
          if (!table_schema[col_uid].is_system) {
            table_columns.push({
              col_name: table_schema[col_uid].label,
              col_uid: col_uid
            })
          }
          selected_col_uids.push(col_uid)
        }

        this.input_tables_info[table_id] = {
          schema: table_schema,
          columns: table_columns,
          merge_col_uid: null,
          selected_col_uids: selected_col_uids,
          prefix: null,
          suffix: null,
        }

      }

      for (let col_uid in this.layer_schema) {
        if (!this.layer_schema[col_uid].is_system) {
          this.layer_columns.push({
            col_name: this.layer_schema[col_uid].label,
            col_uid: col_uid
          })
        }
      }

      this.is_loading = false
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
