<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card v-if="is_init">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left :color="'#9400d3'">mdi-draw</v-icon>
          <span class="body-1">
            Draw Vector Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-col style="max-width: 400px;">
          <v-text-field
              outlined
              dense
              v-model="layer_obj.name"
              label="Name"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-select
              dense
              outlined
              v-model="layer_obj.config.feature_type"
              :items="feature_types"
              item-text="title"
              item-value="feature_type"
              label="Layer type"
              :hide-details="true"
          >
            <template v-slot:selection="{item}">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-text-field
              outlined
              type="number"
              min="3"
              max="12"
              dense
              v-model="layer_obj.config.coordinate_precision"
              label="Coordinate Precision Digits"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-divider></v-divider>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="layer_obj.config.show_description_and_comment"
              :hide-details="true"
              dense
              label="Add description and comments"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              rows="1"
              clearable
              dense
              auto-grow
              v-model="layer_obj.config.description"
              label="Description"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              dense
              rows="1"
              clearable
              auto-grow
              v-model="layer_obj.config.comments"
              label="Comments"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="layer_obj.config.stage.show"
              :hide-details="true"
              dense
              label="Show stage"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.stage.show">
          <v-select
              outlined
              dense
              v-model="layer_obj.config.stage.stage"
              :items="stages"
              item-text="stage"
              item-value="stage"
              label="Stage"
              :hide-details="true"
          >
            <template v-slot:selection="{item}">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
<!--        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">-->
<!--          <v-switch-->
<!--              :disabled="!($store.state.auth.isLoggedIn && $store.state.is_online)"-->
<!--              v-model="upload_blob"-->
<!--              :hide-details="true"-->
<!--              dense-->
<!--              label="Upload to cloud"-->
<!--          ></v-switch>-->
<!--        </v-col>-->

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               @click="submit"
               :disabled="layer_obj.name.length < 1"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {c_qfDocument_vector_layer} from "@/services/app_utils";
import {get_utc_dt_iso} from "@/services/datetime_api";
import {deepClone} from "@/services/generic";
import {templates} from "@/store";

export default {
  name: "DriveVectorLayerCreateDialog",
  props: {
    show_dialog: {type: Boolean},
    map_id: {type: String},
    callback_function: {type: Function},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      is_init: false,

      layer_obj: {},
      upload_blob: true,

      feature_types: [
        {title: 'Point', feature_type: 'Point', icon: 'mdi-map-marker', color: '#ff7f50'},
        {title: 'Line', feature_type: 'Line', icon: 'mdi-vector-polyline', color: '#ff00ff'},
        {title: 'Polygon', feature_type: 'Polygon', icon: 'mdi-vector-polygon', color: '#800080'},
      ],
      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],
    }
  },
  methods: {

    async submit() {

      let json_arrays = {geometry: [], row_uid: []}

      await c_qfDocument_vector_layer(
          this.layer_obj.name,
          this.layer_obj.config.feature_type,
          json_arrays,
          this.map_id,
          this.upload_blob,
          this.layer_obj.config)

      if (this.callback_function !== null) {
        await this.callback_function(this.map_id)
      }

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    init() {
      this.layer_obj = deepClone(templates.qfDocument)
      this.layer_obj.config = deepClone(templates.qfDocument__config__templates.layer)
      this.layer_obj.type = 'layer'
      this.layer_obj.name = 'New Vector Layer'
      this.layer_obj.config.feature_type = 'Line'
      this.layer_obj.config.current_state = 'Create'
      this.layer_obj.config.edit_state.uid = this.$store.state.auth.user.uid
      this.layer_obj.config.edit_state.utc_iso_dt = get_utc_dt_iso()
      this.layer_obj.config.schema = {
        row_uid: {label: 'row_uid', type: 'String', is_system: true, config: {precision: null, timezone: null,}},
        geometry: {label: 'geometry', type: 'Object', is_system: true, config: {precision: null, timezone: null,}},
      }
      this.is_init = true
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>