import {parse} from "json2csv";

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export function f(num) {
    return parseFloat(num)
}

export function round(num, digits) {
    const d = 10 * digits
    return Math.round((num + Number.EPSILON) * d) / d
}

export function r2(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export async function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

export async function get_random_color() {
    return random_colors_list[await getRandomIntInclusive(0, random_colors_list.length - 1)].color_val
}

export async function get_random_int(min, max) {
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}


export async function delete_by_value(arr_list, del_value) {
    return arr_list.filter(item => item !== del_value)
}

export async function trv_dt(dict_obj) {

    function process_dt(o, k, v) {
        const k_arr = k.split('_')
        if (k_arr.length > 1) {
            let dtel = k_arr.slice(-1)[0]
            if (dtel === 'dt' && v !== null) {
                v = new Date(v);    // to convert to miliseconds
                o[k] = v;
            }

        }

    }

    function isArray(o) {
        return Object.prototype.toString.call(o) === '[object Array]'
    }

    function traverseArray(arr) {
        arr.forEach(function (x) {
            traverse(x)
        })
        return arr
    }

    function traverseObject(obj) {
        for (const [key, value] of Object.entries(obj)) {
            process_dt(obj, key, value);
            traverse(value)
        }
        return obj
    }

    function traverse(x) {
        if (isArray(x)) {
            x = traverseArray(x)
        } else if (typeof x === 'object' && x !== null) {
            x = traverseObject(x)
        }
        return x
    }

    return traverse(dict_obj);

}

export async function readFileAsync(file, file_type) {
    // filetypes: text, array_buffer, binary_string
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;

        switch (file_type) {
            case 'text':
                reader.readAsText(file);
                break;
            case 'array_buffer':
                reader.readAsArrayBuffer(file);
                break;
            case 'binary_string':
                reader.readAsBinaryString(file);
                break;
            case 'url':
                reader.readAsDataURL(file);
                break;
            default:
                reader.readAsText(file);
                break;
        }

    })
}

export function unique_array(arr) {
    return [...new Set(arr)]
}

export function remove_from_list(items_list, item_value) {
    items_list = items_list.filter((e) => e !== item_value)
    return items_list
}

export async function csv_2_arr(csv_file, has_header = false, col_names = []) {
    let csv_str = await readFileAsync(csv_file, 'text')

    let rows_str = csv_str.split('\n')
    if (has_header) {
        col_names = rows_str[0].replace('\r', '').replaceAll('\"', '').split(',')
        rows_str.shift()
    }
    let json_arr = []
    for (let row_str of rows_str) {
        if (row_str.replaceAll(',', '').replaceAll('\r', '').replaceAll(' ', '').length > 1) { // check if empty
            let row_arr = row_str.split(',')
            let json_item = {}
            for (let col_idx in col_names) {
                let col_name = col_names[col_idx]
                json_item[col_name] = row_arr[col_idx].replace('\r', "").replaceAll('\"', '')
            }
            json_arr.push(json_item)
        }

    }
    return {json_arr, col_names}
}


export async function export_json_2_csv(objArray, file_name) {

    objArray = deepClone(objArray)

    let csv = parse(objArray)

    let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

    let hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(blob);
    hiddenElement.target = '_blank';
    hiddenElement.download = file_name;
    hiddenElement.click();

}

export async function export_raster(layer_blob, file_name) {


    let blob = new Blob([layer_blob], {type: 'image/tiff'});

    let hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(blob);
    hiddenElement.target = '_blank';
    hiddenElement.download = file_name;
    hiddenElement.click();

}

export async function get_ha_ac(area_m2) {
    let area_ha = r2(area_m2 / 10000);
    let area_ac = r2(area_m2 * 0.000247105);
    return {area_ha, area_ac}
}

const random_colors_list = [
    {
        "color_name": "lime",
        "color_val": "#00ff00"
    },
    {
        "color_name": "coral",
        "color_val": "#ff7f50"
    },
    {
        "color_name": "gold",
        "color_val": "#ffd700"
    },
    {
        "color_name": "magenta",
        "color_val": "#ff00ff"
    },
    {
        "color_name": "maroon",
        "color_val": "#800000"
    },
    {
        "color_name": "olive",
        "color_val": "#808000"
    },
    {
        "color_name": "orange",
        "color_val": "#ffa500"
    },
    {
        "color_name": "orchid",
        "color_val": "#da70d6"
    },
    {
        "color_name": "purple",
        "color_val": "#800080"
    },
    {
        "color_name": "chocolate",
        "color_val": "#d2691e"
    },
    {
        "color_name": "red",
        "color_val": "#ff0000"
    },
    {
        "color_name": "salmon",
        "color_val": "#fa8072"
    },
    {
        "color_name": "blue",
        "color_val": "#0000ff"
    },
    {
        "color_name": "brown",
        "color_val": "#a52a2a"
    },
    {
        "color_name": "cyan",
        "color_val": "#00ffff"
    },
    {
        "color_name": "green",
        "color_val": "#008000"
    },
    {
        "color_name": "teal",
        "color_val": "#008080"
    },
    {
        "color_name": "violet",
        "color_val": "#ee82ee"
    },
    {
        "color_name": "yellow",
        "color_val": "#ffff00"
    },
    {
        "color_name": "yellowgreen",
        "color_val": "#9acd32"
    },
    {
        "color_name": "aqua",
        "color_val": "#00ffff"
    },
    {
        "color_name": "aquamarine",
        "color_val": "#7fffd4"
    },
    {
        "color_name": "blueviolet",
        "color_val": "#8a2be2"
    },
    {
        "color_name": "cadetblue",
        "color_val": "#5f9ea0"
    },
    {
        "color_name": "chartreuse",
        "color_val": "#7fff00"
    },
    {
        "color_name": "cornflowerblue",
        "color_val": "#6495ed"
    },
    {
        "color_name": "crimson",
        "color_val": "#dc143c"
    },
    {
        "color_name": "darkblue",
        "color_val": "#00008b"
    },
    {
        "color_name": "darkcyan",
        "color_val": "#008b8b"
    },
    {
        "color_name": "darkgoldenrod",
        "color_val": "#b8860b"
    },
    {
        "color_name": "darkgreen",
        "color_val": "#006400"
    },

    {
        "color_name": "darkmagenta",
        "color_val": "#8b008b"
    },
    {
        "color_name": "darkolivegreen",
        "color_val": "#556b2f"
    },
    {
        "color_name": "darkorange",
        "color_val": "#ff8c00"
    },
    {
        "color_name": "darkorchid",
        "color_val": "#9932cc"
    },
    {
        "color_name": "darkred",
        "color_val": "#8b0000"
    },
    {
        "color_name": "darksalmon",
        "color_val": "#e9967a"
    },

    {
        "color_name": "darkslateblue",
        "color_val": "#483d8b"
    },
    {
        "color_name": "darkslategray",
        "color_val": "#2f4f4f"
    },
    {
        "color_name": "darkslategrey",
        "color_val": "#2f4f4f"
    },
    {
        "color_name": "darkturquoise",
        "color_val": "#00ced1"
    },
    {
        "color_name": "darkviolet",
        "color_val": "#9400d3"
    },
    {
        "color_name": "deeppink",
        "color_val": "#ff1493"
    },
    {
        "color_name": "deepskyblue",
        "color_val": "#00bfff"
    },

    {
        "color_name": "dodgerblue",
        "color_val": "#1e90ff"
    },
    {
        "color_name": "firebrick",
        "color_val": "#b22222"
    },

    {
        "color_name": "forestgreen",
        "color_val": "#228b22"
    },
    {
        "color_name": "fuchsia",
        "color_val": "#ff00ff"
    },

    {
        "color_name": "goldenrod",
        "color_val": "#daa520"
    },
    {
        "color_name": "greenyellow",
        "color_val": "#adff2f"
    },

    {
        "color_name": "hotpink",
        "color_val": "#ff69b4"
    },
    {
        "color_name": "indianred",
        "color_val": "#cd5c5c"
    },
    {
        "color_name": "indigo",
        "color_val": "#4b0082"
    },

    {
        "color_name": "lawngreen",
        "color_val": "#7cfc00"
    },

    {
        "color_name": "lightcoral",
        "color_val": "#f08080"
    },

    {
        "color_name": "lightgreen",
        "color_val": "#90ee90"
    },

    {
        "color_name": "lightsalmon",
        "color_val": "#ffa07a"
    },
    {
        "color_name": "lightseagreen",
        "color_val": "#20b2aa"
    },
    {
        "color_name": "lightskyblue",
        "color_val": "#87cefa"
    },


    {
        "color_name": "orangered",
        "color_val": "#ff4500"
    },

    {
        "color_name": "peru",
        "color_val": "#cd853f"
    },

    {
        "color_name": "rebeccapurple",
        "color_val": "#663399"
    },

    {
        "color_name": "royalblue",
        "color_val": "#4169e1"
    },
    {
        "color_name": "saddlebrown",
        "color_val": "#8b4513"
    },
    {
        "color_name": "sandybrown",
        "color_val": "#f4a460"
    },
    {
        "color_name": "seagreen",
        "color_val": "#2e8b57"
    },

    {
        "color_name": "sienna",
        "color_val": "#a0522d"
    },

    {
        "color_name": "slateblue",
        "color_val": "#6a5acd"
    },

]
