<template>
  <v-navigation-drawer
      hide-overlay absolute permanent
      :app="false"
      :disable-resize-watcher="true"
      :disable-route-watcher="true"
      :mobile-breakpoint="0"
      :stateless="true"
      class="fill-height"
      ref="theDrawer"
      :width="width"
      :right="!is_left"
      style="margin-left: 20px;"
  >
    <v-app-bar
        flat dense
        height="32"
        color="white"
        v-if="is_left"
    >
      <v-btn icon small color="black" @click="decreaseDrawerWidth">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon small color="black" @click="increaseDrawerWidth">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon small color="black" @click="hideDrawer">
        <v-icon>mdi-chevron-double-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar
        flat dense
        color="white"
        v-else>

      <v-btn v-if="drawer_type==='FeatureInfo'" icon small color="black" @click="close_identify">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn v-else icon small color="black" @click="hideDrawer">
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon small color="black" @click="decreaseDrawerWidth">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon small color="black" @click="increaseDrawerWidth">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>


    </v-app-bar>

    <v-col v-if="drawer_type==='MapLayersList'">
      <MapsLayersList></MapsLayersList>
    </v-col>
    <v-col v-if="drawer_type==='FeatureInfo' && $store.state.feature_info_drawer.show">
      <MapsVectorLayerFeatureInfo></MapsVectorLayerFeatureInfo>
    </v-col>

    <v-col v-else>

    </v-col>

  </v-navigation-drawer>
</template>

<script>
import MapsLayersList from "@/components/Maps/MapsLayersList";
import MapsVectorLayerFeatureInfo from "@/components/Maps/MapsVectorLayerFeatureInfo";

export default {
  name: "MapsDrawer",
  components: {MapsLayersList, MapsVectorLayerFeatureInfo},
  props: {
    drawer_type: {type: String},
    show_drawer: {type: Boolean},
  },
  data() {
    return {
      width: '350px',
      borderSize: 10,
      is_left: false,
    }
  },
  methods: {
    close_identify() {
      this.$emit('update:show_drawer', false)
      this.$store.state.identify_flag = false
      this.$store.state.feature_info_drawer.read_as_dict = false
    },
    hideDrawer() {
      this.$emit('update:show_drawer', false)
    },
    async setDrawerBorderWidth() {
      let i = this.$refs.theDrawer.$el.querySelector(
          ".v-navigation-drawer__border"
      );
      i.style.width = this.borderSize + "px";
      i.style.backgroundColor = "white";
      i.style.cursor = "ew-resize";


    },
    setEvents() {
      const minSize = this.borderSize;
      const el = this.$refs.theDrawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const direction = el.classList.contains("v-navigation-drawer--right")
          ? "right"
          : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
          "mousedown",
          (e) => {
            if (e.offsetX < minSize) {
              e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
      );

      document.addEventListener(
          "mouseup",
          (e) => {
            el.style.transition = '';
            this.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
      );
    },
    decreaseDrawerWidth() {
      let width = parseInt(this.width.replace('px', ''))
      if (this.is_left) {
        this.width = width - 10 + 'px'
      } else {
        this.width = width + 10 + 'px'
      }

    },
    increaseDrawerWidth() {

      let width = parseInt(this.width.replace('px', ''))

      if (this.is_left) {
        this.width = width + 10 + 'px'
      } else {
        this.width = width - 10 + 'px'
      }

      // this.width = width + 10 + 'px'
    },
    setPanelWidth() {
      if (screen.width < 500) {
        this.width = 350
      }
      if (this.$vuetify.breakpoint.mobile) {
        this.width = 200
      }
    },
    async init() {
      switch (this.drawer_type) {
        case 'MapLayersList':
          this.is_left = true;
          break;
        case 'FeatureInfo':
          this.is_left = false
          this.width = 150
          break;
        default:
          break;
      }
      await this.setPanelWidth()
      await this.setDrawerBorderWidth()
      await this.setEvents()
    },

  },
  mounted() {
    this.init()


  }
}
</script>

<style scoped>

</style>
