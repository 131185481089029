<template>
  <v-container>

    <v-col style="max-width: 400px;">
      <v-autocomplete
          v-model="selected_table_id"
          :items="input_tables_list"
          @change="on_select_table"
          dense
          small-chips
          deletable-chips
          clearable
          label="Select Link Table"
          item-text="table_name"
          item-value="table_id"
          :hide-details="true"
      ></v-autocomplete>
    </v-col>

<!--    <v-col v-if="selected_table_id !== null">-->
<!--      <v-btn x-small @click="on_show_link_table()">-->
<!--        <v-icon small color="green" left>mdi-table</v-icon>-->
<!--        Preview Link Table-->
<!--      </v-btn>-->
<!--    </v-col>-->


    <v-col style="max-width: 500px;">
      <v-select
          label="Select Columns"
          v-model="selected_col_uids"
          @change="do_process"
          :items="col_list"
          item-text="col_name"
          item-value="col_uid"
          deletable-chips
          multiple
          small-chips
          clearable
          :hide-details="true"
      ></v-select>
    </v-col>

    <v-col style="max-width: 500px;">
      <v-select
          label="Select Statistics"
          v-model="selected_stats"
          @change="do_process"
          :items="stats"
          item-text="name"
          item-value="val"
          deletable-chips
          multiple
          small-chips
          clearable
          :hide-details="true"
      ></v-select>
    </v-col>
    <v-col>
      <v-autocomplete
          v-model="plot_color"
          :items="colors_list"
          dense
          chips
          label="Color"
          item-text="color_name"
          item-value="color_val"
          @change="do_process"
          :hide-details="true"
          style="max-width: 400px;"
      >
        <template v-slot:selection="data">
          <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
          >
            <v-avatar left>
              <v-btn icon>
                <v-icon :color="data.item.color_val">mdi-circle</v-icon>
              </v-btn>
            </v-avatar>
            {{ data.item.color_name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-btn icon>
              <v-icon :color="data.item.color_val">mdi-circle</v-icon>
            </v-btn>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>

    </v-col>

    <v-col>
      <v-radio-group
          label="Type:"
          v-model="plot_type"
          :hide-details="true"
          @change="do_process"
          row>
        <v-radio class="caption"
                 label="Line"
                 color="green"
                 value="line"
        ></v-radio>
        <v-radio class="caption"
                 label="Bar"
                 color="orange"
                 value="bar"
        ></v-radio>
      </v-radio-group>

      <v-radio-group
          label="Orientation:"
          v-model="plot_orientation"
          :hide-details="true"
          @change="do_process"
          row>
        <v-radio class="caption"
                 label="Horizontal"
                 color="orange"
                 value="h"
        ></v-radio>
        <v-radio class="caption"
                 label="Vertical"
                 color="green"
                 value="v"
        ></v-radio>
      </v-radio-group>

      <v-switch
          style="max-width: 400px;"
          @change="do_process"
          v-model="reverse"
          dense
          :hide-details="true"
          label="Reverse Axis"
      ></v-switch>

       <v-switch
          style="max-width: 400px;"
          v-model="show_table"
          dense
          :hide-details="true"
          label="Show Table"
      ></v-switch>
    </v-col>

    <TablesSimpleTable
        v-if="table_data.all.length > 0 && show_table"
        :header_pairs_list.sync="header_pairs_list"
        :table_data.sync="table_data.all"
    ></TablesSimpleTable>

    <v-card
        v-for="(dict, key) in plot_data " :key="key"
        max-width="500"
        style="margin-bottom: 10px;"
    >
      <v-card-title>{{ dict.name }}</v-card-title>
      <v-card-text>
        <div v-bind:id="key"></div>
      </v-card-text>
    </v-card>


  </v-container>
</template>

<script>

import TablesSimpleTable from "@/components/Tables/TablesSimpleTable";
import {newPlot} from 'plotly.js-dist-min'
import * as aq from "arquero";
import {colors_list} from "@/assets/data/qf_static_data";
import {filter_json_arrays_for_row_uids} from "@/services/json_arrays_api";
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";

export default {
  name: "PlotsLinkedTableSummaryStat",
  props: {
    json_arrays: {type: Object},
    schema: {type: Object},
    selected_uid_list: {type: Array},
    map_layer_dict: {type: Object},
  },
  components: {TablesSimpleTable},
  data() {
    return {

      tables: {},
      input_tables_list: [],
      selected_table_id: null,

      td: null,
      td_selected: null,
      colors_list: colors_list,
      col_list: [],
      table_json_arrays: {},
      selected_col_uids: [],
      stats: [
        {name: 'Min.', val: 'min',},
        {name: 'Max.', val: 'max'},
        {name: 'Average', val: 'average'},
        {name: 'Mean', val: 'mean'},
        {name: 'Median', val: 'median'},
        {name: 'Sum', val: 'sum'},

        {name: 'Count', val: 'count'},
        {name: 'Count unique values', val: 'distinct'},

        {name: 'Standard deviation (sample)', val: 'stdev'},
        {name: 'Standard deviation (population)', val: 'stdevp'},
        {name: 'Variance (sample)', val: 'variance'},
        {name: 'Variance (population)', val: 'variancep'},

        {name: 'Mode (most frequent)', val: 'mode'},

        {name: '1th quantile', val: 'quantile__0.01'},
        {name: '5th quantile', val: 'quantile__0.05'},
        {name: '10th quantile', val: 'quantile__0.1'},
        {name: '25th quantile', val: 'quantile__0.25'},
        {name: '50th quantile', val: 'quantile__0.5'},
        {name: '75th quantile', val: 'quantile__0.75'},
        {name: '90th quantile', val: 'quantile__0.9'},
        {name: '95th quantile', val: 'quantile__0.95'},
        {name: '99th quantile', val: 'quantile__0.99'},

      ],
      selected_stats: ['sum', 'mean'],

      summary_stats_cols: {},
      summary_stats_vars: {},

      plot_data: {},
      plot_type: 'line', // line, bar
      plot_color: '#9370db',
      plot_orientation: 'v',
      reverse: false,

      show_table: false,
      table_data: {
        all: [],
        selected: [],
      },
      header_pairs_list: [],

    }
  },
  methods: {

    async do_process() {
      console.log('in do_process')
      console.log(this.selected_col_uids)
      if (this.selected_col_uids.length > 0 && this.selected_stats.length > 0) {
        await this.calc_summary()
      }
    },


    async calc_summary() {

      // add selected option

      let summary_stats_cols = {}
      for (let col_uid of this.selected_col_uids) {
        summary_stats_cols[col_uid] = {}
        for (let stat of this.selected_stats) {
          let stat_arr = stat.split('__')
          let op_arg = aq.op[stat_arr[0]](col_uid)
          if (stat_arr.length === 2) op_arg = aq.op[stat_arr[0]](col_uid, stat_arr[1])
          // let x = await aq.agg(this.td, aq.op[stat](col_uid))
          // if col_uid is in the layer
          summary_stats_cols[col_uid][stat] = parseFloat(aq.agg(this.td, op_arg))

          // if col_uid is in the time series table,
          // select the date column
          // for each unique value in the date column get all data. Create
          // set

        }
      }
      console.log('🪲', summary_stats_cols)

      // Do similar for selected rows
      let summary_stats_cols_selected = {}
      for (let col_uid of this.selected_col_uids) {
        summary_stats_cols_selected[col_uid] = {}
        for (let stat of this.selected_stats) {
          let stat_arr = stat.split('__')
          let op_arg = aq.op[stat_arr[0]](col_uid)
          if (stat_arr.length === 2) op_arg = aq.op[stat_arr[0]](col_uid, stat_arr[1])
          // let x = await aq.agg(this.td, aq.op[stat](col_uid))
          summary_stats_cols_selected[col_uid][stat] = parseFloat(aq.agg(this.td_selected, op_arg))
        }
      }

      // loop over stat and get col_uids
      let summary_stats_vars = {}
      let summary_stats_vars_selected = {}
      let plot_data = {}

      this.table_data.all = []
      this.table_data.selected = []
      let table_data_all = []
      let table_data_selected = []
      this.header_pairs_list = [{field: 'stat', headerName: 'Stat.'}]
      for (let col_uid of this.selected_col_uids) {
        this.header_pairs_list.push({
          field: this.schema[col_uid].label,
          headerName: this.schema[col_uid].label,
        })
      }

      for (let stat of this.selected_stats) {

        let stat_name = this.stats.filter(e => e.val === stat)[0].name

        summary_stats_vars[stat] = {}
        summary_stats_vars_selected[stat] = {}
        plot_data[stat] = {
          name: stat_name,
          data: null
        }
        let x = [], y = [];
        let x_selected = [], y_selected = [];
        let this_row = {stat: stat_name}
        let this_selected_row = {stat_name: stat_name}
        for (let col_uid of this.selected_col_uids) {
          summary_stats_vars[stat][col_uid] = summary_stats_cols[col_uid][stat]
          summary_stats_vars_selected[stat][col_uid] = summary_stats_cols_selected[col_uid][stat]

          x.push(this.schema[col_uid].label)
          y.push(summary_stats_cols[col_uid][stat])

          x_selected.push(this.schema[col_uid].label)
          y_selected.push(summary_stats_cols_selected[col_uid][stat])

          this_row[this.schema[col_uid].label] = summary_stats_cols[col_uid][stat]
          this_selected_row[this.schema[col_uid].label] = summary_stats_cols_selected[col_uid][stat]
        }
        table_data_all.push(this_row)
        table_data_selected.push(this_selected_row)

        if (this.reverse) {
          x = x.reverse()
          y = y.reverse()

          x_selected = x_selected.reverse()
          y_selected = y_selected.reverse()
        }
        console.log(stat_name)
        let data_dict = {
          x: x,
          y: y,
          type: this.plot_type,
          orientation: this.plot_orientation,
          name: stat_name,
          marker: {
            color: this.plot_color,
            width: 3
          }
        }

        let data_dict_selected = {
          x: x_selected,
          y: y_selected,
          type: this.plot_type,
          orientation: this.plot_orientation,
          name: stat_name + ' (selected)',
          marker: {
            color: 'red',
            width: 3
          }
        }

        if (this.plot_orientation === 'h') {
          data_dict.x = y
          data_dict.y = x

          data_dict_selected.x = y_selected
          data_dict_selected.y = x_selected
        }
        plot_data[stat].data = [data_dict]
        plot_data[stat].data.push(data_dict_selected)

      }
      console.log(summary_stats_vars)
      console.log(plot_data)
      console.log('🏓', this.table_data)
      this.plot_data = plot_data

      this.$set(this.table_data, 'all', table_data_all)
      this.$set(this.table_data, 'selected', table_data_selected)

      for (let stat_name in this.plot_data) {
        let data = plot_data[stat_name].data
        this.do_plot(stat_name, data, 100)
      }

      // plot data


    },
    do_plot(div_id, data, delay_ms) {
      setTimeout(() => {
        let layout = {
          // showlegend: false,
          autosize: true,
          xaxis: {
            showgrid: false,
            showline: false,
          },
          yaxis: {
            showgrid: false,
            showline: false,
          },
        }
        newPlot(div_id, data, layout)
      }, delay_ms)
    },
    async on_select_table() {
      console.log('on_select_table')
      console.log(this.selected_table_id)
      // load table
      this.table_json_arrays = await idb_unzip_blob(this.selected_table_id)

      await this.set_col_list()

    },

    async set_col_list() {
      this.col_list = []

      this.col_list.push({
        col_uid: null,
        col_name: 'Row Number'
      })
      for (let col_uid in this.schema) {
        if (!this.schema[col_uid].is_system) {
          this.col_list.push({
            col_uid: col_uid,
            col_name: this.schema[col_uid].label
          })
        }
      }

      if (this.selected_table_id !== null) {
        let table_schema = this.tables[this.selected_table_id].config.schema

        for (let col_uid in table_schema) {
          if (!table_schema[col_uid].is_system) {
            this.col_list.push({
              col_uid: col_uid,
              col_name: '🔗 ' + table_schema[col_uid].label
            })
          }
        }


      }

    },
    async init() {
      this.tables = await get_qfDocument_type_as_dict('table')
      for (let table_id in this.map_layer_dict.link) {
        this.input_tables_list.push({
          table_id: this.tables[table_id].id,
          table_name: this.tables[table_id].name,
        })
      }
      console.log(this.input_tables_list)

      this.td = aq.table(this.json_arrays)

      await this.set_col_list()

      let json_arrays_selected = await filter_json_arrays_for_row_uids(this.json_arrays, this.selected_uid_list)
      this.td_selected = aq.table(json_arrays_selected)

    },
  },
  watch: {
    json_arrays() {
      this.init()
    },
    schema() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
