<template>
  <v-card
      v-if="layer_obj !== null"
      :loading="is_loading"
      style="margin-bottom: 5px;"
      elevation="2">
    <v-app-bar
        flat
        dense
        height="auto"
        color="white"
    >
      <v-checkbox dense style="font-size: 6px;"
                  v-model="map_layer_dict.config.show_layer"
                  :label="layer_obj.name"
                  hide-details
                  @change="on_click_layer_checkbox"
      >
        <template v-slot:label>
          <div class="text-caption">
            <v-chip
                label small
                style="padding: 0;"
            >
              <v-icon small>
                mdi-pyramid
              </v-icon>
            </v-chip>
            {{ layer_obj.name }}
          </div>
        </template>
      </v-checkbox>

      <v-spacer></v-spacer>

      <v-btn
          icon
          small
          @click="switch_show_details"
      >
        <v-icon v-if="!map_layer_dict.config.show_details">mdi-chevron-down</v-icon>
        <v-icon v-if="map_layer_dict.config.show_details">mdi-chevron-up</v-icon>
      </v-btn>
    </v-app-bar>

    <div v-if="map_layer_dict.config.show_details">
      <v-container>
        <MapsTileLayerTools
            v-if="layer_tools_show_mode !== 'none' && !is_loading"
            :layer_tools_show_mode.syc="layer_tools_show_mode"
            :on_select_tool="on_select_tool"
        ></MapsTileLayerTools>

        <v-col>
          <v-autocomplete
              @change="on_change_layer"
              dense
              outlined
              v-model="tile_options.layers"
              :items="wms_gc_json.Capability.Layer.Layer"
              item-text="Title"
              item-value="Name"
              label="Layer"
              :hide-details="true"
          ></v-autocomplete>
        </v-col>

        <v-col>
          <v-autocomplete
              @change="on_change_style"
              v-if="tile_options.layers !== null"
              dense
              outlined
              v-model="tile_options.styles"
              :items="styles_list"
              item-text="Title"
              item-value="Name"
              label="Style"
              :hide-details="true"
          ></v-autocomplete>
        </v-col>

        <v-subheader class="pl-0">
          Opacity
        </v-subheader>
        <v-slider
            style="max-width: 300px;"
            v-model="tile_options.opacity"
            min="0"
            max="1"
            step="0.05"
            color="red"
            thumb-color="blue"
            thumb-label="always"
            prepend-icon="mdi-opacity"
            @change="set_layer_opacity"
        ></v-slider>
        <div v-if="time_list.length > 0">
          <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="selected_time"
                  label="Select Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="selected_time"
                :allowed-dates="allowedDates"
                @input="date_menu = false"
                @change="set_layer_date"
                no-title
                scrollable
                show-adjacent-months
            >
            </v-date-picker>
          </v-menu>

        </div>

        <div>
          <v-img
              v-if="legend_obj.has_legend"
              max-width="400"
              contain
              :src="legend_obj.img_url"
          ></v-img>
        </div>
      </v-container>


      <MapsConfirmDeleteLayerFromMapDialog
          v-if="MapsConfirmDeleteLayerFromMapDialog_show"
          :show_dialog.sync="MapsConfirmDeleteLayerFromMapDialog_show"
          :layer_name="layer_obj.name"
          :callback_function="submit_delete"
      ></MapsConfirmDeleteLayerFromMapDialog>

    </div>

    <DriveAllocationWarning
        v-if="DriveAllocationWarning_show"
        :show_dialog.sync="DriveAllocationWarning_show"
    ></DriveAllocationWarning>

    <DriveTileLayerWMSDialog
        v-if="DriveTileLayerWMSDialog_show"
        :show_dialog.sync="DriveTileLayerWMSDialog_show"
        :action="DriveTileLayerWMSDialog_action"
        :map_id="map_id"
        :layer_id="layer_id"
        :refresh_map_function="layers_list_init_function"
        :refresh_qfdrive_function="null"
    ></DriveTileLayerWMSDialog>

  </v-card>
</template>

<script>

import axios from "axios";
import {DateTime} from "luxon";
import {tileLayer, CRS} from 'leaflet'
import WMSCapabilities from 'ol/format/WMSCapabilities';
import MapsTileLayerTools from "@/components/Maps/MapsTileLayerTools";
import MapsConfirmDeleteLayerFromMapDialog from "@/components/Maps/MapsConfirmDeleteLayerFromMapDialog";
import DriveTileLayerWMSDialog from "@/components/Drive/DriveTileLayerWMSDialog";
import DriveAllocationWarning from "@/components/Drive/DriveAllocationWarning";

import {
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
} from "@/services/app_utils";
import {d_qfDocument, d_qfDocument_from_qfCollection} from "@/services/app_api";
import {deepClone} from "@/services/generic";
import {store, templates} from "@/store";


export default {
  name: "MapsTileLayerWMS",
  components: {
    MapsTileLayerTools,
    MapsConfirmDeleteLayerFromMapDialog,
    DriveTileLayerWMSDialog,
    DriveAllocationWarning,
  },
  props: {
    map_id: {type: String},
    layer_id: {type: String},
    z_index: {type: Number},
    layers_list_init_function: {type: Function},
    update_map_layer_dict: {type: Function},
  },
  data() {
    return {

      layer_obj: null,  // layer object
      map_layer_dict: null, // = maps[this.map_id].layers.dict[this.layer_id]

      tile_obj: null,
      tile_options: null,

      MapsConfirmDeleteLayerFromMapDialog_show: false,
      layer_tools_show_mode: 'all', // all, none, top_only

      DriveAllocationWarning_show: false,
      is_loading: false,

      DriveTileLayerWMSDialog_show: false,
      DriveTileLayerWMSDialog_action: 'Edit',

      wms_gc_json: null,
      wms_layer_object: {},
      styles_list: [],
      time_list: [],
      selected_time: null,
      date_menu: false,

      layer_options: {},
      legend_obj: {
        has_legend: false,
        name: null,
        img_b64: null,
        img_url: null,
        width: null,
        height: null,
        format: null,
      }
    }
  },
  methods: {

    async on_change_layer() {
      this.wms_layer_object = await this.get_wms_layer_object(this.wms_gc_json, this.tile_options.layers)
      this.styles_list = this.wms_layer_object.Style

      let params_dict = {}
      params_dict.layers = this.tile_options.layers

      if (!this.styles_list.includes(this.tile_options.styles)) {
        this.tile_options.syles = this.styles_list[0].Name
        await this.set_style()
        params_dict.styles = this.tile_options.styles
      }

      // check if this.selected_date is in time_list
      this.time_list = await this.get_wms_layer_time_dimension(this.wms_layer_object)
      if (this.time_list.length > 0) {
        if (this.selected_time === null || !this.time_list.includes(this.selected_time)) {
          this.selected_time = this.time_list[this.time_list.length - 1]
          params_dict.time = DateTime.fromFormat(this.selected_time, 'yyyy-MM-dd', {zone: 'utc'}).toISO()
        }
      }

      this.tile_obj.setParams(params_dict)
    },
    async set_style() {
      let legend_url = null
      let wms_style_name = null
      for (let style_item of this.styles_list) {
        if (this.tile_options.styles === style_item.Name) {
          wms_style_name = style_item.Name
          if ('LegendURL' in style_item) {
            legend_url = style_item.LegendURL[0]
          }
          break;
        }
      }
      if (wms_style_name === null){
        this.tile_options.styles = this.styles_list[0].Name
        console.log('❓', this.tile_options.styles)
      }

      if (legend_url !== null) {
        this.legend_obj = {
          has_legend: true,
          format: legend_url.Format,
          width: legend_url.size[0],
          height: legend_url.size[1],
          img_url: legend_url.OnlineResource,
        }
      } else {
        this.legend_obj = {
          has_legend: false,
          format: null,
          width: null,
          height: null,
          img_url: null,
        }
      }
      this.tile_options.LegendURL = legend_url
    },

    async on_change_style() {
      await this.set_style()
      this.tile_obj.setParams({
        layers: this.tile_options.layers,
        styles: this.tile_options.styles,
      })
    },

    async get_wms_capabilities(url) {
      const parser = new WMSCapabilities();
      if (url.includes('?')) url = url.split('?')[0]
      url = `${url}?request=GetCapabilities&service=WMS`
      let resp = await axios.get(url)
      let wms_gc_json = null
      if (resp.status === 200) {
        let gc_xml = resp.data
        wms_gc_json = parser.read(gc_xml)
      }
      return wms_gc_json
    },
    async get_wms_layer_object(wms_gc_json, wms_layer_name) {
      let wms_layer_object = null
      for (let layer_item of wms_gc_json.Capability.Layer.Layer) {
        if (wms_layer_name === layer_item.Name) {
          wms_layer_object = layer_item
          break;
        }
      }
      return wms_layer_object
    },
    get_wms_layer_legend(wms_layer, style_name) {
      let legend_url = null
      if ('Style' in wms_layer) {
        for (let style_item of wms_layer.Style) {
          if (style_item.Name === style_name) {
            if ('LegendURL' in style_item) {
              legend_url = style_item.LegendURL[0]
              break
            }
          }
        }
      }

      if (legend_url !== null) {
        this.legend_obj = {
          has_legend: true,
          format: legend_url.Format,
          width: legend_url.size[0],
          height: legend_url.size[1],
          img_url: legend_url.OnlineResource,
        }
      } else {
        this.legend_obj = {
          has_legend: false,
          format: null,
          width: null,
          height: null,
          img_url: null,
        }
      }
    },

    async get_wms_layer_time_dimension(wms_layer) {
      let time_list = []
      if ('Dimension' in wms_layer) {
        if (wms_layer.Dimension.length > 0) {
          let dimension_item = wms_layer.Dimension[0]
          if (dimension_item.name === 'time') {
            time_list = wms_layer.Dimension[0].values.split(',')
            time_list = await this.iso_date_list_to_date_obj_list(time_list)
          }
        }
      }
      return time_list
    },
    async iso_date_list_to_date_obj_list(iso_date_list) {
      let date_obj_list = []
      for (let iso_date of iso_date_list) {
        date_obj_list.push(DateTime.fromISO(iso_date, {zone: 'utc'}).toUTC().toFormat('yyyy-MM-dd'))
      }
      return date_obj_list
    },
    async set_layer_date() {
      this.tile_obj.setParams({
        time: DateTime.fromFormat(this.selected_time, 'yyyy-MM-dd', {zone: 'utc'}).toISO()
      })
    },
    allowedDates(val) {
      if (this.time_list.includes(val)) return true
      return false
    },

    async process_leaflet_wms() {
      console.log('💩 in process_leaflet_wms')

      let tile_options_template = deepClone(templates.qfDocument__config__templates.layer.tile_layer_options.leaflet_wms)
      let layer_url = this.tile_options.url
      if (this.wms_gc_json === null) {
        this.wms_gc_json = await this.get_wms_capabilities(layer_url)
      }

      if (this.wms_gc_json !== null) {

        this.wms_layer_object = await this.get_wms_layer_object(this.wms_gc_json, this.tile_options.layers)

        if (this.styles_list.length === 0) {
          for (let layer_item of this.wms_gc_json.Capability.Layer.Layer) {
            if (this.tile_options.layers === layer_item.Name) {
              this.styles_list = layer_item.Style
              break;
            }
          }
        }

        this.time_list = await this.get_wms_layer_time_dimension(this.wms_layer_object)

        this.layer_options = {}
        if (this.time_list.length > 0) {
          if (this.selected_time === null || !this.time_list.includes(this.selected_time)) {
            this.selected_time = this.time_list[this.time_list.length - 1]
          }
          this.layer_options.time = DateTime.fromFormat(this.selected_time, 'yyyy-MM-dd', {zone: 'utc'}).toISO()
        }

        this.get_wms_layer_legend(this.wms_layer_object, this.tile_options.styles)

        if (this.tile_options.api_key_key !== null && this.tile_options.api_key_value !== null) {
          if (this.tile_options.api_key_key.length > 1 && this.tile_options.api_key_value.length > 1) {
            layer_url += `?${this.tile_options.api_key_key}=${this.tile_options.api_key_value}`
          }
        }

        if (this.tile_options.attribution !== null) {
          if (this.tile_options.attribution.length > 2) this.layer_options.attribution = this.tile_options.attribution
        }
        let forbidden_keys = ['url', 'api_key_key', 'api_key_value', 'attribution', 'LegendURL']
        for (let key in tile_options_template) {
          if (key in this.tile_options && !forbidden_keys.includes(key)) {
            let the_val = this.tile_options[key]
            let the_val_default = tile_options_template[key]

            if (the_val !== the_val_default) {
              this.layer_options[key] = the_val
            }
          }
        }

        if ('crs' in this.layer_options) {
          if (this.layer_options.crs !== null) {
            this.layer_options.crs = CRS[this.layer_options.crs]
          }
        }

        this.layer_options.zIndex = this.z_index
        this.layer_options.pane = this.layer_id

        if (this.$store.state.map.getPane(this.layer_id) === undefined) {
          this.$store.state.map.createPane(this.layer_id);
        }
        this.$store.state.map.getPane(this.layer_id).style.zIndex = this.z_index

        if (this.tile_obj !== null) {
          await this.fg_obj.removeFrom(this.$store.state.map)
          await this.tile_obj.remove()
        }

        this.tile_obj = await tileLayer.wms(layer_url, this.layer_options)

        if (this.map_layer_dict.config.show_layer) {
          await this.add_layer_to_map()
        }

      } else {
        console.log('Show error')
      }

    },

    set_layer_opacity() {
      this.tile_obj.setOpacity(this.tile_options.opacity)
    },

    async edit_layer() {
      this.DriveTileLayerWMSDialog_action = 'Edit'
      this.DriveTileLayerWMSDialog_show = true
    },

    async duplicate_layer() {
      this.DriveTileLayerWMSDialog_action = 'Duplicate'
      this.DriveTileLayerWMSDialog_show = true
    },
    async submit_delete(permanent_delete = false) {

      await this.remove_layer_from_map()
      if (permanent_delete) {
        await d_qfDocument(this.layer_id)
      } else {
        await d_qfDocument_from_qfCollection(this.map_id, this.layer_obj)
      }
      this.layers_list_init_function(this.map_id)
    },
    // Tools
    async on_select_tool(action, row_uid = null) {
      switch (action) {
        case 'delete':
          this.MapsConfirmDeleteLayerFromMapDialog_show = true;
          break;
        case 'edit_layer':
          await this.edit_layer()
          break;
        case 'duplicate_layer':
          await this.duplicate_layer()
          break;
        default:
          break;
      }
    },

    // layer on-off
    async on_click_layer_checkbox() {
      if (!this.map_layer_dict.config.show_layer) {
        await this.remove_layer_from_map()
      } else {
        await this.add_layer_to_map()
      }
      await this.do_update_map_layer_dict()
    },
    async do_update_map_layer_dict(save_map = false) {
      await this.update_map_layer_dict(this.layer_id, this.map_layer_dict, save_map)
    },
    async switch_show_details() {
      this.map_layer_dict.config.show_details = !this.map_layer_dict.config.show_details
      await this.do_update_map_layer_dict()
    },
    async remove_layer_from_map() {
      if (this.tile_obj !== null) {
        await this.tile_obj.removeFrom(this.$store.state.map)
      }
    },
    async add_layer_to_map() {
      if (this.tile_obj !== null) {
        await this.tile_obj.addTo(this.$store.state.map)
      }
    },

    // INIT
    async init_function() {

      let maps = await get_qfCollection_type_as_dict('map')
      let layers = await get_qfDocument_type_as_dict('layer')

      if (this.layer_id in layers) {
        this.is_loading = false
        this.map_layer_dict = maps[this.map_id].layers.dict[this.layer_id]
        this.layer_obj = layers[this.layer_id]
        this.tile_options = this.layer_obj.config.tile_layer_options.leaflet_wms

        await this.process_leaflet_wms()

        this.is_loading = false

      } else {
        console.log('NO LAYER')
      }

    },

  },


  beforeDestroy() {
    if (this.tile_obj !== null) {
      this.tile_obj.removeFrom(this.$store.state.map)
      this.tile_obj.remove()
    } else {
      let cnt = 0
      let interval_id = setInterval(() => {
        cnt += 1
        if (this.tile_obj !== null) {
          this.tile_obj.removeFrom(this.$store.state.map)
          this.tile_obj.remove()
          this.tile_obj = null
          clearInterval(interval_id);
        } else if (cnt >= 50) {
          clearInterval(interval_id);
        }
      }, 100);
    }
  },
  watch: {
    '$store.state.is_sync_done': async function () {
      let layers = await get_qfDocument_type_as_dict('layer')
      if (layers[this.layer_id].version > this.layer_obj.version) {
        await this.init_function()
      }
    },
  },
  mounted() {
    this.init_function()
  }
}
</script>

<style scoped>

.box {
  float: left;
  height: 20px;
  width: 20px;
  clear: both;
}

</style>
