import {store} from "@/store"
import {
    clear_map,
    r_anonymous_user_data,
    r_user_data
} from "@/services/app_api";
import {r_user_profile} from "@/services/user_api";
import {show_snackbar} from "@/services/app_utils";

export async function init_r_app() {
    console.log('init_r_app')
    if (store.state.auth.isLoggedIn) {
        store.state.init_loaded = false
        await clear_map()
        store.state.init_loaded = true
        if (navigator.onLine) {
            await r_user_profile()
            await r_user_data()
        }

    } else {
        store.state.init_loaded = false
        await clear_map()
        store.state.init_loaded = true
        show_snackbar('You are in anonymous mode. All your data will be saved to the local disk only. Login to backup your data to the cloud.', 'red', 10000, true)
        await r_anonymous_user_data()
    }

}