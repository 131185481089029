<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="pink" left>mdi-vector-link</v-icon>
          <span class="body-1">
            Link Layer with Table(s)
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3">
          <v-system-bar color="green" class="white--text caption">Step 1: Select Layer Column</v-system-bar>
          <v-card-text>
            <v-col style="max-width: 400px;">
              <v-select
                  label="Layer Column Name"
                  v-model="layer_column_uid"
                  :items="layer_columns"
                  item-text="col_name"
                  item-value="col_uid"
                  :hide-details="true"
              ></v-select>
            </v-col>
            <v-btn small @click="show_layer_table">
              <v-icon color="orange" left>mdi-table</v-icon>
              Show Layer Table
            </v-btn>

            <v-btn small @click="show_layer_schema">
              <v-icon color="orange" left>mdi-table-cog</v-icon>
              Show Layer Schema
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3">
          <v-system-bar color="green" class="white--text caption">Step 2: Select Table(s)</v-system-bar>

          <v-col style="max-width: 400px;">
            <v-autocomplete
                v-model="link_table_ids"
                :items="input_tables_list"
                dense
                small-chips
                deletable-chips
                clearable
                multiple
                label="Select Link Table(s)"
                item-text="table_name"
                item-value="table_id"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>
        </v-card>

        <v-card style="margin-bottom: 10px; margin-top: 10px;" elevation="3" v-if="link_table_ids.length > 0">
          <v-system-bar color="green" class="white--text caption">Step 3: Select Link Column for Table(s)</v-system-bar>
          <v-card-text>
            <v-card v-for="link_table_id in link_table_ids" :key="link_table_id" style="margin-bottom: 10px;"
                    elevation="3">
              <v-system-bar color="blue" class="white--text caption">{{ tables[link_table_id].name }}</v-system-bar>

              <v-col style="max-width: 400px;">
                <v-select
                    label="Link Column Name"
                    v-model="input_tables_info[link_table_id].link_col_uid"
                    :items="input_tables_info[link_table_id].columns"
                    item-text="col_name"
                    item-value="col_uid"
                    :hide-details="true"
                ></v-select>
              </v-col>

              <v-btn small @click="show_table_table(link_table_id)">
                <v-icon color="green" left>mdi-table</v-icon>
                Show Table Data
              </v-btn>
              <v-btn small @click="show_table_schema(link_table_id)">
                <v-icon color="green" left>mdi-table-cog</v-icon>
                Show Table Schema
              </v-btn>

            </v-card>
          </v-card-text>
        </v-card>

        <DriveTableViewDialog
            v-if="DriveTableViewDialog_show"
            :show_dialog.sync="DriveTableViewDialog_show"
            :callback_function="null"
            :the_title="active_table_name"
            :json_arrays="active_table_json_arrays"
            :schema="active_table_schema"
        ></DriveTableViewDialog>

        <DriveSchemaDialog
            v-if="DriveSchemaDialog_show"
            :show_dialog.sync="DriveSchemaDialog_show"
            :update_schema_callback="null"
            :schema="active_table_schema"
            :json_arrays="active_table_json_arrays"
            :idx="null"
        ></DriveSchemaDialog>

        <p class="caption red--text" v-if="errors_list.length > 0">
          <strong>Errors:</strong><br>
          <b v-for="error in errors_list" :key="error">
            {{ error }}<br>
          </b>
        </p>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="purple"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";
import DriveSchemaDialog from "@/components/Drive/DriveSchemaDialog";
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";

export default {
  name: "MapsVectorLayerLinkTableDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    layer_obj: {type: Object},
    layer_json_arrays: {type: Object},
    layer_schema: {type: Object},
    map_layer_link: {type: Object},
    submit_change_link: {type: Function},
  },
  components: {DriveTableViewDialog, DriveSchemaDialog},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      is_loading: false,

      tables: {},

      layer_columns: [],
      layer_column_uid: null,

      input_tables_list: [],
      input_tables_info: {},
      link_table_ids: [],


      active_table_json_arrays: {},
      active_table_schema: {},
      active_table_name: null,

      DriveTableViewDialog_show: false,
      DriveSchemaDialog_show: false,

      errors_list: [],

    }
  },
  methods: {
    async show_layer_table() {
      this.active_table_json_arrays = this.layer_json_arrays
      this.active_table_schema = this.layer_schema
      this.active_table_name = this.layer_obj.name
      this.DriveTableViewDialog_show = true
    },
    async show_layer_schema() {
      this.active_table_json_arrays = this.layer_json_arrays
      this.active_table_schema = this.layer_schema
      this.active_table_name = this.layer_obj.name
      this.DriveSchemaDialog_show = true
    },
    async show_table_table(table_id) {
      this.active_table_json_arrays = await idb_unzip_blob(table_id)
      this.active_table_schema = this.tables[table_id].config.schema
      this.active_table_name = this.tables[table_id].name
      this.DriveTableViewDialog_show = true
    },
    async show_table_schema(table_id) {
      this.active_table_json_arrays = await idb_unzip_blob(table_id)
      this.active_table_schema = this.tables[table_id].config.schema
      this.active_table_name = this.tables[table_id].name
      this.DriveSchemaDialog_show = true
    },
    async submit() {
      console.log('✅', this.layer_obj)
      this.errors_list = []
      if (this.layer_column_uid === null) this.errors_list.push(`'Layer Column Name' is empty`)
      if (this.link_table_ids.length === 0) this.errors_list.push(`No link table is selected`)
      for (let link_table_id of this.link_table_ids) {
        if (this.input_tables_info[link_table_id].link_col_uid === null) {
          this.errors_list.push(`Link Column name is empty for ${this.tables[link_table_id].name} table`)
        }
      }

      if (this.errors_list.length === 0) {
        let map_layer_link = {}
        for (let link_table_id of this.link_table_ids) {
          let link_col_uid = this.input_tables_info[link_table_id].link_col_uid
          map_layer_link[link_table_id] = {'link_col_uid': link_col_uid}
        }

        this.$emit('update:map_layer_link', map_layer_link)
        await this.submit_change_link()

        this.cancel()
      }

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      console.log('🚩🚩🚩🚩', this.map_layer_link)
      this.is_loading = true
      this.tables = await get_qfDocument_type_as_dict('table')

      this.input_tables_list = []
      this.input_tables_info = {}

      for (let table_id in this.tables) {
        this.input_tables_list.push({
          table_id: this.tables[table_id].id,
          table_name: this.tables[table_id].name,
        })

        let table_obj = this.tables[table_id]
        let table_schema = table_obj.config.schema
        let table_columns = []
        let selected_col_uids = []
        for (let col_uid in table_schema) {
          if (!table_schema[col_uid].is_system) {
            table_columns.push({
              col_name: table_schema[col_uid].label,
              col_uid: col_uid
            })
          }
          selected_col_uids.push(col_uid)
        }

        this.input_tables_info[table_id] = {
          schema: table_schema,
          columns: table_columns,
          link_col_uid: null,
        }
        if (table_id in this.map_layer_link) {
          let link_col_uid = this.map_layer_link[table_id].link_col_uid
          this.link_table_ids.push(table_id)
          this.input_tables_info[table_id].link_col_uid = link_col_uid
        }

      }

      for (let col_uid in this.layer_schema) {
        if (!this.layer_schema[col_uid].is_system) {
          this.layer_columns.push({
            col_name: this.layer_schema[col_uid].label,
            col_uid: col_uid
          })
        }
      }

      this.is_loading = false


    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
