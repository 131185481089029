import {ref, set, get, child, update, push, remove} from "firebase/database";
import {database} from '@/firebase/firebase';

const dbRef = ref(database)

export async function rtdb_c_key(path) {
    return push(child(dbRef, path)).key
}

export async function rtdb_c(path, content) {
    await set(ref(database, path), content);
}


export async function rtdb_r(path) {

    const resp = await get(child(dbRef, path))

    if (resp.exists()) {
        return resp.val()
    } else {
        return null
    }

}

export async function rtdb_u(path, content) {
    // update_dict = {
    //   '/users/123': {name: 'ali'}
    // }

    // can pass both a key or a whole dict
    const update_dict = {}
    update_dict[path] = content

    await update(dbRef, update_dict)

}

export async function rtdb_u_multiple(update_dict) {
    // update_dict = {
    //   '/users/123': {name: 'ali'}
    // }

    // can pass both a key or a whole dict
    await update(dbRef, update_dict)

}

export async function rtdb_d_async(del_path) {
    const del_dict = {}
    del_dict[del_path] = null

    // await update(dbRef, del_dict)
    // await database.ref(del_path).remove()
    await remove(ref(database, del_path))
}

export function rtdb_d(del_path) {
    const del_dict = {}
    del_dict[del_path] = null

    // update(dbRef, del_dict)

    // remove(dbRef, del_path)
    remove(ref(database, del_path))
    // database.ref(del_path).remove()

}

export async function rtdb_d_multiple(del_path_list) {
    const del_dict = {}
    for (let del_path of del_path_list) {
        del_dict[del_path] = null
    }

    await update(dbRef, del_dict)

}

