<template>
  <div>
    <MapsMapSearchLocation></MapsMapSearchLocation>

    <v-btn icon @click="map.zoomIn(0.5)" title="zoom in" color="black">
      <v-icon>mdi-magnify-plus</v-icon>
    </v-btn>
    <v-btn icon @click="map.zoomOut(0.5)" title="zoom out" color="black">
      <v-icon>mdi-magnify-minus</v-icon>
    </v-btn>
    <v-btn icon @click="zoom_window" title="zoom to a window by drawing a rectangle" color="black">
      <v-icon>mdi-magnify-expand</v-icon>
    </v-btn>

    <v-btn icon @click="set_identify" title="Identify" color="black">
      <v-icon color="black" v-if="$store.state.identify_flag">mdi-information</v-icon>
      <v-icon color="blue-grey lighten-3" v-if="!$store.state.identify_flag">mdi-information</v-icon>
    </v-btn>

  </div>
</template>

<script>
import MapsMapSearchLocation from "@/components/Maps/MapsMapSearchLocation";

export default {
  name: "MapsMapTools",
  components: {MapsMapSearchLocation},
  data() {
    return {
      map: null,
      zoom_window_tool: null,
    }
  },
  methods: {

    set_identify() {
      console.log('identify clicked')
      this.$store.state.identify_flag = !this.$store.state.identify_flag

    },

    async zoom_window() {

      this.set_draw_off()
      this.$store.state.map.pm.enableDraw('Rectangle');
      this.$store.state.map.on('pm:create', async (e) => {
        e.layer.removeFrom(this.$store.state.map)
        this.map.fitBounds(e.layer.getBounds())
        this.set_draw_off()
      })

    },
    set_draw_off() {
      this.$store.state.map.pm.disableDraw()
      this.$store.state.map.off('pm:drawstart')
      this.$store.state.map.off('pm:drawend')
      this.$store.state.map.off('pm:create')
    },

  },
  mounted() {

    let interval_id = setInterval(() => {
      if (this.$store.state.map !== null) {
        this.map = this.$store.state.map
        clearInterval(interval_id);
      }
    }, 100);
  }
}
</script>

<style scoped>

</style>
