<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card height='100%' :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon>mdi-table</v-icon>
          <span class="body-1">
            {{ the_title }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50; set_table_size()">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50; set_table_size()">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false; set_table_size()">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true; set_table_size()">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="height: 95%; padding: 0; margin: 0;" ref="the_dialog">

        <v-col style="margin-bottom: 10px; margin-top: 10px;">
          Number of rows: {{table_data.length}}
        </v-col>

        <ag-grid-vue
            :style="{width: table_width + 'px', height: table_height + 'px', padding: 0, margin: 0}"
            class="ag-theme-alpine"
            :columnDefs="table_headers"
            :rowData="table_data"
            :animateRows="true"
            @grid-ready="onGridReady"
            :rowDragManaged="true"
            :editType="'fullRow'"
        ></ag-grid-vue>
      </v-card-text>
      <v-card-actions v-if="!dialog_size.fullscreen">
        <v-btn small text @click="cancel">Close</v-btn>
        <v-btn small text @click="save"
               :disabled="is_loading"
               v-if="editable && doc_obj !== null">
          Save</v-btn>
        <v-progress-circular indeterminate color="orange" v-if="is_loading"></v-progress-circular>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue"
import {u_qfDocument} from "@/services/app_api";

export default {
  name: "DriveTableViewDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    the_title: {type: String, default: 'Table'},
    doc_obj: {type: Object, default: null},
    json_arrays: {type: Object},
    schema: {type: Object},
    editable: {type: Boolean, default: false},
  },
  components: {AgGridVue},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800,
      },
      is_loading: false,
      table_width: 800,
      table_height: 500,

      // search: null,
      table_data: [],
      table_headers: [],

      gridApi: null,
      columnApi: null,
      gridColumnApi: null,

    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    resize_cols() {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    },
    async set_table_size() {
      setTimeout(() => {
        this.table_width = this.$refs.the_dialog.clientWidth
        this.table_height = this.$refs.the_dialog.clientHeight
        this.resize_cols()
      }, 100)
    },
    async json_arrays_to_json_list(json_arrays) {
      let col_uids = Object.keys(json_arrays)
      let table_data = []
      for (let idx in json_arrays.row_uid) {
        let the_dict = {}
        for (let col_uid of col_uids) {
          the_dict[col_uid] = json_arrays[col_uid][idx]
        }
        table_data.push(the_dict)
      }
      return table_data
    },
    async get_table_headers(json_arrays, schema) {
      let col_uids = Object.keys(json_arrays)
      let table_headers = []
      for (let col_uid of col_uids) {
        if (!schema[col_uid].is_system) {
          table_headers.push({
            field: col_uid,
            headerName: schema[col_uid].label,
            editable: this.editable,
            resizable: true,
            sortable: true,
            filter: true,
          })
        }
      }
      table_headers[0].rowDrag = true
      return table_headers
    },
    async save() {
      this.is_loading = true
      // activate if cell is edited
      // convert table to json_arrays and save
      let col_uids = Object.keys(this.table_data[0])
      let json_arrays = {}
      for (let col_uid of col_uids){
        json_arrays[col_uid] = []
      }
      for (let row_item of this.table_data){
        for (let col_uid of col_uids){
          json_arrays[col_uid].push(row_item[col_uid])
        }
      }
      console.log(col_uids)
      console.log(json_arrays)

      await u_qfDocument(this.doc_obj, json_arrays, this.doc_obj.docBlob.is_uploaded)
      this.is_loading = false
      this.cancel()
    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {

      this.table_data = await this.json_arrays_to_json_list(this.json_arrays)
      this.table_headers = await this.get_table_headers(this.json_arrays, this.schema)
      await this.set_table_size()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
