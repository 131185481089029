<template>
  <v-col scrollable style="padding: 0; margin: 0; !important;">

    <SyncApplySync v-if="$store.state.is_sync_ready"></SyncApplySync>

    <v-btn small style="text-transform: none; margin-bottom: 10px; margin-top: 10px; margin-right: 10px;" color="green"
           class="white--text"
           @click="DriveDialog_show = true;">
      <v-icon left>mdi-database-marker</v-icon>
      <span>qfDrive</span>
    </v-btn>
    <v-btn small style="text-transform: none; margin-bottom: 10px; margin-top: 10px;" color="purple" class="white--text"
           @click="MewaGeeTasksListDialog_show=true" v-if="$store.state.auth.isLoggedIn">
      <v-icon left>mdi-google-earth</v-icon>
      Load Water Use Data
    </v-btn>
    <DriveDialog
        v-if="DriveDialog_show"
        :show_dialog.sync="DriveDialog_show"
        :callback_function="init_function"
        :map_id="map_id"
    ></DriveDialog>
    <br>
    <MapsMapTools></MapsMapTools>

    <v-divider></v-divider>

    <div style="margin-top: 20px; margin-bottom: 5px;"
         v-if="maps_list.length > 0 && map_id !== null && init_maps_loaded">
      <v-select
          outlined
          @change="on_change_map"
          dense
          v-model="map_id"
          :items="maps_list"
          item-text="map_name"
          item-value="map_id"
          label="Select Map"
          :hide-details="true"
      >
      </v-select>

      <v-col>

        <v-row>
          <v-btn icon @click="save_map" title="Save Map" color="black">
            <v-icon :color="'#D500F9'">mdi-content-save</v-icon>
          </v-btn>

          <v-btn icon @click="edit_map" title="Edit Map" color="black">
            <v-icon :color="'#00ff00'">mdi-pencil</v-icon>
          </v-btn>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  title="Add Layer to Map"
                  color="black"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon :color="'#ff7f50'">mdi-layers-plus</v-icon>
              </v-btn>
            </template>
            <v-list dense nav>

              <v-list-item @click="edit_map">
                <v-list-item-icon>
                  <v-icon color="green">mdi-layers-triple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Select from qfDrive</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="import_vector_layer">
                <v-list-item-icon>
                  <v-icon :color="'#ff7f50'">mdi-application-import</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Import Vector Layer(s)</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="DriveVectorLayerCreateDialog_show = true">
                <v-list-item-icon>
                  <v-icon :color="'#8a2be2'">mdi-draw-pen</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Draw Vector Layer</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-menu
              transition="slide-y-transition"
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense nav>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon v-if="maps[map_id].config.stage.stage === 'Development'" color="green">mdi-test-tube</v-icon>
                  <v-icon v-if="maps[map_id].config.stage.stage === 'Testing'" color="purple">mdi-truck-delivery
                  </v-icon>
                  <v-icon v-if="maps[map_id].config.stage.stage === 'Production'" color="red">mdi-bullseye-arrow
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ maps[map_id].config.stage.stage }} stage</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item
                  v-for="(item, index) in map_menu_items"
                  @click="on_select_map_menu_item(item.action)"
                  :key="index">
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-menu>
        </v-row>
      </v-col>


    </div>

    <div v-if="map_id !== null && init_maps_loaded">
      <div v-if="layer_id_list.length > 0">
        <v-subheader class="font-weight-bold">
          Map Layers
        </v-subheader>

<!--        <v-col>-->
<!--          <v-select-->
<!--              v-if="$store.state.auth.isLoggedIn && HasBaseGeeLayer"-->
<!--              outlined-->
<!--              @change="on_change_BaseGeeLayer_year"-->
<!--              dense-->
<!--              v-model="BaseGeeLayer_year"-->
<!--              :items="[2015, 2016, 2017]"-->
<!--              label="Select Base Year (for Tile layers)"-->
<!--              :hide-details="true"-->
<!--          >-->
<!--          </v-select>-->
<!--        </v-col>-->


        <draggable :list="layer_id_list" handle=".handle" group="tags" @change="on_drag_layer">
          <transition-group type="transition" name="flip-list">
            <div
                v-for="(layer_id, arr_index) in layer_id_list"
                :key="layer_id"
                class="handle"
            >
              <MapsLayer
                  :ref="layer_id"
                  :map_id="map_id"
                  :layer_id="layer_id"
                  :layer_type="layers[layer_id].config.layer_type"
                  :layer_format="layers[layer_id].config.layer_format"
                  :z_index="z_index_list[arr_index]"
                  :layers_list_init_function="init_function"
                  :update_map_layer_dict="update_map_layer_dict"
              ></MapsLayer>
            </div>
          </transition-group>
        </draggable>

      </div>
      <div v-else >
        <v-alert v-if="false"
            style="margin-top: 10px;"
            max-width="300"
            border="left"
            color="red"
            text
            dense
            type="warning"
        >
          This map has no layer.
        </v-alert>
      </div>
    </div>

    <div v-else style="margin-top: 10px; margin-bottom: 10px;">

      <v-btn small @click="create_map" color="orange" class="white--text">
        <v-icon small left>mdi-new-box</v-icon>
        Create New Map
      </v-btn>

    </div>

    <MapsLayersMewaBaseGroup
        v-if="$store.state.auth.isLoggedIn"
        :map_id="map_id"
    ></MapsLayersMewaBaseGroup>

    <MapsMapBaseLayer></MapsMapBaseLayer>

    <!--    <DemoFeatures></DemoFeatures>-->
    <!--    <v-btn @click="SwmmReadInputDialog_show=true">TEST SWMMM</v-btn>-->
    <SwmmReadInputDialog
        v-if="SwmmReadInputDialog_show"
        :show_dialog.sync="SwmmReadInputDialog_show"
        :callback_function="null"
    ></SwmmReadInputDialog>

    <DriveVectorLayerImportDialog
        v-if="DriveVectorLayerImportDialog_show"
        :show_dialog.sync="DriveVectorLayerImportDialog_show"
        :map_id="map_id"
        :callback_function="init_function"
    ></DriveVectorLayerImportDialog>

    <DriveVectorLayerCreateDialog
        v-if="DriveVectorLayerCreateDialog_show"
        :show_dialog.sync="DriveVectorLayerCreateDialog_show"
        :map_id="map_id"
        :callback_function="init_function"
    ></DriveVectorLayerCreateDialog>

    <DriveMapDialog
        v-if="DriveMapDialog_show"
        :show_dialog.sync="DriveMapDialog_show"
        :callback_function="init_function"
        :map_id="map_id"
        :action="DriveMapDialog_action"
    ></DriveMapDialog>

    <DriveConfirmDeleteDialog
        v-if="DriveConfirmDeleteDialog_show"
        :show_dialog.sync="DriveConfirmDeleteDialog_show"
        :callback_function="init_reset"
        :dialog_title="'Delete Map'"
        :item_type_name="'Map'"
        :item_type="'qfCollection'"
        :item_name="delete_item.name"
        :item_id="delete_item.id"
    ></DriveConfirmDeleteDialog>

    <MewaGeeTasksListDialog
        v-if="MewaGeeTasksListDialog_show"
        :show_dialog.sync="MewaGeeTasksListDialog_show"
    ></MewaGeeTasksListDialog>

  </v-col>
</template>

<script>

import MapsLayersMewaBaseGroup from "@/components/Maps/MapsLayersMewaBaseGroup";
import MewaGeeTasksListDialog from "@/components/MEWA/MewaGeeTasksListDialog";
// import DemoFeatures from "@/components/Temp/DemoFeatures";
import draggable from 'vuedraggable'
import DriveDialog from "@/components/Drive/DriveDialog";
import MapsMapTools from "@/components/Maps/MapsMapTools";
import MapsMapBaseLayer from "@/components/Maps/MapsMapBaseLayer";
import DriveVectorLayerImportDialog from "@/components/Drive/DriveVectorLayerImportDialog";
import DriveMapDialog from "@/components/Drive/DriveMapDialog";
import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";
import DriveVectorLayerCreateDialog from "@/components/Drive/DriveVectorLayerCreateDialog";
import MapsLayer from "@/components/Maps/MapsLayer";
import SyncApplySync from "@/components/Sync/SyncApplySync";
import {deepClone} from "@/services/generic";
import {get_qfCollection_type_as_dict, get_qfDocument_type_as_dict} from "@/services/app_utils";
import {u_qfCollection, u_qfDocument} from "@/services/app_api";

import SwmmReadInputDialog from "@/components/SWMM/SwmmReadInputDialog";


export default {
  name: "MapsLayersList",
  components: {
    DriveVectorLayerCreateDialog,
    draggable,
    DriveDialog,
    MapsMapTools,
    MapsMapBaseLayer,
    DriveVectorLayerImportDialog,
    DriveMapDialog,
    DriveConfirmDeleteDialog,
    MapsLayer,
    SyncApplySync,
    // DemoFeatures,
    SwmmReadInputDialog,
    MewaGeeTasksListDialog,
    MapsLayersMewaBaseGroup,
  },
  props: {},
  data() {
    return {

      z_index_list: [10, 9, 8, 7],
      maps: {},
      maps_list: [],
      map_id_list: [],
      map_id: null,
      layers: {},
      layer_id_list: [],
      init_maps_loaded: false,

      DriveDialog_show: false,
      DriveVectorLayerCreateDialog_show: false,

      DriveMapDialog_show: false,
      DriveMapDialog_action: 'Create',

      DriveVectorLayerImportDialog_show: false,

      map_menu_items: [
        {title: 'Save Map', icon: 'mdi-content-save', action: 'save_map', color: '#D500F9'},
        {title: 'Duplicate Map (Save As)', icon: 'mdi-content-save-all', action: 'duplicate_map', color: '#D500F9',},
        {title: 'Edit Map', icon: 'mdi-pencil', action: 'edit_map', color: '#00ff00',},
        {
          title: 'Import Vector Layer(s)',
          icon: 'mdi-application-import',
          action: 'import_vector_layer',
          color: '#ff7f50',
        },
        {title: 'Draw Vector Layer', icon: 'mdi-draw-pen', action: 'draw_layer', color: '#8a2be2'},
        {title: 'Delete Map', icon: 'mdi-delete', action: 'delete_map', color: '#ff0000'},
      ],

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,

      },

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],

      SwmmReadInputDialog_show: false,
      MewaGeeTasksListDialog_show: false,

      BaseGeeLayer_year: 2015,
      HasBaseGeeLayer: false,

    }
  },
  methods: {

    check_mewa_tile_layer_years() {
      // https://storage.googleapis.com/halo-mewa-tiles/optical/{year}/{z}/{x}/{y}
      // check url for all tile layers in the map

      for (let layer_id of this.layer_id_list) {
        let layer_type = this.layers[layer_id].config.layer_type
        let layer_format = this.layers[layer_id].config.layer_format
        if (layer_type === 'tile' && layer_format === 'leaflet_tms') {
          let layer_url = this.layers[layer_id].config.tile_layer_options.leaflet_tms.url
          if (layer_url.includes("https://storage.googleapis.com/halo-mewa-tiles")) {
            this.HasBaseGeeLayer = true
          }
        }
      }

    },

    async on_change_BaseGeeLayer_year() {

      // save map first
      await this.save_map()

      for (let layer_id of this.layer_id_list) {
        let layer_type = this.layers[layer_id].config.layer_type
        let layer_format = this.layers[layer_id].config.layer_format
        if (layer_type === 'tile' && layer_format === 'leaflet_tms') {
          let layer_url = this.layers[layer_id].config.tile_layer_options.leaflet_tms.url
          if (layer_url.includes("https://storage.googleapis.com/halo-mewa-tiles")) {

            this.layers[layer_id].config.tile_layer_options.leaflet_tms.url = layer_url.replace(/\d{4}/, this.BaseGeeLayer_year)
            console.log(layer_url)

            // now just save the layers
            await u_qfDocument(this.layers[layer_id], null, null)

          }
        }
      }
      await this.init_function()

    },

    create_map() {
      this.DriveMapDialog_action = 'Create'
      this.DriveMapDialog_show = true
    },

    edit_map() {
      this.DriveMapDialog_action = 'Edit'
      this.DriveMapDialog_show = true
    },
    duplicate_map() {
      this.DriveMapDialog_action = 'Duplicate'
      this.DriveMapDialog_show = true
    },

    async update_map_layer_dict(layer_id, layer_dict, save_map = false) {
      this.maps[this.map_id].layers.dict[layer_id] = deepClone(layer_dict)
      if (save_map) {
        await this.save_map()
      }
    },

    async on_select_map_menu_item(action) {

      switch (action) {
        case 'save_map':
          await this.save_map()
          break;
        case 'duplicate_map':
          this.duplicate_map()
          break;
        case 'edit_map':
          this.edit_map()
          break;
        case 'import_vector_layer':
          this.import_vector_layer()
          break;
        case 'draw_layer':
          this.DriveVectorLayerCreateDialog_show = true
          break;
        case 'delete_map':
          this.DriveConfirmDeleteDialog_show = true
          this.delete_item.name = this.maps[this.map_id].name
          this.delete_item.id = this.map_id
          break;
        default:
          break;
      }


    },

    async save_map() {
      await u_qfCollection(this.maps[this.map_id], true)
    },

    import_vector_layer() {
      this.DriveVectorLayerImportDialog_show = true
    },
    async on_drag_layer() {
      let idx = -1
      this.layer_id_list = []
      for (let layer_id of this.maps[this.map_id].layers.list) {
        this.layer_id_list.push(layer_id)
        idx += 1
        if (this.$store.state.map.getPane(layer_id) !== undefined) {
          this.$store.state.map.getPane(layer_id).style.zIndex = this.z_index_list[idx]
        }
      }
      this.maps[this.map_id].layers.list = this.layer_id_list
    },
    async on_change_map() {
      let map_id = deepClone(this.map_id)

      setTimeout(() => {
        this.map_id = map_id
        this.layer_id_list = this.maps[this.map_id].layers.list
        // if (this.$store.state.auth.isLoggedIn) {
        //   this.check_mewa_tile_layer_years()
        // }

        this.init_maps_loaded = true
      }, 100)
    },
    async create_maps_list() {
      let maps_list = []
      let map_id_list = []
      this.maps = await get_qfCollection_type_as_dict('map')
      this.layers = await get_qfDocument_type_as_dict('layer')
      for (let map_id in this.maps) {
        map_id_list.push(map_id)
        maps_list.push({
          map_id: map_id,
          map_name: this.maps[map_id].name
        })
      }
      this.maps_list = maps_list
      this.map_id_list = map_id_list
    },
    async init_function() {
      this.init_maps_loaded = false
      this.DriveVectorLayerImportDialog_show = false
      setTimeout(async () => {
        await this.create_maps_list()
        if (this.maps_list.length > 0) {
          if (this.map_id === null || !this.map_id_list.includes(this.map_id)) {
            this.map_id = this.map_id_list[0]
          }
          await this.on_change_map()
        }
      }, 500)

    },
    async init_reset() {
      this.z_index_list = []
      let i = 300
      while (i > 0) {
        this.z_index_list.push(i)
        i = i - 3
      }

      let interval_id = setInterval(() => {
        if (this.$store.state.map !== null && this.$store.state.init_loaded) {
          this.init_function()
          clearInterval(interval_id);
        }
      }, 10)
    }
  },
  watch: {
    '$store.state.init_loaded': function () {
      this.init_reset()
    },
    '$store.state.is_sync_done': function () {
      if (this.$store.state.is_sync_done) {
        this.init_reset()
      }
    },
  },
  mounted() {
    this.init_reset()
  }

}
</script>

<style scoped>

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

</style>
