<template>
  <v-card flat :loading="loading">
    <v-card-text>

      <v-row>
        <v-spacer></v-spacer>

        <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense nav>

            <v-list-item @click="DriveVectorLayerImportDialog_show=true">
              <v-list-item-icon>
                <v-icon color="orange">mdi-application-import</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Import Vector Layer(s)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="selected_items.length > 0"></v-divider>

            <v-list-item
                v-if="selected_items.length > 0"
                @click="delete_selected">
              <v-list-item-icon>
                <v-icon color="red">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Delete Selected</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="selected_items.length > 0 && selected_has_on_disk"
                :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"
                @click="upload_selected">
              <v-list-item-icon :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online">
                <v-icon color="blue">mdi-cloud-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Upload Selected</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="selected_items.length > 0"
                @click="export_selected_as_zip">
              <v-list-item-icon>
                <v-icon color="green">mdi-cloud-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Export Selected in zip archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-menu>
      </v-row>

      <p></p>


      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
              style="max-width: 300px;"
              background-color="blue-grey lighten-5"
              v-model="search"
              append-icon="mdi-magnify"
              color="green"
              label="Search table rows"
              outlined
              dense
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-row>

      </v-col>

      <v-data-table
          :headers="layers_table_headers"
          :items="layers_table"
          :items-per-page="-1"
          :search="search"
          item-key="layer_id"
          class="elevation-1"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          :hide-default-footer="layers_table.length < 10"

          show-select
          v-model="selected_items"
          @item-selected="on_item_selected"
          @toggle-select-all="on_select_all_items"

      >
<!--        <template v-slot:item.source_status="{ item }">-->
<!--          <v-btn icon>-->
<!--            <v-icon v-if="item.source_status.is_uploaded" color="green">-->
<!--              mdi-cloud-->
<!--            </v-icon>-->
<!--            <v-icon v-if="!item.source_status.is_uploaded" color="red">-->
<!--              mdi-cloud-->
<!--            </v-icon>-->
<!--            <v-icon v-if="item.source_status.is_local" color="green">-->
<!--              mdi-harddisk-->
<!--            </v-icon>-->
<!--            <v-icon v-if="!item.source_status.is_local" color="red">-->
<!--              mdi-harddisk-->
<!--            </v-icon>-->
<!--          </v-btn>-->
<!--        </template>-->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container style="margin-bottom: 10px; margin-top: 10px;">
              <p class="caption" v-if="layer_maps[item.layer_id].length > 0">
                <b>Maps:</b><br>
                <span v-for="map in layer_maps[item.layer_id]" :key="map.map_id">
                  {{ map.map_name }}<br>
                  </span>
              </p>

              <v-col>
                <v-btn small @click="on_show_table(item.layer_id)">
                  <v-icon color="green" left>mdi-table</v-icon>
                  View As Table
                </v-btn>
              </v-col>

<!--              <v-container v-if="!item.source_status.is_uploaded && !item.source_status.is_local">-->
<!--                <b class="body-1 red&#45;&#45;text">This layer is missing</b><br>-->
<!--                <p class="caption brown&#45;&#45;text" style="line-height: 1.1em;">-->
<!--                  This layer is not available in the cloud. From the layer meta data-->
<!--                  it is stored in the following browser.-->
<!--                  Please login back to the same device and browser and upload-->
<!--                  the layer to the cloud to make it available everywhere<br>-->
<!--                </p>-->
<!--                <p class="caption orange&#45;&#45;text" style="line-height: 1em;">-->
<!--                  Browser: {{ layers[item.layer_id].audit[0].device.browser_name }}<br>-->
<!--                  Browser version: {{ layers[item.layer_id].audit[0].device.browser_version }}<br>-->
<!--                  OS: {{ layers[item.layer_id].audit[0].device.os_name }}<br>-->
<!--                  Device Type: {{ layers[item.layer_id].audit[0].device.device_type }}<br>-->
<!--                </p>-->
<!--              </v-container>-->
              <v-row justify="space-around">
                <v-spacer></v-spacer>

                <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense nav>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.layer_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item
                        v-if="item.source_status.is_local"
                        @click="edit_layer">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Edit Layer Info</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-if="item.source_status.is_local"
                        @click="on_show_table(item.layer_id)">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Edit Layer Table</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-if="item.source_status.is_local"
                        @click="duplicate_layer">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-content-save-all</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Duplicate Layer (Save As)</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

<!--                    <v-list-item-->
<!--                        v-if="!item.source_status.is_uploaded && item.source_status.is_local"-->
<!--                        :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"-->
<!--                        @click="upload_layer(item.layer_id, false)">-->
<!--                      <v-list-item-icon>-->
<!--                        <v-icon color="blue">mdi-cloud</v-icon>-->
<!--                      </v-list-item-icon>-->
<!--                      <v-list-item-content>-->
<!--                        <v-list-item-title>Upload to cloud</v-list-item-title>-->
<!--                      </v-list-item-content>-->
<!--                    </v-list-item>-->

                    <v-list-item
                        v-if="item.source_status.is_local"
                        @click="DriveVectorLayerExportDialog_show=true">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-file-export</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Export Layer</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="delete_layer(item.layer_id, false)">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Delete Layer</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>


            </v-container>
          </td>

          <DriveVectorLayerExportDialog
              v-if="DriveVectorLayerExportDialog_show"
              :show_dialog.sync="DriveVectorLayerExportDialog_show"
              :layer_id="item.layer_id"
              :show_save_warning="false"
              :selected_uid_list="[]"
          ></DriveVectorLayerExportDialog>

          <DriveVectorLayerDialog
              v-if="DriveVectorLayerDialog_show"
              :show_dialog.sync="DriveVectorLayerDialog_show"
              :action="DriveVectorLayerDialog_action"
              :map_id="null"
              :layer_id="item.layer_id"
              :selected_uid_list="[]"
              :refresh_map_function="null"
              :refresh_qfdrive_function="init_list_data"
              :show_save_warning="false"
          ></DriveVectorLayerDialog>

          <DriveTableViewDialog
              v-if="DriveTableViewDialog_show"
              :show_dialog.sync="DriveTableViewDialog_show"
              :callback_function="null"
              :the_title="layers[item.layer_id].name"
              :doc_obj="layers[item.layer_id]"
              :json_arrays="active_layer_json_arrays"
              :schema="active_layer_schema"
              :editable="true"
          ></DriveTableViewDialog>

        </template>
      </v-data-table>

    </v-card-text>
    <DriveVectorLayerImportDialog
        v-if="DriveVectorLayerImportDialog_show"
        :show_dialog.sync="DriveVectorLayerImportDialog_show"
        :map_id="null"
        :callback_function="init_list_data"
    ></DriveVectorLayerImportDialog>

    <DriveAllocationWarning
        v-if="DriveAllocationWarning_show"
        :show_dialog.sync="DriveAllocationWarning_show"
    ></DriveAllocationWarning>

    <DriveConfirmDeleteDialog
        v-if="DriveConfirmDeleteDialog_show"
        :show_dialog.sync="DriveConfirmDeleteDialog_show"
        :callback_function="after_delete_layer"
        :dialog_title="'Delete Layer'"
        :item_type_name="'Layer'"
        :item_type="'qfDocument'"
        :item_name="delete_item.name"
        :item_id="delete_item.id"
    ></DriveConfirmDeleteDialog>

  </v-card>
</template>

<script>

import DriveVectorLayerImportDialog from "@/components/Drive/DriveVectorLayerImportDialog";
import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";
import DriveVectorLayerExportDialog from "@/components/Drive/DriveVectorLayerExportDialog";
import DriveVectorLayerDialog from "@/components/Drive/DriveVectorLayerDialog";
import DriveAllocationWarning from "@/components/Drive/DriveAllocationWarning";
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";

import prettyBytes from 'pretty-bytes';
import {
  r_user_data,
  u_qfDocument, d_qfDocument,
} from "@/services/app_api";
import {idb_r} from "@/services/idb_api";

import {
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
  idb_unzip_blob,
} from "@/services/app_utils";
import {zip_pack_geojson_list} from "@/services/gis_utils";

export default {
  name: "DriveVectorLayersList",
  props: {
    callback_function: {type: Function},
    map_id: {type: String},
  },
  components: {
    DriveVectorLayerImportDialog,
    DriveConfirmDeleteDialog,
    DriveVectorLayerExportDialog,
    DriveVectorLayerDialog,
    DriveAllocationWarning,
    DriveTableViewDialog,
  },
  data() {
    return {
      loading: false,
      maps: {},
      layers: {},
      layers_table: [],
      search: null,
      layers_table_headers: [
        {text: 'Name', value: 'layer_name'},
        {text: 'Type', value: 'layer_type'},
        {text: 'Size', value: 'layer_size'},
        {text: 'No. Maps', value: 'num_maps'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      layer_maps: {},

      selected_items: [],
      selected_has_on_disk: false,

      DriveVectorLayerImportDialog_show: false,

      DriveAllocationWarning_show: false,

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,
      },
      DriveVectorLayerExportDialog_show: false,

      DriveVectorLayerDialog_show: false,
      DriveVectorLayerDialog_action: 'Edit',

      DriveTableViewDialog_show: false,
      active_layer_json_arrays: {},
      active_layer_schema: {},

    }
  },
  methods: {
    async on_show_table(layer_id){

      this.active_layer_json_arrays = await idb_unzip_blob(layer_id)
      this.active_layer_schema = this.layers[layer_id].config.schema
      this.DriveTableViewDialog_show = true
    },
    async edit_layer() {
      this.DriveVectorLayerDialog_action = 'Edit'
      this.DriveVectorLayerDialog_show = true
    },

    async duplicate_layer() {
      this.DriveVectorLayerDialog_action = 'Duplicate'
      this.DriveVectorLayerDialog_show = true
    },

    async export_selected_as_zip() {

      let geojson_list = []

      for (let item of this.selected_items) {
        if (item.source_status.is_local) {

          if (this.layers[item.layer_id].config.layer_type === 'vector') {
            let geojson = await idb_unzip_blob(item.layer_id)
            const file_name = item.layer_name.replace('.geojson', '').replace('.shp', '').replace('.kml', '') + '.geojson'
            geojson_list.push({
              name: file_name,
              geojson: geojson
            })
          }
        }
      }

      if (geojson_list.length > 0) {
        await zip_pack_geojson_list(geojson_list, 'selected_files_geojson.zip')
      }

    },

    async remove_from_selected(layer_id) {
      let selected_items = []
      for (let item of this.selected_items) {
        if (item.layer_id !== layer_id) {
          selected_items.push(item)
        }
      }
      this.selected_items = selected_items
    },

    // async process_selected_rows() {
    //   setTimeout(() => {
    //     this.selected_has_on_disk = false
    //     for (let item of this.selected_items) {
    //       if (!item.source_status.is_uploaded && item.source_status.is_local) {
    //         this.selected_has_on_disk = true
    //       }
    //     }
    //   })
    // },

    async on_item_selected() {
      // await this.process_selected_rows()
    },
    async on_select_all_items() {
      // await this.process_selected_rows()
    },
    async delete_selected() {
      // TO CHECK
      this.loading = true
      for (let item of this.selected_items) {
        await this.remove_from_selected(item.layer_id)
        await this.delete_layer(item.layer_id, true)
      }

      if (this.callback_function !== null) {
        await this.callback_function(this.map_id)
      }

      await r_user_data(null)
      this.loading = false
      await this.init_list_data()
      await this.process_selected_rows()

    },

    async upload_layer(layer_id) {
      // TO CHECK
      this.loading = true
      let geojson = await idb_unzip_blob(layer_id)
      await u_qfDocument(this.layers[layer_id], geojson, true)

      this.loading = false
      await this.remove_from_selected(layer_id)
      await this.init_list_data()
      await this.process_selected_rows()
    },

    async delete_layer(layer_id, is_multi = false) {

      if (is_multi) {
        this.loading = true
        await d_qfDocument(layer_id)
        await this.init_list_data()
        // if (this.callback_function !== null) {
        //   await this.callback_function(this.map_id)
        // }
        await this.after_delete_layer(layer_id)
      } else {
        this.delete_item.name = this.layers[layer_id].name
        this.delete_item.id = layer_id
        this.DriveConfirmDeleteDialog_show = true
      }
    },
    async after_delete_layer(layer_id) {
      this.delete_item.name = null
      this.delete_item.id = null

      this.loading = false
      await this.remove_from_selected(layer_id)
      await this.init_list_data()
      await this.process_selected_rows()

    },


    async get_layer_use_in_maps(layer_id) {
      let maps_list = []

      for (let map_id in this.maps) {
        let map_obj = this.maps[map_id]

        if (layer_id in map_obj.layers.dict) {
          maps_list.push({
            map_id: map_id,
            map_name: map_obj.name
          })
        }

      }
      return maps_list
    },
    async init_list_data() {

      this.layers = {}
      let layers = await get_qfDocument_type_as_dict('layer')

      for (let layer_id in layers) {
        if (layers[layer_id].config.layer_type === 'vector') {
          this.layers[layer_id] = layers[layer_id]
        }
      }

      this.maps = await get_qfCollection_type_as_dict('map')

      let layers_table = []
      let layer_maps = {}
      for (let layer_id in this.layers) {
        let layer_obj = this.layers[layer_id]

        let source_status = {
          is_uploaded: layer_obj.docBlob.is_uploaded,
          is_local: true
        }

        if (await idb_r(`${layer_id}__blob`) === null) source_status.is_local = false

        layer_maps[layer_id] = await this.get_layer_use_in_maps(layer_id)

        let layer_type
        if (layer_obj.config.layer_type === 'vector') {
          layer_type = layer_obj.config.feature_type
        } else {
          layer_type = layer_obj.config.layer_type
        }


        layers_table.push({
          layer_id: layer_id,
          layer_name: layer_obj.name,
          layer_type: layer_type,
          layer_size: this.do_prettyBytes(layer_obj.docBlob.size),
          source_status: source_status,
          num_maps: layer_maps[layer_id].length
        })
      }
      this.$set(this, 'layers_table', layers_table)
      this.$set(this, 'layer_maps', layer_maps)
      this.$forceUpdate()
    },

    do_prettyBytes(num) {
      if (num === null || num === undefined) {
        return null
      } else {
        return prettyBytes(num)
      }
    },
    the_prettyBytes(num_byte) {
      return prettyBytes(num_byte)
    },
  },
  watch: {
    '$store.state.is_sync_done': async function () {
      if (this.$store.state.is_sync_done) {
        await this.init_list_data()
      }

    }
  },
  mounted() {
    this.init_list_data()
  },
}
</script>

<style scoped>

</style>
