<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left color="red">mdi-delete</v-icon>
          <span class="body-1">
            Delete Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-radio-group
          v-model="delete_type"
          column
      >
        <v-radio class="caption"
            label="Delete from this Map only"
            color="green"
            value="map"
        ></v-radio>
        <v-radio
            label="Delete everywhere"
            color="red"
            value="permanent"
        ></v-radio>
      </v-radio-group>

      <p class="body-2" v-if="delete_type === 'permanent'">
        Are you sure you want to <strong style="color: red;">permanently delete</strong> <strong>{{ layer_name }}</strong> layer?<br><br>
        Note that this layer and its properties and meta data will be <strong>permanently deleted</strong> from the cloud storage.
      </p>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="purple"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

export default {
  name: "MapsConfirmDeleteLayerFromMapDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    layer_name: {type: String},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      delete_type: 'map',

    }
  },
  methods: {
    async submit() {

      if (this.delete_type === 'permanent'){
        this.callback_function(true)
      } else {
        this.callback_function(false)
      }

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
