<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="purple" left>mdi-export</v-icon>
          <span class="body-1">
            Export Table Data
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-text-field
              v-model="file_name"
              label="File Name"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-select
              outlined
              dense
              v-model="file_format"
              :items="file_formats"
              label="Export Format"
              :hide-details="true"
          >
          </v-select>
        </v-col>

        <v-divider></v-divider>
        <v-switch
            v-if="selected_uid_list.length > 0"
            v-model="selected_only"
            :hide-details="true"
            label="Include selected features only"
        ></v-switch>

        <v-switch
            v-model="include_system_uid"
            :hide-details="true"
            label="Include qfDrive unique id for rows"
        ></v-switch>

        <v-alert
            v-if="show_save_warning"
            style="margin-top: 10px;"
            max-width="400"
            border="left"
            color="green"
            text
            dense
            type="info"
        >
          Only saved changes will be exported.
        </v-alert>


      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               :disabled="is_loading"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {save_as_geojson_file, save_as_kml_file} from "@/services/gis_utils";
import {export_json_2_csv, remove_from_list} from "@/services/generic";
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import {filter_json_arrays_for_row_uids, json_arrays_to_json_list} from "@/services/json_arrays_api";

export default {
  name: "DriveTableExportDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    table_id: {type: String},
    selected_uid_list: {type: Array, default: []},
    show_save_warning: {type: Boolean, default: false}
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      is_loading: false,

      table_obj: {},
      file_name: null,
      file_format: 'CSV',
      file_formats: ['CSV'],
      selected_only: false,
      include_system_uid: false,
    }
  },
  methods: {
    async submit() {
      this.is_loading = true;
      let json_arrays = await idb_unzip_blob(this.table_id)

      if (this.selected_only) {
        json_arrays = await filter_json_arrays_for_row_uids(json_arrays, this.selected_uid_list)
      }

      let schema = this.table_obj.config.schema

      let exclude_col_names = []
      for (let col_uid in schema){
        if (schema[col_uid].is_system){
          exclude_col_names.push(col_uid)
        }
      }

      if (this.include_system_uid) {
        exclude_col_names = exclude_col_names.filter(e => e !== 'row_uid')
      }

      let json_list = await json_arrays_to_json_list(json_arrays, schema, exclude_col_names)

      switch (this.file_format) {
        case 'CSV':
          this.file_name += '.csv'
          await export_json_2_csv(json_list, this.file_name)
          break;
        default:
          break;
      }
      this.is_loading = false
      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      let tables = await get_qfDocument_type_as_dict('table')
      this.table_obj = tables[this.table_id]
      this.file_name = this.table_obj.name.replace('.csv', '').replace('.shp', '').replace('.kml', '')

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
