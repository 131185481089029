<template>
  <div>

    <MapsDrawer :drawer_type="'MapLayersList'" :show_drawer.sync="showLeftDrawer" v-show="showLeftDrawer"></MapsDrawer>
    <MapsDrawer :drawer_type="'FeatureInfo'" :show_drawer.sync="$store.state.feature_info_drawer.show" v-show="$store.state.feature_info_drawer.show"></MapsDrawer>

    <v-system-bar height="0.01" flat dense>
      <v-btn
          height="32"
          width="32"
          v-if="!showLeftDrawer"
          fab small color="white" tile
          style="z-index: 10; margin-top: 50px;"
          @click="showDrawer('Left')">
        <v-icon color="black">mdi-chevron-double-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-system-bar>

    <MapsMainMap></MapsMainMap>

  </div>
</template>

<script>

import MapsDrawer from "@/components/Maps/MapsDrawer";
import MapsMainMap from "@/components/Maps/MapsMainMap";

export default {
  name: "MapsView",
  components: {
    MapsDrawer, MapsMainMap
  },
  props: {},
  data() {
    return {
      showLeftDrawer: true,
    }
  },
  methods: {
    showDrawer(side) {
      this.$set(this, `show${side}Drawer`, true)
      console.log(this.showLeftDrawer)
    },
  },
  watch: {},
  mounted() {
  },
}
</script>

<style scoped>

</style>
