import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet";
import {map, control} from 'leaflet'
// import "esri-leaflet"
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import "leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled"
import 'leaflet-makimarkers'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/leaflet.markercluster'
import 'leaflet.heat'

// import 'leaflet-distortableimage/dist/vendor'
// import 'leaflet-distortableimage/dist/vendor.css'
// import 'leaflet-distortableimage/dist/leaflet.distortableimage.css'
// import 'leaflet-distortableimage/dist/leaflet.distortableimage'

// import 'leaflet-geotiff-2'
// import "leaflet-geotiff-2/dist/leaflet-geotiff-rgb";
// import "leaflet-geotiff-2/dist/leaflet-geotiff-vector-arrows";
// import "leaflet-geotiff-2/dist/leaflet-geotiff-plotty"; // requires plotty

import store from "@/store";

export async function set_leaflet_libraries() {

    if (store.state.L === null) {
        L.MakiMarkers.accessToken = store.state.secrets.mapbox

        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('@/assets/images/marker-icon-2x.png'),
            iconUrl: require('@/assets/images/marker-icon.png'),
            shadowUrl: require('@/assets/images/marker-shadow.png'),
        });
        store.state.L = L
    }
}

export async function init_map(div_id, center_lat, center_lon, center_zoom) {

    store.state.map = map(div_id, {
        editable: true,
        zoomControl: false,
        attributionControl: true,
        boxZoom: true,
        touchZoom: true,
        continuousWorld: false,
        noWrap: true,
        zoomSnap: 0,
        zoomDelta: 0.01,
        maxZoom: 24,
    }).setView([center_lat, center_lon], center_zoom);

    store.state.map.scrollWheelZoom = true
    store.state.map.dragging.enable();
    store.state.map.touchZoom.enable();
    store.state.map.doubleClickZoom.enable();
    store.state.map.keyboard.enable();
    control.scale({maxWidth: 150, position: 'bottomright'}).addTo(store.state.map);


}

// NOTE: for point layer click issues with VectorGrid see the following post
// https://stackoverflow.com/questions/58072432/popup-window-for-points-using-leaflet-vectorgrid-error-lat-of-undefined
