<template>
  <div>
    <MapsVectorLayer
        v-if="layer_type === 'vector'"
        :map_id.sync="map_id"
        :layer_id.sync="layer_id"
        :z_index.sync="z_index"
        :layers_list_init_function="layers_list_init_function"
        :update_map_layer_dict="update_map_layer_dict"
    ></MapsVectorLayer>
<!--    <MapsRasterLayer-->
<!--        v-if="layer_type === 'raster'"-->
<!--        :map_id.sync="map_id"-->
<!--        :layer_id.sync="layer_id"-->
<!--        :z_index.sync="z_index"-->
<!--        :layers_list_init_function="layers_list_init_function"-->
<!--        :update_map_layer_dict="update_map_layer_dict"-->
<!--    ></MapsRasterLayer>-->
<!--    <MapsTileLayer-->
<!--        v-if="layer_type === 'tile' && layer_format !== 'leaflet_wms'"-->
<!--        :map_id.sync="map_id"-->
<!--        :layer_id.sync="layer_id"-->
<!--        :z_index.sync="z_index"-->
<!--        :layers_list_init_function="layers_list_init_function"-->
<!--        :update_map_layer_dict="update_map_layer_dict"-->
<!--    ></MapsTileLayer>-->
    <MapsTileLayerTMS
        v-if="layer_type === 'tile' && layer_format === 'leaflet_tms'"
        :map_id.sync="map_id"
        :layer_id.sync="layer_id"
        :z_index.sync="z_index"
        :layers_list_init_function="layers_list_init_function"
        :update_map_layer_dict="update_map_layer_dict"
    ></MapsTileLayerTMS>
    <MapsTileLayerWMS
        v-if="layer_type === 'tile' && layer_format === 'leaflet_wms'"
        :map_id.sync="map_id"
        :layer_id.sync="layer_id"
        :z_index.sync="z_index"
        :layers_list_init_function="layers_list_init_function"
        :update_map_layer_dict="update_map_layer_dict"
    ></MapsTileLayerWMS>


  </div>
</template>

<script>

import MapsVectorLayer from "@/components/Maps/MapsVectorLayer";
// import MapsRasterLayer from "@/components/Maps/MapsRasterLayer";
// import MapsTileLayer from "@/components/Maps/MapsTileLayer";
import MapsTileLayerTMS from "@/components/Maps/MapsTileLayerTMS";
import MapsTileLayerWMS from "@/components/Maps/MapsTileLayerWMS";

export default {
  name: "MapsLayer",
  components: {
    MapsVectorLayer,
    // MapsRasterLayer,
    // MapsTileLayer,
    MapsTileLayerTMS,
    MapsTileLayerWMS,
  },
  props: {
    map_id: {type: String},
    layer_id: {type: String},
    layer_type: {type: String},
    layer_format: {type: String},
    z_index: {type: Number},
    layers_list_init_function: {type: Function},
    update_map_layer_dict: {type: Function},
  },
  data() {
    return {}
  },
  methods: {},
  watch: {},
  mounted() {
  },
}
</script>

<style scoped>

</style>
