<template>
  <div>
    <v-tabs
        v-model="tab1"
        fixed-tabs
        height="24"
    >
      <v-tabs-slider color="purple"></v-tabs-slider>

      <v-tab href="#tablevars">
        <v-btn small text color="green" style="text-transform: none;">
          <v-icon small left>mdi-chart-line</v-icon>
          Table Vars
        </v-btn>
      </v-tab>
      <v-tab href="#linkedtablevars">
        <v-btn small text color="purple" style="text-transform: none;">
          <v-icon small left>mdi-chart-line-stacked</v-icon>
          Linked Table Vars
        </v-btn>
      </v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab1" style="height: 100%; min-height: 100%;">
      <v-tab-item value="tablevars" style="height: 100%; min-height: 100%;">
        <v-divider></v-divider>

        <v-tabs
            v-model="tab2"
            fixed-tabs
            height="24"
        >
          <v-tabs-slider color="orange"></v-tabs-slider>
          <v-tab href="#tablevars_plots">
            <v-btn small text color="green" style="text-transform: none;">
              <v-icon small left>mdi-chart-line</v-icon>
              Table Vars Plots
            </v-btn>
          </v-tab>

          <v-tab href="#tablevars_summary">
            <v-btn small text color="green" style="text-transform: none;">
              <v-icon small left>mdi-chart-box</v-icon>
              Table Vars Summary
            </v-btn>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab2" style="height: 100%; min-height: 100%;">
          <v-tab-item value="tablevars_plots" style="height: 100%; min-height: 100%;">
            <v-divider></v-divider>
            <PlotsTableVariables
                v-if="tab2 === 'tablevars_plots'"
                :json_arrays.sync="json_arrays"
                :schema.sync="schema"
                :selected_uid_list.sync="selected_uid_list"
            ></PlotsTableVariables>
          </v-tab-item>


          <v-tab-item value="tablevars_summary" style="height: 100%; min-height: 100%;">
            <v-divider></v-divider>
            <PlotsSummaryStat
                v-if="tab2 === 'tablevars_summary'"
                :json_arrays.sync="json_arrays"
                :schema.sync="schema"
                :selected_uid_list.sync="selected_uid_list"
            ></PlotsSummaryStat>
          </v-tab-item>

        </v-tabs-items>

      </v-tab-item>

      <v-tab-item value="linkedtablevars" style="height: 100%; min-height: 100%;">
        <v-divider></v-divider>

        <v-tabs
            v-model="tab3"
            fixed-tabs
            height="24"
        >
          <v-tabs-slider color="purple"></v-tabs-slider>
          <v-tab href="#linkedtablevars_plots">
            <v-btn small text color="purple" style="text-transform: none;">
              <v-icon small left>mdi-chart-line</v-icon>
              Linked Table Vars Plots
            </v-btn>
          </v-tab>

          <v-tab href="#linkedtablevars_summary" v-if="false">
            <v-btn small text color="purple" style="text-transform: none;">
              <v-icon small left>mdi-chart-box</v-icon>
              Linked Table Vars Summary
            </v-btn>
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab3" style="height: 100%; min-height: 100%;">
          <v-tab-item value="linkedtablevars_plots" style="height: 100%; min-height: 100%;">
            <v-divider></v-divider>
            <PlotsLinkedTableVariables
                v-if="tab3 === 'linkedtablevars_plots'"
                :json_arrays.sync="json_arrays"
                :schema.sync="schema"
                :selected_uid_list.sync="selected_uid_list"
                :map_layer_dict="map_layer_dict"
            ></PlotsLinkedTableVariables>
          </v-tab-item>


          <v-tab-item value="linkedtablevars_summary" style="height: 100%; min-height: 100%;" v-if="false">
            <v-divider></v-divider>
            <PlotsLinkedTableSummaryStat
                v-if="tab3 === 'linkedtablevars_summary'"
                :json_arrays.sync="json_arrays"
                :schema.sync="schema"
                :selected_uid_list.sync="selected_uid_list"
                :map_layer_dict="map_layer_dict"
            ></PlotsLinkedTableSummaryStat>
          </v-tab-item>

        </v-tabs-items>


      </v-tab-item>


    </v-tabs-items>


  </div>

</template>

<script>


import PlotsTableVariables from "@/components/Plots/PlotsTableVariables";
import PlotsLinkedTableVariables from "@/components/Plots/PlotsLinkedTableVariables";
import PlotsSummaryStat from "@/components/Plots/PlotsSummaryStat";
import PlotsLinkedTableSummaryStat from "@/components/Plots/PlotsLinkedTableSummaryStat";

/*
* for one record, plot line or bar chart of values for selected columns
* similar for average, median, min, max of selected columns for all records or for selected records
*  */


export default {
  name: "MapsVectorLayerPlots",
  props: {
    json_arrays: {type: Object},
    schema: {type: Object},
    selected_uid_list: {type: Array},
    map_layer_dict: {type: Object},
  },
  components: {
    PlotsTableVariables,
    PlotsLinkedTableVariables,
    PlotsSummaryStat,
    PlotsLinkedTableSummaryStat
  },
  data() {
    return {
      tab1: 'single_var',
      tab2: null,
      tab3: null,

    }
  },
  methods: {

    async init() {
    },

  },
  watch: {},
  beforeDestroy() {
  },

  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}

</style>
