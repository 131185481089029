import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export let store = new Vuex.Store({
    state: {
        version: '22.40',
        stage: 'dev',
        is_online: true,
        L: null,
        init_loaded: false,
        device_info: {},

        auth: {
            user: {
                uid: null
            },
            isLoggedIn: false,
        },

        auto_apply_sync: false, // if true, will automatically apply the sync.
        is_sync_ready: false,

        is_sync_done: false,

        user_settings: {
            user_profile: {},
            user_subscriptions: {},
        },

        cur_plan: {
            name: 'Free Beta',
            expiry_date: null,
            limits: {
                maps: 50,
                num_files: 100,
                total_size: 100000000, // 100 MB
                sharing: false,
            },
        },

        // FLAGS
        draw_edit_flag: {
            mode: null, // draw, edit
            layer_id: null,
        },
        identify_flag: false,
        feature_info_drawer: {
            show: false,
            read_as_dict: false,
            row_uid: null,
            rec: {},
            schema: {},
            feature_style: {},
            feature_type: 'Polygon',
            layer_name: null,
            layer_id: null,

            rec_dict: {},

        },

        map: null,
        maps_map: null,

        snackbar: {
            show: false,
            text: null,
            color: 'pink',
            timeout: 5000,
            multi_line: false,
        },
        secrets: {
            mapbox: 'pk.eyJ1IjoiYWxpZXJzaGFkaSIsImEiOiJjazg2dDJnNGIwbGFiM2ZtY3ExdjQ3Z3BvIn0.UlrwVCrd5ZPJ68BU2Z0yYA',
        },
    },
    mutations: {},
    actions: {},
    modules: {}
})

if (process.env.NODE_ENV === 'production') {
    store.state.stage = 'prod'
} else {
    store.state.stage = 'dev'
}


export default store;


export const templates = {

    anonymous_user: {
        uid: 'anonymous',
        email: 'anonymous',
        isAnonymous: true
    },


    qfDocument: {
        id: null,
        name: null,
        type: null, // layer (vector, tile, raster), style, query, table, photo, doc, template, bookmark

        config: {}, // qfDocument__config__templates[$type]

        audit: [], // template.audit

        version: 0,
        is_uploaded: false,

        docBlob: {
            has_blob: false, // set it true for layers
            version: 0,
            size: 0,
            type: 'json', // json, string, image, etc.
            path: null,

            is_zipped: true,
            is_uploaded: false,
            cloud_only: false,
        },

        revisions: {}, // key: get_utc_dt_iso(), value -> copy of qfDocument

    },

    audit: { // use app_utils.get_audit_obj
        state: null, // C or U
        version: null,
        utc_iso_dt: null,
        uid: null,
        device: null, // store.state.device_info
        archive_id: null,

    },

    qfDocument__config__templates: {
        layer: {
            show_description_and_comment: false,
            description: null,
            comments: null,

            is_locked: false,
            stage: {
                show: false,
                stage: 'Development', // Development, Testing, Production
            },

            layer_type: null, // vector, raster, tile
            layer_format: null, // for vector(GeoJSON) for raster (png, jpeg, tiff), for tile (wms, etc.), for table (json)
            feature_type: null, // for vector(Point, Line, Polygon) for raster (null), for tile (null)

            is_imported: false,

            import_info: {
                file_name: null,
                file_size: null,
                file_type: null,
                file_lastModified: null,
            },

            current_state: null, // 'Create', 'Edit'
            edit_state: {
                uid: null, // when a user starts editing a layer. we can lock it and let other users know
                utc_iso_dt: null, // get_utc_dt_iso()
            },

            coordinate_precision: 6,

            schema: {}, // uid: {label: null, type: null, config: {precision: null, timezone: null,}}

            tile_layer_options: {
                is_system: false,
                leaflet_tms: {
                    url: null,
                    api_key_key: null,
                    api_key_value: null,
                    attribution: null,
                    // tms
                    minZoom: 0,
                    maxZoom: 18,
                    // grid
                    tileSize: 256,
                    opacity: 1,
                    maxNativeZoom: null,
                    minNativeZoom: null,


                },
                leaflet_wms: {
                    url: null,
                    api_key_key: null,
                    api_key_value: null,
                    attribution: null,
                    // wms
                    layers: null,
                    styles: null,
                    format: 'image/png',
                    transparent: false,
                    version: '1.1.1',
                    crs: null,
                    uppercase: false,
                    // tms
                    minZoom: 0,
                    maxZoom: 18,
                    // grid
                    tileSize: 256,
                    opacity: 1,
                    maxNativeZoom: null,
                    minNativeZoom: null,
                    LegendURL: null,
                },
                mapbox_raster_tile: {
                    url: null,
                    access_token: null,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    // tms
                    minZoom: 0,
                    maxZoom: 18,
                    // grid
                    tileSize: 256,
                    opacity: 1,
                    maxNativeZoom: null,
                    minNativeZoom: null,
                },
                mapbox_vector_tile: {
                    url: null,
                    access_token: null,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    // tms
                    minZoom: 0,
                    maxZoom: 18,
                    // grid
                    tileSize: 256,
                    opacity: 1,
                    maxNativeZoom: null,
                    minNativeZoom: null,
                },
                esri_dynamic_map_layer: {
                    url: null,
                    token: null,
                    attribution: null,
                    layers: null,
                    format: 'png24',
                    transparent: true,
                    opacity: 1,
                    useCors: true,
                    minZoom: 0,
                    maxZoom: 18,

                },
                esri_feature_layer: {},

            },


        },
        style: {
            show_description_and_comment: false,
            description: null,
            comments: null,

            is_locked: false,
            stage: {
                show: false,
                stage: 'Development', // Development, Testing, Production
            },

            layer_type: null,
            feature_type: null,
            style: {},

        },
        query: {
            show_description_and_comment: false,
            description: null,
            comments: null,

            is_locked: false,
            stage: {
                show: false,
                stage: 'Development', // Development, Testing, Production
            },
            query_groups: [],

        },
        table: {
            show_description_and_comment: false,
            description: null,
            comments: null,

            is_locked: false,
            stage: {
                show: false,
                stage: 'Development', // Development, Testing, Production
            },

            table_type: null, // table
            table_format: null, // json

            is_imported: false,

            import_info: {
                file_name: null,
                file_size: null,
                file_type: null,
                file_lastModified: null,
            },

            current_state: null, // 'Create', 'Edit'
            edit_state: {
                uid: null, // when a user starts editing a layer. we can lock it and let other users know
                utc_iso_dt: null, // get_utc_dt_iso()
            },

            schema: {}, // uid: {label: null, type: null, config: {precision: null, timezone: null,}}

        },

    },

    // qfCollection ========================================
    qfCollection__qfDocument__groups: {
        layer: 'layers',
        table: 'tables',
        doc: 'docs',
        style: 'styles',
        query: 'queries',
        bookmark: 'bookmarks',
        template: 'templates',
    },
    qfCollection: {
        id: null,
        name: null,
        type: null, // map, dashboard, ...

        config: {}, // qfCollection__config__templates[$type]

        layers: {
            dict: {}, // see qfCollection__qfDocumentType__dict_templates.layers
            list: [], // list of qfDocument.id of used layers
            config: {}
        },
        tables: {
            dict: {}, // see qfCollection__qfDocumentType__dict_templates.tables
            list: [], // list of qfDocument.id of used tables
            config: {}
        },
        docs: {
            dict: {}, // see qfCollection__qfDocumentType__dict_templates.docs
            list: [],
            config: {}
        },
        styles: {
            dict: {}, // see qfCollection__qfDocumentType__dict_templates.styles
            list: [],
            config: {}
        },
        queries: {
            dict: {}, // see qfCollection__qfDocumentType__dict_templates.queries
            list: [],
            config: {}
        },
        bookmarks: {
            dict: {},
            list: [],
            config: {}
        },
        templates: {
            dict: {},
            list: [],
            config: {}
        },

        // for any new type, add it to qfCollection__qfDocument__groups


        audit: [], // {state: 'c or u', utc_dt: null, uid: null, device: null, archive_id: null}

        version: 0,
        revisions: {}, // key: get_utc_dt_iso(), value -> copy of qfCollection

    },
    qfCollection_config_templates: {
        map: {
            show_description_and_comment: false,
            description: null,
            comments: null,

            is_locked: false,
            stage: {
                show: false,
                stage: 'Development', // Development, Testing, Production
            },

            current_state: null, // 'create', 'edit'
            edit_state: {
                uid: null, // when a user starts editing a layer. we can lock it and let other users know
                utc_dt: null,
            },

        },
        dashboard: {},
    },

    qfCollection__qfDocumentType__dict_templates: { // used by check_template
        layers: {
            id: null,

            config: {
                show_layer: true,
                show_details: true,
            },

            style: {}, // templates.feature_style[feature_type] and also similar to style assets
            style_config: {
                style_id: null,
            },
            style_revisions: {}, // // key: get_utc_dt_iso(), value -> copy of style

            query: {},// full copy of the query asset (see assets for query)
            query_config: {
                query_id: null,
                query_action: null, // Select or Filter
                fid_list: [],
                is_applied: false,
            },
            query_revisions: {}, // // key: get_utc_dt_iso(), value -> copy of query

            link: {}, // id of tables and rules for each table. $table_id{link_uid: null}
            link_config: {},
            link_revisions: {}, // key: get_utc_dt_iso(), value -> copy of link

        },
        tables: {
            id: null,

            config: {
                show_table: true,
                show_details: false,
            },

            query: {},// full copy of the query asset (see assets for query)
            query_config: {
                query_id: null,
                query_action: null, // Select or Filter
                fid_list: [],
                is_applied: false,
            },
            query_revisions: {}, // key: get_utc_dt_iso(), value -> copy of style


        },
        docs: {},
        styles: {},
        queries: {},
        bookmarks: {},
        templates: {},
    },


    feature_style: {
        Polygon: { // this is asset -> obj
            feature_type: 'Polygon',
            style_type: 'simple', // simple, categorized, graduated
            style_rule: {
                col_name: null,
                col_type: 'Number', // Number, String
                reverse_colors: false,
                style_obj: null, // {name: 'RdGr', colors_list: [{color: '#123', val: 100}]}
            },

            fillColor: '#9370db',
            fillOpacity: 0.4,
            fill: true,

            color: '#9370db',
            weight: 5,
            opacity: 0.65,
        },
        Line: {
            feature_type: 'Line',
            style_type: 'simple', // simple, categorized, graduated
            style_rule: {
                col_name: null,
                col_type: 'Number', // Number, String
                style_obj: null, // {name: 'RdGr', colors_list: [{color: '#123', val: 100}]}
            },

            color: '#9370db',
            weight: 5,
            opacity: 0.65,
        },
        Point: {
            feature_type: 'Point',
            style_type: 'simple', // simple, categorized, graduated
            style_rule: {
                col_name: null,
                col_type: 'Number', // Number, String
                style_obj: null, // {name: 'RdGr', colors_list: [{color: '#123', val: 100}]}
            },

            fillColor: '#9370db',
            fillOpacity: 0.4,
            fill: true,

            color: '#9370db',
            weight: 5,
            opacity: 0.65,

            icon_type: 'maki',
            icon_name: 'star',
            icon_size: 's',
            icon_opacity: 0.65,

            marker_type: 'circleMarker', // circleMarker or marker
            radius: 3,
            cluster: {
                is_cluster: false,
            },
            heatmap: {
                has_heatmap: false,
            },
        },


    },

    user_profile: {
        version: 0,
        first_name: null,
        last_name: null,
        full_name: null,
        abrv_name: null,
    },
    subscriptions: {
        current: {
            name: null,
            expiry_date: null,
        },
        history: []
    },
    cur_plan: {
        name: 'Free',
        expiry_date: null,
        limits: {
            projects: 1,
            num_files: 10,
            total_size: '10 MB',
            sharing: false,


        },
    },


}

