<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left color="purple">mdi-table-cog</v-icon>
          <span class="body-1">
            Layer Schema
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-card
            v-for="(item, uid) in the_schema"
            :key="uid"
            v-if="!item.is_system"
            style="max-width: 400px; margin-bottom: 10px;">
          <v-card-text>
            <v-text-field
                outlined
                dense
                v-model="item.label"
                label="Column Name"
                :hide-details="false"
            ></v-text-field>

            <v-select
                outlined
                dense
                v-model="item.type"
                :items="['String', 'Number', 'Date', 'URL']"
                label="Type"
                :hide-details="false"
            ></v-select>
            <v-btn small @click="on_click_DriveArraySummaryDialog(uid)">
              <v-icon left color="indigo">mdi-file-chart</v-icon>
              Show Summary
            </v-btn>
            <v-btn small
                   :disabled="Object.keys(the_schema).length === 1"
                   @click="delete_column(uid)">
              <v-icon color="red">mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>

        <v-col>
          <v-btn text small @click="add_column">
            <v-icon small>mdi-plus</v-icon>
            Add Column
          </v-btn>
        </v-col>

        <DriveArraySummaryDialog
            v-if="DriveArraySummaryDialog_show"
            :show_dialog.sync="DriveArraySummaryDialog_show"
            :callback_function="null"
            :in_array="DriveArraySummaryDialog_in_array"
            :the_label="DriveArraySummaryDialog_the_label"
            :the_type="DriveArraySummaryDialog_the_type"
        ></DriveArraySummaryDialog>
      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="purple"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import DriveArraySummaryDialog from "@/components/Drive/DriveArraySummaryDialog";
import {deepClone, getRandomIntInclusive} from "@/services/generic";
import {v4 as uuidv4} from "uuid";

export default {
  name: "DriveSchemaDialog",
  props: {
    show_dialog: {type: Boolean},
    update_schema_callback: {type: Function},
    schema: {type: Object},
    json_arrays: {type: Object},
    idx: {type: Number},
  },
  components: {DriveArraySummaryDialog},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },
      the_schema: {},
      the_json_arrays: {},

      DriveArraySummaryDialog_show: false,
      DriveArraySummaryDialog_in_array: [],
      DriveArraySummaryDialog_the_label: null,
      DriveArraySummaryDialog_the_type: null,
    }
  },
  methods: {

    async delete_column(uid) {
      this.$delete(this.the_schema, uid)
      this.$delete(this.the_json_arrays, uid)
    },
    async get_null_array(){
      let uid = Object.keys(this.the_schema)[0]
      let arr_length = this.the_json_arrays[uid].length
      return Array(arr_length).fill(null)

    },
    async add_column() {
      let uid = uuidv4().replaceAll('-', '')
      let item_schema = {
        label: `New Col ${await getRandomIntInclusive(1, 99)}`,
        type: 'String',
        config: {precision: null, timezone: null,}
      }
      this.$set(this.the_schema, uid, item_schema)

      this.the_json_arrays[uid] = await this.get_null_array()
    },

    on_click_DriveArraySummaryDialog(uid) {
      this.DriveArraySummaryDialog_show = true
      this.DriveArraySummaryDialog_in_array = this.the_json_arrays[uid]
      this.DriveArraySummaryDialog_the_label = this.the_schema[uid].label
      this.DriveArraySummaryDialog_the_type = this.the_schema[uid].type
    },

    async submit() {
      await this.update_schema_callback(this.the_schema, this.the_json_arrays, this.idx)
      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      this.the_schema = deepClone(this.schema)
      this.the_json_arrays = deepClone(this.json_arrays)

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
