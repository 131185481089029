import {v4 as uuidv4} from "uuid";
import {store} from "@/store";
// import {idb_c, idb_u_documents} from "@/services/idb_api";
import {rtdb_c, rtdb_d, rtdb_r} from "@/services/fb_rtdb_api";
import {async_storage_get_blob} from "@/services/fb_storage_api";


export async function c_task(doc_obj){
    const uid = store.state.auth.user.uid
    let doc_id = doc_obj.id
    if (doc_id === null) {
        doc_id = uuidv4().replaceAll('-', '')
        doc_obj.id = doc_id
    }
    doc_obj.uid = uid

    // await idb_u_documents(doc_id, doc_obj, 'mewa_gee_task', false, false)
    await rtdb_c(`mewa/${uid}/mewa_gee_task/${doc_id}`, doc_obj)

}

export async function r_tasks(){
    const uid = store.state.auth.user.uid
    let gee_tasks = await rtdb_r(`mewa/${uid}/mewa_gee_task`)
    console.log(gee_tasks)
    if (gee_tasks === null){
        gee_tasks = {}
    }
    // if (gee_tasks !== null){
    //     await idb_c('mewa_gee_task', gee_tasks, false)
    // }
    return gee_tasks
}

export async function d_task(doc_id){
    const uid = store.state.auth.user.uid
    rtdb_d(`mewa/${uid}/mewa_gee_task/${doc_id}`)
}

export async function import_gee_file(filename){
    const uid = store.state.auth.user.uid
    let file_path = null
    let blob_obj = null

    file_path = `user_data/${uid}/GEE_MEWA_task_files/${filename}`

    // switch (task_type){
    //     case 'Delineate fields':
    //         file_path = `user_data/${uid}/GEE_MEWA_task_files/gee_field_delineation_demo__${task_id}.geojson`
    //         break
    //     case 'Water Use':
    //         file_path = `user_data/${uid}/GEE_MEWA_task_files/gee_water_use_demo__m__${task_id}.csv`
    //         break
    //     default:
    //         break
    // }
    // if (file_path !== null){

    blob_obj = await async_storage_get_blob(file_path)
    console.log(blob_obj)

    // }
    return blob_obj



}

export async function check_tasks(){

}
