<template>
  <v-card flat :loading="loading">
    <v-card-text>
      <p></p>

      <v-col>
        <v-btn small @click="create_map">
          <v-icon small left>mdi-new-box</v-icon>
          New Map
        </v-btn>
      </v-col>


      <v-col v-if="maps_table.length > 2">
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
              style="max-width: 300px;"
              background-color="blue-grey lighten-5"
              v-model="search"
              append-icon="mdi-magnify"
              color="green"
              label="Search for a map"
              outlined
              dense
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-row>

      </v-col>

      <v-data-table
          :headers="maps_table_headers"
          :items="maps_table"
          :items-per-page="-1"
          :search="search"
          item-key="map_id"
          class="elevation-1"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          :hide-default-footer="maps_table.length < 10"

      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container style="margin-bottom: 10px; margin-top: 10px;">

              <p class="caption" v-if="maps[item.map_id].layers.list.length > 0">
                <b>List of layers:</b><br>
                <span v-for="layer_item in maps[item.map_id].layers.dict" :key="layer_item.id">
                  {{ layers[layer_item.id].name }}<br>
                </span>
              </p>

              <v-row justify="space-around">
                <v-btn @click="edit_map(item.map_id)" small style="margin-right: 10px;">
                  <v-icon small left color="green">mdi-pencil</v-icon>
                  Edit
                </v-btn>

                <v-btn @click="duplicate_map(item.map_id)" small style="margin-right: 10px;">
                  <v-icon small left color="purple">mdi-content-save-all</v-icon>
                  Save As
                </v-btn>

                <v-btn @click="delete_map(item.map_id)" small style="margin-right: 10px;">
                  <v-icon small left color="red">mdi-delete</v-icon>
                  Delete
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </td>

        </template>
      </v-data-table>
      <DriveMapDialog
          v-if="DriveMapDialog_show"
          :show_dialog.sync="DriveMapDialog_show"
          :callback_function="init_list_maps"
          :map_id="map_id"
          :action="DriveMapDialog_action"
      ></DriveMapDialog>

      <DriveConfirmDeleteDialog
          v-if="DriveConfirmDeleteDialog_show"
          :show_dialog.sync="DriveConfirmDeleteDialog_show"
          :callback_function="after_delete_map"
          :dialog_title="'Delete Map'"
          :item_type_name="'Map'"
          :item_type="'qfCollection'"
          :item_name="delete_item.name"
          :item_id="delete_item.id"
      ></DriveConfirmDeleteDialog>

    </v-card-text>
  </v-card>
</template>

<script>

import prettyBytes from "pretty-bytes";
import DriveMapDialog from "@/components/Drive/DriveMapDialog";
import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";

import {get_qfCollection_type_as_dict, get_qfDocument_type_as_dict} from "@/services/app_utils";

export default {
  name: "DriveMapsList",
  props: {
    callback_function: {type: Function},
  },
  components: {
    DriveMapDialog,
    DriveConfirmDeleteDialog
  },
  data() {
    return {
      loading: false,

      layers: {},
      maps: {},

      map_id: null,

      maps_table: [],
      search: null,
      maps_table_headers: [
        {text: 'Name', value: 'map_name'},
        {text: 'Description', value: 'map_description'},
        {text: 'Layers', value: 'num_layers'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],

      selected_items: [],

      DriveMapDialog_show: false,
      DriveMapDialog_action: 'Create',

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,
      },

    }
  },
  methods: {

    async remove_from_selected(map_id) {
      let selected_items = []
      for (let item of this.selected_items) {
        if (item.map_id !== map_id) {
          selected_items.push(item)
        }
      }
      this.selected_items = selected_items
    },

    async delete_map(map_id) {
      this.delete_item.name = this.maps[map_id].name
      this.delete_item.id = map_id
      this.DriveConfirmDeleteDialog_show = true

    },
    async after_delete_map(map_id) {
      this.delete_item.name = null
      this.delete_item.id = null
      await this.init_list_maps()
      if (this.callback_function !== null) {
        await this.callback_function(this.map_id)
      }
      await this.remove_from_selected(map_id)
    },
    create_map(){
      this.map_id = null
      this.DriveMapDialog_action = 'Create'
      this.DriveMapDialog_show = true
    },

    edit_map(map_id) {
      this.map_id = map_id
      this.DriveMapDialog_action = 'Edit'
      this.DriveMapDialog_show = true
    },
    duplicate_map(map_id) {
      this.map_id = map_id
      this.DriveMapDialog_action = 'Duplicate'
      this.DriveMapDialog_show = true
    },

    calc_num_layers(map_obj) {
      if ('layers' in map_obj) {
        return map_obj.layers.list.length
      } else {
        return 0
      }
    },
    async init_list_maps() {

      if (this.callback_function !== null) {
        this.callback_function(this.map_id)
      }

      this.map_id = null

      this.layers = await get_qfDocument_type_as_dict('layer')
      this.maps = await get_qfCollection_type_as_dict('map')

      this.maps_table = []
      for (let map_id in this.maps) {

        let map_obj = this.maps[map_id]

        this.maps_table.push({
          map_id: map_id,
          map_name: map_obj.name,
          map_description: map_obj.config.description,
          num_layers: this.calc_num_layers(map_obj),
        })
      }

    },
    the_prettyBytes(num_byte) {
      return prettyBytes(num_byte)
    },
  },
  watch: {},
  mounted() {
    this.init_list_maps()
  },
}
</script>

<style scoped>

</style>
