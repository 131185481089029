<template>

  <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          @click="reset_form"
          text
          small
          v-bind="attrs"
          v-on="on"
      >
        <v-icon small left>mdi-login</v-icon>
        Log In
      </v-btn>
    </template>
    <v-card
        :loading="loading"
    >

      <v-toolbar color="indigo" dense dark>

        <v-toolbar-title>
          <span v-if="!forgot_password_is_active">
            Log In
          </span>
          <span v-if="forgot_password_is_active">
            Reset Password
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
          style="margin-top: 20px;"
          contain
          max-height="60"
          :src="require('@/assets/questfeed/logo_h.png')"
      ></v-img>
      <v-card-text>


        <v-form
            ref="form"
            v-model="is_valid"
            lazy-validation
        >

          <v-col cols="12">
            <v-text-field
                prepend-inner-icon="mdi-email"
                label="Email"
                v-model="email"
                :rules="emailRules"
                :disabled="loading"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="12"
                 v-if="!forgot_password_is_active">
            <v-text-field
                prepend-inner-icon="mdi-lock"
                :append-outer-icon="password_visible? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                v-model="password"
                :rules="passwordRules"
                :type="password_visible? 'text' : 'password'"
                @click:append-outer="password_visible = !password_visible"
                :disabled="loading"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-btn
                v-if="!forgot_password_is_active"
                @click="forgot_password_is_active = true"
                text small style="text-transform: none; color: dimgray;">I forgot my password
            </v-btn>
          </v-col>

        </v-form>

        <p style="color: red;">
          {{ server_error_message }}
        </p>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            small
            text
            :disabled="loading"
            @click="cancel"
        >
          Close
        </v-btn>
        <v-btn
            v-if="!forgot_password_is_active"
            small
            class="primary"
            :disabled="loading"
            @click="login"
        >
          Log In
        </v-btn>

        <v-btn
            v-if="forgot_password_is_active"
            small
            class="primary"
            :disabled="loading"
            @click="send_password_reset_email"
        >
          Send Password Reset Email
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import {signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import {auth} from "@/firebase/firebase";
import {idb_upsert_user} from "@/services/idb_api";

export default {
  name: "LogIn",
  data: () => ({
    dialog: false,
    loading: false,
    is_valid: true,
    email: null,
    password: null,
    password_visible: false,
    server_error_message: null,

    forgot_password_is_active: false,

    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required'
    ],
  }),
  methods: {
    reset_form() {
      this.loading = false
      this.email = null
      this.password = null
      this.password_visible = false
      this.server_error_message = null
      this.forgot_password_is_active = false

    },

    async login() {

      this.server_error_message = null

      await this.$refs.form.validate()

      if (this.is_valid) {
        this.loading = true
        signInWithEmailAndPassword(auth, this.email, this.password)
            .then(async (userCredential) => {

              let user = userCredential.user;
              console.log('user in login', user)

              this.$store.state.auth.user = user
              this.$store.state.auth.isLoggedIn = true

              await idb_upsert_user(user, true)
              await this.close()
            })
            .catch((error) => {
              let errorCode = error.code;
              let errorMessage = error.message;
              console.log(errorCode)
              console.log(errorMessage)
              this.server_error_message = errorMessage
              this.loading = false
            });
      }

    },
    async send_password_reset_email() {

      this.server_error_message = null

      await this.$refs.form.validate()

      if (this.is_valid) {
        this.loading = true
        sendPasswordResetEmail(auth, this.email)
            .then(() => {
              this.close()
            })
            .catch((error) => {
              let errorCode = error.code;
              let errorMessage = error.message;
              console.log(errorCode)
              console.log(errorMessage)
              this.server_error_message = errorMessage
              this.loading = false
            });
      }


    },
    async close() {
      this.dialog = false
      this.loading = false
      this.server_error_message = null

      this.is_valid = true
    },
    async cancel() {
      await this.$refs.form.reset()
      await this.$refs.form.resetValidation()

      await this.close()
    },


  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
