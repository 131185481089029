import Localbase from "localbase";
import {store} from '@/store'
import * as CryptoJS from 'crypto-js'
import {DateTime} from "luxon";
import {file_to_zip_blob} from "@/services/app_utils";
import {get_utc_dt_iso} from "@/services/datetime_api";

let db = new Localbase('questfeed')
db.config.debug = false


export async function encrypt_obj(data, secret_key) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secret_key).toString();
}

export async function decrypt_obj(ciphertext, secret_key) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, secret_key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}


export async function idb_get_uid() {
    let user = store.state.auth.user
    if (user === null) {
        return 'anonymous'
    } else {
        return store.state.auth.user.uid
    }
}

// USERS document

export async function idb_upsert_user(user_obj, is_active = true) {
    let idb_users = await db.collection('users').doc('users').get()
    if (idb_users === null) idb_users = {}
    idb_users[user_obj.uid] = {
        email: user_obj.email,
        is_active: is_active,
    }
    if (is_active) {
        for (let uid in idb_users) {
            if (uid !== user_obj.uid) {
                idb_users[uid].is_active = false
            }
        }
    }

    await db.collection('users').doc('users').set(idb_users)
}

export async function idb_update_user(user_obj) {

}

export async function idb_r_users() {
    let idb_users = await db.collection('users').doc('users').get()
    if (idb_users === null) idb_users = {}
    if (!('anonymous' in idb_users)) {
        await idb_upsert_user({uid: 'anonymous', email: 'anonymous', is_active: false})
        idb_users = await db.collection('users').doc('users').get()
    }

    return idb_users
}


// OTHERS document

export async function idb_c(key, val, is_enc = false) {
    let uid = await idb_get_uid()
    if (is_enc) {
        val = encrypt_obj(val, uid)
    }
    await db.collection(uid).doc(key).set(val)
}

export async function idb_r(key, is_enc = false) {
    let uid = await idb_get_uid()
    let idb_rec = await db.collection(uid).doc(key).get()
    if (is_enc) {
        idb_rec = decrypt_obj(idb_rec, uid)
    }
    return idb_rec
}


export async function idb_r_all() {
    let uid = await idb_get_uid()
    return await db.collection(uid).get({keys: true})
}

export async function idb_u(key, val, is_enc = false) {
    let uid = await idb_get_uid()
    if (is_enc) {
        val = encrypt_obj(val, uid)
    }
    await db.collection(uid).doc(key).update(val)
}

export async function idb_d(key) {
    let uid = await idb_get_uid()
    let idb_rec = await db.collection(uid).doc(key).get()
    if (idb_rec !== null) {
        await db.collection(uid).doc(key).delete()
    }

}

/* qfDocuments */
export async function idb_u_qfDocuments(qfDocument_id, qfDocument_obj) {
    let qfDocuments = await idb_r('qfDocuments', false)
    if (qfDocuments === null) qfDocuments = {}

    qfDocuments[qfDocument_id] = qfDocument_obj
    await idb_c('qfDocuments', qfDocuments, false)
}




export async function idb_qfDocument_save_blob(qfDocument_id, blob_version, blob_obj) {

    let rec = {
        version: blob_version,
        blob_obj: blob_obj
    }
    await idb_c(`${qfDocument_id}__blob`, rec, false)

}

export async function idb_u_qfDocuments_history(qfDocument_id, rev_type, rev_action, rev_comments, qfDocument_obj, blob_obj = null) {
    let qfDocuments_history = await idb_r('qfDocuments_history', false)
    if (qfDocuments_history === null) qfDocuments_history = {}

    if (!(qfDocument_id in qfDocuments_history)) qfDocuments_history[qfDocument_id] = {}

    let iso_datetime = get_utc_dt_iso()
    qfDocuments_history[qfDocument_id][iso_datetime] = {
        uid: store.state.auth.user.uid,
        rev_type: rev_type,
        rev_action: rev_action,
        rev_comments: rev_comments,
        qfDocument: qfDocument_obj, // full copy of qfDocument object
    }
    await idb_c('qfDocuments_history', qfDocuments_history, false)

    if (blob_obj !== null) {

        if (qfDocument_obj.docBlob.is_zipped) {
            blob_obj = await file_to_zip_blob(blob_obj, qfDocument_obj.docBlob.type)
        }

        let rec = {
            version: qfDocument_obj.docBlob.version,
            rev_type: rev_type,
            rev_action: rev_action,
            rev_comments: rev_comments,
            blob_obj: blob_obj
        }
        await idb_c(`${qfDocument_id}__${iso_datetime}__history_blob`, rec, false)
    }

}

export async function idb_u_qfCollections_history(qfCollection_id, rev_type, rev_action, rev_comments, qfCollection_obj) {
    let qfCollections_history = await idb_r('qfCollections_history', false)
    if (qfCollections_history === null) qfCollections_history = {}

    if (!(qfCollection_id in qfCollections_history)) qfCollections_history[qfCollection_id] = {}

    let iso_datetime = get_utc_dt_iso()
    qfCollections_history[qfCollection_id][iso_datetime] = {
        uid: store.state.auth.user.uid,
        rev_type: rev_type,
        rev_action: rev_action,
        rev_comments: rev_comments,
        qfCollection: qfCollection_obj, // full copy of qfCollection object
    }
    await idb_c('qfCollections_history', qfCollections_history, false)

}


/* qfCollections */
export async function idb_u_qfCollections(qfCollection_id, qfCollection_obj) {
    let qfCollections = await idb_r('qfCollections', false)
    if (qfCollections === null) {
        qfCollections = {}
    }
    qfCollections[qfCollection_id] = qfCollection_obj
    await idb_c('qfCollections', qfCollections, false)

}

export async function idb_get_item(item_name) {
    // item_name -> qfCollections, qfDocuments, etc.
    let item = await idb_r(item_name)
    if (item === null) item = {}
    return item
}


/* documents */
export async function idb_u_documents(document_id, document_obj, document_type, has_version = false, has_blob = false) {
    let documents = await idb_r(document_type, false)
    if (documents === null) documents = {}

    documents[document_id] = document_obj
    await idb_c(document_type, documents, false)

}
