<template>
  <div>
    <v-col style="max-width: 400px;">
      <v-autocomplete
          v-model="selected_table_id"
          :items="input_tables_list"
          @change="on_select_table"
          dense
          small-chips
          deletable-chips
          clearable
          label="Select Link Table"
          item-text="table_name"
          item-value="table_id"
          :hide-details="true"
      ></v-autocomplete>
    </v-col>

    <div v-if="selected_table_id !== null">
      <v-col></v-col>
      <v-col>
        <v-row>
          <v-autocomplete
              style="max-width: 420px;"
              v-model="selected_style_id"
              :items="db_styles_list"
              dense
              label="Select Style"
              item-text="name"
              item-value="id"
              @change="set_mewa_style"
              :hide-details="true"
          ></v-autocomplete>
          <div style="max-width: 200px; margin-left: 10px;">
            <v-menu
                v-model="date_picker.show"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                max-width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date_picker.date"
                    label="Select Date for Style"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :hide-details="true"
                ></v-text-field>
              </template>
              <v-date-picker
                  min="2013-01"
                  max="2025-12"
                  type="month"
                  v-model="date_picker.date"
                  @input="date_picker.show=false"
                  @change="set_mewa_style"
              ></v-date-picker>
            </v-menu>
          </div>

        </v-row>

      </v-col>
      <v-col></v-col>
      <v-col></v-col>

      <!--      <v-col>-->
      <!--        <v-btn x-small @click="MapsVectorLayerStyleDialog_show=true">-->
      <!--          SET STYLE-->
      <!--        </v-btn>-->
      <!--      </v-col>-->

      <v-col>
        <v-row>
          <v-btn x-small @click="on_show_link_table" style="margin-left: 5px; margin-right: 5px;">
            <v-icon small color="green" left>mdi-table</v-icon>
            Preview Link Table
          </v-btn>

          <v-btn x-small @click="on_select_table" style="margin-left: 5px; margin-right: 5px;">
            <v-icon small color="pink" left>mdi-table-refresh</v-icon>
            Re-calc
          </v-btn>

          <v-btn x-small @click="do_plot" style="margin-left: 5px; margin-right: 5px;">
            <v-icon small color="orange" left>mdi-chart-bar</v-icon>
            Refresh Plots
          </v-btn>

        </v-row>
      </v-col>

      <v-col>
        <v-row>
          <v-select
              style="max-width: 200px; margin-right: 10px;"
              label="ET Unit"
              v-model="et_vol_unit"
              @change="on_select_table"
              :items="['m3', 'Thousand m3', 'Million m3']"
              :hide-details="true"
          ></v-select>

          <v-select
              style="max-width: 200px; margin-right: 10px;"
              label="ET Statistic"
              v-model="selected_stat_et"
              @change="do_plot"
              :items="stats"
              item-text="name"
              item-value="val"
              :hide-details="true"
          ></v-select>

          <v-select
              style="max-width: 200px; margin-right: 10px;"
              label="Plot Type"
              v-model="plot_type"
              @change="do_plot"
              :items="plot_types"
              item-text="name"
              item-value="val"
              :hide-details="true"
          ></v-select>
        </v-row>

      </v-col>

      <v-col style="max-width: 400px;">
        <v-row>
          <v-menu
              v-model="filter_st_date.show"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              max-width="400"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="filter_st_date.value"
                  label="Filter Start Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                min="2013-01"
                max="2025-12"
                type="month"
                v-model="filter_st_date.value"
                @input="filter_st_date.show=false"
                @change="process_data"
            ></v-date-picker>
          </v-menu>
          <span style="width: 10px;"></span>
          <v-menu
              v-model="filter_en_date.show"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              max-width="400"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="filter_en_date.value"
                  label="Filter End Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                min="2013-01"
                max="2025-12"
                type="month"
                v-model="filter_en_date.value"
                @input="filter_en_date.show=false"
                @change="process_data"
            ></v-date-picker>
          </v-menu>
        </v-row>


      </v-col>

      <br>
      <p>
        <b>Total ET (all): </b> {{ Math.round(total_et.all * 100) / 100 }}<br>
        <b>Total ET (selected): </b> {{ Math.round(total_et.selected * 100) / 100 }}<br>

        <b>Total Area (all): </b> {{ Math.round(total_area.all * 100) / 100 }} ha<br>
        <b>Total Area (selected): </b> {{ Math.round(total_area.selected * 100) / 100 }} ha<br>
      </p>
      <br>

      <v-card elevation="3">
        <v-toolbar dense color="cyan" dark>
          <v-toolbar-title class="body-1">Evapotranspiration ({{ selected_stat_et }})</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <PlotsLineOrBar
              v-if="plots.et.show"
              :plot_data="plots.et.data"
              :plot_type="plot_type"
          ></PlotsLineOrBar>
          <v-switch
              style="max-width: 400px;"
              v-model="plot_table_data.et.show"
              dense
              :hide-details="true"
              label="Show ET Timeseries Data"
          ></v-switch>
          <h3 v-if="plot_table_data.et.show">ET Timeseries Data</h3>
          <TablesSimpleTable
              v-if="plot_table_data.et.show"
              :header_pairs_list="plot_table_data.et.headers"
              :table_data="plot_table_data.et.data"
              :export_file_name="'ET_stats.csv'"
          ></TablesSimpleTable>
          <v-switch
              style="max-width: 400px;"
              v-model="summary_stat_data.et.show"
              dense
              :hide-details="true"
              label="Show ET Summary Stats"
          ></v-switch>
          <h3 v-if="summary_stat_data.et.show">ET Summary Stats</h3>
          <TablesSimpleTable
              v-if="summary_stat_data.et.show"
              :header_pairs_list="summary_stat_data.et.headers"
              :table_data="summary_stat_data.et.data"
              :export_file_name="'ET_summary_stats.csv'"
          ></TablesSimpleTable>
        </v-card-text>
      </v-card>

      <v-col></v-col>

      <v-card elevation="3">
        <v-toolbar dense color="green" dark>
          <v-toolbar-title class="body-1">NDVI ({{ selected_stat_ndvi }})</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <PlotsLineOrBar
              v-if="plots.ndvi.show"
              :plot_data="plots.ndvi.data"
              :plot_type="plot_type"
          ></PlotsLineOrBar>
          <v-switch
              class="caption"
              style="max-width: 400px;"
              v-model="plot_table_data.ndvi.show"
              dense
              :hide-details="true"
              label="Show NDVI Timeseries Data"
          ></v-switch>
          <h3 v-if="plot_table_data.ndvi.show">NDVI Timeseries Data</h3>
          <TablesSimpleTable
              v-if="plot_table_data.ndvi.show"
              :header_pairs_list="plot_table_data.ndvi.headers"
              :table_data="plot_table_data.ndvi.data"
              :export_file_name="'NDVI_stats.csv'"
          ></TablesSimpleTable>
          <v-switch
              style="max-width: 400px;"
              v-model="summary_stat_data.ndvi.show"
              dense
              :hide-details="true"
              label="Show NDVI Summary Stats"
          ></v-switch>
          <h3 v-if="summary_stat_data.ndvi.show">NDVI Summary Stats</h3>
          <TablesSimpleTable
              v-if="summary_stat_data.ndvi.show"
              :header_pairs_list="summary_stat_data.ndvi.headers"
              :table_data="summary_stat_data.ndvi.data"
              :export_file_name="'NDVI_summary_stats.csv'"
          ></TablesSimpleTable>
        </v-card-text>
      </v-card>


      <DriveTableViewDialog
          v-if="DriveTableViewDialog_show"
          :show_dialog.sync="DriveTableViewDialog_show"
          :callback_function="null"
          :the_title="DriveTableViewDialog_name"
          :doc_obj="DriveTableViewDialog_doc_obj"
          :json_arrays="DriveTableViewDialog_json_arrays"
          :schema="DriveTableViewDialog_schema"
          :editable="false"
      ></DriveTableViewDialog>

      <MapsVectorLayerStyleDialog
          v-if="MapsVectorLayerStyleDialog_show"
          :show_dialog.sync="MapsVectorLayerStyleDialog_show"
          :callback_function="null"
          :feature_type.sync="layer_obj.config.feature_type"
          :map_layer_style.sync="map_layer_dict.style"
          :submit_change_style="apply_submit_change_style"
          :cancel_change_style="cancel_change_style"
          :set_style="set_style"
          :json_arrays="json_arrays"
          :schema="schema"
      ></MapsVectorLayerStyleDialog>
    </div>


  </div>

</template>

<script>

import PlotsLineOrBar from "@/components/Plots/PlotsLineOrBar";
import TablesSimpleTable from "@/components/Tables/TablesSimpleTable";
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";
import MapsVectorLayerStyleDialog from "@/components/Maps/MapsVectorLayerStyleDialog";

import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import {deepClone, unique_array} from "@/services/generic";
import * as ss from "simple-statistics";
import {v4 as uuidv4} from "uuid";

import {
  get_rec_for_row_uid,
  json_arrays_to_json_list_no_label, json_list_to_json_arrays
} from "@/services/json_arrays_api";
import {templates} from "@/store";
import {DateTime} from "luxon";

export default {
  name: "MewaBottomSheetPlots",
  props: {
    json_arrays: {type: Object},
    schema: {type: Object},
    selected_uid_list: {type: Array},
    map_layer_dict: {type: Object},
    set_style: {type: Function},
    update_json_arrays_func: {type: Function},
    layer_obj: {type: Object},
    submit_change_style: {type: Function},
  },
  components: {
    PlotsLineOrBar,
    TablesSimpleTable,
    DriveTableViewDialog,
    MapsVectorLayerStyleDialog
  },
  data() {
    return {
      tables: {},
      input_tables_list: [],
      selected_table_id: null,
      col_list: [],

      plot_type: 'bar',
      plot_types: [
        {name: 'Line', val: 'lines',},
        {name: 'Bar', val: 'bar',},
        {name: 'Bar (Stacked)', val: 'bar_stack',},
      ],

      table_json_arrays: {},
      table_json_arrays_filt: {},
      table_json_list: [],
      table_json_list_filt: [],
      table_schema: {},

      stats: [
        {name: 'Min.', val: 'min',},
        {name: 'Max.', val: 'max'},
        {name: 'Mean', val: 'mean'},
        {name: 'Median', val: 'median'},
        {name: 'Sum', val: 'sum'},
        {name: 'Count', val: 'count'},
        {name: 'Count unique values', val: 'distinct'},
        {name: 'Variance', val: 'variance'},
        {name: 'Standard deviation', val: 'standardDeviation'},
      ],

      filter_st_date: {show: false, value: '2013-01'},
      filter_en_date: {show: false, value: '2023-12'},

      et_vol_unit: 'm3', // m3, 'Thousand m3', 'Million m3'

      ndvi_dict: {}, // list of ndvi for each date
      et_dict: {}, // list of et for each date

      ndvi_ts: {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      },
      et_ts: {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      },

      ndvi_dict_filt: {}, // list of ndvi for each date
      et_dict_filt: {}, // list of et for each date

      ndvi_ts_filt: {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      },
      et_ts_filt: {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      },

      selected_stat_et: 'sum',
      selected_stat_ndvi: 'mean',

      plots: {
        ndvi: {show: false, data: null,},
        et: {show: false, data: null,}
      },

      plot_table_data: {
        ndvi: {show: false, data: [], headers: []},
        et: {show: false, data: [], headers: []},
      },
      summary_stat_data: {
        ndvi: {show: false, data: [], headers: []},
        et: {show: false, data: [], headers: []},
      },
      total_et: {
        all: null,
        selected: null
      },
      total_area: {
        all: null,
        selected: null,
      },

      DriveTableViewDialog_show: false,
      DriveTableViewDialog_doc_obj: null,
      DriveTableViewDialog_json_arrays: {},
      DriveTableViewDialog_schema: {},
      DriveTableViewDialog_name: null,

      MapsVectorLayerStyleDialog_show: false,

      date_picker: {
        show: false,
        date: '2021-01',
      },

      db_styles_dict: {},
      db_styles_list: [],
      db_style: {},
      selected_style_id: null,
      style_obj: {}


    }
  },
  methods: {

    apply_submit_change_style() {
      this.update_json_arrays_func()
      this.submit_change_style()
    },

    cancel_change_style() {
      this.MapsVectorLayerStyleDialog_show = false
    },

    async on_show_link_table() {
      this.DriveTableViewDialog_json_arrays = await idb_unzip_blob(this.selected_table_id)
      this.DriveTableViewDialog_doc_obj = this.tables[this.selected_table_id]
      this.DriveTableViewDialog_schema = this.tables[this.selected_table_id].config.schema
      this.DriveTableViewDialog_name = this.tables[this.selected_table_id].name
      this.DriveTableViewDialog_show = true
    },

    async reset_data() {
      this.ndvi_dict = {} // list of ndvi for each date
      this.et_dict = {} // list of et for each date

      this.ndvi_ts = {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      }
      this.et_ts = {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      }

      this.ndvi_dict_filt = {} // list of ndvi for each date
      this.et_dict_filt = {} // list of et for each date

      this.ndvi_ts_filt = {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      }
      this.et_ts_filt = {
        date: [],
        stat: {min: [], max: [], mean: [], median: [], sum: [], variance: [], standardDeviation: [], count: []},
      }
    },
    async get_data(table_json_arrays) {
      let ndvi_dict = {}
      let et_dict = {}

      let stat_list = ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']

      let ndvi_ts = {
        date: [],
        stat: {
          count: []
        },
      }

      let et_ts = {
        date: [],
        stat: {
          count: []
        }, // for each stat make a list of vals
      }

      let date_col_uid = null
      let ndvi_col_uid = null
      let et_col_uid = null

      for (let col_uid in this.table_schema) {
        if (this.table_schema[col_uid].label === 'date') date_col_uid = col_uid
        // if (this.table_schema[col_uid].label === 'NDVI_mean') ndvi_col_uid = col_uid
        if (this.table_schema[col_uid].label === 'NDVI') ndvi_col_uid = col_uid
        // if (this.table_schema[col_uid].label === 'ETd_sum_m3') et_col_uid = col_uid
        if (this.table_schema[col_uid].label === 'ETm_CM') et_col_uid = col_uid
      }

      let date_list = table_json_arrays[date_col_uid]
      let ndvi_list = table_json_arrays[ndvi_col_uid]
      let et_list = table_json_arrays[et_col_uid]

      let date_unique0 = await unique_array(date_list).sort()

      let flt_st_datetime = DateTime.fromISO(this.filter_st_date.value)
      let flt_en_datetime = DateTime.fromISO(this.filter_en_date.value)

      let date_unique = []
      for (let date_val of date_unique0) {

        let date_val_datetime = DateTime.fromISO(date_val)
        console.log(date_val_datetime)
        if (date_val_datetime > flt_st_datetime && date_val_datetime <= flt_en_datetime) {
          date_unique.push(date_val)
        }
      }

      for (let date_val of date_unique) {

        let ndvi_filt = []
        let et_filt = []
        for (let idx in date_list) {
          if (date_val === date_list[idx]) {
            let ndvi_val = parseFloat(ndvi_list[idx])
            let et_val = parseFloat(et_list[idx])
            if (!isNaN(ndvi_val)) ndvi_filt.push(ndvi_val)
            if (!isNaN(et_val)) et_filt.push(et_val)
          }
        }

        ndvi_dict[date_val] = ndvi_filt
        et_dict[date_val] = et_filt

      }

      for (let date_val of date_unique) {
        ndvi_ts.date.push(date_val)
        et_ts.date.push(date_val)

        ndvi_ts.stat.count.push(ndvi_dict[date_val].length)
        et_ts.stat.count.push(et_dict[date_val].length)
      }

      for (let stat of stat_list) {
        ndvi_ts.stat[stat] = []
        et_ts.stat[stat] = []

        for (let date_val of date_unique) {
          if (ndvi_dict[date_val].length > 0) {
            ndvi_ts.stat[stat].push(ss[stat](ndvi_dict[date_val]))
          }
          if (et_dict[date_val].length > 0) {
            et_ts.stat[stat].push(ss[stat](et_dict[date_val]))
          }
        }
      }

      return {ndvi_dict, et_dict, ndvi_ts, et_ts}

    },
    async process_data() {

      await this.reset_data()

      let out_all = await this.get_data(this.table_json_arrays)


      this.ndvi_dict = out_all.ndvi_dict
      this.et_dict = out_all.et_dict
      this.ndvi_ts = out_all.ndvi_ts
      this.et_ts = out_all.et_ts

      if (this.selected_uid_list.length > 0) {
        let out_filt = await this.get_data(this.table_json_arrays_filt)
        this.ndvi_dict_filt = out_filt.ndvi_dict
        this.et_dict_filt = out_filt.et_dict
        this.ndvi_ts_filt = out_filt.ndvi_ts
        this.et_ts_filt = out_filt.et_ts
      }

      await this.set_table_data()
      await this.do_plot()

      await this.set_summary_stat_data()
      await this.calc_total_area()

    },
    async calc_total_area() {
      this.total_area.all = 0
      this.total_area.selected = 0
      // find area column
      let layer_area_col_uid = null
      for (let col_uid in this.schema) {
        if (this.schema[col_uid].label === 'area') layer_area_col_uid = col_uid
      }
      for (let idx in this.json_arrays.row_uid) {
        let row_uid = this.json_arrays.row_uid[idx]
        let area = this.json_arrays[layer_area_col_uid][idx]

        this.total_area.all += area
        if (this.selected_uid_list.includes(row_uid)) {
          this.total_area.selected += area
        }
      }
    },
    async set_summary_stat_data() {

      // NDVI ------------------------------
      // this.summary_stat_data.ndvi.show = false
      this.summary_stat_data.ndvi.data = []

      for (let stat2 of ['min', 'max', 'mean', 'median', 'sum']) {
        let row = {}
        row.stat = stat2
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
          if (this.ndvi_ts.stat[stat].length > 0) {
            row[stat] = ss[stat2](this.ndvi_ts.stat[stat])
          } else {
            row[stat] = null
          }

        }
        if (this.selected_uid_list.length > 0) {
          for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
            if (this.ndvi_ts_filt.stat[stat].length > 0) {
              row[stat + '_selected'] = ss[stat2](this.ndvi_ts_filt.stat[stat])
            } else {
              row[stat + '_selected'] = null
            }

          }
        }
        this.summary_stat_data.ndvi.data.push(row)
      }
      this.summary_stat_data.ndvi.headers = []
      this.summary_stat_data.ndvi.headers.push({field: 'stat', headerName: 'Statistic'})
      for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
        this.summary_stat_data.ndvi.headers.push({field: stat, headerName: stat})
      }
      if (this.selected_uid_list.length > 0) {
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
          this.summary_stat_data.ndvi.headers.push({field: stat + '_selected', headerName: stat + '_selected'})
        }
      }
      // this.summary_stat_data.ndvi.show = true

      // ET ------------------------------
      // this.summary_stat_data.et.show = false
      this.summary_stat_data.et.data = []

      for (let stat2 of ['min', 'max', 'mean', 'median', 'sum']) {
        let row = {}
        row.stat = stat2
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
          if (this.et_ts.stat[stat].length > 0) {
            row[stat] = ss[stat2](this.et_ts.stat[stat])
          } else {
            row[stat] = null
          }
          if (stat === 'sum') this.total_et.all = row[stat]
        }
        if (this.selected_uid_list.length > 0) {
          for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
            if (this.et_ts_filt.stat[stat].length > 0) {
              row[stat + '_selected'] = ss[stat2](this.et_ts_filt.stat[stat])
            } else {
              row[stat + '_selected'] = null
            }
            if (stat === 'sum') this.total_et.selected = row[stat + '_selected']

          }
        }
        this.summary_stat_data.et.data.push(row)
      }
      this.summary_stat_data.et.headers = []
      this.summary_stat_data.et.headers.push({field: 'stat', headerName: 'Statistic'})
      for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
        this.summary_stat_data.et.headers.push({field: stat, headerName: stat})
      }
      if (this.selected_uid_list.length > 0) {
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation']) {
          this.summary_stat_data.et.headers.push({field: stat + '_selected', headerName: stat + '_selected'})
        }
      }
      // this.summary_stat_data.et.show = true


    },

    async set_table_data() {
      // NDVI
      // this.plot_table_data.ndvi.show = false
      this.plot_table_data.ndvi.data = []
      for (let idx in this.ndvi_ts.date) {
        let row = {
          'date': this.ndvi_ts.date[idx],
        }
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
          row[stat] = this.ndvi_ts.stat[stat][idx]
        }
        if (this.selected_uid_list.length > 0) {
          for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
            row[stat + '_selected'] = this.ndvi_ts_filt.stat[stat][idx]
          }
        }
        this.plot_table_data.ndvi.data.push(row)
      }
      this.plot_table_data.ndvi.headers = []
      this.plot_table_data.ndvi.headers.push({field: 'date', headerName: 'Date'})
      for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
        this.plot_table_data.ndvi.headers.push({field: stat, headerName: stat})
      }

      if (this.selected_uid_list.length > 0) {
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
          this.plot_table_data.ndvi.headers.push({field: stat + '_selected', headerName: stat + '_selected'})
        }
      }
      // this.plot_table_data.ndvi.show = true

      // ET ------------------------------------------------------------
      // this.plot_table_data.et.show = false
      this.plot_table_data.et.data = []

      for (let idx in this.et_ts.date) {
        let row = {
          'date': this.et_ts.date[idx],
        }
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
          row[stat] = this.et_ts.stat[stat][idx]
        }
        if (this.selected_uid_list.length > 0) {
          for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
            row[stat + '_selected'] = this.et_ts_filt.stat[stat][idx]
          }
        }
        this.plot_table_data.et.data.push(row)
      }
      this.plot_table_data.et.headers = []
      this.plot_table_data.et.headers.push({field: 'date', headerName: 'Date'})
      for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
        this.plot_table_data.et.headers.push({field: stat, headerName: stat})
      }

      if (this.selected_uid_list.length > 0) {
        for (let stat of ['min', 'max', 'mean', 'median', 'sum', 'variance', 'standardDeviation', 'count']) {
          this.plot_table_data.et.headers.push({field: stat + '_selected', headerName: stat + '_selected'})
        }
      }
      // this.plot_table_data.et.show = true


    },

    async do_plot() {

      this.plots.ndvi.show = false
      this.plots.et.show = false
      // NDVI
      this.plots.ndvi.data = [{
        x: this.ndvi_ts.date,
        y: this.ndvi_ts.stat[this.selected_stat_ndvi],
        color: 'orange', width: 3, name: 'All data'
      }]
      if (this.selected_uid_list.length > 0) {
        this.plots.ndvi.data.push({
          x: this.ndvi_ts_filt.date,
          y: this.ndvi_ts_filt.stat[this.selected_stat_ndvi],
          color: 'green', width: 4, name: 'Selected data'
        })
      }
      this.plots.ndvi.show = true
      // ET

      this.plots.et.data = [{
        x: this.et_ts.date,
        y: this.et_ts.stat[this.selected_stat_et],
        color: 'orange', width: 3, name: 'All data'
      }]
      if (this.selected_uid_list.length > 0) {
        this.plots.et.data.push({
          x: this.et_ts_filt.date,
          y: this.et_ts_filt.stat[this.selected_stat_et],
          color: 'green', width: 4, name: 'Selected data'
        })
      }

      setTimeout(() => {
        this.plots.et.show = true
      }, 500)


    },

    async filter_table_json_list() {

      let layer_label_col_uid = null
      for (let col_uid in this.schema) {
        if (this.schema[col_uid].label === 'label') layer_label_col_uid = col_uid
      }

      let table_label_col_uid = null
      for (let col_uid in this.table_schema) {
        if (this.table_schema[col_uid].label === 'label') table_label_col_uid = col_uid
      }

      let layer_label_list = []
      for (let layer_row_uid of this.selected_uid_list) {
        let this_rec = await get_rec_for_row_uid(this.json_arrays, layer_row_uid, ['geometry'])
        layer_label_list.push(this_rec[layer_label_col_uid])
      }
      return this.table_json_list.filter((el) => {
        return layer_label_list.includes(parseFloat(el[table_label_col_uid]));
      });

    },

    async create_style_list() {

      let db_styles_dict = {}
      let db_styles_list = []

      let styles = await get_qfDocument_type_as_dict('style')

      for (let style_id in styles) {
        let style_obj = styles[style_id]
        if (style_obj.config.layer_type === 'vector') {
          if (style_obj.config.feature_type === this.layer_obj.config.feature_type) {
            db_styles_dict[style_id] = style_obj
            db_styles_list.push({
              id: style_id,
              name: style_obj.name,
              description: style_obj.config.description
            })
          }
        }


      }

      this.db_styles_dict = db_styles_dict
      this.db_styles_list = db_styles_list
      // if (this.style_id === null) {
      //   this.db_style = deepClone(templates.qfDocument)
      //   this.db_style.type = 'style'
      //   this.db_style.config = deepClone(templates.qfDocument__config__templates.style)
      // } else {
      //   if (this.style_id in this.db_styles_dict) {
      //     this.db_style = this.db_styles_dict[this.style_id]
      //   }
      // }

    },
    async set_mewa_style() {
      // set temp_style column
      await this.set_temp_style()
      await this.set_db_style()

    },
    async set_db_style() {

      if (this.selected_style_id !== null) {
        this.db_style = this.db_styles_dict[this.selected_style_id]
        this.style_obj = this.db_style.config.style;

        let map_layer_dict = this.map_layer_dict
        map_layer_dict.style = this.db_style.config.style
        this.$emit('update:map_layer_dict', map_layer_dict)
        this.apply_submit_change_style(true)
        //
        // let layer_style = deepClone(this.map_layer_dict.style)

        //
        // layer_style.style_type = "graduated"
        // layer_style.style_rule = {
        //   col_name: 'temp_style',
        //   col_type: 'Number',
        //   reverse_colors: false,
        //
        // }

      }

    },

    async set_temp_style() {

      let temp_style_col_uid = null
      let layer_label_col_uid = null
      for (let col_uid in this.schema) {
        if (this.schema[col_uid].label === 'label') layer_label_col_uid = col_uid
        if (this.schema[col_uid].label === 'temp_style') temp_style_col_uid = col_uid
      }
      // remove temp style from layer json_arrays and its schema
      if (temp_style_col_uid !== null) {
        delete this.json_arrays[temp_style_col_uid]
        delete this.schema[temp_style_col_uid]
      }

      temp_style_col_uid = uuidv4().replaceAll('-', '')

      let table_label_col_uid = null
      let table_date_col_uid = null
      let table_ET_sum_m3_col_uid = null
      console.log(this.table_schema)

      for (let col_uid in this.table_schema) {
        console.log(this.table_schema[col_uid].label)
        if (this.table_schema[col_uid].label === 'label') table_label_col_uid = col_uid
        if (this.table_schema[col_uid].label === 'date') table_date_col_uid = col_uid
        if (this.table_schema[col_uid].label === 'ETm_CM') table_ET_sum_m3_col_uid = col_uid
      }
      console.log('🪛')
      // calc values for each label (with a logic) and make a label -> style_val dict
      // e.g. put values for 2015-01
      let style_label_vals_dict = {}
      for (let idx in this.table_json_arrays.row_uid) {

        let table_date = this.table_json_arrays[table_date_col_uid][idx]

        if (table_date.substring(0, 7) === this.date_picker.date) {
          let table_label = this.table_json_arrays[table_label_col_uid][idx]
          let table_ETd_sum = this.table_json_arrays[table_ET_sum_m3_col_uid][idx]

          let unit_multiplier = 1
          if (this.et_vol_unit === 'Thousand m3') unit_multiplier = 1000
          if (this.et_vol_unit === 'Million m3') unit_multiplier = 1000000

          style_label_vals_dict[table_label] = table_ETd_sum * unit_multiplier
        }
      }

      // fill the temp_style_col with values
      this.json_arrays[temp_style_col_uid] = []
      for (let idx in this.json_arrays.row_uid) {
        let layer_label = this.json_arrays[layer_label_col_uid][idx]
        if (layer_label in style_label_vals_dict) {
          this.json_arrays[temp_style_col_uid].push(style_label_vals_dict[layer_label])
        } else {
          this.json_arrays[temp_style_col_uid].push(null)
        }

      }
      this.schema[temp_style_col_uid] = {
        is_system: false,
        label: 'temp_style',
        type: 'String',
        config: {precision: null, time_zone: null,}
      }

      this.$emit('update:json_arrays', this.json_arrays)
      this.$emit('update:schema', this.schema)
      this.update_json_arrays_func()

    },

    async calc_vol_et() {

      let layer_label_col_uid = null
      let layer_area_col_uid = null
      for (let col_uid in this.schema) {
        if (this.schema[col_uid].label === 'label') layer_label_col_uid = col_uid
        if (this.schema[col_uid].label === 'area') layer_area_col_uid = col_uid
      }

      let table_label_col_uid = null
      let table_ETd_sum_col_uid = null
      for (let col_uid in this.table_schema) {
        if (this.table_schema[col_uid].label === 'label') table_label_col_uid = col_uid
        if (this.table_schema[col_uid].label === 'ETd_sum') table_ETd_sum_col_uid = col_uid
      }

      let ETd_sum_col_uid = uuidv4().replaceAll('-', '')
      this.table_schema[ETd_sum_col_uid] = {
        is_system: false,
        label: 'ETd_sum_m3',
        type: 'String',
        config: {precision: null, time_zone: null,}
      }

      // make a dict for label -> area
      let label_area_dict = {}
      for (let idx in this.json_arrays.row_uid) {
        let label = this.json_arrays[layer_label_col_uid][idx]
        label_area_dict[label] = this.json_arrays[layer_area_col_uid][idx]
      }


      let unit_multiplier = 1
      if (this.et_vol_unit === 'Thousand m3') unit_multiplier = 0.001
      if (this.et_vol_unit === 'Million m3') unit_multiplier = 0.000001

      this.table_json_arrays[ETd_sum_col_uid] = []
      for (let idx in this.table_json_arrays.row_uid) {

        let label = this.table_json_arrays[table_label_col_uid][idx]
        let ETd_sum = this.table_json_arrays[table_ETd_sum_col_uid][idx]

        let ETd_sum_m3 = null
        if (label in label_area_dict) {
          ETd_sum_m3 = label_area_dict[label] * ETd_sum * 10 * unit_multiplier
        }
        this.table_json_arrays[ETd_sum_col_uid].push(ETd_sum_m3)
      }

    },

    async on_filter() {

    },


    async on_select_table() {

      // load table
      if (this.selected_table_id !== null) {

        this.table_json_arrays = await idb_unzip_blob(this.selected_table_id)
        this.table_schema = this.tables[this.selected_table_id].config.schema

        // calculate vol ET based on layer area and table_json_arrays
        // await this.calc_vol_et()

        this.table_json_list = await json_arrays_to_json_list_no_label(this.table_json_arrays, this.table_schema, ['geometry'])
        this.table_json_arrays_filt = await json_list_to_json_arrays(await this.filter_table_json_list())

        await this.process_data()
      }


    },

    async init() {

      this.tables = await get_qfDocument_type_as_dict('table')
      for (let table_id in this.map_layer_dict.link) {
        this.input_tables_list.push({
          table_id: this.tables[table_id].id,
          table_name: this.tables[table_id].name,
        })
      }

      await this.create_style_list()

    },

  },
  watch: {},
  beforeDestroy() {
  },

  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
}

</style>
