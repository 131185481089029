import {storage} from '@/firebase/firebase';
import {ref, deleteObject, getDownloadURL, uploadBytes, getBlob, list} from "firebase/storage";


export function storage_upload(file_obj, path) {
    const storageRef = ref(storage, path);
    uploadBytes(storageRef, file_obj)
}

export async function async_storage_upload(file_obj, path) {
    const storageRef = ref(storage, path);
    let upload_out = await uploadBytes(storageRef, file_obj)
    return upload_out.metadata
}

export async function storage_get_url(path) {
    const storageRef = ref(storage, path);
    return getDownloadURL(storageRef)
}


export async function async_storage_get_url(path) {
    const storageRef = ref(storage, path);
    return await getDownloadURL(storageRef)
}

export async function async_storage_get_blob(path) {
    const storageRef = ref(storage, path);
    return await getBlob(storageRef)
}


export async function async_storage_delete(path) {

    const storageRef = ref(storage, path);
    let list_res = await list(storageRef)
    if (list_res.items.length > 0){
        await deleteObject(storageRef)
    }

}

export function storage_delete(path) {
    // try{
    //     const storageRef = ref(storage, path);
    //     deleteObject(storageRef)
    // } catch (error){
    //     console.log('error in storage_delete', error)
    // }
    const storageRef = ref(storage, path);
    // list(storageRef).then((res) => {
    //     console.log('res in list', res)
    //     if (res.items.length > 0) {
    //         deleteObject(storageRef)
    //     }
    //
    // })
    deleteObject(storageRef)
        .then(() => {
            console.log('deleted in storage', path)
        })
        .catch((error) => {
            console.log('error in del storage', error)
        })

}


