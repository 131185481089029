import Vue from 'vue'
import VueRouter from 'vue-router'
import MapsView from "@/components/Maps/MapsView";
import DwsView from "@/components/DWS/DwsView";

// TO DO: change routes based on the subdomain - this is to use one codebase for all apps
// console.log('in router')
// console.log(window.location)

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: MapsView
    },

    {
        path: '/maps',
        name: 'maps',
        component: MapsView
    },
    {
        path: '/dws',
        name: 'dws',
        component: DwsView
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    props: true,
    routes
})

export default router
