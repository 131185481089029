<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="orange">mdi-application-import</v-icon>
          <span class="body-1">
            Import Vector Layer(s)
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-file-input
              outlined
              multiple
              accept=".json, .geojson, .kml, .zip, .csv"
              show-size
              :clearable="true"
              label="Select files"
              v-model="input_files"
              @change="on_file_select"
              :hide-details="true"
          ></v-file-input>
          <p class="caption">Select GeoJSON, KML, SHP, or CSV files.<br>
            zip all SHP files (including dbf, shx, prj) into one file.<br>
            CSV files must have latitude and longitude columns.

          </p>
        </v-col>

        <v-col v-if="input_layers_list.length > 0">

          <v-card v-for="(input_layer, idx) in input_layers_list" :key="idx" style="margin-bottom: 10px;" elevation="3">
            <v-system-bar color="blue" class="white--text caption">{{ input_layer.file_info.file_name }}</v-system-bar>

            <v-col style="max-width: 400px;">
              <v-text-field
                  outlined
                  dense
                  v-model="input_layer.name"
                  label="Name"
                  :hide-details="true"
              ></v-text-field>
            </v-col>

            <v-col style="max-width: 400px;">
              <v-text-field
                  outlined
                  type="number"
                  min="3"
                  max="12"
                  dense
                  v-model="input_layer.coordinate_precision"
                  label="Coordinate Precision"
                  :hide-details="true"
              ></v-text-field>
            </v-col>

            <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
              <v-switch
                  v-model="input_layer.stage.show"
                  :hide-details="true"
                  dense
                  label="Show stage"
              ></v-switch>
            </v-col>

            <v-col style="max-width: 400px;" v-if="input_layer.stage.show">
              <v-select
                  outlined
                  dense
                  v-model="input_layer.stage.stage"
                  :items="stages"
                  item-text="stage"
                  item-value="stage"
                  label="Stage"
                  :hide-details="true"
              >
                <template v-slot:selection="{item}">
                  <v-list-item dense>
                    <v-list-item-icon>
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.stage }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" dense>
                    <v-list-item-icon>
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.stage }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
              <v-switch
                  v-model="input_layer.show_description_and_comment"
                  :hide-details="true"
                  dense
                  label="Show description and comments"
              ></v-switch>
            </v-col>

            <v-col style="max-width: 400px;" v-if="input_layer.show_description_and_comment">
              <v-textarea
                  outlined
                  rows="1"
                  clearable
                  dense
                  auto-grow
                  v-model="input_layer.description"
                  label="Description"
                  :hide-details="true"
              ></v-textarea>
            </v-col>

            <v-col style="max-width: 400px;" v-if="input_layer.show_description_and_comment">
              <v-textarea
                  outlined
                  dense
                  rows="1"
                  clearable
                  auto-grow
                  v-model="input_layer.comments"
                  label="Comments"
                  :hide-details="true"
              ></v-textarea>
            </v-col>

            <v-col
                v-if="input_layer.is_csv"
                style="max-width: 300px;">
              <v-select
                  v-model="input_layer.csv_dict.lat_col_uid"
                  :items="input_layer.csv_dict.latlon_col_names"
                  item-text="label"
                  item-value="uid"
                  :hide-details="true"
                  label="Select Lat"
                  required
              ></v-select>
              <v-select
                  v-model="input_layer.csv_dict.lon_col_uid"
                  :items="input_layer.csv_dict.latlon_col_names"
                  item-text="label"
                  item-value="uid"
                  :hide-details="true"
                  label="Select Lon"
                  required
              ></v-select>

            </v-col>


<!--            <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">-->
<!--              <v-switch-->
<!--                  :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"-->
<!--                  v-model="input_layer.upload_blob"-->
<!--                  :hide-details="true"-->
<!--                  dense-->
<!--                  label="Upload to cloud"-->
<!--              ></v-switch>-->
<!--            </v-col>-->

            <DriveTableViewDialog
                v-if="DriveTableViewDialog_show"
                :show_dialog.sync="DriveTableViewDialog_show"
                :json_arrays="active_table_json_arrays"
                :schema="active_table_schema"
                :the_title="active_table_name"
            ></DriveTableViewDialog>

            <DriveSchemaDialog
                v-if="DriveSchemaDialog_show"
                :show_dialog.sync="DriveSchemaDialog_show"
                :update_schema_callback="update_schema_callback"
                :schema="input_layer.schema"
                :json_arrays="input_layer.json_arrays"
                :idx="idx"
            ></DriveSchemaDialog>

            <v-card-actions>

              <v-btn small @click="on_show_table(idx)">
                <v-icon color="green" left>mdi-table</v-icon>
                View Table
              </v-btn>
              <v-btn small @click="DriveSchemaDialog_show=true">
                <v-icon color="purple" left>mdi-table-cog</v-icon>
                Edit Schema
              </v-btn>

              <v-btn @click="delete_file_item(idx)" small>
                <v-icon color="red" left>mdi-delete</v-icon>
                Delete
              </v-btn>

            </v-card-actions>
          </v-card>

        </v-col>


        <v-alert v-if="csv_latlon_has_error" type="warning" color="red">
          Please make sure lat and lon for all csv layers are set.
        </v-alert>

        <v-progress-linear v-if="loading" indeterminate color="blue"></v-progress-linear>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="green" class="white--text"
               :disabled="loading || input_layers_list.length < 1"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";
import DriveSchemaDialog from "@/components/Drive/DriveSchemaDialog";
import {deepClone, readFileAsync} from "@/services/generic";
import {templates} from "@/store";
import {file_to_gejson_for_qfDocument} from "@/services/gis_utils";
import {c_qfDocument_vector_layer} from "@/services/app_utils";
import {v4 as uuidv4} from "uuid";
import {DateTime} from "luxon";
import {truncate} from "@turf/turf";
import * as csv from "csvtojson";

export default {
  name: "DriveVectorLayerImportDialog",
  components: {DriveTableViewDialog, DriveSchemaDialog},
  props: {
    show_dialog: {type: Boolean},
    map_id: {type: String, default: null},
    callback_function: {type: Function},
    input_file: {type: File},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      input_files: [],
      input_layers_list: [],

      DriveTableViewDialog_show: false,
      DriveSchemaDialog_show: false,

      active_table_json_arrays: {},
      active_table_schema: {},
      active_table_name: null,

      loading: false,

      csv_latlon_has_error: false,

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],

    }
  },
  methods: {

    async on_file_select() {
      this.loading = true
      await this.reset_data()
      for (let input_file of this.input_files) {
        console.log(input_file)
        if (input_file.type.includes('csv')) {
          await this.process_csv_file(input_file)
        } else {
          await this.process_vector_file(input_file)
        }
      }
      this.loading = false
    },
    async reset_data() {
      this.input_layers_list = []
      this.csv_latlon_has_error = false
    },

    // VECTOR FILES
    async process_vector_file(input_file) {

      let geojson_list_out = await file_to_gejson_for_qfDocument(input_file)

      for (let geojson_item of geojson_list_out) {
        let feature_type = geojson_item.feature_type
        let file_name = geojson_item.file_name

        let geojson = geojson_item.geojson

        let uid_dict = {}
        for (let key_label in geojson.features[0].properties) {
          uid_dict[key_label] = uuidv4().replaceAll('-', '')
        }

        let json_arrays = await this.geojson_to_json_arrays(geojson, uid_dict)
        let schema = await this.set_schema(json_arrays, uid_dict)

        this.input_layers_list.push({
          name: file_name,
          description: null,
          comments: null,
          stage: {
            show: false,
            stage: 'Development', // Development, Testing, Production
          },
          show_description_and_comment: false,
          coordinate_precision: 6,
          is_csv: false,
          csv_dict: {},

          upload_blob: true,

          file_info: {
            file_name: file_name,
            file_size: input_file.size,
            file_type: input_file.type,
            file_lastModified: input_file.lastModified,
          },

          feature_type: feature_type,
          schema: schema,
          json_arrays: json_arrays,

        })

      }


    },
    async geojson_to_json_arrays(geojson, uid_dict) {
      let json_arrays = {geometry: [], row_uid: []}
      for (let key_label in uid_dict) {
        let key_uid = uid_dict[key_label]
        json_arrays[key_uid] = []
      }

      for (let feature of geojson.features) {
        json_arrays.geometry.push(feature.geometry)
        let properties = feature.properties
        for (let key_label in properties) {
          let key_uid = uid_dict[key_label]
          json_arrays[key_uid].push(properties[key_label])
        }
        // to add row_uid as uuid
        json_arrays.row_uid.push(uuidv4().replaceAll('-', ''))
      }

      return json_arrays

    },

    // CSV FILES
    async process_csv_file(input_file) {

      let csv_string = await readFileAsync(input_file, 'text')
      let csv_json = await csv({output: 'json'}).fromString(csv_string)

      let uid_dict = {}
      for (let key_label in csv_json[0]) {
        uid_dict[key_label] = uuidv4().replaceAll('-', '')
      }

      let json_arrays = await this.csv_json_to_json_arrays(csv_json, uid_dict)
      let schema = await this.set_schema(json_arrays, uid_dict)

      this.input_layers_list.push({
        name: input_file.name,
        description: null,
        comments: null,
        stage: {
          show: false,
          stage: 'Development', // Development, Testing, Production
        },
        show_description_and_comment: false,
        coordinate_precision: 6,
        is_csv: true,
        csv_dict: {
          latlon_col_names: await this.get_latlon_col_names(schema),
          lat_col_uid: null,
          lon_col_uid: null,
        },

        upload_blob: true,

        file_info: {
          file_name: input_file.name,
          file_size: input_file.size,
          file_type: input_file.type,
          file_lastModified: input_file.lastModified,
        },

        feature_type: 'Point',
        schema: schema,
        json_arrays: json_arrays,

      })


    },
    async csv_json_to_json_arrays(csv_json, uid_dict) {
      let json_arrays = {geometry: [], row_uid: []}

      for (let key_label in uid_dict) {
        let key_uid = uid_dict[key_label]
        json_arrays[key_uid] = []
      }

      for (let json_item of csv_json) {
        json_arrays.geometry.push(null)

        for (let key_label in json_item) {
          let key_uid = uid_dict[key_label]
          json_arrays[key_uid].push(json_item[key_label])
        }
        // to add row_uid as uuid
        json_arrays.row_uid.push(uuidv4().replaceAll('-', ''))
      }

      return json_arrays

    },
    async json_arrays_latlon_to_geometry(json_arrays, lat_col_uid, lon_col_uid) {

      json_arrays.geometry = []
      for (let row_uid in json_arrays[lat_col_uid]) {
        json_arrays.geometry.push({
          "type": "Point",
          "coordinates": [
            json_arrays[lon_col_uid][row_uid], // -76.9750541388, // lon
            json_arrays[lat_col_uid][row_uid]  // 38.8410857803 // lat
          ]
        })
      }

      return json_arrays

    },
    async get_latlon_col_names(schema) {

      let latlon_col_names = []

      for (let col_uid in schema) {
        let item = schema[col_uid]
        if (item.type === 'Number' && !item.is_system) {
          latlon_col_names.push({
            label: item.label,
            uid: col_uid
          })
        }
      }

      return latlon_col_names


    },

    // SCHEMA
    async set_schema(json_arrays, uid_dict) {
      let json_arrays_unique = deepClone(json_arrays)
      for (let uid in json_arrays_unique) {
        json_arrays_unique[uid] = [...new Set(json_arrays_unique[uid])].sort()
      }

      let schema = {
        row_uid: {label: 'row_uid', type: 'String', is_system: true, config: {precision: null, timezone: null,}},
        geometry: {label: 'geometry', type: 'Object', is_system: true, config: {precision: null, timezone: null,}},
      }

      for (let key_label in uid_dict) {
        let key_uid = uid_dict[key_label]

        let unique_arr = json_arrays_unique[key_uid]
        let col_type = 'Number'
        for (let val_item of unique_arr) {
          if (isNaN(val_item)) {
            col_type = 'String'
            break;
          }
        }

        if (col_type === 'String') {

          for (let val_item of unique_arr) {
            if (val_item.includes('http')) {
              col_type = 'URL'
              break;
            }
            if (isNaN(val_item) && DateTime.fromJSDate(new Date(val_item)).isValid) {
              col_type = 'Date'
              break;
            }
          }
        }

        schema[key_uid] = {
          label: key_label,
          type: col_type,
          is_system: false, // to show to the user or not. for feature uid
          config: {precision: null, timezone: null,}
        }
      }

      return schema
    },
    async update_schema_callback(new_schema, json_arrays, layer_idx) {
      let layer_item = this.input_layers_list[layer_idx]

      if (layer_item.is_csv) {
        layer_item.csv_dict.latlon_col_names = await this.get_latlon_col_names(new_schema)
      }

      layer_item.schema = new_schema
      layer_item.json_arrays = json_arrays

      this.$set(this.input_layers_list, layer_idx, layer_item)

    },

    // OTHER
    async delete_file_item(idx) {

      this.$delete(this.input_files, idx)
      this.$delete(this.input_layers_list, idx)

    },
    async on_show_table(idx) {
      this.active_table_json_arrays = this.input_layers_list[idx].json_arrays
      this.active_table_schema = this.input_layers_list[idx].schema
      this.active_table_name = this.input_layers_list[idx].name
      this.DriveTableViewDialog_show = true
    },

    // ON SUBMIT
    async check_csv_latlon() {

      let has_error = false
      for (let layer_item of this.input_layers_list) {
        if (layer_item.is_csv) {
          if (layer_item.csv_dict.lat_col_uid === null || layer_item.csv_dict.lon_col_uid === null) {
            has_error = true
          }
        }
      }
      return has_error

    },
    async create_layers() {

      for (let layer_item of this.input_layers_list) {

        if (layer_item.is_csv) {
          layer_item.json_arrays = await this.json_arrays_latlon_to_geometry(
              layer_item.json_arrays,
              layer_item.csv_dict.lat_col_uid,
              layer_item.csv_dict.lon_col_uid,
          )
        }

        for (let geometry of layer_item.json_arrays.geometry) {
          geometry = await truncate(geometry, {
            precision: parseInt(layer_item.coordinate_precision),
            coordinates: 2,
            mutate: true
          })
        }

        let layer_config = {}
        layer_config.show_description_and_comment = layer_item.show_description_and_comment
        layer_config.description = layer_item.description
        layer_config.comments = layer_item.comments
        layer_config.stage = layer_item.stage

        layer_config.is_imported = true
        layer_config.import_info = deepClone(templates.qfDocument__config__templates.layer.import_info)
        layer_config.import_info.file_name = layer_item.file_info.file_name
        layer_config.import_info.file_size = layer_item.file_info.file_size
        layer_config.import_info.file_type = layer_item.file_info.file_type
        layer_config.import_info.file_lastModified = layer_item.file_info.file_lastModified

        layer_config.coordinate_precision = parseInt(layer_item.coordinate_precision)
        layer_config.schema = layer_item.schema

        await c_qfDocument_vector_layer(
            layer_item.name,
            layer_item.feature_type,
            layer_item.json_arrays,
            this.map_id,
            layer_item.upload_blob,
            layer_config)
      }

    },

    async submit() {
      this.loading = true
      this.csv_latlon_has_error = await this.check_csv_latlon()

      if (!this.csv_latlon_has_error) {
        await this.create_layers()

        if (this.callback_function !== null) {
          this.callback_function(this.map_id)
        }
        this.cancel()
      }

      this.loading = false

    },
    cancel() {
      this.reset_data()
      this.$emit('update:show_dialog', false)

    },
    async init() {
      await this.reset_data()
      if (this.input_file !== undefined){
        this.input_files.push(this.input_file)
        await this.on_file_select()
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
