<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="purple" left>mdi-export</v-icon>
          <span class="body-1">
            Export Raster Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-text-field
              v-model="file_name"
              label="File Name"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-select
              outlined
              dense
              v-model="file_format"
              :items="file_formats"
              label="Export Format"
              :hide-details="true"
          >
          </v-select>
        </v-col>

        <v-divider></v-divider>

        <v-alert
            v-if="show_save_warning"
            style="margin-top: 10px;"
            max-width="400"
            border="left"
            color="green"
            text
            dense
            type="info"
        >
          Only saved changes will be exported.
        </v-alert>


      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               :disabled="is_loading"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {save_as_geojson_file, save_as_kml_file} from "@/services/gis_utils";
import {export_json_2_csv, export_raster} from "@/services/generic";
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import {idb_r} from "@/services/idb_api";

export default {
  name: "DriveRasterLayerExportDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    layer_id: {type: String},
    show_save_warning: {type: Boolean, default: false}
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      is_loading: false,

      file_name: null,
      file_format: 'GeoTIFF',
      file_formats: ['GeoTIFF'],
      selected_only: false,
    }
  },
  methods: {
    async submit() {
      this.is_loading = true;
      let rec = await idb_r(`${this.layer_id}__blob`)
      let layer_blob = rec.blob_obj

      switch (this.file_format) {
        case 'GeoTIFF':
          this.file_name += '.tiff'
          console.log(this.file_name)
          await export_raster(layer_blob, this.file_name)
          break;
        default:
          break;
      }
      this.is_loading = false
      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      let layers = await get_qfDocument_type_as_dict('layer')
      let layer_obj = layers[this.layer_id]
      this.file_name = layer_obj.name

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
