import {store, templates} from "@/store";
import {v4 as uuidv4} from "uuid";
import {
    async_storage_get_blob,
    async_storage_upload,
    storage_delete,
} from "@/services/fb_storage_api";
import {
    firestore_c_doc,
    firestore_r_doc,
    firestore_d_doc,
    firestore_r_collection,
} from "@/services/fb_firestore_api";
import {rtdb_c, rtdb_r, rtdb_d} from "@/services/fb_rtdb_api";
import {
    deepClone,
    unique_array, remove_from_list,
} from "@/services/generic";
import {r_user_profile} from "@/services/user_api";
import {
    idb_c,
    idb_r,
    idb_d,
    idb_get_item,
    idb_u_qfDocuments,
    idb_u_qfCollections, idb_qfDocument_save_blob,
} from "@/services/idb_api";


import {file_to_zip_blob, get_audit_obj} from "@/services/app_utils";


export async function reload_firestore() {


    if (store.state.auth.isLoggedIn && store.state.is_online) {

        const uid = store.state.auth.user.uid

        let qfDocuments_server = await firestore_r_collection(`/user_data/${uid}/qfDocuments`)
        let qfDocuments_local = await idb_r(`qfDocuments`, false)

        let qfCollections_server = await firestore_r_collection(`/user_data/${uid}/qfCollections`)

        console.log('🏁🏁🏁🏁🏁🏁')
        console.log(qfDocuments_server)
        console.log(qfDocuments_local)

        let server_qfDocument_ids_list = Object.keys(qfDocuments_server)
        let local_qfDocument_ids_list = Object.keys(qfDocuments_local)

        console.log(local_qfDocument_ids_list)
        console.log(server_qfDocument_ids_list)

        // local_qfDocument_ids_list.push('123', '456')

        let del_blob_ids = local_qfDocument_ids_list.filter(item => !server_qfDocument_ids_list.includes(item));
        for (let del_blob_id of del_blob_ids) {
            let del_obj = qfDocuments_local[del_blob_id]
            if (del_obj.docBlob.has_blob) {
                await idb_d(`${del_blob_id}__blob`)
            }
        }

        await idb_c('qfDocuments', qfDocuments_server, false)
        await idb_c('qfCollections', qfCollections_server, false)

        // check the version for the blobs and replace with the latest version
        for (let qfDocument_id of server_qfDocument_ids_list) {
            let qfDocument_server = qfDocuments_server[qfDocument_id]
            let qfDocument_local = qfDocuments_local[qfDocument_id]

            if (qfDocument_server.docBlob.has_blob) {

                if (qfDocument_local.docBlob.version < qfDocument_server.docBlob.version) {
                    await qfDocument_blob_download(qfDocument_id, qfDocument_server)
                }
            }
        }

        store.state.is_sync_ready = true
    }


}


export async function r_user_data() {

    store.state.init_loaded = false
    if (store.state.auth.isLoggedIn && store.state.is_online) {
        await r_user_profile()
        await reload_firestore()
        store.state.is_sync_done = false
        await check_template_qfDocuments() // TO DO: check if it makes an infinite loop
        await check_template_qfCollections()
        store.state.is_sync_done = true
        store.state.is_sync_ready = false
    }
    store.state.init_loaded = true
}


export async function r_anonymous_user_data() {

    store.state.init_loaded = false
    await check_template_qfDocuments()
    await check_template_qfCollections()
    store.state.init_loaded = true

}

export async function clear_map() {
    if (store.state.map !== null) {
        store.state.map.eachLayer(function (layer) {
            if (!('do_not_remove' in layer)) {
                store.state.map.removeLayer(layer);
            }
        });

        store.state.map.pm.disableDraw()
        store.state.map.off('pm:drawstart')
        store.state.map.off('pm:drawend')
        store.state.map.off('pm:create')
    }
}

/* CHECK TEMPLATES */
export async function check_template(item_obj, the_template) {

    for (let tk in the_template) {
        if (!(tk in item_obj)) {
            item_obj[tk] = the_template[tk]
        } else {
            if (typeof the_template[tk] === 'object') {
                for (let tk1 in the_template[tk]) {
                    if (!(tk1 in item_obj[tk])) {
                        item_obj[tk][tk1] = the_template[tk][tk1]
                    }
                }
            }
        }
    }
    return item_obj
}

export async function check_template_qfDocuments() {
    let qfDocuments = await idb_get_item('qfDocuments')
    for (let qfDocument_id in qfDocuments) {
        let qfDocument_obj = qfDocuments[qfDocument_id]
        let qfDocument_type = qfDocument_obj.type
        qfDocument_obj = await check_template(qfDocument_obj, templates.qfDocument)
        qfDocument_obj.config = await check_template(qfDocument_obj.config, templates.qfDocument__config__templates[qfDocument_type])
    }
    await idb_c('qfDocuments', qfDocuments, false)
}

export async function check_template_qfCollections() {
    let qfCollections = await idb_get_item('qfCollections')
    for (let qfCollection_id in qfCollections) {
        let qfCollection_obj = qfCollections[qfCollection_id]
        qfCollection_obj = await check_template(qfCollection_obj, templates.qfCollection)

        for (let group_name in templates.qfCollection__qfDocumentType__dict_templates) {
            for (let item_id in qfCollection_obj[group_name].dict) {
                let item_dict = qfCollection_obj[group_name].dict[item_id]
                qfCollection_obj[group_name].dict[item_id] = await check_template(
                    item_dict,
                    templates.qfCollection__qfDocumentType__dict_templates[group_name]
                )
            }
        }
    }
    await idb_c('qfCollections', qfCollections, false)
}

/* STORE-DOWNLOAD-UPLOAD */
export async function qfCollection_obj_upload(qfCollection_id, qfCollection_obj) {
    let is_uploaded = false
    if (store.state.auth.isLoggedIn && store.state.is_online) {
        const uid = store.state.auth.user.uid
        qfCollection_obj.is_uploaded = true
        await firestore_c_doc(`user_data/${uid}/qfCollections/${qfCollection_id}`, qfCollection_obj)
        is_uploaded = true
    }
    return is_uploaded
}

export async function qfDocument_obj_upload(qfDocument_id, qfDocument_obj) {
    let is_uploaded = false
    // we need to check for allocation when creating or importing new layers
    if (store.state.auth.isLoggedIn && store.state.is_online) {
        const uid = store.state.auth.user.uid
        qfDocument_obj.is_uploaded = true
        await firestore_c_doc(`user_data/${uid}/qfDocuments/${qfDocument_id}`, qfDocument_obj)
        is_uploaded = true
    }
    return is_uploaded
}

export async function qfDocument_blob_store_in_idb(qfDocument_id, qfDocument_obj, blob_obj) {
    let rec = {
        version: qfDocument_obj.docBlob.version,
        blob_obj: blob_obj
    }
    await idb_c(`${qfDocument_id}__blob`, rec, false)
}

export async function qfDocument_blob_download(qfDocument_id, qfDocument_obj) {
    if (store.state.auth.isLoggedIn && store.state.is_online) {
        let blob_obj = await async_storage_get_blob(qfDocument_obj.docBlob.path)
        await qfDocument_blob_store_in_idb(qfDocument_id, qfDocument_obj, blob_obj)
        return true
    } else {
        return false
    }

}

export async function qfDocument_blob_upload(qfDocument_id, qfDocument_obj, blob_obj = null) {
    console.log('in qfDocument_blob_upload')
    const uid = store.state.auth.user.uid

    let is_uploaded = false
    if (store.state.auth.isLoggedIn && store.state.is_online) {
        console.log('1')
        if (blob_obj === null) {
            let rec = await idb_r(`${qfDocument_id}__blob`, false)
            if (rec === null) {
                blob_obj = null
            } else {
                blob_obj = rec.blob_obj
            }
        }
        console.log(blob_obj)
        if (blob_obj !== null) {
            if (qfDocument_obj.docBlob.is_zipped) {
                qfDocument_obj.docBlob.path = `user_data/${uid}/qfDocuments/${qfDocument_id}.zip`
            } else {
                qfDocument_obj.docBlob.path = `user_data/${uid}/qfDocuments/${qfDocument_id}.${qfDocument_obj.docBlob.type}`
            }

            let upload_metadata = await async_storage_upload(blob_obj, qfDocument_obj.docBlob.path)
            console.log('upload_metadata')
            console.log(upload_metadata)
            if (!(upload_metadata === null || upload_metadata === undefined)) {
                console.log('4')
                is_uploaded = true
                qfDocument_obj.docBlob.is_uploaded = true
                await idb_u_qfDocuments(qfDocument_id, qfDocument_obj)
                await qfDocument_obj_upload(qfDocument_id, qfDocument_obj)
            }

        }
    }
    return is_uploaded
}


/* qfDocuments */
export async function c_qfDocument(doc_obj, blob_obj = null, upload_blob = false) {

    let is_uploaded
    let doc_id = doc_obj.id
    if (doc_id === null) {
        doc_id = uuidv4().replaceAll('-', '')
        doc_obj.id = doc_id
    }
    doc_obj.version = 0
    doc_obj.audit = []
    doc_obj.audit.push(await get_audit_obj('C', doc_obj.version, null))

    // -------- IDB --------------

    await idb_u_qfDocuments(doc_id, doc_obj)

    if (doc_obj.docBlob.has_blob) {
        doc_obj.docBlob.version = 0

        if (blob_obj !== null) {
            if (doc_obj.docBlob.is_zipped) {
                blob_obj = await file_to_zip_blob(blob_obj, doc_obj.docBlob.type)
            }
            doc_obj.docBlob.size = blob_obj.size
            await idb_qfDocument_save_blob(doc_id, 0, blob_obj)
        }
        await idb_u_qfDocuments(doc_id, doc_obj)
    }

    // -------- FIRESTORE & STORAGE --------------
    if (doc_obj.docBlob.has_blob && blob_obj !== null && upload_blob) {
        is_uploaded = await qfDocument_blob_upload(doc_id, doc_obj, blob_obj)
        // this uploads both blob and doc
    } else {
        is_uploaded = await qfDocument_obj_upload(doc_id, doc_obj)
    }


    return {doc_id, is_uploaded}

}

export async function u_qfDocument(doc_obj, blob_obj = null, upload_blob = false) {
    console.log('🔶 in u_qfdocument')
    let is_uploaded
    const doc_id = doc_obj.id
    doc_obj.version += 1

    doc_obj.audit.push(await get_audit_obj('U', doc_obj.version, null))

    // -------- IDB --------------

    await idb_u_qfDocuments(doc_id, doc_obj)

    if (doc_obj.docBlob.has_blob) {
        doc_obj.docBlob.version += 1

        if (blob_obj !== null) {
            if (doc_obj.docBlob.is_zipped) {
                blob_obj = await file_to_zip_blob(blob_obj, doc_obj.docBlob.type)
            }
            doc_obj.docBlob.size = blob_obj.size

            await idb_qfDocument_save_blob(doc_id, doc_obj.docBlob.version, blob_obj)
        }
        await idb_u_qfDocuments(doc_id, doc_obj)
    }

    // -------- FIRESTORE & STORAGE --------------

    if (doc_obj.docBlob.has_blob && blob_obj !== null && upload_blob) {
        is_uploaded = await qfDocument_blob_upload(doc_id, doc_obj, blob_obj)
        // this uploads both blob and doc
    } else {
        is_uploaded = await qfDocument_obj_upload(doc_id, doc_obj)
    }

    return is_uploaded

}

export async function d_qfDocument(qfDocument_id) {
    const uid = store.state.auth.user.uid
    let qfDocuments = await idb_get_item('qfDocuments')
    let qfDocument_obj;
    if (qfDocument_id in qfDocuments) {
        qfDocument_obj = deepClone(qfDocuments[qfDocument_id])
        delete qfDocuments[qfDocument_id]
        await idb_c('qfDocuments', qfDocuments, false)
    } else {
        qfDocument_obj = await firestore_r_doc(`user_data/${uid}/qfDocuments/${qfDocument_id}`)
    }

    if (qfDocument_obj !== null) {

        await d_qfDocument_from_all_qfCollections(qfDocument_id, qfDocument_obj)

        // delete from idb
        if (qfDocument_obj.docBlob.has_blob) {
            await idb_d(`${qfDocument_id}__blob`)
        }

        // remove from firestore
        if (store.state.auth.isLoggedIn && store.state.is_online) {
            firestore_d_doc(`user_data/${uid}/qfDocuments/${qfDocument_id}`)

            if (qfDocument_obj.is_uploaded) {
                storage_delete(qfDocument_obj.docBlob.path)
            }
        }

    }
}

export async function d_qfDocument_from_qfCollection(qfCollection_id, qfDocument_obj) {
    let qfCollections = await idb_get_item('qfCollections')
    let qfCollection_obj = qfCollections[qfCollection_id]

    let qfDocument_id = qfDocument_obj.id
    let qfDocument_type = qfDocument_obj.type

    let group_name = templates.qfCollection__qfDocument__groups[qfDocument_type]

    delete qfCollection_obj[group_name].dict[qfDocument_id]
    qfCollection_obj[group_name].list = await remove_from_list(qfCollection_obj[group_name].list, qfDocument_id)
    await u_qfCollection(qfCollection_obj)
}

export async function d_qfDocument_from_all_qfCollections(qfDocument_id, qfDocument_obj) {
    let qfCollections = await idb_get_item('qfCollections')
    for (let qfCollection_id in qfCollections) {
        await d_qfDocument_from_qfCollection(qfCollection_id, qfDocument_obj)
    }
}


/* qfCollections */

export async function c_qfCollection(qfCollection_obj) {

    let qfCollection_id

    qfCollection_id = uuidv4().replaceAll('-', '')
    qfCollection_obj.id = qfCollection_id
    qfCollection_obj.version = 0
    qfCollection_obj.audit.push(await get_audit_obj('C', qfCollection_obj.version, null))

    await idb_u_qfCollections(qfCollection_id, qfCollection_obj)

    if (store.state.auth.isLoggedIn && store.state.is_online) {
        await qfCollection_obj_upload(qfCollection_id, qfCollection_obj)
    }


}

export async function u_qfCollection(qfCollection_obj, upload_obj = true) {
    const qfCollection_id = qfCollection_obj.id
    qfCollection_obj.version += 1
    qfCollection_obj.audit.push(await get_audit_obj('U', qfCollection_obj.version, null))

    await idb_u_qfCollections(qfCollection_id, qfCollection_obj)

    if (store.state.auth.isLoggedIn && store.state.is_online && upload_obj) {
        await qfCollection_obj_upload(qfCollection_id, qfCollection_obj)
    }

}

export async function d_qfCollection(qfCollection_id) {

    const uid = store.state.auth.user.uid

    // delete from idb
    let qfCollections = await idb_get_item('qfCollections')

    delete qfCollections[qfCollection_id]

    await idb_c('qfCollections', qfCollections, false)

    // remove from rtdb
    if (store.state.auth.isLoggedIn && store.state.is_online) {
        firestore_d_doc(`user_data/${uid}/qfCollections/${qfCollection_id}`)
    }

}
