<template>
  <v-card style="margin-bottom: 10px;">
    <v-card-text>
      <div id="plot_div"></div>
    </v-card-text>
  </v-card>
</template>

<script>

import {newPlot} from 'plotly.js-dist-min'

export default {
  name: "PlotsLineWithBounds",
  props: {
    plot_data: {type: Array},
  },
  data() {
    return {
      template_plot_data: [{
        x: [],
        y: [],
        color: 'green',
        width: 4,
      }]
    }
  },
  methods: {
    async do_plot() {
      // let data = []
      console.log(this.plot_data)
      console.log('---------------------')

      let layout = {
        // showlegend: false,
        autosize: true,
        // margin: {
        //   l: 50,
        //   r: 50,
        //   b: 50,
        //   t: 50,
        //   pad: 4
        // },
        xaxis: {
          range: [this.plot_data[0].x[0], this.plot_data[0].x[this.plot_data[0].x.length-1]],
          showgrid: false,
          showline: false,
        },
        yaxis: {
          showgrid: false,
          showline: false,
        },
      }

      newPlot('plot_div', this.plot_data, layout);
    },
    init() {


      this.do_plot()
    },
  },
  watch: {
    plot_data() {
      this.do_plot()
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
