<template>
  <v-card
      v-if="layer_obj !== null"
      :loading="is_loading"
      style="margin-bottom: 5px;"
      elevation="2">
    <v-app-bar
        flat
        dense
        height="auto"
        color="white"
    >
      <v-checkbox dense style="font-size: 6px;"
                  v-model="map_layer_dict.config.show_layer"
                  :label="layer_obj.name"
                  hide-details
                  @change="on_click_layer_checkbox"
      >
        <template v-slot:label>
          <div class="text-caption">
            <v-chip
                label small
                style="padding: 0;"
            >
              <v-icon small>
                mdi-pyramid
              </v-icon>
            </v-chip>
            {{ layer_obj.name }}
          </div>
        </template>
      </v-checkbox>

      <v-spacer></v-spacer>

      <v-btn
          icon
          small
          @click="switch_show_details"
      >
        <v-icon v-if="!map_layer_dict.config.show_details">mdi-chevron-down</v-icon>
        <v-icon v-if="map_layer_dict.config.show_details">mdi-chevron-up</v-icon>
      </v-btn>
    </v-app-bar>

    <div v-if="map_layer_dict.config.show_details">
      <v-container>
        <MapsTileLayerTools
            v-if="layer_tools_show_mode !== 'none' && !is_loading"
            :layer_tools_show_mode.syc="layer_tools_show_mode"
            :on_select_tool="on_select_tool"
        ></MapsTileLayerTools>

        <v-subheader class="pl-0">
          Opacity
        </v-subheader>
        <v-slider
            style="max-width: 300px;"
            v-model="tile_options.opacity"
            min="0"
            max="1"
            step="0.05"
            color="red"
            thumb-color="blue"
            thumb-label="always"
            prepend-icon="mdi-opacity"
            @change="set_layer_opacity"
        ></v-slider>

        <div>
          <v-img
              v-if="legend_obj.has_legend"
              max-width="400"
              contain
              :src="legend_obj.img_url"
          ></v-img>
        </div>
      </v-container>


      <MapsConfirmDeleteLayerFromMapDialog
          v-if="MapsConfirmDeleteLayerFromMapDialog_show"
          :show_dialog.sync="MapsConfirmDeleteLayerFromMapDialog_show"
          :layer_name="layer_obj.name"
          :callback_function="submit_delete"
      ></MapsConfirmDeleteLayerFromMapDialog>

    </div>

    <DriveAllocationWarning
        v-if="DriveAllocationWarning_show"
        :show_dialog.sync="DriveAllocationWarning_show"
    ></DriveAllocationWarning>

    <DriveTileLayerTMSDialog
        v-if="DriveTileLayerTMSDialog_show"
        :show_dialog.sync="DriveTileLayerTMSDialog_show"
        :action="DriveTileLayerTMSDialog_action"
        :map_id="map_id"
        :layer_id="layer_id"
        :refresh_map_function="layers_list_init_function"
        :refresh_qfdrive_function="null"
    ></DriveTileLayerTMSDialog>

  </v-card>
</template>

<script>

import axios from "axios";
import {DateTime} from "luxon";
import {tileLayer, CRS} from 'leaflet'
import MapsTileLayerTools from "@/components/Maps/MapsTileLayerTools";
import MapsConfirmDeleteLayerFromMapDialog from "@/components/Maps/MapsConfirmDeleteLayerFromMapDialog";
import DriveTileLayerTMSDialog from "@/components/Drive/DriveTileLayerTMSDialog";
import DriveAllocationWarning from "@/components/Drive/DriveAllocationWarning";

import {
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
} from "@/services/app_utils";
import {d_qfDocument, d_qfDocument_from_qfCollection} from "@/services/app_api";
import {deepClone} from "@/services/generic";
import {store, templates} from "@/store";


export default {
  name: "MapsTileLayerTMS",
  components: {
    MapsTileLayerTools,
    MapsConfirmDeleteLayerFromMapDialog,
    DriveTileLayerTMSDialog,
    DriveAllocationWarning,
  },
  props: {
    map_id: {type: String},
    layer_id: {type: String},
    z_index: {type: Number},
    layers_list_init_function: {type: Function},
    update_map_layer_dict: {type: Function},
  },
  data() {
    return {

      layer_obj: null,  // layer object
      map_layer_dict: null, // = maps[this.map_id].layers.dict[this.layer_id]

      tile_obj: null,
      tile_options: {opacity: 1},

      MapsConfirmDeleteLayerFromMapDialog_show: false,
      layer_tools_show_mode: 'all', // all, none, top_only

      DriveAllocationWarning_show: false,
      is_loading: false,

      DriveTileLayerTMSDialog_show: false,
      DriveTileLayerTMSDialog_action: 'Edit',

      layer_options: {},
      legend_obj: {
        has_legend: false,
        name: null,
        img_b64: null,
        img_url: null,
        width: null,
        height: null,
        format: null,
      }
    }
  },
  methods: {

    async process_leaflet_tms() {

      await this.remove_layer_from_map()

      if (this.$store.state.map.getPane(this.layer_id) === undefined) {
        this.$store.state.map.createPane(this.layer_id);
      }
      this.$store.state.map.getPane(this.layer_id).style.zIndex = this.z_index

      this.tile_options = deepClone(this.layer_obj.config.tile_layer_options.leaflet_tms)

      let tile_options = this.tile_options
      console.log('tile_options', tile_options)
      let tile_options_template = deepClone(templates.qfDocument__config__templates.layer.tile_layer_options.leaflet_tms)
      console.log('tile_options_template 00', tile_options_template)
      let layer_url = tile_options.url

      if (tile_options.api_key_key !== null && tile_options.api_key_value !== null) {
        if (tile_options.api_key_key.length > 1 && tile_options.api_key_value.length > 1) {
          layer_url += `?${tile_options.api_key_key}=${tile_options.api_key_value}`
        }
      }

      let layer_options = {}
      if (tile_options.attribution !== null) {
        if (tile_options.attribution.length > 2) layer_options.attribution = tile_options.attribution
      }
      let forbidden_keys = ['url', 'api_key_key', 'api_key_value', 'attribution']
      for (let key in tile_options_template) {
        if (key in tile_options && !forbidden_keys.includes(key)) {
          let the_val = tile_options[key]
          let the_val_default = tile_options_template[key]

          if (the_val !== the_val_default) {
            layer_options[key] = parseFloat(the_val)
          }
        }
      }

      layer_options.zIndex = this.z_index
      layer_options.pane = this.layer_id

      console.log('TMS OPTIONS',layer_url, layer_options)

      this.tile_obj = tileLayer(layer_url, layer_options)

    },

    set_layer_opacity() {
      this.tile_obj.setOpacity(this.tile_options.opacity)
    },

    async edit_layer() {
      this.DriveTileLayerTMSDialog_action = 'Edit'
      this.DriveTileLayerTMSDialog_show = true
    },

    async duplicate_layer() {
      this.DriveTileLayerTMSDialog_action = 'Duplicate'
      this.DriveTileLayerTMSDialog_show = true
    },
    async submit_delete(permanent_delete = false) {

      await this.remove_layer_from_map()
      if (permanent_delete) {
        await d_qfDocument(this.layer_id)
      } else {
        await d_qfDocument_from_qfCollection(this.map_id, this.layer_obj)
      }
      this.layers_list_init_function(this.map_id)
    },
    // Tools
    async on_select_tool(action, row_uid = null) {
      switch (action) {
        case 'delete':
          this.MapsConfirmDeleteLayerFromMapDialog_show = true;
          break;
        case 'edit_layer':
          await this.edit_layer()
          break;
        case 'duplicate_layer':
          await this.duplicate_layer()
          break;
        default:
          break;
      }
    },

    // layer on-off
    async on_click_layer_checkbox() {
      if (!this.map_layer_dict.config.show_layer) {
        await this.remove_layer_from_map()
      } else {
        await this.add_layer_to_map()
      }
      await this.do_update_map_layer_dict()
    },
    async do_update_map_layer_dict(save_map = false) {
      await this.update_map_layer_dict(this.layer_id, this.map_layer_dict, save_map)
    },
    async switch_show_details() {
      this.map_layer_dict.config.show_details = !this.map_layer_dict.config.show_details
      await this.do_update_map_layer_dict()
    },
    async remove_layer_from_map() {
      if (this.tile_obj !== null) {
        await this.tile_obj.removeFrom(this.$store.state.map)
      }
    },
    async add_layer_to_map() {
      if (this.tile_obj !== null) {
        await this.tile_obj.addTo(this.$store.state.map)
      }
    },

    // INIT
    async init_function() {

      let maps = await get_qfCollection_type_as_dict('map')
      let layers = await get_qfDocument_type_as_dict('layer')

      if (this.layer_id in layers) {
        this.is_loading = false
        this.map_layer_dict = maps[this.map_id].layers.dict[this.layer_id]
        this.layer_obj = layers[this.layer_id]

        await this.process_leaflet_tms()
        await this.on_click_layer_checkbox()

        this.is_loading = false

      } else {
        console.log('NO LAYER')
      }

    },

  },


  beforeDestroy() {
    if (this.tile_obj !== null) {
      this.tile_obj.removeFrom(this.$store.state.map)
      this.tile_obj.remove()
    } else {
      let cnt = 0
      let interval_id = setInterval(() => {
        cnt += 1
        if (this.tile_obj !== null) {
          this.tile_obj.removeFrom(this.$store.state.map)
          this.tile_obj.remove()
          this.tile_obj = null
          clearInterval(interval_id);
        } else if (cnt >= 50) {
          clearInterval(interval_id);
        }
      }, 100);
    }
  },
  watch: {
    '$store.state.is_sync_done': async function () {
      let layers = await get_qfDocument_type_as_dict('layer')
      if (layers[this.layer_id].version > this.layer_obj.version) {
        await this.init_function()
      }
    },
  },
  mounted() {
    this.init_function()
  }
}
</script>

<style scoped>

.box {
  float: left;
  height: 20px;
  width: 20px;
  clear: both;
}

</style>
