<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="purple" left>mdi-export</v-icon>
          <span class="body-1">
            Export Vector Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-text-field
              v-model="file_name"
              label="File Name"
              outlined
              dense
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-select
              outlined
              dense
              v-model="file_format"
              :items="file_formats"
              label="Export Format"
              :hide-details="true"
          >
          </v-select>
        </v-col>

        <v-col v-if="selected_uid_list.length > 0">
          <v-switch
              v-model="selected_only"
              :hide-details="true"
              dense
              label="Include selected features only"
          ></v-switch>
        </v-col>

        <v-col>
          <v-switch
              v-model="include_system_uid"
              :hide-details="true"
              dense
              label="Include qfDrive unique id for features"
          ></v-switch>
        </v-col>


        <v-alert
            v-if="show_save_warning"
            style="margin-top: 10px;"
            max-width="400"
            border="left"
            color="green"
            text
            dense
            type="info"
        >
          Only saved changes will be exported.
        </v-alert>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="purple"
               :disabled="is_loading"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {save_as_geojson_file, save_as_kml_file} from "@/services/gis_utils";
import {export_json_2_csv} from "@/services/generic";
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import {filter_json_arrays_for_row_uids} from "@/services/json_arrays_api";

export default {
  name: "DriveVectorLayerExportDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    layer_id: {type: String},
    selected_uid_list: {type: Array, default: []},
    show_save_warning: {type: Boolean, default: false}
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      is_loading: false,

      layer_obj: {},
      file_name: null,
      file_format: 'GeoJSON',
      file_formats: ['GeoJSON', 'KML', 'CSV (table)'],
      selected_only: false,
      include_system_uid: false,
    }
  },
  methods: {

    async json_arrays_to_geojson(json_arrays) {

      if (this.selected_only) {
        json_arrays = await filter_json_arrays_for_row_uids(json_arrays, this.selected_uid_list)
      }

      let schema = this.layer_obj.config.schema
      let geojson = {
        "type": "FeatureCollection",
        "features": []
      }

      let col_uids = Object.keys(json_arrays).filter(e => e !== 'geometry' && e !== 'row_uid')

      for (let idx in json_arrays.row_uid) {
        let feature_properties = {}
        for (let col_uid of col_uids) {
          let col_label = schema[col_uid].label
          feature_properties[col_label] = json_arrays[col_uid][idx]
        }
        if (this.include_system_uid) {
          feature_properties.row_uid = json_arrays.row_uid[idx]
        }

        geojson.features.push({
          type: 'Feature',
          properties: feature_properties,
          geometry: json_arrays.geometry[idx]
        })
      }

      return geojson

    },

    async submit() {
      this.is_loading = true;
      let json_arrays = await idb_unzip_blob(this.layer_id)
      let geojson = await this.json_arrays_to_geojson(json_arrays)

      switch (this.file_format) {
        case 'GeoJSON':
          this.file_name += '.geojson'
          await save_as_geojson_file(geojson, this.file_name)
          break;
        case 'KML':
          this.file_name += '.kml'
          await save_as_kml_file(geojson, this.file_name)
          break;
        case 'CSV (table)':
          this.file_name += '.csv'
          console.log(this.file_name)
          let props_list = []
          for (let feature of geojson.features) {
            props_list.push(feature.properties)
          }
          console.log(props_list)
          await export_json_2_csv(props_list, this.file_name)
          break;
        default:
          break;
      }
      this.is_loading = false
      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      let layers = await get_qfDocument_type_as_dict('layer')
      this.layer_obj = layers[this.layer_id]
      this.file_name = this.layer_obj.name.replace('.geojson', '').replace('.shp', '').replace('.kml', '')

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
