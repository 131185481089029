<template>
  <v-app>
    <TheNavbar></TheNavbar>
    <v-main >
      <router-view/>
    </v-main>
    <AppSnackbar></AppSnackbar>
<!--    <AppWarningBeta v-if="show_app_warning_beta" :show_dialog.sync="show_app_warning_beta"></AppWarningBeta>-->
  </v-app>
</template>

<script>

import TheNavbar from "@/components/App/TheNavbar";
import AppSnackbar from "@/components/App/AppSnackbar";
// import AppWarningBeta from "@/components/App/AppWarningBeta";
import {init_r_app} from "@/services/init_api";
import {database} from "@/firebase/firebase";
import {goOffline, goOnline} from "firebase/database";
import {show_snackbar} from "@/services/app_utils";
import Bowser from "bowser"
import {set_leaflet_libraries} from "@/services/leaflet_api";

export default {
  name: 'App',
  components: {
    TheNavbar,
    AppSnackbar,
    // AppWarningBeta,
  },
  data() {
    return {
      show_app_warning_beta: false,
    }
  },
  methods: {
    async on_user_online() {
      goOnline(database)

      if (!this.$store.state.is_online) {
        show_snackbar('You are back online', 'green', 5000)
        this.$store.state.is_online = true
        setTimeout(async () => {
          await init_r_app()
        }, 100)
      }
      this.$store.state.is_online = true

    },
    on_user_offline() {
      show_snackbar('You are offline', 'pink', 5000)
      goOffline(database)
      this.$store.state.is_online = false
    },
    async get_browser_info() {
      let browser_info = Bowser.parse(window.navigator.userAgent)
      this.$store.state.device_info = {
        browser_name: browser_info.browser.name,
        browser_version: browser_info.browser.version,
        os_name: browser_info.os.name,
        device_type: browser_info.platform.type
      }
    },
  },
  watch: {
    '$store.state.auth.user': async function () {
      console.log('in app AFTER Auth.user watch')
      console.log(this.$store.state.auth.user)
      await init_r_app()
    }
  },

  mounted() {
    set_leaflet_libraries()
    this.get_browser_info()
    window.addEventListener('online', () => {
      this.on_user_online()
    });
    window.addEventListener('offline', () => {
      this.on_user_offline()
    });

  }

};
</script>

<style>
html { overflow-y: auto }
.v-tab {
  text-transform: none !important;
}
</style>