import store, {templates} from "@/store";
import JSZip from "jszip";
import {DateTime} from "luxon";
import {idb_r} from "@/services/idb_api";
import {deepClone, get_random_color, readFileAsync} from "@/services/generic";
import {c_qfDocument, u_qfCollection, u_qfDocument} from "@/services/app_api";
import {check_user_files} from "@/services/user_api";
import {get_utc_dt_iso} from "@/services/datetime_api";


export async function set_random_style(feature_type) {
    let feature_style = deepClone(templates.feature_style[feature_type])
    let color = await get_random_color()

    switch (feature_type) {
        case 'Polygon':
            feature_style.fillColor = color
            feature_style.color = color
            break;
        case 'Line':
            feature_style.color = color
            break;
        case 'Point':
            feature_style.fillColor = color
            feature_style.color = color
            break;
    }

    feature_style.fillColor = color
    feature_style.color = color

    return feature_style
}

export function show_snackbar(the_text, the_color, the_timeout = 5000, the_multi_line = false) {
    store.state.snackbar.show = false
    setTimeout(() => {
        store.state.snackbar.text = the_text
        store.state.snackbar.timeout = the_timeout
        store.state.snackbar.color = the_color
        store.state.snackbar.multi_line = the_multi_line
        store.state.snackbar.show = true
    }, 500)

}

export async function get_table_headers(json_arrays, schema) {
    let col_uids = Object.keys(json_arrays).filter(e => e !== 'geometry')
    let table_headers = []
    for (let col_uid of col_uids) {
        if (!schema[col_uid].is_system) {
            table_headers.push({
                value: col_uid, text: schema[col_uid].label,
            })
        }
    }
    return table_headers
}

export async function get_table_data(json_arrays) {
    let col_uids = Object.keys(json_arrays).filter(e => e !== 'geometry')
    let table_data = []
    for (let idx in json_arrays.row_uid) {
        let the_dict = {}
        for (let col_uid of col_uids) {
            the_dict[col_uid] = json_arrays[col_uid][idx]
        }
        table_data.push(the_dict)
    }
    return table_data
}


export async function file_to_zip_blob(file_obj, file_type) {

    switch (file_type) {
        case 'json':
            file_obj = JSON.stringify(file_obj, null, 0)
            break;
        default:
            file_obj = await readFileAsync(file_obj, 'array_buffer')
            break;
    }

    let zip = new JSZip()
    zip.file('file', file_obj)

    let zip_promise = zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
            level: 9
        }
    }).then(async (zipBlob) => {
        return zipBlob
    })

    return await zip_promise
}


export async function unzip_blob(zip_blob_obj, file_type) {
    let read_type
    switch (file_type) {
        case 'json':
            read_type = 'string'
            break;
        case 'tiff':
            read_type = 'array_buffer'
            break;
        default:
            read_type = 'string'
            break;
    }

    let zip = await JSZip.loadAsync(zip_blob_obj)
    let zipData = await zip.file('file').async(read_type);

    let zipOut = null
    switch (file_type) {
        case 'json':
            zipOut = JSON.parse(zipData)
            break;
        default:
            break;
    }

    return zipOut

}

export async function idb_unzip_blob(qfDocument_id) {
    const qfDocuments = await idb_r('qfDocuments');
    if (qfDocument_id in qfDocuments) {
        let qfDocument_obj = qfDocuments[qfDocument_id]
        let rec = await idb_r(`${qfDocument_id}__blob`)

        if (rec === null) return null

        return await unzip_blob(rec.blob_obj, qfDocument_obj.docBlob.type)

    } else {
        return null
    }

}


export async function get_audit_obj(state, version, archive_id) {

    return {
        state: state,
        version: version,
        utc_iso_dt: get_utc_dt_iso(),
        uid: store.state.auth.user.uid,
        device: store.state.device_info,
        archive_id: archive_id,
    }

}




export async function get_qfDocument_type_as_dict(the_type) {
    const qfDocuments = await idb_r('qfDocuments');
    let the_dict = {}
    let qfDocument_obj
    for (let qfDocument_id in qfDocuments) {
        qfDocument_obj = qfDocuments[qfDocument_id]
        if (qfDocument_obj.type === the_type) {
            the_dict[qfDocument_id] = qfDocument_obj
        }
    }
    return the_dict
}

export async function get_qfDocument_history(qfDocument_id) {
    const qfDocuments_history = await idb_r('qfDocuments_history');

    if (qfDocument_id in qfDocuments_history){
        return qfDocuments_history[qfDocument_id]
    } else {
        return null
    }

}

export async function get_qfCollection_type_as_dict(the_type) {
    const qfCollections = await idb_r('qfCollections');
    let the_dict = {}
    let qfCollection_obj
    for (let qfCollection_id in qfCollections) {
        qfCollection_obj = qfCollections[qfCollection_id]
        if (qfCollection_obj.type === the_type) {
            the_dict[qfCollection_id] = qfCollection_obj
        }
    }
    return the_dict
}


export async function c_qfDocument_vector_layer(layer_name,
                                                feature_type,
                                                json_arrays,
                                                map_id = null,
                                                upload_blob = false,
                                                config = null) {
    let doc_obj = deepClone(templates.qfDocument)
    doc_obj.type = 'layer'
    doc_obj.name = layer_name

    doc_obj.config = deepClone(templates.qfDocument__config__templates.layer)

    if (config !== null) {
        for (let key in config) {
            doc_obj.config[key] = config[key]
        }
    }

    doc_obj.config.layer_type = 'vector'
    doc_obj.config.layer_format = 'GeoJSON'
    doc_obj.config.feature_type = feature_type

    doc_obj.docBlob.has_blob = true
    doc_obj.docBlob.type = 'json'
    let resp = await c_qfDocument(doc_obj, json_arrays, upload_blob)
    let layer_id = resp.doc_id
    let is_uploaded = resp.is_uploaded

    if (map_id !== null) {
        let maps = await get_qfCollection_type_as_dict('map')
        let map = maps[map_id]

        map.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
        map.layers.dict[layer_id].id = layer_id
        map.layers.dict[layer_id].style = await set_random_style(feature_type)
        map.layers.list.unshift(layer_id)

        await u_qfCollection(map)
    }

    return {layer_id, is_uploaded}
}


export async function c_qfDocument_query(query_name, config = null) {
    let doc_obj = deepClone(templates.qfDocument)
    doc_obj.type = 'query'
    doc_obj.name = query_name

    doc_obj.config = deepClone(templates.qfDocument__config__templates.query)

    if (config !== null) {
        for (let key in config) {
            doc_obj.config[key] = config[key]
        }
    }
    doc_obj.docBlob.has_blob = false

    return await c_qfDocument(doc_obj, null, false)

}


export async function check_user_allocations(file_size) {
    let files_dict = {
        num: 0,
        size: 0,
        num_limit: store.state.cur_plan.limits.num_files,
        size_limit: store.state.cur_plan.limits.total_size,
    }


    // go over layers and calculate size and count of files and return as dict
    let layers = await get_qfDocument_type_as_dict('layer')

    for (let layer_item of Object.values(layers)) {
        if (layer_item.is_uploaded) {
            files_dict.num += 1
            files_dict.size += layer_item.docBlob.size
        }
    }
    files_dict.num += 1
    files_dict.size += file_size

    if (files_dict.num < files_dict.num_limit && files_dict.size < files_dict.size_limit) {
        return true
    } else {
        return false
    }
}


































