<template>

  <div id="geocoder" style="margin-bottom: 10px;" v-show="$store.state.is_online"></div>

</template>

<script>
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {latLng, latLngBounds, LatLng} from 'leaflet'

export default {
  name: "MapsMapSearchLocation",
  data() {
    return {
      mapBoxAccessToken: this.$store.state.secrets.mapbox,
      mapboxGeocoderObj: null,
    }
  },
  methods: {
    addMapboxGeocoder() {
      this.mapboxGeocoderObj = new MapboxGeocoder({
        accessToken: this.mapBoxAccessToken,
        placeholder: 'Search for address',
        limit: 5,
      })
      this.mapboxGeocoderObj.addTo('#geocoder')
      this.mapboxGeocoderObj.on('result', (ev) => {
        if ('bbox' in ev.result) {
          var bbox = ev.result.bbox; //   [minX,minY,maxX,maxY]

          var corner1 = latLng(bbox[1], bbox[0]),
              corner2 = latLng(bbox[3], bbox[2]);
          this.$store.state.map.fitBounds(latLngBounds(corner1, corner2));
        } else if ('center' in ev.result) {
          var center = ev.result.center; //   [Lon, Lat]

          // this.map.panTo(new LatLng(center[1], center[0]));
          this.$store.state.map.setView(new LatLng(center[1], center[0]), 16);
        }
      })
    },
  },
  mounted() {
    this.addMapboxGeocoder()
  }

}
</script>

<style scoped>

</style>
