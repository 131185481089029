<template>
  <v-card
      class="mx-auto"
      max-width="120"
      min-width="120"
  >
    <v-app-bar
        flat
        dense
    >
      <v-spacer></v-spacer>
      <v-app-bar-title>
        <span class="caption text-center" style="text-transform: capitalize">{{ i_name }}</span>
      </v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-card-text>
      <v-row align="center">
        <v-btn icon @click="DwsPlotDialog_show=true">
          <v-icon>mdi-chart-line</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
      </v-row>

      <v-col class="text-center">
        <span class="caption">{{ Math.round(i_value * 100) / 100 }}</span>
        <span class="caption" v-html="i_unit_display"></span>

        <table
            style="margin: 0 auto; width: 50px; min-width: 50px; max-width: 50px; height: 100px; min-height: 100px; max-height: 100px; border-spacing: 0; border: 0.5px solid gray;">
          <tr :style="{'height': `${100 - value_percentage}px`, 'background-color': 'white', 'border': 0}">
            <td style="border: 0;"></td>
          </tr>
          <tr :style="{'height': `${value_percentage}px`, 'background-color': color, 'border': 0}">
            <td style="border: 0;"></td>
          </tr>
        </table>

      </v-col>


    </v-card-text>

    <DwsPlotDialog
        v-if="DwsPlotDialog_show"
        :show_dialog.sync="DwsPlotDialog_show"
        :callback_function="null"
        :table_name="table_name"
    ></DwsPlotDialog>

  </v-card>
</template>

<script>
import DwsPlotDialog from "@/components/DWS/DwsPlotDialog";

export default {
  name: "DwsTankCard",
  components: {DwsPlotDialog},
  props: {
    i_name: {type: String},
    i_value: {type: Number},
    i_range_list: {type: Array},
    i_unit_display: {type: String},
    i_min: {type: Number},
    i_max: {type: Number},
    table_name: {type: String},

  },
  data() {
    return {
      interval: null,
      the_i_value: this.i_value,
      the_i_min: this.i_min,
      the_i_max: this.i_max,
      DwsPlotDialog_show: false,
    }
  },
  computed: {
    color() {
      if (this.the_i_value < this.i_range_list[0]) return 'green'
      if (this.the_i_value < this.i_range_list[1]) return 'orange'
      return 'red'

    },
    value_percentage() {
      if (this.the_i_value > 0) {
        return 100 * (this.the_i_value / this.the_i_max)
      } else {
        return 100 - 100 * (this.the_i_value / this.the_i_min)
      }


    },
    animationDuration() {
      let anim_dur = 1 - (this.the_i_value - this.the_i_min) / (this.the_i_max - this.the_i_min)
      if (anim_dur > 0.9) anim_dur = 0.9
      return `${anim_dur}s`
    },
  },

  methods: {},
  watch: {},
  mounted() {

  },
}
</script>

<style scoped>
@keyframes metronome-example {
  from {
    transform: scale(.5);
  }

  to {
    transform: scale(1);
  }
}

.v-avatar--metronome {
  animation-name: metronome-example;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>