<template>
  <v-card
      style="margin-bottom: 5px;"
      elevation="2">
    <v-app-bar
        flat
        dense
        height="auto"
        color="white"
    >
      <v-checkbox dense style="font-size: 6px;"
                  v-model="show_group_layers"
                  label="MEWA Base Layers"
                  hide-details
                  @change="on_click_group_checkbox"
      >
        <template v-slot:label>
          <div class="text-caption">
            <v-chip
                label small
                style="padding: 0;"
            >
              <v-icon small>
                mdi-map
              </v-icon>
            </v-chip>
            MEWA Base Layers
          </div>
        </template>
      </v-checkbox>

      <v-spacer></v-spacer>

      <v-btn
          icon
          small
          @click="switch_show_group_details"
      >
        <v-icon v-if="!show_group_details">mdi-chevron-down</v-icon>
        <v-icon v-if="show_group_details">mdi-chevron-up</v-icon>
      </v-btn>

    </v-app-bar>

    <div v-if="show_group_details">
      <v-container>

        <v-col>
          <v-subheader class="pl-0">
            Year
          </v-subheader>
          <v-slider
              style="max-width: 300px;"
              v-model="mewa_layer_year"
              min="2015"
              max="2022"
              step="1"
              color="red"
              thumb-color="red"
              thumb-label="always"
              prepend-icon="mdi-calendar-month"
              @change="set_mewa_layers()"
          ></v-slider>
        </v-col>

        <MapsVectorLayer
            :map_id.sync="map_id"
            :layer_id.sync="mewa_vector_layer_id"
            :z_index.sync="mewa_vector_layer_z_index"
            :layers_list_init_function="null"
            :update_map_layer_dict="null"
        ></MapsVectorLayer>


        <v-card
            v-for="(layer_dict, layer_name) in mewa_layers_dict"
            style="margin-bottom: 5px;"
            elevation="2">
          <v-app-bar
              flat
              dense
              height="auto"
              color="white"
          >
            <v-checkbox dense style="font-size: 6px;"
                        v-model="layer_dict.show_layer"
                        hide-details
                        @change="on_click_layer_checkbox(layer_name)"
            >
              <template v-slot:label>
                <div class="text-caption">
                  <v-chip
                      label small
                      style="padding: 0;"
                  >
                    <v-icon small>
                      mdi-layers-outline
                    </v-icon>
                  </v-chip>
                  {{ layer_name }}
                </div>
              </template>
            </v-checkbox>

            <v-spacer></v-spacer>

            <v-btn
                icon
                small
                @click="layer_dict.show_details = !layer_dict.show_details"
            >
              <v-icon v-if="!layer_dict.show_details">mdi-chevron-down</v-icon>
              <v-icon v-if="layer_dict.show_details">mdi-chevron-up</v-icon>
            </v-btn>

          </v-app-bar>

          <div v-if="layer_dict.show_details">
            <v-container>


              <v-subheader class="pl-0">
                Opacity
              </v-subheader>
              <v-slider
                  style="max-width: 300px;"
                  v-model="layer_dict.opacity"
                  min="0"
                  max="1"
                  step="0.05"
                  color="red"
                  thumb-color="blue"
                  thumb-label="always"
                  prepend-icon="mdi-opacity"
                  @change="set_layer_opacity(layer_name)"
              ></v-slider>
              <v-col v-if="layer_dict.legend_ready">
                <MapsLayerLegendTable :color-value-pairs="layer_dict.legend_pairs"
                                      :opacity="layer_dict.opacity"></MapsLayerLegendTable>
              </v-col>

            </v-container>
          </div>

        </v-card>


      </v-container>

    </div>


  </v-card>
</template>

<script>

import MapsLayerLegendTable from "@/components/Maps/MapsLayerLegendTable";
import MapsVectorLayer from "@/components/Maps/MapsVectorLayer";

import {tileLayer} from "leaflet";

export default {
  name: "MapsLayersMewaBaseGroup",
  props: {
    map_id: {type: String},
  },
  components: {
    MapsLayerLegendTable,
    MapsVectorLayer,
  },
  data() {
    return {

      show_group_details: true,

      show_group_layers: true,

      mewa_layer_year: 2022,
      mewa_layer_years: [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
      mewa_layers_dict: {

        'Field Boundary': {
          url: 'https://storage.googleapis.com/halo-mewa-tiles/field-annual-data/YEAR/{z}/{x}/{y}.pbf',
          layer_type: 'vectorGrid',
          layer_id: 'FieldBoundary',
          has_legend: false,
          legend_ready: false,
          legend_dict: {
            min: 0,
            max: 1,
            palette: [
              "#ffffcc", "#c2e699", "#78c679", "#31a354", "#006837"
            ]
          },
          legend_pairs: [],
          z_index: 15,
          opacity: 1,
          layer_obj: null,
          show_layer: false,
          show_details: false,
        },

        'Field Use': {
          url: 'https://storage.googleapis.com/halo-mewa-tiles/field-use/YEAR/{z}/{x}/{y}',
          layer_type: 'tileLayer',
          layer_id: 'Field Use',
          has_legend: true,
          legend_ready: false,
          legend_dict: {
            min: 0,
            max: 1,
            palette: [
              "#ffffcc", "#c2e699", "#78c679", "#31a354", "#006837"
            ]
          },
          legend_pairs: [],
          z_index: 14,
          opacity: 1,
          layer_obj: null,
          show_layer: false,
          show_details: false,
        },

        'Yearly ET': {
          url: 'https://storage.googleapis.com/halo-mewa-tiles/field-et-annual/YEAR/{z}/{x}/{y}',
          layer_type: 'tileLayer',
          layer_id: 'Yearly ET',
          has_legend: true,
          legend_ready: false,
          legend_dict: {
            min: 0,
            max: 1500,
            palette: ["#86340c", "#c49e0d", "#f3fe34", "#aefeae", "#11f9fd", "#698afc", "#5813fc"]
          },
          legend_pairs: [],
          z_index: 13,
          opacity: 1,
          layer_obj: null,
          show_layer: false,
          show_details: false,
        },


        'Daily ET': {
          url: 'https://storage.googleapis.com/halo-mewa-tiles/ETd_mean/YEAR/{z}/{x}/{y}',
          layer_type: 'tileLayer',
          layer_id: 'Daily ET',
          has_legend: true,
          legend_ready: false,
          legend_dict: {
            min: 0,
            max: 6,
            palette: ["#86340c", "#c49e0d", "#f3fe34", "#aefeae", "#11f9fd", "#698afc", "#5813fc"]
          },
          legend_pairs: [],
          z_index: 12,
          opacity: 1,
          layer_obj: null,
          show_layer: false,
          show_details: false,
        },

        'NDVI': {
          url: 'https://storage.googleapis.com/halo-mewa-tiles/ndvi/YEAR/{z}/{x}/{y}',
          layer_type: 'tileLayer',
          layer_id: 'NDVI',
          has_legend: true,
          legend_ready: false,
          legend_dict: {
            min: 0,
            max: 1,
            palette: [
              '#CE7E45', '#CE7E45', '#DF923D', '#F1B555',
              '#FCD163', '#99B718', '#74A901', '#66A000',
              '#529400', '#3E8601', '#207401', '#056201',
              '#004C00', '#023B01', '#012E01', '#011D01',
              '#011301'
            ]
          },
          legend_pairs: [],
          z_index: 11,
          opacity: 1,
          layer_obj: null,
          show_layer: false,
          show_details: false,
        },

        'Optical': {
          url: 'https://storage.googleapis.com/halo-mewa-tiles/optical/YEAR/{z}/{x}/{y}',
          layer_type: 'tileLayer',
          layer_id: 'Optical',
          has_legend: false,
          legend_ready: false,
          legend_dict: null,
          legend_pairs: [],
          z_index: 10,
          opacity: 1,
          layer_obj: null,
          show_layer: true,
          show_details: false,
        },

      },

      mewa_vector_layer_id: null,
      mewa_vector_layer_z_index: null,


    }
  },
  methods:
      {
        async switch_show_group_details() {
          this.show_group_details = !this.show_group_details
        }
        ,

        set_layer_opacity(layer_name) {
          this.mewa_layers_dict[layer_name].layer_obj.setOpacity(this.mewa_layers_dict[layer_name].opacity)
        },

        async on_click_group_checkbox() {

          for (let layer_name in this.mewa_layers_dict) {
            this.remove_layer_from_map(layer_name)
          }

          if (this.show_group_layers) {
            for (let layer_name in this.mewa_layers_dict) {
              if (this.mewa_layers_dict[layer_name].show_layer) {
                this.add_layer_to_map(layer_name)
              }

            }
          }


        },


        async on_click_layer_checkbox(layer_name) {
          if (this.show_group_layers) {
            if (!this.mewa_layers_dict[layer_name].show_layer) {
              this.remove_layer_from_map(layer_name)
            } else {
              this.add_layer_to_map(layer_name)
            }
          }

        }
        ,
        remove_layer_from_map(layer_name) {
          if (this.mewa_layers_dict[layer_name].layer_obj !== null) {
            this.mewa_layers_dict[layer_name].layer_obj.removeFrom(this.$store.state.map)
          }
        }
        ,
        add_layer_to_map(layer_name) {
          if (this.show_group_layers) {
            if (this.mewa_layers_dict[layer_name].layer_obj !== null) {
              this.mewa_layers_dict[layer_name].layer_obj.addTo(this.$store.state.map)
            }
          }
        },
        async assignValuesToColors(colorData) {
          const {min, max, palette} = colorData;
          const colorCount = palette.length;
          const step = (max - min) / (colorCount - 1);

          return palette.map((color, index) => {
            const value = min + index * step;
            return {color, value};
          });
        },

        async set_mewa_layers() {

          this.$store.state.identify_flag = false
          // this.set_off_click_vector_tiles()

          for (let layer_name in this.mewa_layers_dict) {

            let layer_dict = this.mewa_layers_dict[layer_name]
            if (layer_dict.has_legend) {
              if (!layer_dict.legend_ready) {
                this.mewa_layers_dict[layer_name].legend_pairs = await this.assignValuesToColors(layer_dict.legend_dict)
                this.mewa_layers_dict[layer_name].legend_ready = true
              }
            }

            if (this.mewa_layers_dict[layer_name].layer_obj !== null) {
              this.$store.state.map.removeLayer(this.mewa_layers_dict[layer_name].layer_obj)
            }

            let layer_url = layer_dict.url.replace('YEAR', this.mewa_layer_year)

            if (layer_dict.layer_type === 'tileLayer') {
              let layer_options = {
                maxZoom: 20,
                maxNativeZoom: 13,
                zIndex: layer_dict.z_index,
              }
              this.mewa_layers_dict[layer_name].layer_obj = tileLayer(layer_url, layer_options)
            }
            if (layer_dict.layer_type === 'vectorGrid') {
              let layer_options = {
                maxZoom: 20,
                minZoom: 0,
                maxNativeZoom: 13,
                zIndex: layer_dict.z_index,

                rendererFactory: L.svg.tile,

                interactive: true,
                vectorTileLayerStyles: {
                  // Polygon: {
                  //   weight: 0.5,
                  //   color: '#FFFF00',
                  //   fillColor: '#000000',
                  //   fillOpacity: 0.1,
                  //   fill: true
                  // }
                },
              }
              layer_options.vectorTileLayerStyles[`${this.mewa_layer_year}ETm3`] = {
                fillOpacity: 0,
                fill: true, // default is false
                // note that although we don't want to fill them,
                // the interactivity is tricky if we don't fill it
                // because it would only work by clicking on the boundary
                // but we can put opacity=0 so we can
                // use the interactivity by clicking anywhere
                // inside the field.
                weight: 1,
                color: '#050505',
                opacity: 1,
              }

              this.mewa_layers_dict[layer_name].layer_obj = this.$store.state.L.vectorGrid.protobuf(layer_url, layer_options)

            }

            if (this.mewa_layers_dict[layer_name].show_layer) {
              setTimeout(() => {
                this.add_layer_to_map(layer_name)
              }, 1000)

            }
          }

          // setTimeout(() => {
          //   this.$store.state.L.vectorGrid.protobuf(
          //       "https://cibo-mvt.s3-ap-southeast-2.amazonaws.com/{z}/{x}/{y}.pbf",
          //       // "https://storage.googleapis.com/halo-mewa-tiles/field-annual-data/2015/{z}/{x}/{y}.pbf",
          //       {
          //         rendererFactory: L.svg.tile,
          //         interactive: true,
          //         attribution: '&copy; PSMA, Cibolabs',
          //         vectorTileLayerStyles: {
          //           cad: {
          //             weight: 0.5,
          //             color: '#FFFF00',
          //             fillColor: '#000000',
          //             fillOpacity: 0.1,
          //             fill: true
          //           }
          //         },
          //         maxNativeZoom: 14,
          //         minZoom: 14,
          //         // rendererFactory: L.svg.tile,
          //         // interactive: true,
          //         // // attribution: '&copy; PSMA, Cibolabs',
          //         // // vectorTileLayerStyles: {
          //         // //   cad: {
          //         // //     weight: 0.5,
          //         // //     color: '#FFFF00',
          //         // //     fillColor: '#000000',
          //         // //     fillOpacity: 0.1,
          //         // //     fill: true
          //         // //   }
          //         // // },
          //         // // maxNativeZoom: 14,
          //         // // minZoom: 14,
          //       }).addTo(this.$store.state.map)
          // }, 5000)

        },

        set_on_click_vector_tiles() {

          if (this.mewa_layers_dict['Field Boundary'].show_layer) {

            this.mewa_layers_dict['Field Boundary'].layer_obj.on('click', async (e) => {
              this.$store.state.feature_info_drawer.show = true;
              this.$store.state.feature_info_drawer.read_as_dict = true;

              let rec = e.layer.properties;
              console.log(rec)
              let rec2 = {}

              rec2['Yearly ET (mm)'] = rec.ET.toFixed(0)
              rec2['Yearly ET (m3)'] = rec.ETm3.toFixed(1)
              rec2['NDVI'] = rec.NDVI.toFixed(2)
              rec2['Area (ha)'] = rec.area.toFixed(1)
              rec2['Field Use (%)'] = (rec.fieldUse * 100).toFixed(0)
              rec2['Days Used'] = rec.useDays.toFixed(0)

              this.$store.state.feature_info_drawer.rec_dict = rec2;
              this.$store.state.feature_info_drawer.layer_name = `${this.mewa_layer_year} ET`

              // e.layer.setStyle(
              //     {
              //       fillOpacity: 0,
              //       fill: true, // default is false
              //       // note that although we don't want to fill them,
              //       // the interactivity is tricky if we don't fill it
              //       // because it would only work by clicking on the boundary
              //       // but we can put opacity=0 so we can
              //       // use the interactivity by clicking anywhere
              //       // inside the field.
              //       weight: 1,
              //       color: '#d01616',
              //       opacity: 1,
              //     }
              // );

            })
          }

        },
        set_off_click_vector_tiles() {
          if (this.mewa_layers_dict['Field Boundary'].show_layer) {
            console.log('OFF CLICK VECTOR TILE')
            this.mewa_layers_dict['Field Boundary'].layer_obj.off('click')
            this.$store.state.feature_info_drawer = {
              show: false,
              rec_dict: {},
              read_as_dict: false,
              row_uid: null,
              rec: {},
              schema: {},
              feature_style: {},
              feature_type: 'Polygon',
              layer_name: null,
              layer_id: null,
            }
          }
        },


      },
  watch: {
    '$store.state.identify_flag': function () {
      if (this.$store.state.identify_flag) {
        console.log('ID ON IN MEWA BASE GROUP')
        this.set_on_click_vector_tiles()
      } else {
        this.set_off_click_vector_tiles()
        console.log('ID OFF IN MEWA BASE GROUP')
      }
    },
  },
  mounted() {
    let interval_id = setInterval(() => {
      if (this.$store.state.map !== null) {
        this.set_mewa_layers()
        clearInterval(interval_id);
      }
    }, 100);
  }
  ,
}
</script>

<style scoped>

</style>
