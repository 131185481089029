<template>
  <v-card flat :loading="loading">
    <v-card-text>

      <v-row>
        <v-spacer></v-spacer>

        <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense nav>

            <v-list-item @click="add_tile_layer('leaflet_tms')">
              <v-list-item-icon>
                <v-icon color="green">mdi-new-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Add new TMS layer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="add_tile_layer('leaflet_wms')">
              <v-list-item-icon>
                <v-icon color="green">mdi-new-box</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Add new WMS layer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

<!--            <v-list-item @click="add_tile_layer('esri_dynamic_map_layer')">-->
<!--              <v-list-item-icon>-->
<!--                <v-icon color="green">mdi-new-box</v-icon>-->
<!--              </v-list-item-icon>-->
<!--              <v-list-item-content>-->
<!--                <v-list-item-title>Add new ESRI Dynamic Map layer</v-list-item-title>-->
<!--              </v-list-item-content>-->
<!--            </v-list-item>-->

            <!--            <v-list-item>-->
            <!--              <v-list-item-icon>-->
            <!--                <v-icon color="orange">mdi-application-import</v-icon>-->
            <!--              </v-list-item-icon>-->
            <!--              <v-list-item-content>-->
            <!--                <v-list-item-title>Select from tile layers library</v-list-item-title>-->
            <!--              </v-list-item-content>-->
            <!--            </v-list-item>-->

          </v-list>
        </v-menu>
      </v-row>

      <p></p>


      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
              style="max-width: 300px;"
              background-color="blue-grey lighten-5"
              v-model="search"
              append-icon="mdi-magnify"
              color="green"
              label="Search table rows"
              outlined
              dense
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-row>

      </v-col>

      <v-data-table
          :headers="tile_layers_table_headers"
          :items="tile_layers_table"
          :items-per-page="-1"
          :search="search"
          item-key="layer_id"
          class="elevation-1"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          :hide-default-footer="tile_layers_table.length < 10"

          show-select
          v-model="selected_items"

      >

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container style="margin-bottom: 10px; margin-top: 10px;">
              <p class="caption" v-if="tile_layer_maps[item.layer_id].length > 0">
                <b>Maps:</b><br>
                <span v-for="map in tile_layer_maps[item.layer_id]" :key="map.map_id">
                  {{ map.map_name }}<br>
                  </span>
              </p>

              <v-row justify="space-around">
                <v-spacer></v-spacer>

                <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense nav>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.layer_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item
                        @click="edit_tile_layer(item.layer_id, item.tile_layer_type)">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Edit Tile Layer</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="duplicate_tile_layer(item.layer_id, item.tile_layer_type)">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-content-save-all</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Duplicate Tile Layer (Save As)</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="delete_tile_layer(item.layer_id, false)">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Delete Tile Layer</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>

            </v-container>
          </td>

        </template>
      </v-data-table>

    </v-card-text>

    <DriveTileLayerTMSDialog
        v-if="DriveTileLayerTMSDialog_show"
        :show_dialog.sync="DriveTileLayerTMSDialog_show"
        :action="DriveTileLayerTMSDialog_action"
        :map_id="null"
        :layer_id="DriveTileLayerTMSDialog_layer_id"
        :refresh_map_function="null"
        :refresh_qfdrive_function="init_list_data"
    ></DriveTileLayerTMSDialog>

    <DriveTileLayerWMSDialog
        v-if="DriveTileLayerWMSDialog_show"
        :show_dialog.sync="DriveTileLayerWMSDialog_show"
        :action="DriveTileLayerWMSDialog_action"
        :map_id="null"
        :layer_id="DriveTileLayerWMSDialog_layer_id"
        :refresh_map_function="null"
        :refresh_qfdrive_function="init_list_data"
    ></DriveTileLayerWMSDialog>

    <DriveTileLayerDialog
        v-if="DriveTileLayerDialog_show"
        :show_dialog.sync="DriveTileLayerDialog_show"
        :action="DriveTileLayerDialog_action"
        :map_id="null"
        :layer_id="DriveTileLayerDialog_layer_id"
        :refresh_map_function="null"
        :refresh_qfdrive_function="init_list_data"
    ></DriveTileLayerDialog>

    <DriveConfirmDeleteDialog
        v-if="DriveConfirmDeleteDialog_show"
        :show_dialog.sync="DriveConfirmDeleteDialog_show"
        :callback_function="after_delete_tile_layer"
        :dialog_title="'Delete Tile Layer'"
        :item_type_name="'Tile Layer'"
        :item_type="'qfDocument'"
        :item_name="delete_item.name"
        :item_id="delete_item.id"
    ></DriveConfirmDeleteDialog>

  </v-card>
</template>

<script>

import DriveTileLayerDialog from "@/components/Drive/DriveTileLayerDialog";
import DriveTileLayerWMSDialog from "@/components/Drive/DriveTileLayerWMSDialog";
import DriveTileLayerTMSDialog from "@/components/Drive/DriveTileLayerTMSDialog";
import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";

import {
  r_user_data,
  d_qfDocument,
} from "@/services/app_api";

import {
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
} from "@/services/app_utils";

export default {
  name: "DriveTileLayersList",
  props: {
    callback_function: {type: Function, default: null},
    map_id: {type: String, default: null},
  },
  components: {
    DriveTileLayerDialog,
    DriveTileLayerTMSDialog,
    DriveTileLayerWMSDialog,
    DriveConfirmDeleteDialog
  },
  data() {
    return {
      loading: false,
      maps: {},
      tile_layers: {},
      tile_layers_table: [],
      search: null,
      tile_layers_table_headers: [
        {text: 'Name', value: 'layer_name'},
        {text: 'Type', value: 'tile_layer_type_title'},
        {text: 'No. Maps', value: 'num_maps'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      tile_layer_maps: {},

      selected_items: [],

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,
      },

      DriveTileLayerDialog_show: false,
      DriveTileLayerDialog_layer_id: null,
      DriveTileLayerDialog_action: 'Add',

      DriveTileLayerTMSDialog_show: false,
      DriveTileLayerTMSDialog_layer_id: null,
      DriveTileLayerTMSDialog_action: 'Add',

      DriveTileLayerWMSDialog_show: false,
      DriveTileLayerWMSDialog_layer_id: null,
      DriveTileLayerWMSDialog_action: 'Add',

      DriveTileLayerESRIDynamicLayerDialog_show: false,
      DriveTileLayerESRIDynamicLayerDialog_layer_id: null,
      DriveTileLayerESRIDynamicLayerDialog_action: 'Add',

    }
  },
  methods: {

    async add_tile_layer(tile_layer_type) {

      switch (tile_layer_type) {
        case 'leaflet_tms':
          this.DriveTileLayerTMSDialog_action = 'Add'
          this.DriveTileLayerTMSDialog_layer_id = null
          this.DriveTileLayerTMSDialog_show = true
          break;
        case 'leaflet_wms':
          this.DriveTileLayerWMSDialog_action = 'Add'
          this.DriveTileLayerWMSDialog_layer_id = null
          this.DriveTileLayerWMSDialog_show = true
          break;
        case 'esri_dynamic_map_layer':
          this.DriveTileLayerDialog_action = 'Add'
          this.DriveTileLayerDialog_layer_id = null
          this.DriveTileLayerDialog_show = true
          // this.DriveTileLayerESRIDynamicLayerDialog_action = 'Add'
          // this.DriveTileLayerESRIDynamicLayerDialog_layer_id = null
          // this.DriveTileLayerESRIDynamicLayerDialog_show = true
          break;
      }
    },

    async edit_tile_layer(layer_id, tile_layer_type) {
      console.log('in edit_tile_layer')
      console.log(tile_layer_type)
      switch (tile_layer_type) {
        case 'leaflet_tms':
          this.DriveTileLayerTMSDialog_action = 'Edit'
          this.DriveTileLayerTMSDialog_layer_id = layer_id
          this.DriveTileLayerTMSDialog_show = true
          break;
        case 'leaflet_wms':
          this.DriveTileLayerWMSDialog_action = 'Edit'
          this.DriveTileLayerWMSDialog_layer_id = layer_id
          this.DriveTileLayerWMSDialog_show = true
          break;
        case 'esri_dynamic_map_layer':
          this.DriveTileLayerDialog_action = 'Edit'
          this.DriveTileLayerDialog_layer_id = layer_id
          this.DriveTileLayerDialog_show = true

          // this.DriveTileLayerESRIDynamicLayerDialog_action = 'Edit'
          // this.DriveTileLayerESRIDynamicLayerDialog_layer_id = layer_id
          // this.DriveTileLayerESRIDynamicLayerDialog_show = true
          break;
      }
    },

    async duplicate_tile_layer(layer_id, tile_layer_type) {
      switch (tile_layer_type) {
        case 'leaflet_tms':
          this.DriveTileLayerTMSDialog_action = 'Duplicate'
          this.DriveTileLayerTMSDialog_layer_id = layer_id
          this.DriveTileLayerTMSDialog_show = true
          break;
        case 'leaflet_wms':
          this.DriveTileLayerWMSDialog_action = 'Duplicate'
          this.DriveTileLayerWMSDialog_layer_id = layer_id
          this.DriveTileLayerWMSDialog_show = true
          break;
        case 'esri_dynamic_map_layer':
          this.DriveTileLayerDialog_action = 'Duplicate'
          this.DriveTileLayerDialog_layer_id = layer_id
          this.DriveTileLayerDialog_show = true
          // this.DriveTileLayerESRIDynamicLayerDialog_action = 'Duplicate'
          // this.DriveTileLayerESRIDynamicLayerDialog_layer_id = layer_id
          // this.DriveTileLayerESRIDynamicLayerDialog_show = true
          break;
      }
    },

    async remove_from_selected(layer_id) {
      let selected_items = []
      for (let item of this.selected_items) {
        if (item.layer_id !== layer_id) {
          selected_items.push(item)
        }
      }
      this.selected_items = selected_items
    },

    async delete_selected() {
      // TO CHECK
      this.loading = true

      for (let item of this.selected_items) {
        await this.remove_from_selected(item.layer_id)
        await this.delete_tile_layer(item.layer_id, true)
      }
      await r_user_data(null)
      this.loading = false
      await this.init_list_data()
    },

    async delete_tile_layer(layer_id, is_multi = false) {
      console.log('in delte_tile_layer', layer_id)
      if (is_multi) {
        this.loading = true
        await d_qfDocument(layer_id)
        await this.init_list_data()
        if (this.callback_function !== null && this.map_id !== null) {
          await this.callback_function(this.map_id)
        }
        await this.after_delete_tile_layer(layer_id)
      } else {
        this.delete_item.name = this.tile_layers[layer_id].name
        this.delete_item.id = layer_id
        this.DriveConfirmDeleteDialog_show = true
      }
    },
    async after_delete_tile_layer(layer_id) {
      console.log('in after_delete_tile_layer', layer_id)
      this.delete_item.name = null
      this.delete_item.id = null

      this.loading = false
      await this.remove_from_selected(layer_id)
      await this.init_list_data()
    },


    async get_tile_layer_use_in_maps(layer_id) {
      let maps_list = []

      for (let map_id in this.maps) {
        let map_obj = this.maps[map_id]

        if (layer_id in map_obj.layers.dict) {
          maps_list.push({
            map_id: map_id,
            map_name: map_obj.name
          })
        }

      }
      return maps_list
    },
    async init_list_data() {
      this.tile_layers = {}
      let layers = await get_qfDocument_type_as_dict('layer')
      for (let layer_id in layers) {
        if (layers[layer_id].config.layer_type === 'tile') {
          this.tile_layers[layer_id] = layers[layer_id]
        }
      }

      this.maps = await get_qfCollection_type_as_dict('map')

      let tile_layers_table = []
      let tile_layer_maps = {}
      for (let layer_id in this.tile_layers) {
        let layer_obj = this.tile_layers[layer_id]

        tile_layer_maps[layer_id] = await this.get_tile_layer_use_in_maps(layer_id)

        let tile_layer_type_title = null
        switch (layer_obj.config.layer_format) {
          case 'leaflet_tms':
            tile_layer_type_title = 'TMS'
            break;
          case 'leaflet_wms':
            tile_layer_type_title = 'WMS'
            break;
          case 'esri_dynamic_map_layer':
            tile_layer_type_title = 'ESRI Dynamic Map Layer'
            break;
          default:
            break;
        }


        tile_layers_table.push({
          layer_id: layer_id,
          layer_name: layer_obj.name,
          tile_layer_type: layer_obj.config.layer_format,
          tile_layer_type_title: tile_layer_type_title,
          num_maps: tile_layer_maps[layer_id].length
        })
      }
      this.$set(this, 'tile_layers_table', tile_layers_table)
      this.$set(this, 'tile_layer_maps', tile_layer_maps)
      this.$forceUpdate()

    },

  },
  watch: {
    '$store.state.is_sync_done': async function () {
      if (this.$store.state.is_sync_done) {
        await this.init_list_data()
      }

    }
  },
  mounted() {
    this.init_list_data()
  },
}
</script>

<style scoped>

</style>
