<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon :color="'#d2691e'">mdi-database-search</v-icon>
          <span class="body-1">
            Query Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text v-if="!is_loading">

        <v-radio-group
            v-if="db_query_list.length > 0"
            v-model="query_selection_mode"
            :hide-details="true"
            @change="on_change_query_selection_mode"
        >
          <v-radio class="caption"
                   label="Create a new query"
                   color="green"
                   value="New"
          ></v-radio>
          <v-radio class="caption"
                   label="Select an existing query"
                   color="orange"
                   value="Select"
          ></v-radio>
        </v-radio-group>


        <v-col v-if="query_selection_mode === 'Select'" style="max-width: 400px;">
          <v-autocomplete
              outlined
              v-model="selected_query_id"
              :items="db_query_list"
              dense
              chips
              label="Select Query"
              item-text="name"
              item-value="id"
              @change="set_query"
              :hide-details="true"
          ></v-autocomplete>

          <v-col v-if="existing_queries_warning_msg !== null" class="caption red--text">
            {{ existing_queries_warning_msg }}
          </v-col>
        </v-col>

        <div v-if="query !== null">

          <v-radio-group
              v-model="the_query_action"
              :hide-details="true"
          >
            <template v-slot:label>
              <div>Query Action (what the query does?)</div>
            </template>
            <v-radio class="caption"
                     label="Select features"
                     color="green"
                     value="Select"
            ></v-radio>
            <v-radio class="caption"
                     label="Filter"
                     color="red"
                     value="Filter"
            ></v-radio>
          </v-radio-group>

          <v-divider style="margin-bottom: 10px; margin-top: 10px; background-color: darkorange;"></v-divider>


          <div v-for="(query_group, group_idx) in query.config.query_groups" :key="group_idx">
            <v-card style="background-color: rgba(201,226,234,0.5); margin-bottom: 10px;">
              <v-card-text>
                <table>
                  <tbody>
                  <tr v-for="(row_item, row_idx) in query_group.query_list" :key="row_idx">
                    <td class="body-1" style="vertical-align: bottom; padding-right: 30px; max-width: 100px;">
                      <div v-if="row_idx === 0">
                        Where
                      </div>
                      <div v-if="row_idx > 0">
                        <v-select
                            v-model="row_item.operator"
                            :items="['And', 'Or']"
                            :hide-details="true"
                        ></v-select>
                      </div>

                    </td>
                    <td>
                      <v-select
                          label="Column Name"
                          v-model="row_item.column_name"
                          :items="Object.keys(columns_dict)"
                          :hide-details="true"
                      ></v-select>
                    </td>
<!--                    <td v-if="row_item.column_name !== null">-->
<!--                      <v-select-->
<!--                          label="Column Type"-->
<!--                          readonly-->
<!--                          v-model="columns_dict[row_item.column_name].column_type"-->
<!--                          :items="column_types"-->
<!--                          :disabled="row_item.column_name === null"-->
<!--                          :hide-details="true"-->
<!--                      ></v-select>-->
<!--                    </td>-->
                    <td v-if="row_item.column_name !== null">
                      <v-select
                          label="Criteria"
                          v-model="row_item.criteria"
                          :disabled="row_item.column_name === null"
                          :items.sync="criteria_types[columns_dict[row_item.column_name].column_type]"
                          :hide-details="true"
                      ></v-select>
                    </td>
                    <td v-if="row_item.column_name !== null && !['Is empty', 'Is not empty'].includes(row_item.criteria)">
                      <v-combobox
                          v-if="columns_dict[row_item.column_name].column_type !== 'Date'"
                          style="max-width: 300px;"
                          label="Value"
                          v-model="row_item.value"
                          :disabled="row_item.criteria === null"
                          :items="columns_dict[row_item.column_name].column_values"
                          :type="columns_dict[row_item.column_name].column_type"
                          single-line
                          hide-details
                          @change="query_to_text(null)"
                      ></v-combobox>

                      <v-menu
                          v-if="columns_dict[row_item.column_name].column_type === 'Date'"
                          v-model="row_item.show_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          left
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="row_item.value"
                              label="Value"
                              v-bind="attrs"
                              v-on="on"
                              :hide-details="true"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="row_item.value"
                            scrollable
                            @change="query_to_text(null)"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td style="max-width: 30px; vertical-align: bottom;">

                      <v-menu
                          transition="slide-x-transition"
                          offset-y
                          left
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense nav>
                          <v-list-item @click="remove_row(group_idx, row_idx)">
                            <v-list-item-icon>
                              <v-icon :color="'#ff0000'">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Remove Row</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="duplicate_row(group_idx, row_idx)">
                            <v-list-item-icon>
                              <v-icon :color="'#6a5acd'">mdi-content-duplicate</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Duplicate Row</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="add_row_to_query(group_idx)" small color="amber lighten-1">
                  <v-icon small left>mdi-plus</v-icon>
                  Add Row
                </v-btn>
                <v-spacer></v-spacer>

                <v-menu
                    transition="slide-y-transition"
                    offset-y
                    left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon color="orange lighten-1">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense nav>
                    <v-list-item @click="remove_group(group_idx)">
                      <v-list-item-icon>
                        <v-icon :color="'#ff0000'">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Remove Group</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="duplicate_group(group_idx)">
                      <v-list-item-icon>
                        <v-icon :color="'#6a5acd'">mdi-content-duplicate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Duplicate Group</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-card-actions>
            </v-card>

            <div style="max-width: 100px; margin-bottom: 20px;"
                 v-if="query.config.query_groups.length > 1 && query.config.query_groups.length -1 !== group_idx">
              <v-select
                  v-model="query_group.operator"
                  :items="['And', 'Or']"
                  :hide-details="true"
              ></v-select>
            </div>
          </div>

          <v-btn @click="add_group_to_query()" small color="orange lighten-1">
            <v-icon small left>mdi-plus</v-icon>
            Add New Group
          </v-btn>

        </div>

        <v-col v-if="query_text !== null">
          <label>Query text:</label>
          <p class="caption" style="color: orangered;">{{ query_text }}</p>
        </v-col>

        <div v-if="query !== null">
          <v-divider style="margin-bottom: 10px; margin-top: 10px; background-color: darkorange;"></v-divider>
          <!--          <v-switch-->
          <!--              style="max-width: 300px;"-->
          <!--              v-model="save_query_in_map_layer"-->
          <!--              :hide-details="true"-->
          <!--              label="Save query in map layer"-->
          <!--          ></v-switch>-->
          <v-switch
              style="max-width: 300px;"
              v-if="query_selection_mode === 'New'"
              v-model="save_new_query"
              :hide-details="true"
              label="Save query in qfDrive"
          ></v-switch>
          <v-switch
              style="max-width: 300px;"
              v-if="query_selection_mode === 'Select' && existing_queries_warning_msg === null"
              v-model="update_selected_query"
              :hide-details="true"
              label="Save query changes in qfDrive"
          ></v-switch>

        </div>

        <div v-if="(save_new_query || update_selected_query) && query !== null">
          <v-col style="max-width: 400px;">
            <v-text-field
                outlined
                dense
                label="Query Name"
                v-model="query.name"
                :hide-details="true"
                required
            ></v-text-field>
          </v-col>
          <v-col style="max-width: 400px;">
            <v-text-field
                outlined
                dense
                label="Query Description"
                v-model="query.config.description"
                :hide-details="true"
            ></v-text-field>
          </v-col>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="purple"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {DateTime} from "luxon";
import {deepClone, get_random_int} from "@/services/generic";
import {isEqual} from "lodash";
import {c_qfDocument_query, get_qfDocument_type_as_dict} from "@/services/app_utils";
import {u_qfDocument} from "@/services/app_api";
import {
  get_json_arrays_unique_vals,
  json_arrays_to_json_dict,
  json_arrays_to_json_dict_with_col_name
} from "@/services/json_arrays_api";

export default {
  name: "MapsVectorLayerQueryDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    json_arrays: {type: Object},
    schema: {type: Object},
    query_action: {type: String, default: 'Select'},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      json_dict: {},

      the_query_action: 'Select',
      is_loading: true,

      query_selection_mode: 'New',
      selected_query_id: null,
      db_queries: {},
      db_query_list: [],

      query_group_template: {
        operator: 'And',
        query_list: [],
      },
      query_row_template: {
        operator: 'And',
        column_name: null,
        criteria: null,
        value: null,
        show_date_menu: false,
      },

      query: {
        id: null,
        name: null,
        type: 'query',
        config: {
          description: null,
          query_groups: [{
            operator: 'And',
            query_list: [{
              operator: 'And',
              column_name: null,
              criteria: null,
              value: null,
              show_date_menu: false,
            }]
          }]
        },
      },

      old_query: null,

      columns_dict: {},

      column_types: ['String', 'Number', 'Date'],
      criteria_types: {
        'String': ['=', '!=', 'Contains', 'Does not contain', 'Is empty', 'Is not empty'],
        'Number': ['=', '!=', '>', '>=', '<', '<='],
        'Date': ['=', '!=', '>', '>=', '<', '<=', 'Is empty', 'Is not empty'],
      },
      show_date_picker: false,

      existing_queries_warning_msg: null,
      save_new_query: false,
      update_selected_query: false,
      save_query_in_map_layer: false,

      query_text: null

    }
  },
  methods: {

    query_to_text(the_query) {

      if (the_query === null || the_query === undefined) {
        the_query = deepClone(this.query)
      }


      let txt = ``
      for (let idx_main in the_query.config.query_groups) {
        txt += `(`
        let query_operator_sub = the_query.config.query_groups[idx_main].operator
        let query_list_sub = the_query.config.query_groups[idx_main].query_list
        for (let flt_idx in query_list_sub) {
          let query_item = query_list_sub[flt_idx]
          if (query_item.column_name !== null) {
            txt += `${query_item.column_name} ${query_item.criteria.toLowerCase()} ${query_item.value}`
            if (flt_idx < query_list_sub.length - 1) {
              txt += ` ${query_list_sub[parseInt(flt_idx) + 1].operator.toUpperCase()} `
            }
          }

        }
        txt += `)`
        if (idx_main < the_query.config.query_groups.length - 1) {
          txt += ` ${query_operator_sub.toUpperCase()} `
        }

      }

      this.query_text = txt
    },

    async remove_row(group_idx, row_idx) {
      this.query.config.query_groups[group_idx].query_list.splice(row_idx, 1)
    },
    async remove_group(group_idx) {
      this.query.config.query_groups.splice(group_idx, 1)
    },
    async duplicate_row(group_idx, row_idx) {
      this.query.config.query_groups[group_idx].query_list.splice(row_idx + 1, 0,
          deepClone(this.query.config.query_groups[group_idx].query_list[row_idx]))
    },
    async duplicate_group(group_idx) {
      this.query.config.query_groups.splice(group_idx + 1, 0,
          deepClone(this.query.config.query_groups[group_idx]))
    },

    async add_group_to_query() {
      let group_dict = deepClone(this.query_group_template)
      group_dict.query_list.push(deepClone(this.query_row_template))
      this.query.config.query_groups.push(group_dict)
    },

    async add_row_to_query(group_idx) {

      let query_row_dict = deepClone(this.query_row_template)
      let prv_query_list = deepClone(this.query.config.query_groups[group_idx].query_list)

      if (prv_query_list.length > 0) {
        query_row_dict.operator = deepClone(prv_query_list[prv_query_list.length - 1].operator)
      } else {
        query_row_dict.operator = 'And'
      }


      this.query.config.query_groups[group_idx].query_list.push(query_row_dict)

    },

    async translate_signs(a, b, sign) {
      switch (sign) {
        case '=':
          return a === b;
        case '!=':
          return a !== b;
        case '>':
          return a > b;
        case '>=':
          return a >= b;
        case '<':
          return a < b;
        case '<=':
          return a <= b;
        case 'Is empty':
          return a === null || a === undefined;
        case 'Is not empty':
          return a !== null && a !== undefined;
        case 'Contains':
          if (a === null || a === undefined) a = ''
          if (b === null || b === undefined) b = ''
          return a.toLowerCase().includes(b.toLowerCase());
        case 'Does not contain':
          if (a === null || a === undefined) a = ''
          if (b === null || b === undefined) b = ''

          return !a.toLowerCase().includes(b.toLowerCase());
      }
    },

    async evaluate_bool_list(bool_list) {

      let bool_out = true
      for (let i = 0; i < bool_list.length; i++) {

        let bool_item = bool_list[i]

        if (i === 0) {
          bool_out = bool_item.the_bool
        } else {
          if (bool_item.operator === 'And') {
            bool_out = bool_out && bool_item.the_bool
          } else {
            bool_out = bool_out || bool_item.the_bool
          }
        }
      }

      return bool_out
    },


    async check_query_valid() {
      for (let idx_main in this.query.config.query_groups) {
        let query_list_sub = this.query.config.query_groups[idx_main].query_list
        for (let flt_idx in query_list_sub) {
          let flt = query_list_sub[flt_idx]

          if (flt.criteria === null) {
            return false
          } else {
            if (!['Is empty', 'Is not empty'].includes(flt.criteria)) {
              if (flt.value === null) {
                return false
              }
            }
          }
        }
      }


      return true

    },

    async apply_query() {

      let is_valid = await this.check_query_valid()
      let query_fid_list = []
      if (is_valid) {

        for (let row_uid in this.json_dict) {

          let rec = this.json_dict[row_uid]

          let group_tf_list = []

          for (let group_idx in this.query.config.query_groups) {
            let tf_list = []

            let query_list = this.query.config.query_groups[group_idx].query_list
            for (let flt_idx in query_list) {

              let flt = query_list[flt_idx]

              let operator = flt.operator
              let col_name = flt.column_name
              let criteria = flt.criteria
              let crit_val = flt.value

              let a = rec[col_name]
              let b = crit_val

              switch (flt.column_type) { // text, number, date
                case 'String':
                  a = String(a)
                  b = String(b)
                  break;
                case 'Number':
                  a = parseFloat(a)
                  b = parseFloat(b)
                  break;
                case 'Date':
                  a = new Date(a).setHours(0, 0, 0, 0)
                  b = new Date(b).setHours(0, 0, 0, 0)
                  break;
              }

              tf_list.push({
                operator: operator,
                the_bool: await this.translate_signs(a, b, criteria),
              })

            }

            group_tf_list.push({
              operator: this.query.config.query_groups[group_idx].operator,
              the_bool: await this.evaluate_bool_list(tf_list)
            })
          }


          if (await this.evaluate_bool_list(group_tf_list)) {
            query_fid_list.push(row_uid)
          }

        }

      }

      return query_fid_list
    },

    async submit() {
      if (this.query_selection_mode === 'New' && this.save_new_query) {
        let resp = await c_qfDocument_query(this.query.name, this.query.config)
        this.query.id = resp.doc_id
      }

      if (this.query_selection_mode === 'Select' && this.update_selected_query) {
        if (!isEqual(this.query, this.db_queries[this.selected_query_id])) {
          await u_qfDocument(this.query, false, false)
        }
      }

      let query_fid_list = await this.apply_query()
      this.callback_function(this.query.id, query_fid_list, this.the_query_action, this.save_query_in_map_layer)
      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },

    on_change_column_type(column_name, column_type) {
      setTimeout(() => {
        this.$set(this.columns_dict[column_name], 'column_type', column_type)
        this.$forceUpdate()
      }, 500)
    },

    on_change_query_selection_mode() {
      if (this.query_selection_mode === 'Select') {
        this.save_new_query = false
        this.old_query = deepClone(this.query)
        this.selected_query_id = null
        this.query = null
      } else {
        this.update_selected_query = false
        if (this.old_query !== null) {
          this.query = deepClone(this.old_query)
        }
      }

    },
    set_query() {
      this.query = deepClone(this.db_queries[this.selected_query_id])
      this.query_to_text()
    },

    async check_db_query_valid(db_query) {
      let columns_list = Object.keys(this.columns_dict)

      for (let idx_main in db_query.config.query_groups) {
        let query_list_sub = db_query.config.query_groups[idx_main].query_list
        for (let flt_idx in query_list_sub) {
          let flt = query_list_sub[flt_idx]

          if (!columns_list.includes(flt.column_name)) {
            return false
          }

        }
      }
      return true
    },


    async create_query_list() {
      this.existing_queries_warning_msg = null
      let num_db_queries = 0

      let db_query_list = []
      let db_queries = {}
      let queries = await get_qfDocument_type_as_dict('query')

      for (let query_id in queries) {
        let query = queries[query_id]

        num_db_queries += 1
        if (await this.check_db_query_valid(query)) {
          db_queries[query_id] = query
          db_query_list.push({
            id: query_id,
            name: query.name,
            description: query.config.description
          })
        }

      }

      this.db_queries = db_queries
      this.db_query_list = db_query_list

      if (db_query_list.length < 1 && num_db_queries > 0) {
        this.existing_queries_warning_msg = `You have ${num_db_queries} saved queries,
        but none of them is compatible this layer.
        This is due to differences in column names in this layer and in the saved queries.
        `
      }


    },

    async init() {
      this.is_loading = true
      if (this.query_action === null) {
        this.the_query_action = 'Select'
      } else {
        this.the_query_action = this.query_action
      }

      this.columns_dict = {}

      let exclude_cols = ['row_uid', 'geometry']
      let cols_list = Object.keys(this.json_arrays).filter(e => !exclude_cols.includes(e))
      let uniques_dict = await get_json_arrays_unique_vals(this.json_arrays)
      for (let col_uid of cols_list){
        let col_name = this.schema[col_uid].label
        this.columns_dict[col_name] = {
          column_type: this.schema[col_uid].type,
          column_values: uniques_dict[col_uid],
          col_uid: col_uid
        }

      }

      this.json_dict = await json_arrays_to_json_dict_with_col_name(this.json_arrays, this.schema)

      if (this.query.name === null) {
        this.query.name = `Query ${await get_random_int(1, 99)}`
      }

      await this.create_query_list()

      this.is_loading = false
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
