import {firestore_c_doc, firestore_r_doc} from "@/services/fb_firestore_api";
import store from "@/store";

import {idb_c, idb_get_item, idb_r} from "@/services/idb_api";

export async function c_user_profile(user_profile) {
    if (store.state.auth.isLoggedIn) {
        user_profile.version = 0

        if (store.state.is_online) {
            const uid = store.state.auth.user.uid
            await firestore_c_doc(`/users/${uid}/settings/user_profile`, user_profile)
        }

        store.state.user_settings.user_profile = user_profile
        await idb_c('user_profile', user_profile, true)
    }

}


export async function u_user_profile(user_profile) {
    if (store.state.auth.isLoggedIn) {
        user_profile.version += 1
        if (store.state.is_online) {
            const uid = store.state.auth.user.uid
            await firestore_c_doc(`/users/${uid}/settings/user_profile`, user_profile)
        }

        store.state.user_settings.user_profile = user_profile
        await idb_c('user_profile', user_profile, true)
    }
}


export async function r_user_profile() {

    if (store.state.auth.isLoggedIn && store.state.is_online) {
        const uid = store.state.auth.user.uid
        if (uid !== 'anonymous') {
            let user_profile = await firestore_r_doc(`/users/${uid}/settings/user_profile`)

            if (user_profile !== null) {
                store.state.user_settings.user_profile = user_profile
                await idb_c('user_profile', user_profile, true)
            }
        }

    }
}

export async function check_user_files() {
    let out_dict = {
        num: 0,
        size: 0,
        num_limit: store.state.cur_plan.limits.num_files,
        size_limit: store.state.cur_plan.limits.total_size,
    }

    if (store.state.auth.isLoggedIn) {
        // go over layers and calculate size and count of files and return as dict
        let layers = await idb_get_item('layers')
        for (let layer_item of Object.values(layers)) {
            if (layer_item.meta.is_uploaded) {
                out_dict.num += 1
                out_dict.size += layer_item.size
            }
        }
    }
    return out_dict
}
