<template>
  <table class="legend-table">
    <tr v-for="(pair, index) in colorValuePairs" :key="index">
      <td class="color-box" :style="{backgroundColor: pair.color, opacity: opacity}"></td>
      <td><span class="caption" style="margin-left: 5px;">{{ pair.value.toFixed(2) }}</span></td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "MapsLayerLegendTable",
  props: {
    colorValuePairs: Array,
    opacity: Number,
  },
  data() {
    return {}
  },
};
</script>

<style scoped>
.legend-table {
  border-collapse: collapse;
}

.color-box {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
}
</style>
