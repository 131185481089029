<template>
  <v-snackbar
      v-if="$store.state.snackbar.show"
      v-model="$store.state.snackbar.show"
      :color="$store.state.snackbar.color"
      top
      right
      :timeout="$store.state.snackbar.timeout"
      dark
      :multi-line="$store.state.snackbar.multi_line"
  >
    {{ $store.state.snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="$store.state.snackbar.show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "AppSnackbar"
}
</script>

<style scoped>

</style>