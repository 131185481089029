<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card v-if="show_dialog">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="green" left>mdi-database-marker</v-icon>
          <span class="body-1">
            qfDrive
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>


      <v-tabs
          v-model="tab"
          centered
          icons-and-text
      >
        <v-tabs-slider color="purple"></v-tabs-slider>

        <v-tab href="#vector_layers">
          Vector Layers
          <v-icon>mdi-vector-polygon</v-icon>
        </v-tab>

        <!--        <v-tab href="#raster_layers">-->
        <!--          Raster Layers-->
        <!--          <v-icon>mdi-checkerboard</v-icon>-->
        <!--        </v-tab>-->

        <v-tab href="#tile_layers">
          Tile Layers
          <v-icon>mdi-pyramid</v-icon>
        </v-tab>

        <v-tab href="#maps">
          Maps
          <v-icon>mdi-map</v-icon>
        </v-tab>

        <v-tab href="#tables">
          Tables
          <v-icon>mdi-table</v-icon>
        </v-tab>

        <v-tab href="#styles">
          Styles
          <v-icon>mdi-brush</v-icon>
        </v-tab>

        <v-tab href="#queries">
          Queries
          <v-icon>mdi-database-search</v-icon>
        </v-tab>

        <!--        <v-tab href="#bookmarks">-->
        <!--          Bookmarks-->
        <!--          <v-icon>mdi-book-marker</v-icon>-->
        <!--        </v-tab>-->
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="vector_layers">
          <v-divider></v-divider>
          <DriveVectorLayersList v-if="tab === 'vector_layers'"
                                 :callback_function="this_callback_function"
                                 :map_id="map_id"
          ></DriveVectorLayersList>
        </v-tab-item>
        <v-tab-item value="raster_layers">
          <v-divider></v-divider>
          <DriveRasterLayersList v-if="tab === 'raster_layers'"
                                 :callback_function="this_callback_function"
                                 :map_id="map_id"
          ></DriveRasterLayersList>
        </v-tab-item>
        <v-tab-item value="tile_layers">
          <v-divider></v-divider>
          <DriveTileLayersList v-if="tab === 'tile_layers'"
          ></DriveTileLayersList>
        </v-tab-item>
        <v-tab-item value="maps">
          <v-divider></v-divider>
          <DriveMapsList v-if="tab === 'maps'"
                         :callback_function="this_callback_function"
          ></DriveMapsList>
        </v-tab-item>
        <v-tab-item value="tables">
          <v-divider></v-divider>
          <DriveTablesList v-if="tab === 'tables'"
          ></DriveTablesList>
        </v-tab-item>
        <v-tab-item value="styles">
          <v-divider></v-divider>
          <DriveStylesList v-if="tab === 'styles'"
          ></DriveStylesList>
        </v-tab-item>
        <v-tab-item value="queries">
          <v-divider></v-divider>
          <DriveQueriesList v-if="tab === 'queries'"
          ></DriveQueriesList>
        </v-tab-item>
        <v-tab-item value="bookmarks">
          <v-divider></v-divider>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

  </v-dialog>

</template>

<script>

import DriveVectorLayersList from "@/components/Drive/DriveVectorLayersList";
import DriveRasterLayersList from "@/components/Drive/DriveRasterLayersList";
import DriveMapsList from "@/components/Drive/DriveMapsList";
import DriveQueriesList from "@/components/Drive/DriveQueriesList";
import DriveStylesList from "@/components/Drive/DriveStylesList";
import DriveTablesList from "@/components/Drive/DriveTablesList";
import DriveTileLayersList from "@/components/Drive/DriveTileLayersList";


export default {
  name: "DriveDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function, default: null},
    map_id: {type: String, default: null},
  },
  components: {
    DriveRasterLayersList, DriveVectorLayersList, DriveMapsList,
    DriveQueriesList, DriveStylesList, DriveTablesList,
    DriveTileLayersList,
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      tab: 'vector_layers',

    }
  },
  methods: {

    this_callback_function() {
      if (this.callback_function !== null) {

        this.callback_function(this.map_id)
      }

    },
    async submit() {
      this.cancel()
    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      this.tab = 'layers'

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>