<template>

  <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
      style="z-index: 10000;"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
          @click="reset_form"
          small
          text
          v-bind="attrs"
          v-on="on"
      >
        <v-icon small left>mdi-account-arrow-right</v-icon>
        Sign Up
      </v-btn>
    </template>
    <v-card
        :loading="loading"
    >
      <v-toolbar color="indigo" dense dark>

        <v-toolbar-title>
          Create Account
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
          style="margin-top: 20px;"
          contain
          max-height="60"
          :src="require('@/assets/questfeed/logo_h.png')"
      ></v-img>

      <v-card-text>

        <v-form
            ref="form"
            v-model="is_valid"
            lazy-validation
        >

          <v-col cols="12">
            <v-text-field
                prepend-inner-icon="mdi-email"
                label="Email"
                v-model="email"
                :rules="emailRules"
                :disabled="loading"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
                prepend-inner-icon="mdi-lock"
                :append-outer-icon="password_visible? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                v-model="password"
                :rules="passwordRules"
                :type="password_visible? 'text' : 'password'"
                @click:append-outer="password_visible = !password_visible"
                :disabled="loading"
                hide-details="auto"
                required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
                v-model="accept_terms"
                :disabled="loading"
                hide-details="auto"
                :rules="[v => !!v || 'You must accept QuestFeed.com terms and policies to continue!']"
                required
            >
              <template v-slot:label>
                <div class="body-2">
                  I accept
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1zwcWf1cYhTJl8e7kSxJ_J1sKoelBzp1C/view?usp=sharing"
                          @click.stop
                          v-on="on"
                      >
                        Terms of Services
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                  and
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1kkgaoSzwibzJonVhammVzLC-62aCEuUc/view?usp=sharing"
                          @click.stop
                          v-on="on"
                      >
                        Privacy Policy
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                </div>
              </template>


            </v-checkbox>
          </v-col>
        </v-form>

        <p style="color: red;">
          {{ server_error_message }}
        </p>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            small
            text
            :disabled="loading"
            @click="cancel"
        >
          Close
        </v-btn>
        <v-btn
            small
            class="primary"
            :disabled="loading"
            @click="signup"
        >
          Sign Up
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import {auth} from "@/firebase/firebase";
import {createUserWithEmailAndPassword} from "firebase/auth";

export default {
  name: "SignUp",
  data: () => ({
    dialog: false,
    loading: false,
    is_valid: true,
    email: null,
    password: null,
    password_visible: false,
    accept_terms: false,
    server_error_message: null,

    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required'
    ],
  }),
  methods: {

    reset_form(){
      this.loading = false
      this.is_valid = true
      this.email = null
      this.password = null
      this.password_visible = false
      // this.accept_terms = false
      this.forgot_password_is_active = false
    },

    async signup() {

      this.server_error_message = null
      await this.$refs.form.validate()
      if (this.is_valid) {
        this.loading = true
        createUserWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {

              let user = userCredential.user;
              console.log(user)
              this.close()
            })
            .catch((error) => {
              let errorCode = error.code;
              let errorMessage = error.message;
              console.log(errorCode)
              console.log(errorMessage)
              this.server_error_message = errorMessage
              this.loading = false
            });
      }

    },
    async close() {
      this.dialog = false
      this.loading = false
      this.server_error_message = null

      this.is_valid = true
    },
    async cancel() {
      await this.$refs.form.reset()
      await this.$refs.form.resetValidation()

      await this.close()
    },

  },
}
</script>

<style scoped>

</style>
