<template>
  <div>
    <DwsDrawer :is_left="true" :show_prop.sync="showLeftDrawer" v-show="showLeftDrawer"></DwsDrawer>
    <DwsDrawer :is_left="false" :show_prop.sync="showRightDrawer" v-show="showRightDrawer"></DwsDrawer>

    <v-system-bar height="0.01" flat dense>
      <v-btn
          height="32"
          width="32"
          v-if="!showLeftDrawer"
          fab small color="white" tile
          style="z-index: 10; margin-top: 50px;"
          @click="showDrawer('Left')">
        <v-icon color="black">mdi-chevron-double-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
<!--      <v-btn-->
<!--          height="32"-->
<!--          width="32"-->
<!--          v-if="!showRightDrawer"-->
<!--          fab small color="white" tile-->
<!--          style="z-index: 10; margin-top: 100px;"-->
<!--          @click="showDrawer('Right')">-->
<!--        <v-icon color="black">mdi-chevron-double-left</v-icon>-->
<!--      </v-btn>-->
    </v-system-bar>

    <DwsMainMap></DwsMainMap>

  </div>
</template>

<script>

import DwsMainMap from "@/components/DWS/DwsMainMap";
import DwsDrawer from "@/components/DWS/DwsDrawer";

export default {
  name: "DwsView",
  components: {
    DwsDrawer, DwsMainMap
  },
  props: {},
  data() {
    return {
      showLeftDrawer: true,
      showRightDrawer: false,

    }
  },
  methods: {
    showDrawer(side) {
      this.$set(this, `show${side}Drawer`, true)
      console.log(this.showLeftDrawer)
    },
  },
  watch: {},
  mounted() {
  },
}
</script>

<style scoped>

</style>
