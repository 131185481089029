<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <span class="body-1">
            {{ dialog_title }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <br>
        <v-col>
          <p class="body-2">
            Are you sure you want to delete this {{ item_type_name.toLowerCase() }}?<br>
            <b style="color: red;">{{ item_name }}</b> {{ item_type_name.toLowerCase() }} and all its related
            information will be deleted from qfGIS system.<br>
            There is no recovery option available at the moment.<br>
          </p>
        </v-col>


      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="red"
               @click="submit_delete"
               :disabled="is_loading"
        >Delete
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import {d_qfDocument, d_qfCollection} from "@/services/app_api";

export default {
  name: "DriveConfirmDeleteDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    dialog_title: {type: String},
    item_type: {type: String},
    item_type_name: {type: String},
    item_name: {type: String},
    item_id: {type: String}
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },
      is_loading: false,
    }
  },
  methods: {

    async submit_delete() {
      this.is_loading = true
      switch (this.item_type) {
        case 'qfDocument':
          await d_qfDocument(this.item_id)
          break;
        case 'qfCollection':
          await d_qfCollection(this.item_id)
          break;
        default:
          break;
      }
      if (this.callback_function !== null) {
        this.callback_function(this.item_id)
      }
      this.is_loading = false

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
