<template>
  <div>
    <!--    <v-toolbar-->
    <!--        color="green"-->
    <!--        dark-->
    <!--    >-->
    <!--      <v-app-bar-nav-icon></v-app-bar-nav-icon>-->

    <!--      <v-toolbar-title>Current Forecast</v-toolbar-title>-->

    <!--      <v-spacer></v-spacer>-->

    <!--      <v-btn icon>-->
    <!--        <v-icon>mdi-dots-vertical</v-icon>-->
    <!--      </v-btn>-->
    <!--    </v-toolbar>-->

    <v-toolbar
        color="white"
        flat
    >

      <v-toolbar-title class="green--text text--darken-4" style="text-transform: uppercase">
        Current Forecast
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
          icon
          light
      >
        <v-icon color="grey darken-2">
          mdi-magnify
        </v-icon>
      </v-btn>
    </v-toolbar>

<!--    Max Level Forecast-->
    <v-card
        max-width="1200"
        class="mx-auto"
    >
      <v-app-bar
          dark dense
          color="blue"
      >
        <v-toolbar-title class="body-1">Max. level during forecast</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-row dense>
          <v-col v-for="(indicator_item, key) in level_forecast_max" :key="key">
            <DwsTankCard
                :i_name="indicator_item.name"
                :i_value="indicator_item.value"
                :i_unit_display="indicator_item.unit_display"
                :i_range_list="indicator_item.range_list"
                :i_min="indicator_item.min"
                :i_max="indicator_item.max"
                :table_name="indicator_item.table_name"
            ></DwsTankCard>

          </v-col>
        </v-row>
      </v-container>

    </v-card>

    <v-card
        max-width="1200"
        class="mx-auto"
    >
      <v-app-bar
          dark dense
          color="teal"
      >
        <v-toolbar-title class="body-1">Max. discharge during forecast</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-row dense>
          <v-col v-for="(indicator_item, key) in discharge_forecast_max" :key="key">
            <DwsTankCard
                :i_name="indicator_item.name"
                :i_value="indicator_item.value"
                :i_unit_display="indicator_item.unit_display"
                :i_range_list="indicator_item.range_list"
                :i_min="indicator_item.min"
                :i_max="indicator_item.max"
                :table_name="indicator_item.table_name"
            ></DwsTankCard>


          </v-col>
        </v-row>
      </v-container>

    </v-card>

    <v-card
        max-width="1200"
        class="mx-auto"
    >
      <v-app-bar
          dark dense
          color="gray"
      >
        <v-toolbar-title class="body-1">Total Storage (m<sup>3</sup>)</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-app-bar>

      <v-container>
        <v-row dense>
          <v-col v-for="(indicator_item, key) in total_storage" :key="key">
            <DwsTankCard
                :i_name="indicator_item.name"
                :i_value="indicator_item.value"
                :i_unit_display="indicator_item.unit_display"
                :i_range_list="indicator_item.range_list"
                :i_min="indicator_item.min"
                :i_max="indicator_item.max"
                :table_name="indicator_item.table_name"
            ></DwsTankCard>


          </v-col>
        </v-row>
      </v-container>

    </v-card>


  </div>
</template>

<script>

// import DwsIndicatorCard from "@/components/DWS/DwsIndicatorCard";
import DwsTankCard from "@/components/DWS/DwsTankCard";

export default {
  name: "DwsDashboard",
  props: {},
  components: {
    // DwsIndicatorCard,
    DwsTankCard
  },
  data() {
    return {

      level_forecast_max: [
        {
          name: 'Location 1',
          value: -9.6,
          unit_display: 'm',
          range_list: [-5, -3],
          min: -10,
          max: -0.1,
          table_name: 'DWS_TimeSeries1',
        },
        {
          name: 'Location 2',
          value: -3.2,
          unit_display: 'm',
          range_list: [-5, -3],
          min: -10,
          max: -0.1,
          table_name: 'DWS_TimeSeries2',
        }
      ],
      discharge_forecast_max: [
        {
          name: 'Location 1',
          value: 1.25,
          unit_display: '<span>m<sup>3</sup>/s</span>',
          range_list: [3, 7],
          min: 0,
          max: 10,
          table_name: 'DWS_TimeSeries1',
        },
        {
          name: 'Location 2',
          value: 7.2,
          unit_display: '<span>m<sup>3</sup>/s</span>',
          range_list: [3, 7],
          min: 0,
          max: 10,
          table_name: 'DWS_TimeSeries2',
        }
      ],
      total_storage: [
        {
          name: 'Now',
          value: 302600,
          unit_display: '<span>m<sup>3</sup></span>',
          range_list: [300000, 700000],
          min: 100000,
          max: 1000000,
          table_name: 'DWS_TimeSeries1',
        },
        {
          name: 'Forecast Max',
          value: 596500,
          unit_display: '<span>m<sup>3</sup></span>',
          range_list: [300000, 700000],
          min: 100000,
          max: 1000000,
          table_name: 'DWS_TimeSeries2',
        }
      ]

    }
  },
  methods: {},
  watch: {},
  mounted() {
  },
}
</script>

<style scoped>

</style>
