<template>

  <div style="margin-bottom: 5px;">
    <v-row>
      <v-spacer></v-spacer>
<!--      <v-btn icon v-if="history.length > 0"-->
<!--             title="Undo feature edit"-->
<!--             @click="on_select_tool('undo')"-->
<!--             :disabled="cur_history_idx === 0 || edit_draw_features_mode">-->
<!--        <v-icon>mdi-undo</v-icon>-->
<!--      </v-btn>-->
<!--      <v-btn icon v-if="history.length > 0"-->
<!--             title="Redo feature edit"-->
<!--             @click="on_select_tool('redo')"-->
<!--             :disabled="cur_history_idx === history.length-1 || cur_history_idx === null || edit_draw_features_mode">-->
<!--        <v-icon>mdi-redo</v-icon>-->
<!--      </v-btn>-->
      <v-btn
          :disabled="edit_draw_features_mode"
          @click="on_select_tool('save_layer')" icon :color="'#D500F9'" title="Save Changes">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn icon
             :disabled="edit_draw_features_mode"
             @click="on_select_tool('toggle_select_mode')" title="Toggle select mode">
        <v-icon color="black" v-if="select_mode">mdi-cursor-default</v-icon>
        <v-icon color="blue-grey lighten-3" v-if="!select_mode">mdi-cursor-default-outline</v-icon>
      </v-btn>
      <v-btn v-if="!MapsVectorLayerBottomSheet_show" @click="on_select_tool('show_bottom_sheet')" icon :color="'#8a2be2'"
             title="Show Table">
        <v-icon :color="'#a52a2a'">mdi-table-eye</v-icon>
      </v-btn>
      <v-btn v-if="MapsVectorLayerBottomSheet_show" @click="on_select_tool('hide_bottom_sheet')" icon :color="'#8a2be2'"
             title="Hide Table">
        <v-icon :color="'#a52a2a'">mdi-table-eye-off</v-icon>
      </v-btn>
      <v-btn @click="on_select_tool('zoom')" icon :color="'#8a2be2'" title="Zoom to Layer">
        <v-icon>mdi-magnify-plus</v-icon>
      </v-btn>

      <v-menu
          transition="slide-y-transition"
          :close-on-content-click="true"
          right
          v-if="layer_tools_show_mode=== 'all'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense nav>

          <v-list-item
              @click="on_select_tool('upload_layer')"
              v-if="!is_blob_uploaded"
              :disabled="!($store.state.auth.isLoggedIn && $store.state.is_online) || edit_draw_features_mode">
            <v-list-item-icon>
              <v-icon :color="'#008080'">mdi-cloud-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Upload to Cloud</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="!is_blob_uploaded && $store.state.auth.isLoggedIn"></v-divider>

          <v-list-item
              @click="on_select_tool('switch_marker_type')"
              v-if="feature_type=== 'Point'">
            <v-list-item-icon>
              <v-icon :color="'#008080'" v-if="feature_style.marker_type==='marker'">mdi-circle</v-icon>
              <v-icon :color="'#008080'" v-if="feature_style.marker_type==='circleMarker'">mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="feature_style.marker_type==='marker'">Convert to Circle Marker</span>
                <span v-if="feature_style.marker_type==='circleMarker'">Convert to Marker</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              @click="on_select_tool('switch_markerCluster')"
              v-if="feature_type=== 'Point'">
            <v-list-item-icon>
              <v-icon :color="'#4d8532'" v-if="feature_style.cluster.is_cluster">mdi-scatter-plot-outline</v-icon>
              <v-icon :color="'#4d8532'" v-if="!feature_style.cluster.is_cluster">mdi-scatter-plot</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="feature_style.cluster.is_cluster">Disable Marker Cluster</span>
                <span v-if="!feature_style.cluster.is_cluster">Enable Marker Cluster</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              @click="on_select_tool('switch_heatmap')"
              v-if="feature_type=== 'Point'">
            <v-list-item-icon>
              <v-icon :color="'#d91010'" v-if="!feature_style.heatmap.has_heatmap">mdi-fire</v-icon>
              <v-icon :color="'#d91010'" v-if="feature_style.heatmap.has_heatmap">mdi-fire-extinguisher</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span v-if="!feature_style.heatmap.has_heatmap">Show HeatMap</span>
                <span v-if="feature_style.heatmap.has_heatmap">Hide HeatMap</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="feature_type=== 'Point'"></v-divider>

          <v-list-item @click="on_select_tool('save_layer')">
            <v-list-item-icon>
              <v-icon :color="'#D500F9'">mdi-content-save</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Save</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('duplicate_layer')">
            <v-list-item-icon>
              <v-icon color="purple">mdi-content-save-all</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Save As</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('export_layer')">
            <v-list-item-icon>
              <v-icon color="purple">mdi-file-export</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Export</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="on_select_tool('query_layer')">
            <v-list-item-icon>
              <v-icon :color="'#d2691e'">mdi-database-search</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Query</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('merge_with_tables')">
            <v-list-item-icon>
              <v-icon color="pink">mdi-merge</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Merge with Table(s)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('link_with_tables')">
            <v-list-item-icon>
              <v-icon color="pink">mdi-vector-link</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Link with (timeseries) Table(s)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="on_select_tool('edit_layer_info')">
            <v-list-item-icon>
              <v-icon :color="'#00ff00'">mdi-rename-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Layer Info</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="on_select_tool('start_draw_mode')">
            <v-list-item-icon>
              <v-icon :color="'#9400d3'">mdi-draw</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Draw Features</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('start_edit_features_mode')">
            <v-list-item-icon>
              <v-icon :color="'#ff00ff'">mdi-layers-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Features</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('start_edit_table_mode')">
            <v-list-item-icon>
              <v-icon color="green">mdi-table-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Table Data</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('change_style')">
            <v-list-item-icon>
              <v-icon :color="'#ff7f50'">mdi-brush</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Style</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="on_select_tool('edit_layer_schema')">
            <v-list-item-icon>
              <v-icon :color="'#6a5acd'">mdi-table-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Table Schema</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="on_select_tool('delete')">
            <v-list-item-icon>
              <v-icon :color="'#ff0000'">mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Delete Layer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MapsVectorLayerTools",
  props: {
    layer_tools_show_mode: {type: String},
    on_select_tool: {type: Function},
    is_blob_uploaded: {type: Boolean},
    MapsVectorLayerBottomSheet_show: {type: Boolean},
    select_mode: {type: Boolean},
    edit_draw_features_mode: {type: Boolean},
    history: {type: Array},
    cur_history_idx: {type: Number},

    feature_type: {type: String},
    feature_style: {type: Object},

    selected_uid_list: {type: Array}

  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>
