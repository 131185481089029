<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <span class="body-1">
            Storage Allocation Warning
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-container>
          <p class="body-1">
            You cannot upload layers to the cloud as you are over your storage allocation.<br>
            <!--            Please subscribe to a higher plan or purchase more storage.<br>-->
          </p>

          <h3 class="blue--text">Current Plan:</h3>
          <p class="body-2">
            Plan name: {{ $store.state.cur_plan.name }}<br>
            Expiry date:
            <span v-if="$store.state.cur_plan.expiry_date === null">
              N/A
            </span>
            <span v-else>
              {{ $store.state.cur_plan.expiry_date }}
            </span>
            <br><br>
            <strong>Plan Allocations:</strong><br>
            <!--            Maps: {{ $store.state.cur_plan.limits.maps }}<br>-->
            File uploads: {{ $store.state.cur_plan.limits.num_files }}<br>
            Total storage: {{ the_prettyBytes($store.state.cur_plan.limits.total_size) }}<br>
          </p>

          <h3 class="red--text">Account Usage Summary</h3>
          <p class="body-2">
            File uploads: {{ summary.num_files }}<br>
            Total storage: {{ the_prettyBytes(summary.total_storage) }}
          </p>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Close</v-btn>
<!--        <v-btn small dark color="purple"-->
<!--               @click="submit"-->
<!--        >Submit-->
<!--        </v-btn>-->

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import prettyBytes from "pretty-bytes";
import {get_qfDocument_type_as_dict} from "@/services/app_utils";

export default {
  name: "DriveAllocationWarning",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 500
      },
      summary: {
        num_file: 0,
        total_storage: 0
      }

    }
  },
  methods: {
    async calc_summary() {

      this.layers = await get_qfDocument_type_as_dict('layer')

      for (let layer_item of Object.values(this.layers)) {
        if (layer_item.is_uploaded) {
          this.summary.num_files += 1
          this.summary.total_storage += layer_item.docBlob.size
        }
      }
    },
    the_prettyBytes(num_byte) {
      return prettyBytes(num_byte)
    },
    // async submit() {
    //
    //   this.cancel()
    //
    // },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      await this.calc_summary()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>

