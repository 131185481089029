<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card v-if="is_init" :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left :color="'#9400d3'">mdi-pyramid</v-icon>
          <span class="body-1">
            {{ action }} WMS Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-col style="max-width: 400px;">
          <v-text-field
              outlined
              dense
              v-model="layer_obj.name"
              label="Name"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              outlined
              dense
              v-model="layer_obj.config.tile_layer_options.leaflet_wms.url"
              label="URL (required)"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-btn small color="orange" class="white--text"
                 :disabled="is_evaluating"
                 @click="evaluate_url">Evaluate URL
          </v-btn>
        </v-col>

        <v-progress-linear v-if="is_evaluating" indeterminate color="blue"></v-progress-linear>


        <div v-if="is_evaluated">

          <v-btn @click="DriveTileLayerWMSselectLayerDialog_show = true">Select Layer</v-btn>

          <v-col>
            <v-autocomplete
                @change="on_change_layer"
                dense
                outlined
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.layers"
                :items="wms_gc_json.Capability.Layer.Layer"
                item-text="Title"
                item-value="Name"
                label="Layer"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete
                @change="on_change_style"
                v-if="layer_obj.config.tile_layer_options.leaflet_wms.layers !== null"
                dense
                outlined
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.styles"
                :items="styles_list"
                item-text="Title"
                item-value="Name"
                label="Style"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>
          <p class="caption red--text" v-if="errors_list.length > 0">
            <b>Errors</b>
            <span v-for="error in errors_list" :key="error">
              {{ error }}
            </span>
            <br>
          </p>

          <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
            <v-switch
                v-model="show_settings"
                :hide-details="true"
                dense
                label="Show settings"
            ></v-switch>
          </v-col>
        </div>

        <div v-if="show_settings && is_evaluated">
          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.api_key_key"
                label="Security key name"
                hint="Field name for security key, such as 'api_key' or 'access_token'."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.api_key_value"
                label="Security key value"
                :hide-details="true"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.attribution"
                label="attribution"
                hint="String to be shown in the attribution control, e.g. '© OpenStreetMap contributors'. It describes the layer data and is often a legal obligation towards copyright holders and tile providers."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-switch
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.transparent"
                dense
                label="transparent"
                hint="If true, the WMS service will return images with transparency."
                persistent-hint
            ></v-switch>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.minZoom"
                label="minZoom"
                type="number"
                hint="The minimum zoom level down to which this layer will be displayed (inclusive)."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.maxZoom"
                label="maxZoom"
                type="number"
                hint="The maximum zoom level down to which this layer will be displayed (inclusive)."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.tileSize"
                type="number"
                label="tileSize"
                hint="Width and height of tiles in the grid. Use a number if width and height are equal, or L.point(width, height) otherwise."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.opacity"
                type="number"
                label="opacity"
                hint="Opacity of the tiles."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.maxNativeZoom"
                type="number"
                label="maxNativeZoom"
                hint="Maximum zoom number the tile source has available. If it is specified, the tiles on all zoom levels higher than maxNativeZoom will be loaded from maxNativeZoom level and auto-scaled."
                persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
                outlined
                dense
                v-model="layer_obj.config.tile_layer_options.leaflet_wms.minNativeZoom"
                type="number"
                label="minNativeZoom"
                hint="Minimum zoom number the tile source has available. If it is specified, the tiles on all zoom levels lower than minNativeZoom will be loaded from minNativeZoom level and auto-scaled."
                persistent-hint
            ></v-text-field>
          </v-col>
        </div>

        <v-divider></v-divider>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="layer_obj.config.show_description_and_comment"
              :hide-details="true"
              dense
              label="Add description and comments"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              rows="1"
              clearable
              dense
              auto-grow
              v-model="layer_obj.config.description"
              label="Description"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              dense
              rows="1"
              clearable
              auto-grow
              v-model="layer_obj.config.comments"
              label="Comments"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="layer_obj.config.stage.show"
              :hide-details="true"
              dense
              label="Show stage"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="layer_obj.config.stage.show">
          <v-select
              outlined
              dense
              v-model="layer_obj.config.stage.stage"
              :items="stages"
              item-text="stage"
              item-value="stage"
              label="Stage"
              :hide-details="true"
          >
            <template v-slot:selection="{item}">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <DriveTileLayerWMSselectLayerDialog
            v-if="DriveTileLayerWMSselectLayerDialog_show"
            :show_dialog.sync="DriveTileLayerWMSselectLayerDialog_show"
            :layers_table="layers_table"
        ></DriveTileLayerWMSselectLayerDialog>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               @click="submit"
               :disabled="layer_obj.name.length < 1 || is_loading || !is_evaluated"
        >Submit
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import WMSCapabilities from 'ol/format/WMSCapabilities';
import {deepClone} from "@/services/generic";
import {templates} from "@/store";
import {c_qfDocument, u_qfDocument} from "@/services/app_api";
import {get_qfDocument_type_as_dict} from "@/services/app_utils";
import axios from "axios";
import DriveTileLayerWMSselectLayerDialog from "@/components/Drive/DriveTileLayerWMSselectLayerDialog";

export default {
  name: "DriveTileLayerWMSDialog",
  props: {
    show_dialog: {type: Boolean},
    action: {type: String}, // Add, Edit, Duplicate
    map_id: {type: String, default: null},
    layer_id: {type: String, default: null},

    refresh_map_function: {type: Function, default: null},
    refresh_qfdrive_function: {type: Function, default: null},
  },
  components: {DriveTileLayerWMSselectLayerDialog},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      is_init: false,
      is_loading: false,
      is_evaluated: false,
      is_evaluating: false,
      errors_list: [],

      layer_obj: {},
      show_settings: false,

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],

      wms_gc_json: {
        Capability: {
          Layer: {
            Layer: []
          }
        }
      },
      styles_list: [],

      DriveTileLayerWMSselectLayerDialog_show: false,
      layers_table: [],
    }
  },
  methods: {

    async on_change_layer() {
      // get and store array of styles
      let wms_layer_name = this.layer_obj.config.tile_layer_options.leaflet_wms.layers
      for (let layer_item of this.wms_gc_json.Capability.Layer.Layer) {
        console.log(layer_item)
        if (wms_layer_name === layer_item.Name) {
          this.styles_list = layer_item.Style
          break;
        }
      }

    },
    async on_change_style() {
      let legend_url = null
      let wms_style_name = this.layer_obj.config.tile_layer_options.leaflet_wms.styles
      for (let style_item of this.styles_list) {
        if (wms_style_name === style_item.Name) {
          console.log('🎨', style_item)
          if ('LegendURL' in style_item) {
            console.log(style_item.LegendURL)
            legend_url = style_item.LegendURL
          }
          break;
        }
      }
      this.layer_obj.config.tile_layer_options.leaflet_wms.LegendURL = legend_url
    },

    layer_to_row(layer, parent_name, parent_title) {

      let row = {
        parent_name: parent_name,
        parent_title: parent_title,
        layer_name: layer.Name,
        layer_title: layer.Title,
        bbox: null,
        crs: null,
        dimension: null,
        styles: null,
      }
      if ('BoundingBox' in layer) {
        if (Array.isArray(layer.BoundingBox)) {
          row.bbox = layer.BoundingBox[0]
        }
      }
      if ('CRS' in layer) {
        if (Array.isArray(layer.CRS)) {
          row.crs = layer.CRS[0]
        }
      }
      if ('Dimension' in layer) {
        if (Array.isArray(layer.Dimension)) {
          row.dimension = layer.Dimension[0]
        }
      }
      if ('Style' in layer) {
        if (Array.isArray(layer.Style)) {
          row.styles = layer.Style
        }
      }
      return row
    },
    decode_layer(the_layer, tb, parent_name, parent_title) {
      if ('Layer' in the_layer) {
        for (let item of the_layer.Layer) {
          this.decode_layer(item, tb, the_layer.Name, the_layer.Title)
        }
      } else {
        tb.push(this.layer_to_row(the_layer, parent_name, parent_title))
      }
      return tb
    },

    async wms_gc_to_table(wms_gc_json) {

      let tb = []
      if ('Capability' in wms_gc_json) {
        if ('Layer' in wms_gc_json.Capability) {

          tb = this.decode_layer(wms_gc_json.Capability.Layer, tb, wms_gc_json.Capability.Name, wms_gc_json.Capability.Title)

          // if ('Layer' in wms_gc_json.Capability.Layer) {
          //   let wms_layers = wms_gc_json.Capability.Layer.Layer
          //   for (let layer_item of wms_layers) {
          //     console.log(layer_item)
          //     if ('Layer' in layer_item) {
          //       if (layer_item.Layer[0].length === 1) {
          //         tb.push(await this.layer_to_row(layer_item.Layer[0], layer_item.Name))
          //       } else {
          //         for (let layer_item2 of layer_item.Layer) {
          //           let category_name = `${layer_item.Layer[0].Name} | ${layer_item2.Layer[0].Name}`
          //           tb.push(await this.layer_to_row(layer_item2.Layer[0], category_name))
          //         }
          //       }
          //     } else {
          //       tb.push(await this.layer_to_row(layer_item, null))
          //     }
          //   }
          // }
        }
      }
      this.layers_table = tb;
      console.log('💻', tb)
    },

    async evaluate_url() {
      this.is_evaluated = false
      this.is_evaluating = true
      let url = this.layer_obj.config.tile_layer_options.leaflet_wms.url
      const parser = new WMSCapabilities();
      url = url.split('?')[0]
      this.layer_obj.config.tile_layer_options.leaflet_wms.url = url
      url = `${url}?request=GetCapabilities&service=WMS`
      console.log('🍎', url)
      let resp = await axios.get(url)

      if (resp.status === 200) {
        let gc_xml = resp.data
        this.wms_gc_json = parser.read(gc_xml)

        console.log('🍎', this.wms_gc_json)
        await this.wms_gc_to_table(this.wms_gc_json)

        let wms_crs_list = []
        for (let item of this.wms_gc_json.Capability.Layer.CRS) {
          wms_crs_list.push(item.toUpperCase())
        }
        console.log(wms_crs_list)

        this.layer_obj.config.tile_layer_options.leaflet_wms.version = this.wms_gc_json.version

        if (wms_crs_list.includes('EPSG:4326')) {
          this.layer_obj.config.tile_layer_options.leaflet_wms.crs = 'EPSG4326'
        } else if (wms_crs_list.includes('EPSG:3857')) {
          this.layer_obj.config.tile_layer_options.leaflet_wms.crs = 'EPSG3857'
        } else if (wms_crs_list.includes('EPSG:3395')) {
          this.layer_obj.config.tile_layer_options.leaflet_wms.crs = 'EPSG3395'
        } else {
          this.errors_list.push("This layer's CRS is not supported.")
        }

        this.is_evaluated = true
      }

      this.is_evaluating = false

    },

    async submit() {
      this.is_loading = true
      console.log('in submit')
      console.log(this.layer_obj)
      switch (this.action) {
        case 'Add':
          await c_qfDocument(
              this.layer_obj,
              null,
              false)
          break;
        case 'Edit':
          await u_qfDocument(this.layer_obj, null, null)
          break;
        case 'Duplicate':
          this.layer_obj.id = null
          await c_qfDocument(
              this.layer_obj,
              null,
              false)
          break;
      }

      if (this.refresh_map_function !== null) {
        this.refresh_map_function(this.map_id)
      }

      if (this.refresh_qfdrive_function !== null) {
        this.refresh_qfdrive_function()
      }

      this.is_loading = false

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      let layers
      switch (this.action) {
        case 'Add':
          this.layer_obj = deepClone(templates.qfDocument)
          this.layer_obj.type = 'layer'
          this.layer_obj.name = 'New WMS Layer'

          this.layer_obj.config = deepClone(templates.qfDocument__config__templates.layer)
          this.layer_obj.config.layer_type = 'tile'

          this.layer_obj.config.layer_format = 'leaflet_wms'
          // to remove
          // this.layer_obj.config.tile_layer_options.leaflet_wms.url = 'https://gsky.nci.org.au/ows/dea?service=WMS&request=GetCapabilities'
          // this.layer_obj.config.tile_layer_options.leaflet_wms.url = 'https://gibs.earthdata.nasa.gov/wms/epsg4326/best/wms.cgi'

          break;
        case 'Edit':
          layers = await get_qfDocument_type_as_dict('layer')
          this.layer_obj = layers[this.layer_id]
          await this.evaluate_url()
          await this.on_change_layer()
          await this.on_change_style()
          break;
        case 'Duplicate':
          layers = await get_qfDocument_type_as_dict('layer')
          this.layer_obj = layers[this.layer_id]
          this.layer_obj.name += ' - COPY'
          await this.evaluate_url()
          await this.on_change_layer()
          await this.on_change_style()

          break;

      }

      this.is_init = true


    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>