<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon color="orange">mdi-application-import</v-icon>
          <span class="body-1">
            Import Tables & Time Series File(s)
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-file-input
              outlined
              multiple
              accept=".csv"
              show-size
              :clearable="true"
              label="Select files"
              v-model="input_files"
              @change="on_file_select"
              :hide-details="true"
          ></v-file-input>
          <p class="caption">Select CSV files.</p>
        </v-col>


        <v-col v-if="input_tables_list.length > 0">

          <v-card v-for="(input_table, idx) in input_tables_list" :key="idx" style="margin-bottom: 10px;" elevation="3">
            <v-system-bar color="blue" class="white--text caption">{{ input_table.file_info.file_name }}</v-system-bar>
            <v-col style="max-width: 400px;">
              <v-text-field
                  v-model="input_table.name"
                  outlined
                  dense
                  label="Name"
                  :hide-details="true"
              ></v-text-field>
            </v-col>


            <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
              <v-switch
                  v-model="input_table.stage.show"
                  :hide-details="true"
                  dense
                  label="Show stage"
              ></v-switch>
            </v-col>

            <v-col style="max-width: 400px;" v-if="input_table.stage.show">
              <v-select
                  outlined
                  dense
                  v-model="input_table.stage.stage"
                  :items="stages"
                  item-text="stage"
                  item-value="stage"
                  label="Stage"
                  :hide-details="true"
              >
                <template v-slot:selection="{item}">
                  <v-list-item dense>
                    <v-list-item-icon>
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.stage }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ active, item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" dense>
                    <v-list-item-icon>
                      <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.stage }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
              <v-switch
                  v-model="input_table.show_description_and_comment"
                  :hide-details="true"
                  dense
                  label="Show description and comments"
              ></v-switch>
            </v-col>

            <v-col style="max-width: 400px;" v-if="input_table.show_description_and_comment">
              <v-textarea
                  outlined
                  rows="1"
                  clearable
                  dense
                  auto-grow
                  v-model="input_table.description"
                  label="Description"
                  :hide-details="true"
              ></v-textarea>
            </v-col>

            <v-col style="max-width: 400px;" v-if="input_table.show_description_and_comment">
              <v-textarea
                  outlined
                  dense
                  rows="1"
                  clearable
                  auto-grow
                  v-model="input_table.comments"
                  label="Comments"
                  :hide-details="true"
              ></v-textarea>
            </v-col>


<!--            <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">-->
<!--              <v-switch-->
<!--                  :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"-->
<!--                  v-model="input_table.upload_blob"-->
<!--                  :hide-details="true"-->
<!--                  dense-->
<!--                  label="Upload to cloud"-->
<!--              ></v-switch>-->
<!--            </v-col>-->

            <DriveTableViewDialog
                v-if="DriveTableViewDialog_show"
                :show_dialog.sync="DriveTableViewDialog_show"
                :json_arrays="active_table_json_arrays"
                :schema="active_table_schema"
                :the_title="active_table_name"
                :doc_obj="null"
            ></DriveTableViewDialog>

            <DriveSchemaDialog
                v-if="DriveSchemaDialog_show"
                :show_dialog.sync="DriveSchemaDialog_show"
                :update_schema_callback="update_schema_callback"
                :schema="input_table.schema"
                :json_arrays="input_table.json_arrays"
                :idx="idx"
            ></DriveSchemaDialog>

            <v-card-actions>

              <v-btn small @click="on_show_table(idx)">
                <v-icon color="green" left>mdi-table</v-icon>
                View Table
              </v-btn>
              <v-btn small @click="DriveSchemaDialog_show=true">
                <v-icon color="purple" left>mdi-table-cog</v-icon>
                Edit Schema
              </v-btn>

              <v-btn @click="delete_file_item(idx)" small>
                <v-icon color="red" left>mdi-delete</v-icon>
                Delete
              </v-btn>

            </v-card-actions>

          </v-card>

        </v-col>


        <v-progress-linear v-if="loading" indeterminate color="blue"></v-progress-linear>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="green" class="white--text"
               :disabled="loading || input_tables_list.length < 1"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";
import DriveSchemaDialog from "@/components/Drive/DriveSchemaDialog";
import {deepClone, readFileAsync} from "@/services/generic";
import {templates} from "@/store";
import {c_qfDocument} from "@/services/app_api";
import * as csv from "csvtojson";
import {v4 as uuidv4} from "uuid";
import {DateTime} from "luxon";

export default {
  name: "DriveTableImportDialog",
  components: {DriveTableViewDialog, DriveSchemaDialog},
  props: {
    show_dialog: {type: Boolean},
    map_id: {type: String, default: null},
    callback_function: {type: Function},
    input_file: {type: File},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      import_file_type: '',

      input_files: [],
      input_tables_list: [],

      DriveTableViewDialog_show: false,
      DriveSchemaDialog_show: false,

      active_table_json_arrays: {},
      active_table_schema: {},
      active_table_name: null,

      loading: false,

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],

    }
  },
  methods: {

    async on_show_table(idx) {
      this.active_table_json_arrays = this.input_tables_list[idx].json_arrays
      this.active_table_schema = this.input_tables_list[idx].schema
      this.active_table_name = this.input_tables_list[idx].name
      this.DriveTableViewDialog_show = true
    },

    async reset_data() {
      this.input_tables_list = []
    },

    async on_file_select() {
      this.loading = true
      await this.reset_data()
      for (let input_file of this.input_files) {
        console.log(input_file)
        if (input_file.type.includes('csv')) {
          await this.process_csv_file(input_file)
        }
      }

      this.loading = false

    },

    async csv_json_to_json_arrays(csv_json, uid_dict) {
      let json_arrays = {row_uid: []}

      for (let key_label in uid_dict) {
        let key_uid = uid_dict[key_label]
        json_arrays[key_uid] = []
      }

      for (let json_item of csv_json) {
        for (let key_label in json_item) {
          let key_uid = uid_dict[key_label]
          json_arrays[key_uid].push(json_item[key_label])
        }
        json_arrays.row_uid.push(uuidv4().replaceAll('-', ''))
      }

      return json_arrays

    },
    async set_schema(json_arrays, uid_dict) {
      let json_arrays_unique = deepClone(json_arrays)
      for (let uid in json_arrays_unique) {
        json_arrays_unique[uid] = [...new Set(json_arrays_unique[uid])].sort()
      }

      let schema = {
        row_uid: {label: 'row_uid', type: 'String', is_system: true, config: {precision: null, timezone: null,}},
      }

      for (let key_label in uid_dict) {
        let key_uid = uid_dict[key_label]

        let unique_arr = json_arrays_unique[key_uid]
        let col_type = 'Number'
        for (let val_item of unique_arr) {
          if (isNaN(val_item)) {
            col_type = 'String'
            break;
          }
        }

        if (col_type === 'String') {

          for (let val_item of unique_arr) {
            if (val_item.includes('http')) {
              col_type = 'URL'
              break;
            }
            if (isNaN(val_item) && DateTime.fromJSDate(new Date(val_item)).isValid) {
              col_type = 'Date'
              break;
            }
          }
        }

        schema[key_uid] = {
          label: key_label,
          type: col_type,
          is_system: false, // to show to the user or not. for feature uid
          config: {precision: null, timezone: null,}
        }
      }

      return schema
    },
    async update_schema_callback(new_schema, json_arrays, table_idx) {
      let table_item = this.input_tables_list[table_idx]

      table_item.schema = new_schema
      table_item.json_arrays = json_arrays

      this.$set(this.input_tables_list, table_idx, table_item)

    },
    async process_csv_file(input_file) {

      let csv_string = await readFileAsync(input_file, 'text')
      let csv_json = await csv({output: 'json'}).fromString(csv_string)

      let uid_dict = {}
      for (let key_label in csv_json[0]) {
        uid_dict[key_label] = uuidv4().replaceAll('-', '')
      }

      let json_arrays = await this.csv_json_to_json_arrays(csv_json, uid_dict)
      let schema = await this.set_schema(json_arrays, uid_dict)

      this.input_tables_list.push({
        name: input_file.name,
        description: null,
        comments: null,
        stage: {
          show: false,
          stage: 'Development', // Development, Testing, Production
        },
        show_description_and_comment: false,

        upload_blob: true,

        file_info: {
          file_name: input_file.name,
          file_size: input_file.size,
          file_type: input_file.type,
          file_lastModified: input_file.lastModified,
        },

        schema: schema,
        json_arrays: json_arrays,

      })

    },

    async create_tables() {

      for (let table_item of this.input_tables_list) {

        let table_obj = deepClone(templates.qfDocument)
        table_obj.name = table_item.name
        table_obj.type = 'table'

        table_obj.docBlob.has_blob = true

        table_obj.config = deepClone(templates.qfDocument__config__templates.table)
        table_obj.config.show_description_and_comment = table_item.show_description_and_comment
        table_obj.config.description = table_item.description
        table_obj.config.comments = table_item.comments
        table_obj.config.stage = table_item.stage

        table_obj.config.is_imported = true
        table_obj.config.import_info = deepClone(templates.qfDocument__config__templates.table.import_info)
        table_obj.config.import_info.file_name = table_item.file_info.file_name
        table_obj.config.import_info.file_size = table_item.file_info.file_size
        table_obj.config.import_info.file_type = table_item.file_info.file_type
        table_obj.config.import_info.file_lastModified = table_item.file_info.file_lastModified

        table_obj.config.schema = table_item.schema

        await c_qfDocument(table_obj, table_item.json_arrays, table_item.upload_blob)

      }

    },
    // OTHER
    async delete_file_item(idx) {

      this.$delete(this.input_files, idx)
      this.$delete(this.input_tables_list, idx)

    },

    async submit() {
      this.loading = true

      await this.create_tables()

      if (this.callback_function !== null) {
        this.callback_function(this.map_id)
      }
      this.cancel()


      this.loading = false

    },
    cancel() {
      this.reset_data()
      this.$emit('update:show_dialog', false)

    },
    async init() {
      await this.reset_data()
      if (this.input_file !== undefined){
        this.input_files.push(this.input_file)
        await this.on_file_select()
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
