<template>
  <v-card id="the_table" style="margin-bottom: 10px; margin-top: 10px; width: 100%;">
    <v-card-text>
      <v-row style="margin-top: 10px; margin-bottom: 10px; margin-left: 10px;">
        <v-btn small text @click="on_resize" color="orange">
          <v-icon left>mdi-arrow-split-vertical</v-icon>
          Resize Table
        </v-btn>
        <v-btn small text @click="onBtnExport" color="green">
          <v-icon left>mdi-file-delimited</v-icon>
          Export CSV
        </v-btn>
      </v-row>

      <ag-grid-vue
          :style="{width: table_width + 'px', height: table_height + 'px', padding: 0, margin: 0}"
          class="ag-theme-alpine"
          :columnDefs="table_headers"
          :rowData="the_table_data"
          @grid-ready="onGridReady"
          :animateRows="true"
          :rowDragManaged="true"
      ></ag-grid-vue>
    </v-card-text>

  </v-card>
</template>

<script>

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue"

export default {
  name: "TablesSimpleTable",
  props: {
    header_pairs_list: {type: Array},
    table_data: {type: Array},

    json_arrays: {type: Object, default: null},
    schema: {type: Object, default: null},

    editable: {type: Boolean, default: false},
    resizable: {type: Boolean, default: true},
    sortable: {type: Boolean, default: true},
    filter: {type: Boolean, default: true},
    row_drag: {type: Boolean, default: true},

    export_file_name: {type: String, default: 'data.csv'}

  },
  components: {AgGridVue},
  data() {
    return {

      table_width: 800,
      table_height: 400,

      the_table_data: [],
      table_headers: [],

      gridApi: null,
      columnApi: null,
      gridColumnApi: null,
    }
  },
  methods: {
    onBtnExport() {
      this.gridApi.exportDataAsCsv({fileName: this.export_file_name});
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    on_resize() {
      this.table_width = document.getElementById('the_table').clientWidth - 30;
      this.table_height = document.getElementById('the_table').clientHeight;
      console.log('📏', this.table_width)
      console.log('📏', this.table_height)
      this.resize_cols()
    },
    resize_cols() {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    },
    async json_arrays_to_json_list(json_arrays) {
      let col_uids = Object.keys(json_arrays)
      let table_data = []
      for (let idx in json_arrays.row_uid) {
        let the_dict = {}
        for (let col_uid of col_uids) {
          the_dict[col_uid] = json_arrays[col_uid][idx]
        }
        table_data.push(the_dict)
      }
      return table_data
    },

    async get_header_pairs_list(schema) {
      let header_pairs_list = []
      for (let col_uid in schema) {
        if (!schema[col_uid].is_system) {
          header_pairs_list.push({
            field: col_uid,
            headerName: schema[col_uid].label,
          })
        }
      }
      return header_pairs_list
    },
    async set_table_headers(header_pairs_list) {
      this.table_headers = []
      for (let idx in header_pairs_list) {
        let pair_item = header_pairs_list[idx]
        let header_item = {
          field: pair_item.field,
          headerName: pair_item.headerName,
          editable: this.editable,
          resizable: this.resizable,
          sortable: this.sortable,
          filter: this.filter,
        }
        if (idx < 1 && this.row_drag) {
          header_item.rowDrag = true
        }
        this.table_headers.push(header_item)
      }
    },
    async init() {
      if (this.json_arrays !== null) {
        this.the_table_data = await this.json_arrays_to_json_list(this.json_arrays)
        let header_pairs_list = await this.get_header_pairs_list(this.schema)
        await this.set_table_headers(header_pairs_list)
      } else {
        this.the_table_data = this.table_data
        await this.set_table_headers(this.header_pairs_list)
      }
      setTimeout(()=>{
        this.on_resize()
      }, 2000)
    },
  },
  watch: {
    table_data() {
      this.init()
    },
    header_pairs_list() {
      this.init()
    },
    json_arrays() {
      this.init()
    },
    schema() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
