<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="loading" v-if="show_dialog">

      <v-toolbar dense color="white">
        <v-toolbar-title>
          <v-icon left color="blue">mdi-map</v-icon>
          <span class="body-1">
            {{ action }}Map
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-text-field
              label="Map Name"
              v-model="map_obj.name"
              :disabled="loading"
              outlined
              dense
              hide-details="auto"
              required
          ></v-text-field>
        </v-col>


        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="map_obj.config.stage.show"
              :hide-details="true"
              dense
              label="Show stage"
          ></v-switch>
        </v-col>


        <v-col style="max-width: 400px;" v-if="map_obj.config.stage.show">
          <v-select
              outlined
              dense
              v-model="map_obj.config.stage.stage"
              :items="stages"
              item-text="stage"
              item-value="stage"
              label="Stage"
              :hide-details="true"
          >
            <template v-slot:selection="{item}">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">
          <v-switch
              v-model="map_obj.config.show_description_and_comment"
              :hide-details="true"
              dense
              label="Show description and comments"
          ></v-switch>
        </v-col>

        <v-col style="max-width: 400px;" v-if="map_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              dense
              rows="1"
              clearable
              auto-grow
              v-model="map_obj.config.description"
              label="Description"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="max-width: 400px;" v-if="map_obj.config.show_description_and_comment">
          <v-textarea
              outlined
              dense
              rows="1"
              clearable
              auto-grow
              v-model="map_obj.config.comments"
              label="Comments"
              :hide-details="true"
          ></v-textarea>
        </v-col>
        <br>
        <v-divider></v-divider>

        <v-col class="text-subtitle-1 text-center text-uppercase">Select map layers</v-col>

        <v-tabs
            v-model="tab"
            centered
            icons-and-text
        >
          <v-tabs-slider color="purple"></v-tabs-slider>

          <v-tab href="#vector_layers">
            Vector Layers
            <v-icon>mdi-vector-polygon</v-icon>
          </v-tab>

          <!--          <v-tab href="#raster_layers">-->
          <!--            Raster Layers-->
          <!--            <v-icon>mdi-checkerboard</v-icon>-->
          <!--          </v-tab>-->

          <v-tab href="#tile_layers">
            Tile Layers
            <v-icon>mdi-pyramid</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="vector_layers">
            <v-divider></v-divider>
            <v-col>
              <v-row>
                <v-spacer></v-spacer>
                <v-text-field
                    v-if="vector_layers_table.length > 10"
                    style="max-width: 300px;"
                    background-color="blue-grey lighten-5"
                    v-model="search_vectors"
                    append-icon="mdi-magnify"
                    color="green"
                    label="Search for vector layers"
                    outlined
                    dense
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-row>

            </v-col>
            <v-data-table
                :headers="vector_layers_table_headers"
                :items="vector_layers_table"
                :items-per-page="-1"
                :search="search_vectors"
                item-key="layer_id"
                class="elevation-1"
                fixed-header
                :single-select="false"
                show-select
                v-model="selected_vector_items"
                @item-selected="on_item_selected_vector"
                @toggle-select-all="on_select_all_items_vector"
                :hide-default-footer="vector_layers_table.length < 10"
            >
              <template v-slot:item.is_uploaded="{ item }">
                <v-btn icon>
                  <v-icon v-if="item.is_uploaded" color="green">
                    mdi-cloud
                  </v-icon>
                  <v-icon v-else color="red">
                    mdi-harddisk
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.is_selected="{ item }">
                <v-btn icon>
                  <v-icon v-if="item.is_selected" color="brown">
                    mdi-check-bold
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>

          </v-tab-item>
          <v-tab-item value="raster_layers">
            <v-divider></v-divider>
            <v-col>
              <v-row>
                <v-spacer></v-spacer>
                <v-text-field
                    v-if="raster_layers_table.length > 10"
                    style="max-width: 300px;"
                    background-color="blue-grey lighten-5"
                    v-model="search_rasters"
                    append-icon="mdi-magnify"
                    color="green"
                    label="Search for raster layers"
                    outlined
                    dense
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-row>

            </v-col>
            <v-data-table
                :headers="raster_layers_table_headers"
                :items="raster_layers_table"
                :items-per-page="-1"
                :search="search_rasters"
                item-key="layer_id"
                class="elevation-1"
                fixed-header
                :single-select="false"
                show-select
                v-model="selected_raster_items"
                @item-selected="on_item_selected_raster"
                @toggle-select-all="on_select_all_items_raster"
                :hide-default-footer="raster_layers_table.length < 10"
            >
              <template v-slot:item.is_uploaded="{ item }">
                <v-btn icon>
                  <v-icon v-if="item.is_uploaded" color="green">
                    mdi-cloud
                  </v-icon>
                  <v-icon v-else color="red">
                    mdi-harddisk
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.is_selected="{ item }">
                <v-btn icon>
                  <v-icon v-if="item.is_selected" color="brown">
                    mdi-check-bold
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>

          </v-tab-item>
          <v-tab-item value="tile_layers">
            <v-divider></v-divider>
            <v-col>
              <v-row>
                <v-spacer></v-spacer>
                <v-text-field
                    v-if="tile_layers_table.length > 10"
                    style="max-width: 300px;"
                    background-color="blue-grey lighten-5"
                    v-model="search_tiles"
                    append-icon="mdi-magnify"
                    color="green"
                    label="Search for tile layers"
                    outlined
                    dense
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-row>

            </v-col>
            <v-data-table
                :headers="tile_layers_table_headers"
                :items="tile_layers_table"
                :items-per-page="-1"
                :search="search_tiles"
                item-key="layer_id"
                class="elevation-1"
                fixed-header
                :single-select="false"
                show-select
                v-model="selected_tile_items"
                @item-selected="on_item_selected_tile"
                @toggle-select-all="on_select_all_items_tile"
                :hide-default-footer="tile_layers_table.length < 10"
            >
              <template v-slot:item.is_selected="{ item }">
                <v-btn icon>
                  <v-icon v-if="item.is_selected" color="brown">
                    mdi-check-bold
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>

          </v-tab-item>
        </v-tabs-items>


      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            small
            text
            :disabled="loading"
            @click="cancel"
        >
          Close
        </v-btn>
        <v-btn
            small
            class="primary"
            :disabled="map_obj.name === null || map_obj.name === '' || loading"
            @click="submit"
        >
          Submit
        </v-btn>

      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import {c_qfCollection, u_qfCollection} from "@/services/app_api";
import prettyBytes from 'pretty-bytes';
import {deepClone} from '@/services/generic';
import {templates} from "@/store";
import {set_random_style, get_qfCollection_type_as_dict, get_qfDocument_type_as_dict} from "@/services/app_utils";

export default {
  name: "DriveMapDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function, default: null},
    map_id: {type: String, default: null},
    action: {type: String, default: 'Create'}, // Create, Edit, Duplicate
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      loading: false,

      tab: 'vector_layers',

      map_obj: {config: {stage: {}}},
      map_obj_org: {},

      layers: {},
      maps: {},

      // vector layers
      search_vectors: null,
      vector_layers_table: [],
      vector_layers_table_headers: [
        {text: 'Name', value: 'layer_name'},
        {text: 'Type', value: 'feature_type'},
        {text: 'Size', value: 'layer_size'},
        {text: 'Selected?', value: 'is_selected'},
      ],
      selected_vector_items: [],

      // raster layers
      search_rasters: null,
      raster_layers_table: [],
      raster_layers_table_headers: [
        {text: 'Name', value: 'layer_name'},
        {text: 'Size', value: 'layer_size'},
        {text: 'Selected?', value: 'is_selected'},
      ],
      selected_raster_items: [],

      // tile layers
      search_tiles: null,
      tile_layers_table: [],
      tile_layers_table_headers: [
        {text: 'Name', value: 'layer_name'},
        {text: 'Selected?', value: 'is_selected'},
      ],
      selected_tile_items: [],

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],
    }
  },
  methods: {

    async on_item_selected_vector() {
      setTimeout(() => {
        for (let table_row of this.vector_layers_table) {
          table_row.is_selected = false
        }
        for (let selected_item of this.selected_vector_items) {
          for (let table_row of this.vector_layers_table) {
            if (table_row.layer_id === selected_item.layer_id) {
              table_row.is_selected = true
            }
          }
        }
      }, 50)
    },
    on_select_all_items_vector() {
      this.on_item_selected_vector()
    },

    async on_item_selected_raster() {
      setTimeout(() => {
        for (let table_row of this.raster_layers_table) {
          table_row.is_selected = false
        }
        for (let selected_item of this.selected_raster_items) {
          for (let table_row of this.raster_layers_table) {
            if (table_row.layer_id === selected_item.layer_id) {
              table_row.is_selected = true
            }
          }
        }
      }, 50)
    },
    on_select_all_items_raster() {
      this.on_item_selected_raster()
    },

    async on_item_selected_tile() {
      setTimeout(() => {
        for (let table_row of this.tile_layers_table) {
          table_row.is_selected = false
        }
        for (let selected_item of this.selected_tile_items) {
          for (let table_row of this.tile_layers_table) {
            if (table_row.layer_id === selected_item.layer_id) {
              table_row.is_selected = true
            }
          }
        }
      }, 50)
    },
    on_select_all_items_tile() {
      this.on_item_selected_tile()
    },

    do_prettyBytes(num) {
      if (num === null || num === undefined) {
        return null
      } else {
        return prettyBytes(num)
      }
    },
    async create_vector_layers_table() {

      this.vector_layers_table = []
      for (let layer_id in this.layers) {
        let layer_obj = this.layers[layer_id]
        if (layer_obj.config.layer_type === 'vector') {
          let is_selected = false
          if (layer_id in this.map_obj.layers.dict) {
            is_selected = true
          }

          console.log(layer_obj.name, layer_obj.is_uploaded)
          console.log(layer_obj.name, layer_obj.docBlob.is_uploaded)
          // console.log(layer_obj)
          if (layer_obj.docBlob.is_uploaded) {
            let table_row = {
              layer_id: layer_id,
              layer_name: layer_obj.name,
              feature_type: layer_obj.config.feature_type,
              layer_size: this.do_prettyBytes(layer_obj.docBlob.size),
              is_uploaded: layer_obj.docBlob.is_uploaded,
              is_selected: is_selected,
            }
            this.vector_layers_table.push(table_row)

            if (is_selected) {
              this.selected_vector_items.push(table_row)
            }
          }


        }

      }

    },

    async create_raster_layers_table() {

      this.raster_layers_table = []
      for (let layer_id in this.layers) {
        let layer_obj = this.layers[layer_id]
        if (layer_obj.config.layer_type === 'raster') {
          let is_selected = false
          if (layer_id in this.map_obj.layers.dict) {
            is_selected = true
          }

          let table_row = {
            layer_id: layer_id,
            layer_name: layer_obj.name,
            layer_size: this.do_prettyBytes(layer_obj.docBlob.size),
            is_uploaded: layer_obj.is_uploaded,
            is_selected: is_selected,
          }

          this.raster_layers_table.push(table_row)

          if (is_selected) {
            this.selected_raster_items.push(table_row)
          }
        }

      }

    },

    async create_tile_layers_table() {

      this.tile_layers_table = []
      for (let layer_id in this.layers) {
        let layer_obj = this.layers[layer_id]
        if (layer_obj.config.layer_type === 'tile') {
          let is_selected = false
          if (layer_id in this.map_obj.layers.dict) {
            is_selected = true
          }

          let table_row = {
            layer_id: layer_id,
            layer_name: layer_obj.name,
            is_selected: is_selected,
          }

          this.tile_layers_table.push(table_row)

          if (is_selected) {
            this.selected_tile_items.push(table_row)
          }
        }

      }

    },

    async process_map() {

      this.map_obj.layers.dict = {}
      this.map_obj.layers.list = []

      if (this.action === 'Create') {

        this.map_obj.layers.list = []
        for (let layer_item of this.selected_vector_items) {
          let layer_id = layer_item.layer_id
          this.map_obj.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
          this.map_obj.layers.dict[layer_id].id = layer_id
          this.map_obj.layers.dict[layer_id].style = await set_random_style(layer_item.feature_type)
          this.map_obj.layers.list.push(layer_id)
        }
        for (let layer_item of this.selected_raster_items) {
          let layer_id = layer_item.layer_id
          this.map_obj.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
          this.map_obj.layers.dict[layer_id].id = layer_id

          this.map_obj.layers.list.push(layer_id)
        }
        for (let layer_item of this.selected_tile_items) {
          let layer_id = layer_item.layer_id
          this.map_obj.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
          this.map_obj.layers.dict[layer_id].id = layer_id

          this.map_obj.layers.list.push(layer_id)
        }
      } else {
        let selected_layer_id_list = []

        for (let layer_item of this.selected_vector_items) {
          selected_layer_id_list.push(layer_item.layer_id)
        }

        for (let layer_item of this.selected_raster_items) {
          selected_layer_id_list.push(layer_item.layer_id)
        }

        for (let layer_item of this.selected_tile_items) {
          selected_layer_id_list.push(layer_item.layer_id)
        }

        for (let layer_id in this.map_obj_org.layers.dict) {
          if (selected_layer_id_list.includes(layer_id)) {
            this.map_obj.layers.dict[layer_id] = this.map_obj_org.layers.dict[layer_id]
            this.map_obj.layers.list.push(layer_id)
          }
        }

        for (let layer_item of this.selected_vector_items) {
          let layer_id = layer_item.layer_id
          if (!(layer_id in this.map_obj.layers.dict)) {
            this.map_obj.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
            this.map_obj.layers.dict[layer_id].id = layer_id
            this.map_obj.layers.dict[layer_id].style = await set_random_style(layer_item.feature_type)
            // this.map_obj.layers.list.push(layer_id)
            this.map_obj.layers.list.unshift(layer_id)
          }
        }

        for (let layer_item of this.selected_raster_items) {
          let layer_id = layer_item.layer_id
          if (!(layer_id in this.map_obj.layers.dict)) {
            this.map_obj.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
            this.map_obj.layers.dict[layer_id].id = layer_id

            // this.map_obj.layers.list.push(layer_id)
            this.map_obj.layers.list.unshift(layer_id)
          }
        }

        for (let layer_item of this.selected_tile_items) {
          let layer_id = layer_item.layer_id
          if (!(layer_id in this.map_obj.layers.dict)) {
            this.map_obj.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
            this.map_obj.layers.dict[layer_id].id = layer_id

            // this.map_obj.layers.list.push(layer_id)
            this.map_obj.layers.list.unshift(layer_id)
          }
        }

      }

    },

    async submit() {
      this.loading = true

      await this.process_map()

      switch (this.action) {
        case 'Create':
          await c_qfCollection(this.map_obj)
          break;
        case 'Edit':
          console.log('Submit edit map', this.map_obj)
          await u_qfCollection(this.map_obj)
          break;
        case 'Duplicate':
          await c_qfCollection(this.map_obj)
          break;
        default:
          break;
      }

      this.callback_function()

      await this.close()
    },
    async close() {
      this.$emit('update:show_dialog', false)
      this.loading = false
    },
    async cancel() {
      await this.close()
    },

    async init() {
      this.layers = await get_qfDocument_type_as_dict('layer')
      this.maps = await get_qfCollection_type_as_dict('map')
      if (this.action === 'Create') {
        this.map_obj = deepClone(templates.qfCollection)
        this.map_obj.type = 'map'
        this.map_obj.name = 'New Map'
        this.map_obj.config = deepClone(templates.qfCollection_config_templates.map)
      } else {
        this.map_obj = this.maps[this.map_id]
        this.map_obj_org = deepClone(this.map_obj)
      }
      await this.create_vector_layers_table()
      await this.create_raster_layers_table()
      await this.create_tile_layers_table()
    },

  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>

</style>