<template>
  <v-navigation-drawer
      hide-overlay absolute permanent
      :app="false"
      :disable-resize-watcher="true"
      :disable-route-watcher="true"
      :mobile-breakpoint="0"
      :stateless="true"
      class="fill-height"
      ref="theDrawer"
      :width="width"
      :right="!is_left"
      style="margin-left: 10px;"
  >
    <v-app-bar
        flat dense
        height="32"
        color="white"
        v-if="is_left"
    >
      <v-btn icon small color="black" @click="decreaseDrawerWidth">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon small color="black" @click="increaseDrawerWidth">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn icon small color="black" @click="hideDrawer">
        <v-icon>mdi-chevron-double-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar
        flat dense
        color="white"
        v-else>
      <v-btn icon small color="black" @click="hideDrawer">
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon small color="black" @click="decreaseDrawerWidth">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon small color="black" @click="increaseDrawerWidth">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

    </v-app-bar>

    <v-col v-if="is_left">

      <v-tabs
          v-model="tab"
          centered
          icons-and-text
      >
        <v-tabs-slider color="purple"></v-tabs-slider>

        <v-tab href="#settings">
          Settings
          <v-icon>mdi-database-cog</v-icon>
        </v-tab>
        <v-tab href="#dashboard">
          Dashboard
          <v-icon>mdi-view-dashboard</v-icon>
        </v-tab>
        <v-tab href="#alerts">
          Alerts
          <v-icon>mdi-bell</v-icon>
        </v-tab>
        <v-tab href="#team">
          Team
          <v-icon>mdi-sitemap</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="settings">
          <v-divider></v-divider>
          <MapsLayersList></MapsLayersList>
        </v-tab-item>
        <v-tab-item value="dashboard">
          <v-divider></v-divider>
          <DwsDashboard></DwsDashboard>
        </v-tab-item>
      </v-tabs-items>

    </v-col>

    <v-col v-else>

    </v-col>

  </v-navigation-drawer>
</template>

<script>
import MapsLayersList from "@/components/Maps/MapsLayersList";
import DwsDashboard from "@/components/DWS/DwsDashboard";

export default {
  name: "DwsDrawer",
  components: {MapsLayersList,DwsDashboard},
  props: {
    is_left: {type: Boolean},
    show_prop: {type: Boolean},
  },
  data() {
    return {
      width: '350px',
      borderSize: 5,

      tab: null,
    }

  },
  methods: {
    hideDrawer() {
      this.$emit('update:show_prop', false)
    },
    setDrawerBorderWidth() {
      let i = this.$refs.theDrawer.$el.querySelector(
          ".v-navigation-drawer__border"
      );
      i.style.width = this.borderSize + "px";
      i.style.backgroundColor = "white";
      // i.style.backgroundColor = "lightblue";
      i.style.cursor = "ew-resize";


    },
    setEvents() {
      const minSize = this.borderSize;
      const el = this.$refs.theDrawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const direction = el.classList.contains("v-navigation-drawer--right")
          ? "right"
          : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
          "mousedown",
          function (e) {
            if (e.offsetX < minSize) {
              e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
      );

      document.addEventListener(
          "mouseup",
          () => {
            el.style.transition = '';
            this.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
      );
    },
    decreaseDrawerWidth() {
      let width = parseInt(this.width.replace('px', ''))
      if (this.is_left) {
        this.width = width - 10 + 'px'
      } else {
        this.width = width + 10 + 'px'
      }

    },
    increaseDrawerWidth() {

      let width = parseInt(this.width.replace('px', ''))

      if (this.is_left) {
        this.width = width + 10 + 'px'
      } else {
        this.width = width - 10 + 'px'
      }

      // this.width = width + 10 + 'px'
    },
    setPanelWidth() {
      if (screen.width < 500) {
        this.width = 350
      }
      if (this.$vuetify.breakpoint.mobile) {
        this.width = 200
      }
    },

  },
  mounted() {
    this.setPanelWidth()
    this.setDrawerBorderWidth()
    this.setEvents()
  }
}
</script>

<style scoped>

</style>
