<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left color="purple">mdi-book-open-variant</v-icon>
          <span class="body-1">
            Summary for <b>{{ the_label }}</b>
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>


      <v-tabs
          v-model="tab"
          centered
          icons-and-text
      >
        <v-tabs-slider color="purple"></v-tabs-slider>

        <v-tab href="#summary" @click="do_plots">
          Summary
          <v-icon>mdi-file-chart</v-icon>
        </v-tab>

        <v-tab href="#unique_values">
          Unique Values
          <v-icon>mdi-format-list-group</v-icon>
        </v-tab>

        <v-tab href="#values">
          All Values
          <v-icon>mdi-view-list-outline</v-icon>
        </v-tab>

      </v-tabs>

      <v-tabs-items v-model="tab">

        <v-tab-item value="unique_values">
          <v-divider></v-divider>
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ the_label }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, idx) in in_array_unique"
                    :key="idx"
                >
                  <td>{{ item }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-tab-item>

        <v-tab-item value="values">
          <v-divider></v-divider>
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ the_label }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, idx) in in_array"
                    :key="idx"
                >
                  <td>{{ item }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-tab-item>

        <v-tab-item value="summary">
          <v-divider></v-divider>
          <v-container style="max-width: 400px;">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                <tr
                    v-for="the_var in ['count', 'min', 'max', 'median', 'mean', 'std']"
                    :key="the_var"
                    v-if="stats[the_var] !== null"
                >
                  <td>{{ the_var.toUpperCase() }}</td>
                  <td>{{ stats[the_var] }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>


          </v-container>
          <v-container>
            <div id="the_line_plot"></div>
            <div id="the_histogram"></div>
          </v-container>


        </v-tab-item>

      </v-tabs-items>

    </v-card>

  </v-dialog>

</template>

<script>

import * as ss from 'simple-statistics'
import {newPlot} from 'plotly.js-dist-min'
import {deepClone} from "@/services/generic";

export default {
  name: "DriveArraySummaryDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    in_array: {type: Array},
    the_label: {type: String},
    the_type: {type: String},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      tab: 'summary',
      in_array_unique: [],
      arr_s: null,
      stats: {
        count: null,
        min: null,
        max: null,
        median: null,
        mean: null,
        std: null
      },

    }
  },
  methods: {
    async get_sorted_unique_list(vals_list) {
      return [...new Set(vals_list)].sort()
    },
    plot_line() {
      let data = [
        {
          y: this.in_array,
          type: 'scatter',
          mode: "lines",
          line: {
            color: 'orange',
            width: 3
          }
        }
      ];

      let layout = {
        showlegend: false,
        autosize: true,
        // margin: {
        //   l: 50,
        //   r: 50,
        //   b: 50,
        //   t: 50,
        //   pad: 4
        // },
        xaxis: {
          showgrid: false,
          showline: false,
        },
        yaxis: {
          showgrid: false,
          showline: false,
        },
      }

      newPlot('the_line_plot', data, layout);
    },
    plot_histogram() {
      let data = [
        {
          y: this.in_array,
          type: 'histogram',
          marker: {
            color: 'green',
            width: 3
          }
        }
      ];

      let layout = {
        showlegend: false,
        autosize: true,
        // margin: {
        //   l: 50,
        //   r: 50,
        //   b: 50,
        //   t: 50,
        //   pad: 4
        // },
        xaxis: {
          showgrid: false,
          showline: false,
        },
        yaxis: {
          showgrid: false,
          showline: false,
        },
      }

      newPlot('the_histogram', data, layout);
    },

    do_plots() {

      setTimeout(() => {
        this.plot_line()
        this.plot_histogram()

      }, 500)

    },

    async submit() {

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {
      this.in_array_unique = await this.get_sorted_unique_list(this.in_array)
      let arr = deepClone(this.in_array).filter(e => !['', null, undefined].includes(e))
      if (this.the_type === 'Number') {
        this.stats.count = this.in_array.length
        this.stats.min = ss.min(arr)
        this.stats.max = ss.max(arr)
        this.stats.median = ss.median(arr)
        this.stats.mean = ss.mean(arr)
        this.stats.std = ss.standardDeviation(arr)
      } else {
        this.stats.count = this.in_array.length
      }

      this.do_plots()

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
