<template>
  <v-card flat :loading="loading">
    <v-card-text>
      <v-col v-if="styles_table.length > 2">
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
              style="max-width: 300px;"
              background-color="blue-grey lighten-5"
              v-model="search"
              append-icon="mdi-magnify"
              color="green"
              label="Search for a style"
              outlined
              dense
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-row>

      </v-col>

      <v-data-table
          :headers="styles_table_headers"
          :items="styles_table"
          :items-per-page="-1"
          :search="search"
          item-key="style_id"
          class="elevation-1"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          :hide-default-footer="styles_table.length < 10"
      >
        <template v-slot:item.style_icon="{ item }">
          <v-chip
              v-if="item.feature_type === 'Polygon'"
              label small
              :color="`${styles[item.style_id].config.style.fillColor}${Math.floor(styles[item.style_id].config.style.fillOpacity*100)}`"
              style="padding: 0;"
          >
            <v-icon small :color="styles[item.style_id].config.style.color">
              mdi-vector-polygon
            </v-icon>
          </v-chip>
          <v-icon small :color="styles[item.style_id].config.style.color"
                  v-if="item.feature_type === 'Point' && styles[item.style_id].config.style.marker_type === 'marker'">
            mdi-map-marker
          </v-icon>
          <v-icon small :color="styles[item.style_id].config.style.color"
                  v-if="item.feature_type === 'Point' && styles[item.style_id].config.style.marker_type === 'circleMarker'">
            mdi-circle
          </v-icon>
          <v-icon small :color="styles[item.style_id].config.style.color" v-if="item.feature_type === 'Line'">
            mdi-vector-polyline
          </v-icon>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container style="margin-bottom: 10px; margin-top: 10px;">
              <v-col v-if="styles[item.style_id].config.style.style_type === 'graduated'">
                <span class="caption">Style Legend</span>
                <table>
                  <tbody>
                  <tr>
                    <th></th>
                    <th class="caption bold">{{ styles[item.style_id].config.style.style_rule.col_name }}</th>
                  </tr>
                  <tr
                      v-for="(legend_item, idx) in styles[item.style_id].config.style.style_rule.style_obj.colors_list"
                      :key="legend_item.color"
                      style="max-height: 20px;"
                  >
                    <td style="vertical-align: middle; max-height: 20px; min-width: 30px;">
                      <span
                          :style="{'background-color':legend_item.color, float: 'left', height: '20px', width: '20px', clear: 'both'}"></span>
                    </td>
                    <td style="vertical-align: middle; max-height: 20px;">
                <span class="caption" style="padding-left: 5px;">
                  <span v-if="idx===0"> &ge; </span>
                  <span v-else> > </span>
                  {{ legend_item.val }}
                </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
              <v-row justify="space-around">
                <!--                <v-btn @click="edit_style(item.style_id)" small style="margin-right: 10px;">-->
                <!--                  <v-icon small left color="green">mdi-pencil</v-icon>-->
                <!--                  Edit Style-->
                <!--                </v-btn>-->

                <v-btn @click="delete_style(item.style_id)" small style="margin-right: 10px;">
                  <v-icon small left color="red">mdi-delete</v-icon>
                  Delete Style
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </td>

        </template>
      </v-data-table>
      <DriveConfirmDeleteDialog
          v-if="DriveConfirmDeleteDialog_show"
          :show_dialog.sync="DriveConfirmDeleteDialog_show"
          :callback_function="after_delete_style"
          :dialog_title="'Delete Style'"
          :item_type_name="'Style'"
          :item_type="'qfDocument'"
          :item_name="delete_item.name"
          :item_id="delete_item.id"
      ></DriveConfirmDeleteDialog>
    </v-card-text>
  </v-card>

</template>

<script>

import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";

import {get_qfCollection_type_as_dict, get_qfDocument_type_as_dict} from "@/services/app_utils";

export default {
  name: "DriveStylesList",
  props: {
    callback_function: {type: Function},
  },
  components: {DriveConfirmDeleteDialog},
  data() {
    return {

      loading: false,
      layers: {},
      maps: {},
      styles: {},

      styles_table: [],
      search: null,
      styles_table_headers: [
        {text: 'Style', value: 'style_icon'},
        {text: 'Name', value: 'style_name'},
        {text: 'Description', value: 'style_description'},
        {text: 'Feature Type', value: 'feature_type'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],

      selected_items: [],

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,
      }
    }
  },
  methods: {

    async remove_from_selected(style_id) {
      let selected_items = []
      for (let item of this.selected_items) {
        if (item.style_id !== style_id) {
          selected_items.push(item)
        }
      }
      this.selected_items = selected_items
    },

    async delete_style(style_id) {

      this.delete_item.name = this.styles[style_id].name
      this.delete_item.id = style_id
      this.DriveConfirmDeleteDialog_show = true

    },
    async after_delete_style(style_id) {
      this.delete_item.name = null
      this.delete_item.id = null
      await this.init()
      await this.remove_from_selected(style_id)
    },

    async init() {

      this.styles_table = []

      this.layers = await get_qfDocument_type_as_dict('layer')
      this.styles = await get_qfDocument_type_as_dict('style')
      this.maps = await get_qfCollection_type_as_dict('map')

      for (let style_id in this.styles) {
        let style_item = this.styles[style_id]
        this.styles_table.push({
          style_icon: '',
          style_id: style_id,
          style_name: style_item.name,
          style_description: style_item.config.description,
          feature_type: style_item.config.feature_type
        })
      }

    },

  },
  watch: {},
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
