import {v4 as uuidv4} from "uuid";
import {deepClone} from "@/services/generic";
import {
    bbox, booleanContains,
    booleanEqual,
    booleanPointInPolygon, booleanWithin,
    combine,
    difference,
    dissolve, featureCollection, getCoords,
    getGeom,
    intersect,
    lineIntersect, lineOffset, lineString, lineToPolygon, point, polygon, randomPoint, voronoi
} from "@turf/turf";

import {
    add_row_to_json_arrays,
    json_arrays_to_geojson,
    remove_row_ids_from_json_arrays
} from "@/services/json_arrays_api";
import {c_qfDocument_vector_layer} from "@/services/app_utils";


export async function split_polygons(split_line_geojson, json_arrays_in) {
    let line_geom = deepClone(getGeom(split_line_geojson))
    let json_arrays_out = deepClone(json_arrays_in)
    let remove_uid_list = []
    for (let idx in json_arrays_in.row_uid) {
        let feature_geometry = json_arrays_in.geometry[idx]
        let split_geojsons = await polygonCut(getGeom(feature_geometry), line_geom);

        if (split_geojsons != null) {
            let cur_row_uid = json_arrays_in.row_uid[idx]
            remove_uid_list.push(cur_row_uid)
            for (let split_geojson of split_geojsons.features) {
                json_arrays_out = await add_row_to_json_arrays(json_arrays_in, split_geojson.geometry, cur_row_uid)
            }
        }
    }

    return await remove_row_ids_from_json_arrays(json_arrays_out, remove_uid_list)

}

export async function union_polygons(json_arrays_in, row_uid_list) {

    let json_arrays_out = deepClone(json_arrays_in)

    let selected_geojson = {
        "type": "FeatureCollection",
        "features": []
    }
    for (let row_uid of row_uid_list) {
        let idx = json_arrays_in.row_uid.indexOf(row_uid)
        let geometry = json_arrays_in.geometry[idx]

        if (geometry.type === 'MultiPolygon') {
            geometry.type = 'Polygon'
            geometry.coordinates = geometry.coordinates[0]
        }

        selected_geojson.features.push({
            type: 'Feature',
            properties: {row_uid},
            geometry: geometry
        })
    }

    let dissolve_geojson = dissolve(deepClone(selected_geojson))

    let dissolve_features = []

    for (let feature1 of dissolve_geojson.features) {
        let same_row_uid = null
        for (let feature0 of selected_geojson.features) {
            if (booleanEqual(feature1.geometry, feature0.geometry)) {
                same_row_uid = feature0.properties.row_uid
                break;
            }
        }
        if (same_row_uid === null) {
            dissolve_features.push(feature1)
        } else {
            let f = deepClone(feature1)
            f.properties.row_uid = same_row_uid
            dissolve_features.push(f)
        }
    }

    for (let dissolve_feature of dissolve_features) {
        let row_uid = null
        if ('row_uid' in dissolve_feature.properties) {
            row_uid = dissolve_feature.properties.row_uid
        }
        json_arrays_out = await add_row_to_json_arrays(json_arrays_out, dissolve_feature.geometry, row_uid)
    }

    json_arrays_out = await remove_row_ids_from_json_arrays(json_arrays_out, row_uid_list)

    return json_arrays_out

}


export async function crop_polygons(crop_polygon_geojson, json_arrays_in) {

    let cols_list = Object.keys(json_arrays_in).filter(e => !['row_uid', 'geometry'].includes(e))
    let json_arrays_out = {row_uid: [], geometry: []}
    for (let col_uid of cols_list) {
        json_arrays_out[col_uid] = []
    }

    for (let idx in json_arrays_in.row_uid) {
        let feature_geometry = json_arrays_in.geometry[idx]
        let intersect_geojson = await intersect(crop_polygon_geojson, feature_geometry)
        if (intersect_geojson !== null) {
            json_arrays_out.row_uid.push(uuidv4().replaceAll('-', ''))
            json_arrays_out.geometry.push(intersect_geojson.geometry)
            for (let col_uid of cols_list) {
                json_arrays_out[col_uid].push(json_arrays_in[col_uid][idx])
            }
        }
    }

    if (json_arrays_out.row_uid.length < 1) {
        json_arrays_out = json_arrays_in
    }
    return json_arrays_out

}

export async function cut_polygons(cut_polygon_geojson, json_arrays_in) {

    let cols_list = Object.keys(json_arrays_in).filter(e => !['row_uid', 'geometry'].includes(e))
    let json_arrays_out = {row_uid: [], geometry: []}
    for (let col_uid of cols_list) {
        json_arrays_out[col_uid] = []
    }

    for (let idx in json_arrays_in.row_uid) {
        let feature_geometry = json_arrays_in.geometry[idx]
        let difference_geojson = await difference(feature_geometry, cut_polygon_geojson)
        if (difference_geojson !== null) {
            json_arrays_out.row_uid.push(uuidv4().replaceAll('-', ''))
            json_arrays_out.geometry.push(difference_geojson.geometry)
            for (let col_uid of cols_list) {
                json_arrays_out[col_uid].push(json_arrays_in[col_uid][idx])
            }
        }
    }

    if (json_arrays_out.row_uid.length < 1) {
        json_arrays_out = json_arrays_in
    }
    return json_arrays_out

}


export async function append_polygons(append_polygon_geojson, json_arrays_in) {

    let geojson_in = await json_arrays_to_geojson(json_arrays_in)
    let geojson_combine = await combine(geojson_in)
    let difference_geojson_all = await difference(append_polygon_geojson, geojson_combine.features[0])

    if (difference_geojson_all !== null) {
        if (difference_geojson_all.geometry.type === 'MultiPolygon') {
            for (let coord_arr of difference_geojson_all.geometry.coordinates) {
                let new_geometry = {
                    type: 'Polygon',
                    coordinates: deepClone(coord_arr)
                }
                json_arrays_in = await add_row_to_json_arrays(json_arrays_in, new_geometry, null)
            }
        } else {

            let new_geometry = {
                type: 'Polygon',
                coordinates: deepClone(difference_geojson_all.geometry.coordinates)
            }
            json_arrays_in = await add_row_to_json_arrays(json_arrays_in, new_geometry, null)
        }
    }

    return json_arrays_in

}

export async function select_by_geometry(json_arrays, selected_area_geojson, selected_uid_list) {

    for (let idx in json_arrays.row_uid) {

        let row_uid = json_arrays.row_uid[idx]
        let geometry = json_arrays.geometry[idx]

        if (['Line', 'LineString', 'MultiLineString'].includes(geometry.type)) {

            let line_intersects = lineIntersect(geometry, selected_area_geojson)

            if (line_intersects.features.length > 0) {
                if (!selected_uid_list.includes(row_uid)) {
                    selected_uid_list.push(row_uid)
                }
            }

        } else if (['Point', 'MultiPoint'].includes(geometry.type)) {

            if (booleanPointInPolygon(point(geometry.coordinates), selected_area_geojson)) {
                if (!selected_uid_list.includes(row_uid)) {
                    selected_uid_list.push(row_uid)
                }
            }
        } else if (['Polygon', 'MultiPolygon'].includes(geometry.type)) {
            let intersect_geojson = await intersect(selected_area_geojson, geometry)

            if (intersect_geojson !== null) {
                if (!selected_uid_list.includes(row_uid)) {
                    selected_uid_list.push(row_uid)
                }
            }
        }

    }

    return selected_uid_list


}

export async function random_points_in_polygons(){
    // inputs: number of points per polygon (or to calculate based on a density, e.g. 1 per km2)
      // selected features (with option to select from the table?)
      // appendix to files
      // option for calculating voronoi polygons or not
      // option to include an inside buffer for each polygon

      // we also need to package some layers together, e.g. random points and thier voronoi layer in a package
      // (possibly as a feature_collection that all features have the same data table)


      // ☑️ TO DO: set these flags in the GUI for the user to decide
      let upload_blob = false

      this.select_mode = false
      this.on_select_mode_off()
      this.edit_draw_features_mode = true


      let layer_obj
      let num_points_per_poly = 1000
      let combine_in_one_layer = false
      let do_voronoi = true

      let geojson_template = {
        "type": "FeatureCollection",
        "features": []
      }

      let selected_geojson = deepClone(geojson_template)
      let random_points_all = deepClone(geojson_template)
      let voronoi_polygons_all = deepClone(geojson_template)


      for (let row_uid of this.selected_uid_list) {
        let idx = this.json_arrays.row_uid.indexOf(row_uid)
        selected_geojson.features.push({
          type: 'Feature',
          properties: {row_uid: this.json_arrays.row_uid[idx]},
          geometry: this.json_arrays.geometry[idx]
        })
      }


      for (let feature of selected_geojson.features) {

        let __id = feature.properties.__id
        let random_point_geojson = deepClone(geojson_template)
        let feature_bbox = bbox(feature) // [ 140.415492, -18.560038, 140.589662, -18.325326 ]
        for (let pnt_cnt = 0; pnt_cnt < num_points_per_poly; pnt_cnt++) {
          if (pnt_cnt < num_points_per_poly) {
            let random_point = randomPoint(1, {bbox: feature_bbox})
            if (booleanContains(feature, random_point.features[0])) {
              random_point_geojson.features.push(random_point.features[0])
              if (combine_in_one_layer) {
                random_points_all.features.push(random_point.features[0])
              }
            }

          }
        }


        if (!combine_in_one_layer) {
          let layer_name = `random pnts ${__id}`
          let feature_type = 'Point'
          await c_qfDocument_vector_layer(layer_name, feature_type, random_point_geojson, this.map_id, upload_blob, null)

        }

        if (do_voronoi) {
          let voronoi_polygons = voronoi(random_point_geojson, {bbox: feature_bbox})
          let voronoi_polygons_clip = deepClone(geojson_template)

          for (let feature1 of voronoi_polygons.features) {
            let intersect_geojson = await intersect(feature, feature1)
            if (intersect_geojson !== null) {
              voronoi_polygons_clip.features.push(intersect_geojson)
            }
            if (combine_in_one_layer) {
              voronoi_polygons_all.features.push(intersect_geojson)
            }
          }

          if (!combine_in_one_layer) {

            let layer_name = `voronoi plgs ${__id}`
            let feature_type = 'Polygon'
            // TO CHECK the following
            await c_qfDocument_vector_layer(layer_name, feature_type, voronoi_polygons_all, this.map_id, upload_blob, null)

          }

        }

      }

      if (combine_in_one_layer) {

        let layer_name = `random pnts all`
        let feature_type = 'Point'
        await c_qfDocument_vector_layer(layer_name, feature_type, random_points_all, this.map_id, upload_blob, null)

        ///////////////////////////

        layer_name = `voronoi plgs all`
        feature_type = 'Polygon'
        await c_qfDocument_vector_layer(layer_name, feature_type, voronoi_polygons_all, this.map_id, upload_blob, null)


      }

      // loop around each selected_geojson
      // for each polygon, calculate bbox
      // create random point in bbox, add it to points if inside the polygon
      // finish when done
      // create a new layer for the points and add to system
      // if voronoi is set to true, calculate them for each case

}


export async function polygonCut(polygonItem, lineItem) {
    const THICK_LINE_UNITS = 'kilometers';
    const THICK_LINE_WIDTH = 0.001;
    let i, j, id, intersectPoints, lineCoords, forCut, forSelect;
    let thickLineString, thickLinePolygon, clipped, polyg, intersect;
    let polyCoords = [];
    let cutPolyGeoms = [];
    let cutFeatures = [];
    let offsetLine = [];
    let retVal = null;

    if (((polygonItem.type !== 'Polygon') && (polygonItem.type !== 'MultiPolygon')) || (lineItem.type !== 'LineString')) {
        return retVal;
    }

    intersectPoints = lineIntersect(polygonItem, lineItem);
    if (intersectPoints.features.length === 0) {
        return retVal;
    }

    lineCoords = getCoords(lineItem);
    if ((booleanWithin(point(lineCoords[0]), polygonItem) ||
        (booleanWithin(point(lineCoords[lineCoords.length - 1]), polygonItem)))) {
        return retVal;
    }

    offsetLine[0] = lineOffset(lineItem, THICK_LINE_WIDTH, {units: THICK_LINE_UNITS});
    offsetLine[1] = lineOffset(lineItem, -THICK_LINE_WIDTH, {units: THICK_LINE_UNITS});

    for (i = 0; i <= 1; i++) {
        forCut = i;
        forSelect = (i + 1) % 2;
        polyCoords = [];
        for (j = 0; j < lineItem.coordinates.length; j++) {
            polyCoords.push(lineItem.coordinates[j]);
        }
        for (j = (offsetLine[forCut].geometry.coordinates.length - 1); j >= 0; j--) {
            polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
        }
        polyCoords.push(lineItem.coordinates[0]);

        thickLineString = lineString(polyCoords);
        thickLinePolygon = lineToPolygon(thickLineString);
        clipped = difference(polygonItem, thickLinePolygon);

        cutPolyGeoms = [];
        for (j = 0; j < clipped.geometry.coordinates.length; j++) {
            polyg = polygon(clipped.geometry.coordinates[j]);
            intersect = lineIntersect(polyg, offsetLine[forSelect]);
            if (intersect.features.length > 0) {
                cutPolyGeoms.push(polyg.geometry.coordinates);
            }
        }

        cutPolyGeoms.forEach(function (geometry, index) {
            id = (i + 1) + '.' + (index + 1);
            cutFeatures.push(polygon(geometry, {id: id}));
        });
    }

    if (cutFeatures.length > 0) retVal = featureCollection(cutFeatures);

    return retVal;
}