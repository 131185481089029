import {db} from "@/firebase/firebase";
import {
    doc,
    collection,
    setDoc,
    updateDoc,
    getDoc,
    getDocsFromServer,
    deleteDoc,
    deleteField
} from "firebase/firestore";


export async function firestore_c_doc(path, content) {
    await setDoc(doc(db, path), content, {merge: true});
}

export async function firestore_u_doc(path, content) {
    await updateDoc(doc(db, path), content);
}


export async function firestore_r_doc(path) {
    const docSnap = await getDoc(doc(db, path));
    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null
    }
}

export async function firestore_r_collection(path) {

    const querySnapshot = await getDocsFromServer(collection(db, path));
    if (querySnapshot.empty) {
        return null
    } else {
        let data = {}
        querySnapshot.forEach((doc) => {
            data[doc.id] = doc.data()
        });
        return data
    }
}

export async function firestore_d_doc(path) {
    await deleteDoc(doc(db, path));
}



