<template>

  <v-card
      style="margin-bottom: 5px;"
      elevation="2">
    <v-app-bar
        flat
        dense
        height="auto"
        color="white"
    >
      <v-checkbox dense style="font-size: 6px;"
                  v-model="show_layer"
                  label="Base Layer"
                  hide-details
                  @change="on_click_layer_checkbox"
      >
        <template v-slot:label>
          <div class="text-caption">
            <v-chip
                label small
                style="padding: 0;"
            >
              <v-icon small>
                mdi-map
              </v-icon>
            </v-chip>
            Base Layer
          </div>
        </template>
      </v-checkbox>
    </v-app-bar>

    <v-col>
      <v-select
          style="margin: 0;"
          v-model="base_layer_name"
          :items="['MapBox Imagery', 'MapBox Streets', 'MapBox Light', 'MapBox Dark', 'MapBox Outdoors', 'Topography']"
          @change="setBaseLayer()"
          :hide-details="true"
      ></v-select>
    </v-col>
  </v-card>

</template>

<script>
import {tileLayer} from 'leaflet'

export default {
  name: "MapsMapBaseLayer",
  data() {
    return {
      show_layer: true,
      mapBoxAccessToken: this.$store.state.secrets.mapbox,
      base_layer_name: 'MapBox Imagery',
      base_layer_obj: null,
      basemaps_dict: {
        'MapBox Imagery': 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}',
        'MapBox Streets': 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}',
        'MapBox Light': 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}',
        'MapBox Dark': 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}',
        'MapBox Outdoors': 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}',
        'Topography': 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
      }
    }
  },
  methods: {
    async on_click_layer_checkbox() {
      if (!this.show_layer) {
        this.remove_layer_from_map()
      } else {
        this.add_layer_to_map()
      }
    },
    remove_layer_from_map() {
      if (this.base_layer_obj !== null) {
        this.base_layer_obj.do_not_remove = true
        this.base_layer_obj.removeFrom(this.$store.state.map)
      }
    },
    add_layer_to_map() {
      if (this.base_layer_obj !== null) {
        this.base_layer_obj.do_not_remove = true
        this.base_layer_obj.addTo(this.$store.state.map).bringToBack()
      }
    },
    setBaseLayer() {

      if (this.base_layer_obj !== null) {
        this.$store.state.map.removeLayer(this.base_layer_obj)
      }

      let base_layer_url = this.basemaps_dict[this.base_layer_name]
      if (base_layer_url.includes('mapbox')) {
        base_layer_url += `?access_token=${this.mapBoxAccessToken}`
      }

      this.base_layer_obj = tileLayer(base_layer_url, {
        maxZoom: 24,
        maxNativeZoom: 16,
        attribution: 'Map data &copy; <a href="https://www.questfeed.com">QuestFeed</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      })
      this.base_layer_obj.do_not_remove = true
      this.base_layer_obj.addTo(this.$store.state.map).bringToBack()

    },
  },
  mounted() {

    let interval_id = setInterval(() => {
      if (this.$store.state.map !== null) {
        this.setBaseLayer()
        clearInterval(interval_id);
      }
    }, 100);

  }
}
</script>

<style scoped>

</style>
