<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left>mdi-chart-line</v-icon>
          <span class="body-1">
            Time series plot
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <div max-width="1100">
          <v-responsive>
            <v-card-text>
              <div id="ts_plot" style="width: 100%;"></div>
            </v-card-text>
          </v-responsive>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn icon style="margin-right: 50px;">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-row>

        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small text @click="cancel">Close</v-btn>
        <!--        <v-btn small dark color="purple"-->
        <!--               @click="submit"-->
        <!--        >Submit-->
        <!--        </v-btn>-->

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import {newPlot} from 'plotly.js-dist-min'

import {DateTime} from "luxon";

export default {
  name: "DwsPlotDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    table_name: {type: String},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      table_id: null,
      table_obj: {},
      table_data: [],
      table_df: null,

    }
  },
  methods: {
    async submit() {

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },

    async plot_timeseries() {
      let dates_list = []
      let values_list = []

      for (let item of this.table_data) {
        dates_list.push(DateTime.fromFormat(item.Date, "M/d/yy H:mm").toISO())
        values_list.push(parseFloat(item.Flow))
      }

      let data1 = {
        name: 'Flow',
        x: dates_list,
        y: values_list,
        type: 'scatter',
        mode: "lines",
        line: {
          color: 'orange',
          width: 3
        }
      }

      let y_min = Math.min(...values_list) * 0.95
      let y_max = Math.max(...values_list) * 1.05

      let v_idx = dates_list.length - 20
      let x_v_item = dates_list[v_idx]

      let x_vertical_line = [x_v_item, x_v_item]
      let y_vertical_line = [y_min, y_max]

      let data_v =
          {
            name: 'Current Date & Time',
            x: x_vertical_line,
            y: y_vertical_line,
            type: 'scatter',
            mode: "lines",
            line: {
              dash: 'dot',
              color: 'blue',
              width: 1
            },
          }

      console.log(data_v)

      let data = [data1, data_v]

      let layout = {
        // title: 'Line and Scatter Plot',
        showlegend: true,
        legend: {
          x: 0.4,
          y: 1.2,
          xanchor: 'right',
          "orientation": "h",
        },
        autosize: true,
        margin: {
          l: 50,
          r: 50,
          b: 50,
          t: 50,
          pad: 4
        },
        xaxis: {
          showgrid: false,
          showline: true,
        },
        yaxis: {
          title: 'Flow (m3/s)',
          showgrid: false,
          showline: true,
          range: [y_min, y_max]
        },


      };

      newPlot('ts_plot', data, layout);

    },

    async init() {

      let tables = await get_qfDocument_type_as_dict('table')
      for (let table_id in tables) {
        let table_obj = tables[table_id]
        if (table_obj.name === this.table_name) {
          this.table_id = table_id
          this.table_obj = table_obj
          this.table_data = await idb_unzip_blob(this.table_id)

          await this.plot_timeseries()
          // for (let item of this.table_data){
          //   // item.Date = DateTime.fromISO(item.Date)
          //   console.log(item.Date)
          //   item.Date2 = DateTime.fromFormat(item.Date, "M/d/yy HH:mm").toISO()
          //   console.log(item.Date2)
          //   dates_list.push(DateTime.fromFormat(item.Date, "M/d/yy HH:mm").toISO())
          //   values_list.push()
          // }
          // console.log(this.table_data)
          //
          // // this.table_df = new dfd.DataFrame(this.table_data)
          // // // let dt_col = dfd.toDateTime(this.table_df["Date"])
          // // // this.table_df.addColumn("D", this.table_df["Date"].dt.values, { inplace: true })
          // // this.table_df.ctypes.print()
          // // this.table_df.print()
          // // console.log(this.table_df["Date"].values)
          // // console.log(this.table_df["Date"].dt.values)
          // // console.log(dfd.toDateTime(this.table_df["Date"]))
          // // console.log(dfd.toDateTime(this.table_df["Date"]))
          //
          //
          // let layout = {
          //   title: "Time Series data",
          //   autosize: true,
          //   xaxis: {
          //     title: "Date2",
          //     autorange: true,
          //     showgrid: false,
          //   },
          //   yaxis: {
          //     title: "Values",
          //     autorange: true,
          //     showgrid: false,
          //   },
          // };
          //
          // let config = {
          //   columns: ["Flow"],
          // };
          //
          // let new_df = this.table_df.setIndex({ column: "Date" });
          // new_df.plot("ts_plot").line({ config, layout });

          break;
        }
      }


    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
