<template>

  <div class="overflow-y-auto" style="margin: 0; padding: 0; z-index: 1000;">
    <v-system-bar color="white" dense>
      <v-chip
          v-if="$store.state.feature_info_drawer.feature_type === 'Polygon'"
          label small
          :color="`${$store.state.feature_info_drawer.feature_style.fillColor}${Math.floor($store.state.feature_info_drawer.feature_style.fillOpacity*100)}`"
          style="padding: 0;"
      >
        <v-icon small :color="$store.state.feature_info_drawer.feature_style.color">
          mdi-vector-polygon
        </v-icon>
      </v-chip>
      <v-icon :color="$store.state.feature_info_drawer.feature_style.color"
              v-if="$store.state.feature_info_drawer.feature_type === 'Point' && $store.state.feature_info_drawer.feature_style.marker_type === 'marker'">
        mdi-map-marker
      </v-icon>
      <v-icon :color="$store.state.feature_info_drawer.feature_style.color"
              v-if="$store.state.feature_info_drawer.feature_type === 'Point' && $store.state.feature_info_drawer.feature_style.marker_type === 'circleMarker'">
        mdi-circle
      </v-icon>
      <v-icon :color="$store.state.feature_info_drawer.feature_style.color"
              v-if="$store.state.feature_info_drawer.feature_type === 'Line'">
        mdi-vector-polyline
      </v-icon>
      <span class="caption">
        {{ $store.state.feature_info_drawer.layer_name }}
      </span>
      <v-spacer></v-spacer>

    </v-system-bar>

    <v-container>

      <v-list dense>

        <div v-if="$store.state.feature_info_drawer.read_as_dict">

          <div v-for="(value, key) in $store.state.feature_info_drawer.rec_dict" :key="key">
            <div class="text-caption purple--text">{{ key }}</div>
            <div class="body-2">
            <span v-if="[null, undefined, ''].includes(value)" style="color: white">
              No Data
            </span>
              <span v-else>
              {{ value }}
            </span>
            </div>
            <v-divider></v-divider>
          </div>


        </div>


        <div v-else v-for="(value, key) in $store.state.feature_info_drawer.rec"
             v-if="!['row_uid', 'geometry'].includes(key)"
             :key="key">
          <div class="text-caption purple--text">{{ $store.state.feature_info_drawer.schema[key].label }}</div>
          <div class="body-2">
            <span v-if="[null, undefined, ''].includes(value)" style="color: white">
              No Data
            </span>
            <span v-else-if="$store.state.feature_info_drawer.schema[key].type === 'URL'">
              <a :href="value" target="_blank">{{ value }}</a>
            </span>
            <span v-else>
              {{ value }}
            </span>
          </div>
          <v-divider></v-divider>
        </div>

      </v-list>


    </v-container>
  </div>

</template>

<script>

export default {
  name: "MapsVectorLayerFeatureInfo",
  props: {},
  data() {
    return {}
  },
  methods: {},
  watch: {},
  mounted() {
  }

}
</script>

<style scoped>

.leaflet-popup-content {
  margin: 0;
}
</style>
