<template>

    <v-dialog
        v-model="show_dialog"
        persistent
        :max-width="dialog_size.max_width"
        :fullscreen="dialog_size.fullscreen"
    >
      <v-card>
        <v-app-bar dense color="white">
          <v-toolbar-title class="body-1">
            <v-icon left :color="'#ff7f50'">mdi-brush</v-icon>
            <span class="body-1">
            Style Layer
          </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon v-if="!dialog_size.fullscreen">
            <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon v-if="!dialog_size.fullscreen">
            <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn icon v-if="dialog_size.fullscreen">
            <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
          </v-btn>
          <v-btn icon v-if="!dialog_size.fullscreen">
            <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon @click="cancel">mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>

          <v-radio-group
              v-if="db_styles_list.length > 0"
              v-model="style_selection_mode"
              :hide-details="true"
              @change="on_change_style_selection_mode"
          >
            <v-radio class="caption"
                     label="Create a new style"
                     color="green"
                     value="New"
            ></v-radio>
            <v-radio class="caption"
                     label="Select an existing style"
                     color="orange"
                     value="Select"
            ></v-radio>
          </v-radio-group>

          <v-col v-if="style_selection_mode === 'Select'" style="max-width: 400px;">

            <v-autocomplete
                outlined
                v-model="selected_style_id"
                :items="db_styles_list"
                dense
                chips
                label="Select Style"
                item-text="name"
                item-value="id"
                @change="set_db_style"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>

          <div v-if="style_obj !== null">
            <v-radio-group
                v-model="style_obj.style_type"
                column
            >
              <template v-slot:label>
                <div>Style Type</div>
              </template>
              <v-radio class="caption"
                       label="Simple"
                       color="green"
                       value="simple"
              ></v-radio>
              <v-radio class="caption"
                       label="Graduated"
                       color="orange"
                       value="graduated"
              ></v-radio>
              <!--        <v-radio class="caption"-->
              <!--                 label="Categorized"-->
              <!--                 color="brown"-->
              <!--                 value="categorized"-->
              <!--        ></v-radio>-->
            </v-radio-group>

            <v-card elevation="3" v-if="style_obj.style_type === 'graduated'">
              <v-card-text>
                <b class="body-2 text--black">Set Legend Color Scheme</b>

                <v-select
                    label="Select Legend Column"
                    style="margin-top: 20px;"
                    v-model="style_obj.style_rule.col_name"
                    @change="on_change_legend_column"
                    :items="cols_list"
                    :hide-details="true"
                    :error="style_obj.style_rule.col_name === null"
                ></v-select>
                <v-col></v-col>


                <v-btn small dark color="purple"
                       v-if="style_obj.style_rule.col_name !== null"
                       @click="DriveColorRangeTable_show=true">
                  <v-icon small left>mdi-palette</v-icon>
                  Change Color Range
                </v-btn>


                <br>

                <v-checkbox
                    v-if="style_obj.style_rule.style_obj !== null"
                    v-model="style_obj.style_rule.reverse_colors"
                    hide-details="auto"
                    required
                    label="Reverse Colors"
                    @change="reverse_colors"
                ></v-checkbox>


                <DriveColorRangeTable
                    v-if="DriveColorRangeTable_show"
                    :show_dialog.sync="DriveColorRangeTable_show"
                    :style_obj.sync="style_obj.style_rule.style_obj"
                    :callback_function="on_set_color_range"
                ></DriveColorRangeTable>

                <div v-if="style_obj.style_rule.style_obj !== null">
                  <span class="caption">Legend</span>
                  <table>
                    <tbody>
                    <tr>
                      <th></th>
                      <th class="caption" style="text-align: left;">{{ style_obj.style_rule.col_name }}</th>
                    </tr>
                    <tr
                        v-for="item in style_obj.style_rule.style_obj.colors_list"
                        :key="item.color"
                        style="max-height: 20px;"
                    >
                      <td style="vertical-align: middle; max-height: 20px; min-width: 30px;">
                      <span
                          :style="{'background-color':item.color, float: 'left', height: '20px', width: '20px', clear: 'both'}"></span>
                      </td>
                      <td style="vertical-align: middle; max-height: 20px;">
                        <v-text-field
                            v-model="item.val"
                            type="number"
                            dense
                            :hide-details="true"
                            :error="item.val === null || item.val === undefined || item.val.length < 1"
                        ></v-text-field>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </v-card-text>


            </v-card>

            <div v-if="feature_type === 'Polygon'">

              <v-autocomplete
                  v-if="style_obj.style_type === 'simple'"
                  v-model="style_obj.fillColor"
                  :items="colors_list"
                  dense
                  chips
                  label="Fill Color"
                  item-text="color_name"
                  item-value="color_val"
                  @change="the_set_style(style_obj)"
                  :hide-details="true"
              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                  >
                    <v-avatar left>
                      <v-btn icon>
                        <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                      </v-btn>
                    </v-avatar>
                    {{ data.item.color_name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-btn icon>
                      <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                    </v-btn>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
                  </v-list-item-content>
                </template>

              </v-autocomplete>
              <v-subheader class="pl-0">
                Fill Opacity
              </v-subheader>
              <v-slider
                  v-model="style_obj.fillOpacity"
                  min="0"
                  max="1"
                  step="0.01"
                  color="red"
                  thumb-color="blue"
                  thumb-label="always"
                  prepend-icon="mdi-opacity"
                  @change="the_set_style(style_obj)"
              ></v-slider>
              <v-autocomplete
                  v-model="style_obj.color"
                  :items="colors_list"
                  dense
                  chips
                  label="Line Color"
                  item-text="color_name"
                  item-value="color_val"
                  @change="the_set_style(style_obj)"
              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                  >
                    <v-avatar left>
                      <v-btn icon>
                        <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                      </v-btn>
                    </v-avatar>
                    {{ data.item.color_name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-btn icon>
                      <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                    </v-btn>

                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-subheader class="pl-0">
                Line Opacity
              </v-subheader>
              <v-slider
                  v-model="style_obj.opacity"
                  min="0"
                  max="1"
                  step="0.01"
                  color="red"
                  thumb-color="blue"
                  thumb-label="always"
                  prepend-icon="mdi-opacity"
                  @change="the_set_style(style_obj)"
              ></v-slider>
              <v-subheader class="pl-0">
                Line Thickness
              </v-subheader>
              <v-slider
                  v-model="style_obj.weight"
                  min="0"
                  max="10"
                  color="red"
                  thumb-color="blue"
                  thumb-label="always"
                  prepend-icon="mdi-format-line-weight"
                  @change="the_set_style(style_obj)"
              ></v-slider>
            </div>
            <div v-if="feature_type === 'Line'">
              <v-autocomplete
                  v-model="style_obj.color"
                  :items="colors_list"
                  dense
                  chips
                  label="Line Color"
                  item-text="color_name"
                  item-value="color_val"
                  @change="the_set_style(style_obj)"
              >
                <template v-slot:selection="data">
                  <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                  >
                    <v-avatar left>
                      <v-btn icon>
                        <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                      </v-btn>
                    </v-avatar>
                    {{ data.item.color_name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-btn icon>
                      <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                    </v-btn>

                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-subheader class="pl-0">
                Line Opacity
              </v-subheader>
              <v-slider
                  v-model="style_obj.opacity"
                  min="0"
                  max="1"
                  step="0.01"
                  color="red"
                  thumb-color="blue"
                  thumb-label="always"
                  prepend-icon="mdi-opacity"
                  @change="the_set_style(style_obj)"
              ></v-slider>
              <v-subheader class="pl-0">
                Line Thickness
              </v-subheader>
              <v-slider
                  v-model="style_obj.weight"
                  min="0"
                  max="10"
                  color="red"
                  thumb-color="blue"
                  thumb-label="always"
                  prepend-icon="mdi-format-line-weight"
                  @change="the_set_style(style_obj)"
              ></v-slider>
            </div>
            <div v-if="feature_type === 'Point'">

              <div v-if="style_obj.marker_type === 'marker'">
                <v-autocomplete
                    v-model="style_obj.icon_name"
                    :items="maki_icons_list"
                    dense
                    chips
                    label="Marker Icon"
                    item-text="icon_name"
                    item-value="icon_name"
                    @change="the_set_style(style_obj)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                    >
                      <v-icon style="margin-top: 5px;" size="25">{{ data.item.icon_svg.pathData[0].d }}</v-icon>
                      {{ data.item.icon_name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-icon size="30">{{ data.item.icon_svg.pathData[0].d }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.icon_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-select
                    @change="the_set_style(style_obj)"
                    v-model="style_obj.icon_size"
                    :items="icon_size_items"
                    item-text="title"
                    item-value="value"
                    label="Icon Size"
                    :hide-details="true"
                ></v-select>

                <v-subheader class="pl-0">
                  Icon Opacity
                </v-subheader>
                <v-slider
                    v-model="style_obj.icon_opacity"
                    min="0"
                    max="1"
                    step="0.01"
                    color="red"
                    thumb-color="blue"
                    thumb-label="always"
                    prepend-icon="mdi-opacity"
                    @change="the_set_style(style_obj)"
                ></v-slider>

                <v-autocomplete
                    v-model="style_obj.color"
                    :items="colors_list"
                    dense
                    chips
                    label="Marker Color"
                    item-text="color_name"
                    item-value="color_val"
                    @change="the_set_style(style_obj)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                    >
                      <v-avatar left>
                        <v-btn icon>
                          <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                        </v-btn>
                      </v-avatar>
                      {{ data.item.color_name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-btn icon>
                        <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                      </v-btn>

                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>

              <div v-if="style_obj.marker_type === 'circleMarker'">
                <v-select
                    @change="the_set_style(style_obj)"
                    v-model="style_obj.radius"
                    :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 50]"
                    label="Radius"
                ></v-select>

                <v-autocomplete
                    v-if="style_obj.style_type === 'simple'"
                    v-model="style_obj.fillColor"
                    :items="colors_list"
                    dense
                    chips
                    label="Fill Color"
                    item-text="color_name"
                    item-value="color_val"
                    @change="the_set_style(style_obj)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                    >
                      <v-avatar left>
                        <v-btn icon>
                          <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                        </v-btn>
                      </v-avatar>
                      {{ data.item.color_name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-btn icon>
                        <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                </v-autocomplete>
                <v-subheader class="pl-0">
                  Fill Opacity
                </v-subheader>
                <v-slider
                    v-model="style_obj.fillOpacity"
                    min="0"
                    max="1"
                    step="0.01"
                    color="red"
                    thumb-color="blue"
                    thumb-label="always"
                    prepend-icon="mdi-opacity"
                    @change="the_set_style(style_obj)"
                ></v-slider>

                <v-autocomplete
                    v-model="style_obj.color"
                    :items="colors_list"
                    dense
                    chips
                    label="Line Color"
                    item-text="color_name"
                    item-value="color_val"
                    @change="the_set_style(style_obj)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                    >
                      <v-avatar left>
                        <v-btn icon>
                          <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                        </v-btn>
                      </v-avatar>
                      {{ data.item.color_name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-btn icon>
                        <v-icon :color="data.item.color_val">mdi-circle</v-icon>
                      </v-btn>

                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.color_name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>

                <v-subheader class="pl-0">
                  Line Thickness
                </v-subheader>
                <v-slider
                    v-model="style_obj.weight"
                    min="0"
                    max="10"
                    color="red"
                    thumb-color="blue"
                    thumb-label="always"
                    prepend-icon="mdi-format-line-weight"
                    @change="the_set_style(style_obj)"
                ></v-slider>


              </div>

            </div>


            <div v-if="db_style !== null">
              <v-divider style="margin-bottom: 10px; margin-top: 10px; background-color: darkorange;"></v-divider>
              <v-switch
                  v-if="style_selection_mode === 'New'"
                  v-model="save_new_style"
                  :hide-details="true"
                  label="Save style in qfDrive"
              ></v-switch>
              <v-switch
                  v-if="style_selection_mode === 'Select'"
                  v-model="update_selected_style"
                  :hide-details="true"
                  label="Save changes to the query"
              ></v-switch>

            </div>

            <div v-if="save_new_style || update_selected_style">
              <v-col style="max-width: 400px;">
                <v-text-field
                    outlined
                    dense
                    label="Style Name"
                    v-model="db_style.name"
                    :hide-details="true"
                    required
                ></v-text-field>
              </v-col>
              <v-col style="max-width: 400px;">
                <v-text-field
                    outlined
                    dense
                    label="Style Description"
                    v-model="db_style.config.description"
                    :hide-details="true"
                ></v-text-field>
              </v-col>
            </div>

          </div>

        </v-card-text>

        <v-card-actions v-if="style_obj !== null">
          <v-btn small text @click="cancel">Cancel</v-btn>
          <v-btn small dark color="purple"
                 :disabled="style_obj.style_rule.col_name === null && style_obj.style_type === 'graduated'"
                 @click="submit"
          >Submit
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>

</template>

<script>


import DriveColorRangeTable from "@/components/Drive/DriveColorRangeTable";
import {deepClone} from "@/services/generic";
import {colors_list} from "@/assets/data/qf_static_data";
import maki_icons from '@/assets/maki_icons/maki_icons.json'
import {templates} from "@/store";
import {get_qfDocument_type_as_dict} from "@/services/app_utils";
import {c_qfDocument, u_qfDocument} from "@/services/app_api";

export default {
  name: "MapsVectorLayerStyleDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},

    feature_type: {type: String},
    map_layer_style: {type: Object},
    submit_change_style: {type: Function},
    cancel_change_style: {type: Function},
    set_style: {type: Function},
    json_arrays: {type: Object},
    schema: {type: Object},
    style_id: {type: String, default: null}
  },
  components: {DriveColorRangeTable},
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },

      style_obj: deepClone(this.map_layer_style),
      style_obj_org: deepClone(this.map_layer_style),
      colors_list: colors_list,
      maki_icons_dict: maki_icons.iconGroups[0].svgs,
      maki_icons_list: [],
      icon_size_items: [
        {title: 'Small', value: 's'},
        {title: 'Medium', value: 'm'},
        {title: 'Large', value: 'l'},
      ],

      DriveColorRangeTable_show: false,
      cols_list: [],

      style_selection_mode: 'New',
      selected_style_id: null,
      db_styles_dict: {},
      db_styles_list: [],
      db_style: {},

      update_selected_style: false,
      save_new_style: false,

    }
  },
  methods: {
    async init() {

      let exclude_cols = ['row_uid', 'geometry']
      let col_uid_list = Object.keys(this.json_arrays).filter(e => !exclude_cols.includes(e))

      for (let col_uid of col_uid_list){
        let col_name = this.schema[col_uid].label
        this.cols_list.push(col_name)
      }

      await this.create_style_list()
      this.create_maki_icons_list()
    },
    async create_style_list() {

      let db_styles_dict = {}
      let db_styles_list = []

      let styles = await get_qfDocument_type_as_dict('style')

      for (let style_id in styles) {
        let style_obj = styles[style_id]
        if (style_obj.config.layer_type === 'vector') {
          if (style_obj.config.feature_type === this.feature_type) {
            db_styles_dict[style_id] = style_obj
            db_styles_list.push({
              id: style_id,
              name: style_obj.name,
              description: style_obj.config.description
            })
          }
        }


      }

      this.db_styles_dict = db_styles_dict
      this.db_styles_list = db_styles_list

      if (this.style_id === null) {
        this.db_style = deepClone(templates.qfDocument)
        this.db_style.type = 'style'
        this.db_style.config = deepClone(templates.qfDocument__config__templates.style)
      } else {
        if (this.style_id in this.db_styles_dict) {
          this.db_style = this.db_styles_dict[this.style_id]
        }
      }


    },

    on_change_style_selection_mode() {
      if (this.style_selection_mode === 'Select') {
        this.save_new_style = false
        this.selected_style_id = null
        this.style_obj = null
      } else {
        this.update_selected_style = false
        this.style_obj = deepClone(this.style_obj_org)
        this.the_set_style(this.style_obj)

      }

    },
    set_db_style() {
      console.log('in set_db_style')
      this.db_style = this.db_styles_dict[this.selected_style_id]
      this.style_obj = this.db_style.config.style;
      this.the_set_style(this.style_obj)
    },

    on_change_legend_column() {

      if (this.style_obj.style_type === 'graduated') {
        if (this.style_obj.style_rule.style_obj === null) {
          this.style_obj.style_rule.style_obj = {
            name: "Spectral",
            colors_list: [
              {
                "color": "#9e0142",
                "val": 100
              },
              {
                "color": "#f98e52",
                "val": 100
              },
              {
                "color": "#ffffbf",
                "val": 100
              },
              {
                "color": "#89d0a5",
                "val": 100
              },
              {
                "color": "#5e4fa2",
                "val": 100
              }
            ]
          }

          this.style_obj.fillOpacity = 1
          this.style_obj.weight = 0

        }

      }
    },
    on_set_color_range() {
      this.style_obj.style_rule.reverse_colors = false
    },
    reverse_colors() {
      let the_colors_list = deepClone(this.style_obj.style_rule.style_obj.colors_list)
      let colors_list = []
      for (let item of the_colors_list) {
        colors_list.push(deepClone(item.color))
      }
      for (let idx in colors_list.reverse()) {
        this.style_obj.style_rule.style_obj.colors_list[idx].color = colors_list[idx]
      }
      console.log(this.style_obj.style_rule.style_obj.colors_list)

    },
    the_set_style(the_style) {
      this.set_style(the_style)
    },
    create_maki_icons_list() {
      for (const [key, value] of Object.entries(this.maki_icons_dict)) {
        this.maki_icons_list.push({
          icon_name: key.replace('.svg', ''),
          icon_value: key,
          icon_svg: value
        })
      }
    },
    async submit() {

      if (this.style_selection_mode === 'New' && this.save_new_style) {
        this.db_style.config.style = this.style_obj
        this.db_style.config.layer_type = 'vector'
        this.db_style.config.feature_type = this.feature_type
        await c_qfDocument(this.db_style, null, true)
      }
      if (this.style_selection_mode === 'Select' && this.update_selected_style) {
        this.db_style.config.style = this.style_obj
        this.db_style.config.layer_type = 'vector'
        this.db_style.config.feature_type = this.feature_type
        await u_qfDocument(this.db_style, null, true)
      }

      this.$emit('update:map_layer_style', this.style_obj)
      this.$emit('update:show_dialog', false)
      this.submit_change_style()

    },
    async cancel() {
      this.the_set_style(this.style_obj_org)
      this.cancel_change_style()
      this.$emit('update:show_dialog', false)
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
