<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <span class="body-1">
            Color Picker
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <p></p>

        <v-col style="max-width: 100px;">
          <v-select
              label="No. of Colors"
              v-model="num_colors"
              :items="[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,17,18,19,20]"
              :hide-details="true"
              @change="init"
          />

        </v-col>

        <v-col>
          <v-row>
            <v-spacer></v-spacer>
            <v-text-field
                style="max-width: 300px;"
                background-color="white"
                v-model="search"
                append-icon="mdi-magnify"
                color="green"
                label="Search table rows"
                outlined
                dense
                single-line
                hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <p></p>

        <v-data-table
            style="background-color: darkgrey;"
            :headers="headers"
            :items="chroma_colors_list"
            class="elevation-1"
            :single-select="true"
            show-select
            v-model="selected"
            :search="search"
            item-key="name"
            :items-per-page="-1"
        >
          <template v-slot:item.colors="{ item }">
            <v-row style="max-height: 5px;">
              <v-col v-for="c in item.colors" :key="c" :style="{'background-color':c}"
                     style="max-width: 2px; min-width: 2px;"></v-col>
            </v-row>
          </template>
        </v-data-table>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="orange"
               :disabled="selected.length < 1"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import chroma from "chroma-js";
import {deepClone} from "@/services/generic";

export default {
  name: "DriveColorRangeTable",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
    style_obj: {type: Object},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800,
      },
      chroma_colors_list: [],
      headers: [{'text': 'Name', 'value': 'name'}, {'text': 'Colors', 'value': 'colors'}],
      selected: [],
      search: null,
      num_colors: 5,
    }
  },
  methods: {
    init() {

      let this_colors_list = []

      for (let color_name in chroma.brewer) {
        let n = color_name[0]
        if (n.toUpperCase() === n) {
          this_colors_list.push({
            name: color_name,
            colors: chroma.scale(chroma.brewer[color_name]).colors(this.num_colors),
            vals: [],
          })
        }

      }
      this.chroma_colors_list = this_colors_list
      console.log('🎨', this_colors_list)
    },

    async submit() {

      let org_style_obj = null
      if (this.style_obj !== null) {
        org_style_obj = deepClone(this.style_obj)
      }

      let style_obj = {
        name: this.selected[0].name,
        colors_list: []
      }

      let idx = -1
      let the_val
      for (let color of this.selected[0].colors) {
        idx += 1

        the_val = 100
        if (org_style_obj !== null) {
          if (idx <= org_style_obj.colors_list.length - 1)
            the_val = org_style_obj.colors_list[idx].val
        }

        style_obj.colors_list.push({
          color: color,
          val: the_val,
        })

      }


      this.$emit('update:style_obj', style_obj)
      if (this.callback_function !== null) {
        this.callback_function()
      }

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },

  },
  mounted() {

    if (this.style_obj !== null) {
      this.num_colors = this.style_obj.colors_list.length
    }
    this.init()
  },
}
</script>

<style scoped>

</style>

