<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <span class="body-1">
            Read SWMM Input File
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-file-input
              outlined
              accept=".inp"
              show-size
              :clearable="true"
              label="Select files"
              v-model="input_file"
              @change="on_file_select"
              :hide-details="true"
          ></v-file-input>
        </v-col>

        <v-btn @click="SwmmOptionsDialog_show=true">Options</v-btn>

        <SwmmOptionsDialog
            v-if="SwmmOptionsDialog_show"
            :show_dialog.sync="SwmmOptionsDialog_show"
            :callback_function="null"
        ></SwmmOptionsDialog>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small dark color="purple"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import SwmmOptionsDialog from "@/components/SWMM/SwmmOptionsDialog";

import {readFileAsync} from "@/services/generic";

export default {
  name: "SwmmReadInputDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
  },
  components: {
    SwmmOptionsDialog,
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },

      input_file: null,

      SwmmOptionsDialog_show: false,

    }
  },
  methods: {

    async check_header(line) {
      if (line.includes('[') && line.includes([']']) && !line.includes([';;'])) {
        return true
      } else {
        return false
      }
    },
    async check_comment(line) {
      if (line.includes(';')) {
        return true
      } else {
        return false
      }
    },

    async on_file_select() {
      // https://github.com/superchen14/swmm-online/tree/master/src
      console.log(this.input_file)

      let inp = await readFileAsync(this.input_file, 'text')
      let lines = inp.split(/\r\n|\n/);
      let network = {}
      let header
      for (let line of lines) {
        if (line.trim().length > 0) {
          if (await this.check_header(line)) {
            header = line.replace('[', '').replace(']', '')
            network[header] = {
              'comments': [],
              'commands': []
            }
          } else if (await this.check_comment(line)) {
            if (!line.includes('\t')) {
              network[header].comments.push(line.replaceAll(';', ''))
            }
          } else {
            let command_list = []

            for (let command of line.split('\t')) {
              command_list.push(command.trim())
            }
            network[header].commands.push(command_list)
          }
        }
      }
      console.log(network)

    },


    async submit() {

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
