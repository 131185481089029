<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="is_loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <span class="body-1">
            Create New MEWA Water Use Task
          </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon :disabled="is_loading">
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-col style="max-width: 400px;">
          <v-text-field
              outlined
              dense
              v-model="gee_task.name"
              label="Task Name (optional)"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-textarea
              outlined
              dense
              rows="3"
              clearable
              auto-grow
              v-model="gee_task.description"
              label="Description (optional)"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-select
              label="Analysis Type"
              v-model="gee_task.task_type"
              :items="task_types"
              item-text="title"
              item-value="val"
              :hide-details="true"
          ></v-select>
        </v-col>

        <v-col style="max-width: 400px; background-color: lightcyan;" v-if="gee_task.task_type === 'export_asfields'">

          <v-col style="max-width: 400px;">
            <v-select
                label="Field boundary type"
                v-model="fields_boundary_type"
                :items="['ROI', 'Layer']"
                :hide-details="true"
            ></v-select>
          </v-col>

          <v-col style="max-width: 400px;" v-if="fields_boundary_type === 'ROI'">
            <p>Select a ROI layer to clip the field boundary layer.<br>
              <b>ROI must be a single polygon feature</b>
            </p>

            <v-autocomplete
                v-model="fields_roi_layer_id"
                :items="input_layers_list"
                dense
                small-chips
                clearable
                label="Select Fields ROI Layer"
                item-text="layer_name"
                item-value="layer_id"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>

          <v-col style="max-width: 400px;" v-if="fields_boundary_type === 'ROI'">
            <v-text-field
                outlined
                dense
                v-model="fields_clip_layer_name"
                label="Fields Clip Layer Name"
                :hide-details="true"
            ></v-text-field>
          </v-col>

          <v-col style="max-width: 400px;">
            <v-autocomplete
                v-model="fields_bnd_layer_id"
                :items="input_layers_list"
                dense
                small-chips
                clearable
                label="Select Field Boundary Layer"
                item-text="layer_name"
                item-value="layer_id"
                :hide-details="true"
            ></v-autocomplete>
          </v-col>


        </v-col>

        <v-col style="max-width: 400px; background-color: lightpink;" v-if="gee_task.task_type === 'export_asgrid'">

          <p>Select a Regional ROI layer for summarizing regional water use data.<br>
            <b>ROI must be a single polygon feature</b>
          </p>

          <v-autocomplete
              v-model="grids_roi_layer_id"
              :items="input_layers_list"
              dense
              small-chips
              clearable
              label="Select Regional ROI Layer"
              item-text="layer_name"
              item-value="layer_id"
              :hide-details="true"
          ></v-autocomplete>
        </v-col>

        <v-menu
            v-model="show_start_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="gee_task.start_date"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="gee_task.start_date"
              @input="show_start_date_menu=false"
              @change="set_start_end_dates"
          ></v-date-picker>
        </v-menu>

        <v-menu
            v-model="show_end_date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="gee_task.end_date"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="gee_task.end_date"
              @input="show_end_date_menu=false"
              @change="set_start_end_dates"
          ></v-date-picker>
        </v-menu>

      </v-card-text>
      <v-card-actions>
        <v-btn small text :disabled="is_loading" @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" :disabled="is_loading" class="white--text"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import {DateTime} from "luxon";
import {v4 as uuidv4} from "uuid";
import {c_task} from "@/components/MEWA/services/mewa_api";
import {c_qfDocument_vector_layer, get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";
import {json_arrays_to_full_geojson, json_arrays_to_geojson} from "@/services/json_arrays_api";
import {deepClone} from "@/services/generic";
import {crop_polygons} from "@/services/geoprocessing_api";

export default {
  name: "MewaGeeNewTaskDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 500
      },
      is_loading: false,

      task_types: [
        {val: 'export_asfields', title: 'Fields'},
        {val: 'export_asgrid', title: 'Regional'},
      ],

      fields_boundary_type: 'Layer',
      grids_roi_layer_id: null,
      fields_roi_layer_id: null,
      fields_bnd_layer_id: null,
      fields_clip_layer_name: null,

      layers: {},
      input_layers_list: [],

      show_start_date_menu: false,
      show_end_date_menu: false,

      gee_task: {
        id: uuidv4().replaceAll('-', ''),
        version: 0,
        uid: null,

        task_type: 'export_asfields',
        name: '',
        description: '',

        boundary_layer_id: null,
        boundary_geojson: null,

        start_date: null,
        end_date: null,

        status: 'db_init', // db_init, gee_submitted, gee_in_progress, gee_done, gee_bucket_transfer, fb_bucket_transfer
        datetime: {
          created: DateTime.now().setZone('utc').toISO(),
          completed: '',
        },
        gee_dict: {},
      },

    }
  },
  methods: {

    async set_start_end_dates() {

      if (this.gee_task.start_date !== null) {
        if (this.gee_task.end_date === null) {
          this.gee_task.end_date = deepClone(this.gee_task.start_date)
        }
      }
      if (this.gee_task.end_date !== null) {
        if (this.gee_task.start_date === null) {
          this.gee_task.start_date = deepClone(this.gee_task.end_date)
        }
      }

    },


    async submit() {
      this.is_loading = true

      if (this.gee_task.task_type === 'export_asgrid') {

        let grids_roi_layer = this.layers[this.grids_roi_layer_id]
        let grids_roi_schema = grids_roi_layer.config.schema
        let grids_roi_json_arrays = await idb_unzip_blob(this.grids_roi_layer_id)
        let grids_roi_geojson = await json_arrays_to_geojson(grids_roi_json_arrays, grids_roi_schema)

        this.gee_task.boundary_layer_id = this.grids_roi_layer_id
        this.gee_task.boundary_geojson = grids_roi_geojson

      } else {

        if (this.fields_boundary_type === 'ROI') {

          let fields_roi_layer = this.layers[this.fields_roi_layer_id]
          let fields_roi_schema = fields_roi_layer.config.schema
          let fields_roi_json_arrays = await idb_unzip_blob(this.fields_roi_layer_id)
          let fields_roi_geojson = await json_arrays_to_geojson(fields_roi_json_arrays, fields_roi_schema)

          let fields_bnd_layer = this.layers[this.fields_bnd_layer_id]

          let fields_bnd_schema = fields_bnd_layer.config.schema
          let fields_bnd_json_arrays = await idb_unzip_blob(this.fields_bnd_layer_id)

          let fields_layer_json_arrays = await crop_polygons(fields_roi_geojson.features[0], fields_bnd_json_arrays)
          fields_bnd_json_arrays = undefined

          let fields_layer_geojson = await json_arrays_to_full_geojson(fields_layer_json_arrays, fields_bnd_schema)

          // store and save the layer in the system
          let fields_layer_obj = fields_bnd_layer

          let out = await c_qfDocument_vector_layer(
              this.fields_clip_layer_name,
              fields_layer_obj.config.feature_type,
              fields_layer_json_arrays,
              null,
              true,
              fields_layer_obj.config)

          this.gee_task.boundary_layer_id = out.layer_id
          this.gee_task.boundary_geojson = fields_layer_geojson

        } else {
          let fields_layer_layer = this.layers[this.fields_bnd_layer_id]
          let fields_layer_schema = fields_layer_layer.config.schema
          let fields_layer_json_arrays = await idb_unzip_blob(this.fields_bnd_layer_id)
          let fields_layer_geojson = await json_arrays_to_full_geojson(fields_layer_json_arrays, fields_layer_schema)

          this.gee_task.boundary_layer_id = this.fields_bnd_layer_id
          this.gee_task.boundary_geojson = fields_layer_geojson
        }

      }


      // set this_task_name and next_task_name based on task_type

      await c_task(this.gee_task)
      this.is_loading = false
      this.cancel()

      if (this.callback_function !== null) {
        this.callback_function()
      }


    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init() {

      this.layers = await get_qfDocument_type_as_dict('layer')
      this.input_layers_list = []

      for (let layer_id in this.layers) {
        let layer = this.layers[layer_id]
        if (layer.config.layer_type === 'vector') {
          this.input_layers_list.push({
            layer_id: layer.id,
            layer_name: layer.name,
          })
        }

      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
