import JSZip from "jszip";
import {check_user_files} from "@/services/user_api";
import {async_storage_upload} from "@/services/fb_storage_api";
import axios from "axios";
import {area, centroid, truncate} from "@turf/turf";
import {idb_r} from "@/services/idb_api";
import tokml from "tokml";
import {parse} from "json2csv";
import {deepClone, readFileAsync} from "@/services/generic";
import toGeoJSON from "@mapbox/togeojson";
import {v4 as uuidv4} from "uuid";
import shp from 'shpjs/dist/shp'
import {store, templates} from "@/store";
import {geoJSON_type_mapping} from "@/assets/data/qf_static_data";

import {
    filter_json_arrays_for_row_uids,
    json_arrays_to_geojson,
    remove_row_ids_from_json_arrays
} from "@/services/json_arrays_api";

export async function geojson_to_zip_blob(geojson) {

    let zip = new JSZip()
    zip.file('file.geojson', JSON.stringify(geojson, null, 0))

    let zip_promise = zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
            level: 9
        }
    }).then(async (zipBlob) => {
        return zipBlob
    })

    return await zip_promise
}

export async function save_as_zip_file(zip, zip_filename) {
    let zip_promise = zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
            level: 9
        }
    }).then(async (zipBlob) => {
        return zipBlob
    })

    let blob = await zip_promise
    let hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(blob);
    hiddenElement.target = '_blank';
    hiddenElement.download = zip_filename;
    hiddenElement.click();
}

export async function zip_pack_geojson_list(geojson_list, zip_filename) {
    // geojson_list = [{name: 'abc', geojson: '...'}]
    let zip = new JSZip()
    for (let geojson_item of geojson_list) {
        zip.file(geojson_item.name, JSON.stringify(geojson_item.geojson, null, 0))
    }
    await save_as_zip_file(zip, zip_filename)
}

export async function zip_pack_files_list(files_list, zip_filename) {
    let zip = new JSZip()
    for (let file_item of files_list) {
        zip.file(file_item.name, file_item.file)
    }
    await save_as_zip_file(zip, zip_filename)
}


export async function zip_pack_csv_list(csv_list, zip_filename) {
    let zip = new JSZip()
    for (let csv_item of csv_list) {
        zip.file(csv_item.name, parse(csv_item.json_list))
    }
    await save_as_zip_file(zip, zip_filename)
}


export async function check_storage_allocation(file_size) {
    let files_dict = {
        num: 0,
        size: 0,
        num_limit: store.state.cur_plan.limits.num_files,
        size_limit: store.state.cur_plan.limits.total_size,
    }
    files_dict = await check_user_files()
    files_dict.num += 1
    files_dict.size += file_size
    if (files_dict.num < files_dict.num_limit && files_dict.size < files_dict.size_limit) {
        return true
    } else {
        return false
    }
}

export function save_as_geojson_file(data, file_name) {
    let json = JSON.stringify(data, null, 4);
    let blob = new Blob([json], {type: "application/json"});

    let hiddenElement = document.createElement('a');
    hiddenElement.href = URL.createObjectURL(blob);
    hiddenElement.target = '_blank';
    hiddenElement.download = file_name;
    hiddenElement.click();
}

export function save_as_kml_file(data, file_name) {
    let json = JSON.parse(JSON.stringify(data, null, 4));
    let kml = tokml(json, {
        documentName: file_name,
        documentDescription: "Created with QuestFeed GIS app (qfGIS.com)"
    })
    const kml_url = encodeURI(kml)
    let hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/kml;charset=utf-8,${kml_url}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = file_name;
    hiddenElement.click();


}

export async function file_to_geojson(file_item) {
    const file_type = file_item.type
    let file_name = file_item.name

    let file_content
    if (file_type === 'application/vnd.google-earth.kml+xml' || file_name.includes('.kml')) {
        let parser = new DOMParser();
        file_content = await readFileAsync(file_item, 'text')
        return toGeoJSON.kml(parser.parseFromString(file_content, "text/xml"))
    } else if (file_name.includes('.geojson') || file_name.includes('.json')) {
        file_content = await readFileAsync(file_item, 'text')
        return JSON.parse(file_content)
    } else if (file_name.includes('.zip')) {
        let buffer = await readFileAsync(file_item, 'array_buffer')
        return await shp(buffer)
    }
}

export async function add_fid_to_geojson(geojson) {
    let __id = 0
    for (let feature of geojson.features) {
        __id += 1
        feature.properties.row_uid = uuidv4().replaceAll('-', '')
        feature.properties.__id = __id
    }
    return geojson
}


export async function add_heatmap(json_arrays, layer_id, z_index) {
    let heatmap_layer_id = `${layer_id}__heatmap`

    if (store.state.map.getPane(heatmap_layer_id) === undefined) {
        store.state.map.createPane(heatmap_layer_id);
    }
    store.state.map.getPane(heatmap_layer_id).style.zIndex = z_index

    let heat_arr = []

    for (let idx in json_arrays.row_uid) {
        let geometry = json_arrays.geometry[idx]
        heat_arr.push(store.state.L.GeoJSON.coordsToLatLng(geometry.coordinates))
    }

    let heatmap_fg = store.state.L.heatLayer(heat_arr)

    heatmap_fg.options.pane = heatmap_layer_id
    return heatmap_fg
}


export async function c_edit_layer(json_arrays, uid_list, layer_id, feature_style) {
    console.log('🍎')
    json_arrays = deepClone(json_arrays)
    let fg = new store.state.L.FeatureGroup()

    for (let row_uid of uid_list){
        let idx = json_arrays.row_uid.indexOf(row_uid)
        let geometry = json_arrays.geometry[idx]
        fg = await add_feature_to_FeatureGroup(fg, geometry, row_uid, layer_id, feature_style)
    }

    fg.options.pane = layer_id

    return fg
}

export async function set_geojson_precision(geojson, precision) {
    return await truncate(geojson, {precision: precision, coordinates: 2, mutate: true})
}


export async function json_arrays_to_featureGroup(json_arrays, feature_type, style, layer_id, z_index,
                                         is_vec = false,
                                         remove_fid_list = [],
                                         filter_fid_list = [],
) {

    json_arrays = deepClone(json_arrays)

    if (filter_fid_list.length > 0) {
        json_arrays = await filter_json_arrays_for_row_uids(json_arrays, filter_fid_list)
    }

    if (remove_fid_list.length > 0) {
        json_arrays = await remove_row_ids_from_json_arrays(json_arrays, remove_fid_list)
    }

    if (store.state.map.getPane(layer_id) === undefined) {
        store.state.map.createPane(layer_id);
    }
    store.state.map.getPane(layer_id).style.zIndex = z_index
    let geojson = await json_arrays_to_geojson(json_arrays)
    let bbox = store.state.L.geoJSON(geojson).getBounds()

    let fg_type = null // markerClusterGroup, vectorGrid, FeatureGroup
    let fg
    if (feature_type === 'Point') {
        if (style.cluster.is_cluster) {
            fg = new store.state.L.markerClusterGroup()
            fg_type = 'markerClusterGroup'
            let icon = store.state.L.MakiMarkers.icon({
                icon: style.icon_name,
                color: style.color,
                size: style.icon_size
            })
            let feature_layer
            for (let idx in json_arrays.row_uid) {
                let row_uid = json_arrays.row_uid[idx]
                let geometry = json_arrays.geometry[idx]

                if (style.marker_type === 'circleMarker') {
                    feature_layer = store.state.L.circleMarker(store.state.L.GeoJSON.coordsToLatLng(geometry.coordinates), {
                        pane: layer_id,
                        weight: style.weight,
                        opacity: style.opacity,
                        fillOpacity: style.fillOpacity,
                        color: style.color,
                        fillColor: style.fillColor
                    })
                    feature_layer.setRadius(style.radius)
                    feature_layer.row_uid = row_uid
                    fg.addLayer(feature_layer)
                } else {
                    feature_layer = store.state.L.marker(store.state.L.GeoJSON.coordsToLatLng(geometry.coordinates), {pane: layer_id})
                    feature_layer.setIcon(icon)
                    feature_layer.setOpacity(style.icon_opacity)
                    feature_layer.row_uid = row_uid
                    fg.addLayer(feature_layer)
                }
            }
        }
    }

    if (fg_type === null) {

        if (json_arrays.row_uid.length < 1000) {
            is_vec = false
        }

        if (is_vec) {
            fg_type = 'vectorGrid'

            fg = store.state.L.vectorGrid.slicer(geojson, {
                maxZoom: 24,
                buffer: 256,
                tolerance: 5, // 3
                rendererFactory: store.state.L.svg.tile,
                interactive: true,
                getFeatureId: function (f) {
                    return f.properties.row_uid;
                },
                // getFeatureId: function (feature) {
                //     return feature.properties["row_uid"]
                // }
                // vectorTileLayerStyles: {
                //     sliced: {radius: 15}
                // },
            })
            /*
            var tileIndex = geojsonvt(data, {
            maxZoom: 14,  // max zoom to preserve detail on; can't be higher than 24
            tolerance: 3, // simplification tolerance (higher means simpler)
            extent: 4096, // tile extent (both width and height)
            buffer: 64,   // tile buffer on each side
            debug: 0,     // logging level (0 to disable, 1 or 2)
            lineMetrics: false, // whether to enable line metrics tracking for LineString/MultiLineString features
            promoteId: null,    // name of a feature property to promote to feature.id. Cannot be used with `generateId`
            generateId: false,  // whether to generate feature ids. Cannot be used with `promoteId`
            indexMaxZoom: 5,       // max zoom in the initial tile index
            indexMaxPoints: 100000 // max number of points per tile in the index
            });
            * */
        } else {
            fg_type = 'FeatureGroup'
            fg = new store.state.L.FeatureGroup()
            for (let idx in json_arrays.row_uid) {
                let row_uid = json_arrays.row_uid[idx]
                let geometry = json_arrays.geometry[idx]
                fg = await add_feature_to_FeatureGroup(fg, geometry, row_uid, layer_id, style)
            }
        }
    }

    fg.options.pane = layer_id

    return {fg, bbox, fg_type, geojson}

}


export async function add_feature_to_FeatureGroup(fg, geometry, row_uid, layer_id, style) {

    let feature_layer
    let icon = store.state.L.MakiMarkers.icon({
        icon: style.icon_name,
        color: style.color,
        size: style.icon_size
    })
    switch (geometry.type) {
        case 'Point':
            if (style.marker_type === 'circleMarker') {
                feature_layer = store.state.L.circleMarker(store.state.L.GeoJSON.coordsToLatLng(geometry.coordinates), {
                    pane: layer_id,
                    weight: style.weight,
                    opacity: style.opacity,
                    fillOpacity: style.fillOpacity,
                    color: style.color,
                    fillColor: style.fillColor
                })
                feature_layer.setRadius(style.radius)
                feature_layer.row_uid = row_uid
                fg.addLayer(feature_layer)
            } else {
                feature_layer = store.state.L.marker(store.state.L.GeoJSON.coordsToLatLng(geometry.coordinates), {pane: layer_id})
                feature_layer.setIcon(icon)
                feature_layer.setOpacity(style.icon_opacity)
                feature_layer.row_uid = row_uid
                fg.addLayer(feature_layer)
            }


            break;
        case 'MultiPoint':
            // create a separate marker for each case and give it the same id
            for (let coords_arr of geometry.coordinates) {
                feature_layer = store.state.L.marker(store.state.L.GeoJSON.coordsToLatLng(coords_arr), {pane: layer_id})
                feature_layer.setIcon(icon)
                feature_layer.setOpacity(style.icon_opacity)
                feature_layer.row_uid = row_uid
                fg.addLayer(feature_layer)
            }
            break;
        case 'LineString':
            feature_layer = store.state.L.polyline(store.state.L.GeoJSON.coordsToLatLngs(geometry.coordinates), {pane: layer_id})
            feature_layer.row_uid = row_uid
            fg.addLayer(feature_layer)
            break;
        case 'MultiLineString':
            feature_layer = store.state.L.polyline(store.state.L.GeoJSON.coordsToLatLngs(geometry.coordinates, 1), {pane: layer_id})
            feature_layer.row_uid = row_uid
            fg.addLayer(feature_layer)
            break;
        case 'Polygon':
            feature_layer = store.state.L.polygon(store.state.L.GeoJSON.coordsToLatLngs(geometry.coordinates, 1), {pane: layer_id})
            feature_layer.row_uid = row_uid
            fg.addLayer(feature_layer)
            break;
        case 'MultiPolygon': // TO MODIFY
            // create a separate polygon for each case and give it the same id
            for (let coords_arr of geometry.coordinates) {
                feature_layer = store.state.L.polygon(store.state.L.GeoJSON.coordsToLatLngs(coords_arr, 1), {pane: layer_id})
                feature_layer.row_uid = row_uid
                fg.addLayer(feature_layer)
            }
            break;
    }
    return fg
}


export async function file_to_gejson_for_qfDocument(file_item) {
    let geojson_list_out = []
    let geojson_in = await file_to_geojson(file_item)
    let geojson_list
    if (Array.isArray(geojson_in)) {
        for (let geojson_item of geojson_in) {
            geojson_item.fileName += '.shp'
        }
        geojson_list = geojson_in
    } else {
        geojson_in.fileName = file_item.name
        geojson_list = [
            geojson_in
        ]
    }

    for (let geojson_item of geojson_list) {
        let layers_dict = {}

        for (let f of geojson_item.features) {
            let f_type0 = f.geometry.type
            if (f_type0 in geoJSON_type_mapping) {
                let f_type1 = geoJSON_type_mapping[f_type0]
                if (!(f_type1 in layers_dict)) {
                    layers_dict[f_type1] = {
                        "type": "FeatureCollection",
                        "features": []
                    }
                }
                layers_dict[f_type1].features.push(f)
            }
        }

        for (let feature_type in layers_dict) {
            geojson_list_out.push({
                feature_type: feature_type,
                geojson: geojson_item,
                file_name: geojson_item.fileName
            })
        }
    }

    return geojson_list_out
}

export async function get_feature_pros(feature_geojson) {
    let feature_centroid = centroid(feature_geojson.geometry);
    let center_lon = feature_centroid.geometry.coordinates[0];
    let center_lat = feature_centroid.geometry.coordinates[1];
    let area_m2 = Math.floor(area(feature_geojson.geometry));
    return {center_lat, center_lon, area_m2}
}




/* VOIDS */
export async function VOID_async_upload_geojson(geojson, layer_id, check_allocation = null) {

    const uid = store.state.auth.user.uid
    let storage_path = `user_data/${uid}/layers/${layer_id}.zip`

    let zipBlob = await geojson_to_zip_blob(geojson)

    let upload_meta_data
    if (check_allocation) {
        let files_dict = {
            num: 0,
            size: 0,
            num_limit: store.state.cur_plan.limits.num_files,
            size_limit: store.state.cur_plan.limits.total_size,
        }
        files_dict = await check_user_files()
        files_dict.num += 1
        files_dict.size += zipBlob.size

        if (files_dict.num < files_dict.num_limit && files_dict.size < files_dict.size_limit) {
            upload_meta_data = await async_storage_upload(zipBlob, storage_path)
        } else {
            upload_meta_data = null
        }
    } else {
        upload_meta_data = await async_storage_upload(zipBlob, storage_path)
    }

    if (upload_meta_data !== null) {
        return {storage_path: storage_path, upload_meta_data: upload_meta_data, zip_blob: zipBlob}
    } else {
        return {storage_path: null, upload_meta_data: null, zip_blob: null}
    }

}

export async function VOID_idb_unzip_geojson(layer_id) {

    let rec = await idb_r(`${layer_id}__blob`)

    if (rec !== null) {
        let zip = await JSZip.loadAsync(rec.blob_obj)
        const jsonData = await zip.file('file.geojson').async("string");
        return JSON.parse(jsonData);
    } else {
        return null
    }

}

export async function VOID_get_zipped_json(url) {
    const resp = await axios.get(url, {type: 'application/zip', responseType: 'arraybuffer'})
    const zip = await JSZip.loadAsync(resp.data)
    const jsonData = await zip.file('file.geojson').async("string");
    return JSON.parse(jsonData);
}

export async function VOID_get_geojson(url) {
    const resp = await axios.get(url)
    return resp.data
}

export async function VOID_check_convert_kml(file_content, file_type, file_name) {
    if (file_type === 'application/vnd.google-earth.kml+xml' || file_name.includes('.kml')) {
        let parser = new DOMParser();
        return toGeoJSON.kml(parser.parseFromString(file_content, "text/xml"))
    } else {
        return JSON.parse(file_content)
    }
}

export async function VOID_vector_to_layer(file_item, project_id) {

    let geojson_in = await file_to_geojson(file_item)
    let geojson_list
    if (Array.isArray(geojson_in)) {
        for (let geojson_item of geojson_in) {
            geojson_item.fileName += '.shp'
        }

        geojson_list = geojson_in
    } else {
        geojson_in.fileName = file_item.name
        geojson_list = [
            geojson_in
        ]
    }

    for (let geojson of geojson_list) {
        let layers_dict = {}

        for (let f of geojson.features) {
            let f_type0 = f.geometry.type
            if (f_type0 in geoJSON_type_mapping) {
                let f_type1 = geoJSON_type_mapping[f_type0]
                if (!(f_type1 in layers_dict)) {
                    layers_dict[f_type1] = {
                        "type": "FeatureCollection",
                        "features": []
                    }
                }
                layers_dict[f_type1].features.push(f)
            }
        }

        for (let feature_type in layers_dict) {
            geojson = await add_fid_to_geojson(geojson)

            let file_name = geojson.fileName
            let layer_obj = deepClone(templates.layer)
            layer_obj.name = file_name
            layer_obj.feature_type = feature_type
            layer_obj.meta.file_name = file_name

            layer_obj.layer_type = 'vector'
            layer_obj.layer_format = 'GeoJSON'

            geojson = await set_geojson_precision(geojson, 6)
            // await c_layer(layer_obj, geojson, project_id, false)
        }
    }

}