import {initializeApp} from 'firebase/app';
import {getAnalytics} from "firebase/analytics";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getDatabase} from "firebase/database";
import {getStorage} from "firebase/storage";
import {getFunctions} from 'firebase/functions';

import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import {deepClone} from "@/services/generic";
import {store, templates} from "@/store";

let firebase_config = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    projectId: process.env.VUE_APP_projectId,
    databaseURL: process.env.VUE_APP_databaseURL,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
}

if (process.env.NODE_ENV === 'production') firebase_config.measurementId = process.env.VUE_APP_measurementId

const firebaseApp = initializeApp(firebase_config);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const database = getDatabase(firebaseApp);
const functions = getFunctions(firebaseApp);


// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// if (process.env.NODE_ENV !== 'production') {
//     self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

if (process.env.NODE_ENV === 'production') {

    const appCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider('6LcFF-UUAAAAAMAfDPWJ4tZYhg8zB2tjnSEcfZs4'),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
    });
    const analytics = getAnalytics(firebaseApp);
}


onAuthStateChanged(auth, (user) => {
    if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        store.state.auth.user = user
        store.state.auth.isLoggedIn = true
    } else {
        // User is signed out
        user = deepClone(templates.anonymous_user)
        store.state.auth.user = user
        store.state.auth.isLoggedIn = false
    }
});


export {
    auth,
    db,
    storage,
    database,
    functions,
    firebaseApp
}

