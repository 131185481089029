<template>
  <v-card
      id="plot_div"
      min-height="300">
  </v-card>
</template>

<script>

import {newPlot} from 'plotly.js-dist-min'

export default {
  name: "PlotsHistogram",
  props: {
    layout: {type: Object, default: null},
    plot_data: {type: Array},
  },
  data() {
    return {
      template_plot_data: [{
        x: [],
        y: [],
        color: 'green',
        width: 4,
      }]
    }
  },
  methods: {
    async do_plot() {
      let data = []
      for (let item of this.plot_data){
        let data_item = {
          type: 'histogram',
          name: item.name,
          marker: {
            color: item.color,
            width: item.width
          }
        }
        if (item.x !== null) data_item.x = item.x
        if (item.y !== null) data_item.y = item.y

        data.push(data_item)
      }

      let layout = this.layout
      if (layout === null) {
        layout = {
          // showlegend: false,
          autosize: true,
          // margin: {
          //   l: 50,
          //   r: 50,
          //   b: 50,
          //   t: 50,
          //   pad: 4
          // },
          xaxis: {
            showgrid: false,
            showline: false,
          },
          yaxis: {
            showgrid: false,
            showline: false,
          },
        }
      }

      newPlot('plot_div', data, layout);
    },
    init(){
      this.do_plot()
    },
  },
  watch: {
    plot_data(){
      this.do_plot()
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
