<template>
  <v-card
      v-if="layer_obj !== null && !show_blob_missing_msg"
      :loading="is_loading"
      style="margin-bottom: 5px;"
      elevation="2">
    <v-app-bar
        flat
        dense
        height="auto"
        color="white"
    >
      <v-checkbox dense style="font-size: 6px;"
                  v-model="map_layer_dict.config.show_layer"
                  :label="layer_obj.name"
                  hide-details
                  @change="on_click_layer_checkbox"
      >
        <template v-slot:label>
          <div class="text-caption">

            <v-icon v-if="is_filtered" small color="red" left>mdi-filter</v-icon>

            <v-chip
                v-if="layer_obj.config.feature_type === 'Polygon'"
                label small
                :color="`${map_layer_dict.style.fillColor}${Math.floor(map_layer_dict.style.fillOpacity*100)}`"
                style="padding: 0;"
            >
              <v-icon small :color="map_layer_dict.style.color">
                mdi-vector-polygon
              </v-icon>
            </v-chip>
            <v-icon small :color="map_layer_dict.style.color"
                    v-if="layer_obj.config.feature_type === 'Point' && map_layer_dict.style.marker_type === 'marker'">
              mdi-map-marker
            </v-icon>
            <v-icon small :color="map_layer_dict.style.color"
                    v-if="layer_obj.config.feature_type === 'Point' && map_layer_dict.style.marker_type === 'circleMarker'">
              mdi-circle
            </v-icon>
            <v-icon small :color="map_layer_dict.style.color" v-if="layer_obj.config.feature_type === 'Line'">
              mdi-vector-polyline
            </v-icon>
            {{ layer_obj.name }}
          </div>
        </template>
      </v-checkbox>

      <v-spacer></v-spacer>

      <v-btn
          icon
          small
          @click="switch_show_details"
      >
        <v-icon v-if="!map_layer_dict.config.show_details">mdi-chevron-down</v-icon>
        <v-icon v-if="map_layer_dict.config.show_details">mdi-chevron-up</v-icon>
      </v-btn>
    </v-app-bar>

    <div v-if="map_layer_dict.config.show_details">

      <v-container v-if="show_blob_missing_msg">
        <b class="body-1 red--text">This layer is missing 1</b><br>
        <p class="caption brown--text" style="line-height: 1.1em;">
          This layer is not available in the cloud. From the layer meta data
          it is stored in the following browser.
          Please login back to the same device and browser and upload
          the layer to the cloud to make it available everywhere<br>
        </p>
        <p class="caption orange--text" style="line-height: 1em;">
          Browser: {{ layer_obj.audit[0].device.browser_name }}<br>
          Browser version: {{ layer_obj.audit[0].device.browser_version }}<br>
          OS: {{ layer_obj.audit[0].device.os_name }}<br>
          Device Type: {{ layer_obj.audit[0].device.device_type }}<br>
        </p>
      </v-container>

      <v-container v-if="!show_blob_missing_msg">

        <MapsVectorLayerTools
            v-if="layer_tools_show_mode !== 'none' && !is_loading"
            :layer_tools_show_mode.syc="layer_tools_show_mode"
            :on_select_tool="on_select_tool"
            :is_blob_uploaded="layer_obj.docBlob.is_uploaded"
            :MapsVectorLayerBottomSheet_show.sync="MapsVectorLayerBottomSheet_show"
            :select_mode.sync="select_mode"
            :edit_draw_features_mode.sync="edit_draw_features_mode"
            :history.sync="history"
            :cur_history_idx.sync="cur_history_idx"
            :feature_type.sync="layer_obj.config.feature_type"
            :feature_style.sync="map_layer_dict.style"
            :selected_uid_list.sync="selected_uid_list"
        ></MapsVectorLayerTools>

        <v-col v-if="is_filtered">
          <v-btn small @click="remove_filter">
            <v-icon color="red" small left>mdi-filter-remove</v-icon>
            Remove Filter
          </v-btn>
        </v-col>

        <MapsConfirmDeleteLayerFromMapDialog
            v-if="MapsConfirmDeleteLayerFromMapDialog_show"
            :show_dialog.sync="MapsConfirmDeleteLayerFromMapDialog_show"
            :layer_name="layer_obj.name"
            :callback_function="submit_delete"
        ></MapsConfirmDeleteLayerFromMapDialog>

        <div v-if="selected_uid_list.length > 0" style="margin-top: 10px;">
          <v-divider></v-divider>
          <v-btn
              @click="clear_selected_features" small text
              style="text-transform: none; margin-bottom: 5px;">
            <v-icon left>mdi-select-remove</v-icon>
            Clear Selection
          </v-btn>
          <v-btn
              @click="invert_selected_features" small text
              style="text-transform: none; margin-bottom: 5px;">
            <v-icon left>mdi-select-inverse</v-icon>
            Invert Selection
          </v-btn>
        </div>

        <div
            style="margin-top: 5px;"
            v-if="$store.state.draw_edit_flag.layer_id === layer_obj.id && $store.state.draw_edit_flag.mode === 'draw'">

          <v-btn
              small
              color="red" dark
              @click="save_layer"
              style="text-transform: none;">
            <v-icon small left>mdi-stop-circle-outline</v-icon>
            Stop Draw Mode
          </v-btn>

          <div v-if="layer_obj.config.feature_type === 'Polygon'">
            <v-btn icon :color="'#800080'" title="Draw Polygon"
                   @click="start_draw_mode('Polygon')">
              <v-icon>mdi-vector-polygon</v-icon>
            </v-btn>
            <v-btn icon :color="'#0000ff'" title="Draw Rectangle"
                   @click="start_draw_mode('Rectangle')">
              <v-icon>mdi-vector-rectangle</v-icon>
            </v-btn>
            <v-btn icon :color="'#6495ed'" title="Draw Circle"
                   @click="start_draw_mode('Circle')">
              <v-icon>mdi-vector-circle</v-icon>
            </v-btn>
          </div>

        </div>

        <div v-if="show_select_edit_tools">

          <div v-if="select_mode">
            <v-divider style="margin-bottom: 5px; margin-top: 5px;"></v-divider>
            <label class="body-2">Select by shape</label><br>
            <v-btn-toggle
                style="margin-left: 0; padding-left: 0;"
                v-model="select_tool"
                @change="select_by_draw_shape"
                dense
                group
            >
              <v-btn
                  title="Select by drawing a polygon"
                  small
                  value="Polygon"
                  text
              >
                <v-icon>mdi-vector-polygon</v-icon>
              </v-btn>

              <v-btn
                  title="Select by drawing a rectangle"
                  small
                  value="Rectangle"
                  text
              >
                <v-icon>mdi-vector-rectangle</v-icon>
              </v-btn>

              <v-btn
                  title="Select by drawing a circle"
                  small
                  value="Circle"
                  text
              >
                <v-icon>mdi-vector-circle</v-icon>
              </v-btn>

            </v-btn-toggle>
            <!--            <div v-if="selected_uid_list.length > 0">-->
            <!--              <v-btn-->
            <!--                  @click="clear_selected_features" small text-->
            <!--                  style="text-transform: none; margin-bottom: 5px;">-->
            <!--                <v-icon left>mdi-select-remove</v-icon>-->
            <!--                Clear Selection-->
            <!--              </v-btn>-->
            <!--              <v-btn-->
            <!--                  @click="invert_selected_features" small text-->
            <!--                  style="text-transform: none; margin-bottom: 5px;">-->
            <!--                <v-icon left>mdi-select-inverse</v-icon>-->
            <!--                Invert Selection-->
            <!--              </v-btn>-->
            <!--            </div>-->
          </div>

          <div
              v-if="$store.state.draw_edit_flag.layer_id === layer_obj.id && $store.state.draw_edit_flag.mode === 'edit'"
              style="max-width: 200px;">
            <v-divider style="margin-bottom: 5px; margin-top: 5px;"></v-divider>

            <v-btn
                small
                color="red" dark
                @click="stop_edit_features_mode"
                style="text-transform: none; margin-bottom: 5px;">
              <v-icon small left>mdi-stop-circle-outline</v-icon>
              Stop Edit Mode
            </v-btn>

            <v-btn
                small
                color="red" dark
                v-if="edit_features_started"
                @click="stop_edit_features"
                style="text-transform: none; margin-bottom: 5px;">
              <v-icon small left>mdi-cancel</v-icon>
              Stop Editing Features
            </v-btn>
            <v-btn
                :disabled="selected_uid_list.length < 1 || edit_features_started"
                small
                color="teal lighten-3"
                v-if="active_edit_tool===null"
                @click="start_edit_features"
                style="text-transform: none; margin-bottom: 5px;">
              <v-icon small left>mdi-pencil</v-icon>
              Edit Selected Features
            </v-btn>

            <v-btn
                :disabled="selected_uid_list.length < 1"
                small
                color="red lighten-3"
                v-if="active_edit_tool===null"
                @click="delete_features"
                style="text-transform: none; margin-bottom: 5px;">
              <v-icon small left>mdi-delete</v-icon>
              Delete Selected Features
            </v-btn>

            <v-col v-if="layer_obj.config.feature_type === 'Polygon'" style="max-width: 300px; margin: 0;">
              <v-divider style="margin-bottom: 5px; margin-top: 5px;"></v-divider>

              <v-btn
                  :disabled="selected_uid_list.length < 2"
                  small
                  color="indigo lighten-3"
                  v-if="active_edit_tool===null"
                  @click="union_polygons"
                  style="text-transform: none; margin-bottom: 5px;">
                <v-icon small left>mdi-vector-union</v-icon>
                Union
              </v-btn>
              <div><span> </span></div>

              <v-btn
                  small
                  color="lime lighten-3"
                  v-if="active_edit_tool===null"
                  @click="split_polygons"
                  style="text-transform: none; margin-bottom: 5px;">
                <v-icon small left>mdi-format-page-split</v-icon>
                Split
              </v-btn>
              <div
                  v-if="active_edit_tool === 'split_polygons'"
                  class="body-2 blue--text" style="max-width: 500px; margin-top: 5px; margin-bottom: 10px;">
                Draw a line over polygons to split them. Double click to finish the line. Press cancel to cancel the
                operation.
              </div>
              <div><span> </span></div>
              <v-btn
                  small
                  color="red" dark
                  v-if="active_edit_tool === 'split_polygons'"
                  @click="cancel_split_polygons"
                  style="text-transform: none;">
                <v-icon small left>mdi-cancel</v-icon>
                Cancel Split
              </v-btn>

              <v-btn
                  small
                  color="amber lighten-3"
                  v-if="active_edit_tool===null"
                  @click="crop_polygons"
                  style="text-transform: none; margin-bottom: 5px;">
                <v-icon small left>mdi-crop</v-icon>
                Crop
              </v-btn>

              <div
                  v-if="active_edit_tool === 'crop_polygons'"
                  class="body-2 blue--text" style="max-width: 500px; margin-top: 5px; margin-bottom: 10px;">
                Draw a polygon over polygon features to crop them. Click on the starting point to finish the polygon.
                Press cancel to cancel the operation.
              </div>

              <v-btn
                  small
                  color="red" dark
                  v-if="active_edit_tool==='crop_polygons'"
                  @click="cancel_crop_polygons"
                  style="text-transform: none;">
                <v-icon small left>mdi-cancel</v-icon>
                Cancel Crop
              </v-btn>

              <v-btn
                  small
                  color="brown lighten-3"
                  v-if="active_edit_tool===null"
                  @click="cut_polygons"
                  style="text-transform: none; margin-bottom: 5px;">
                <v-icon small left>mdi-content-cut</v-icon>
                Cut & Erase
              </v-btn>

              <div
                  v-if="active_edit_tool==='cut_polygons'"
                  class="body-2 blue--text" style="max-width: 500px; margin-top: 5px; margin-bottom: 10px;">
                Draw a polygon over polygon features to cut them. Click on the starting point to finish the polygon.
                Press cancel to cancel the operation.
              </div>

              <v-btn
                  small
                  color="red" dark
                  v-if="active_edit_tool==='cut_polygons'"
                  @click="cancel_cut_polygons"
                  style="text-transform: none;">
                <v-icon small left>mdi-cancel</v-icon>
                Cancel Cut & Erase
              </v-btn>

              <v-btn
                  small
                  color="light-green lighten-3"
                  v-if="active_edit_tool===null"
                  @click="append_polygons"
                  style="text-transform: none; margin-bottom: 5px;">
                <v-icon small left>mdi-shape-polygon-plus</v-icon>
                Auto-complete
              </v-btn>

              <div
                  v-if="active_edit_tool==='append_polygons'"
                  class="body-2 blue--text" style="max-width: 500px; margin-top: 5px; margin-bottom: 10px;">
                Draw a polygon over polygon features to append to them. Click on the starting point to finish the
                polygon. Press cancel to cancel the operation.
              </div>

              <v-btn
                  small
                  color="red" dark
                  v-if="active_edit_tool==='append_polygons'"
                  @click="cancel_append_polygons"
                  style="text-transform: none;">
                <v-icon small left>mdi-cancel</v-icon>
                Cancel Auto-complete
              </v-btn>

            </v-col>

          </div>

        </div>

        <v-col v-if="map_layer_dict.style.style_type === 'graduated'">
          <span class="caption">Legend</span>
          <table>
            <tbody>
            <tr>
              <th></th>
              <th class="caption bold">{{ map_layer_dict.style.style_rule.col_name }}</th>
            </tr>
            <tr
                v-for="(item, idx) in map_layer_dict.style.style_rule.style_obj.colors_list"
                :key="item.color"
                style="max-height: 20px;"
            >
              <td style="vertical-align: middle; max-height: 20px; min-width: 30px;">
                <span
                    :style="{'background-color':item.color, float: 'left', height: '20px', width: '20px', clear: 'both'}"></span>
              </td>
              <td style="vertical-align: middle; max-height: 20px;">
                <span class="caption" style="padding-left: 5px;">
                  <span v-if="idx===0"> &ge; </span>
                  <span v-else> > </span>
                  {{ item.val }}
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </v-col>

      </v-container>

    </div>

    <DriveSchemaDialog
        v-if="DriveSchemaDialog_show"
        :show_dialog.sync="DriveSchemaDialog_show"
        :update_schema_callback="update_schema_callback"
        :schema="layer_obj.config.schema"
        :json_arrays="json_arrays"
        :idx="null"
    ></DriveSchemaDialog>

    <MapsVectorLayerBottomSheet
        v-if="MapsVectorLayerBottomSheet_show"
        :show_dialog.sync="MapsVectorLayerBottomSheet_show"
        :map_layer_dict.sync="map_layer_dict"
        :layer_obj.sync="layer_obj"
        :json_arrays.sync="json_arrays"
        :selected_uid_list.sync="selected_uid_list"
        :clear_selected_features="clear_selected_features"
        :invert_selected_features="invert_selected_features"
        :start_edit_table_mode="start_edit_table_mode"
        :highlight_selected_items="highlight_selected_items"
        :update_json_arrays_in_map="update_json_arrays_in_map"
        :table_data_is_editable.sync="table_data_is_editable"
        :MapsVectorLayerQueryDialog_show.sync="MapsVectorLayerQueryDialog_show"
        :DriveVectorLayerExportDialog_show.sync="DriveVectorLayerExportDialog_show"
        :set_style="set_style"
        :on_update_schema="on_update_schema"
        :submit_change_style="submit_change_style"
    ></MapsVectorLayerBottomSheet>

    <DriveAllocationWarning
        v-if="DriveAllocationWarning_show"
        :show_dialog.sync="DriveAllocationWarning_show"
    ></DriveAllocationWarning>

    <MapsVectorLayerQueryDialog
        v-if="MapsVectorLayerQueryDialog_show"
        :show_dialog.sync="MapsVectorLayerQueryDialog_show"
        :callback_function="apply_layer_query"
        :json_arrays="json_arrays"
        :schema="layer_obj.config.schema"
        :query_action.sync="filt_action"
    ></MapsVectorLayerQueryDialog>

    <MapsVectorLayerMergeTableDialog
        v-if="MapsVectorLayerMergeTableDialog_show"
        :show_dialog.sync="MapsVectorLayerMergeTableDialog_show"
        :update_current_layer_callback_function="MapsVectorLayerMergeTableDialog_update_current_layer_callback"
        :layer_obj="layer_obj"
        :layer_json_arrays="json_arrays"
        :layer_schema="layer_obj.config.schema"
        :map_id="map_id"
    ></MapsVectorLayerMergeTableDialog>

    <MapsVectorLayerLinkTableDialog
        v-if="MapsVectorLayerLinkTableDialog_show"
        :show_dialog.sync="MapsVectorLayerLinkTableDialog_show"
        :layer_obj="layer_obj"
        :layer_json_arrays="json_arrays"
        :layer_schema="layer_obj.config.schema"
        :map_layer_link.sync="map_layer_dict.link"
        :submit_change_link="submit_change_link"
    ></MapsVectorLayerLinkTableDialog>

    <MapsVectorLayerStyleDialog
        v-if="MapsVectorLayerStyleDialog_show"
        :show_dialog.sync="MapsVectorLayerStyleDialog_show"
        :callback_function="null"
        :feature_type.sync="layer_obj.config.feature_type"
        :map_layer_style.sync="map_layer_dict.style"
        :submit_change_style="submit_change_style"
        :cancel_change_style="cancel_change_style"
        :set_style="set_style"
        :json_arrays="json_arrays"
        :schema="layer_obj.config.schema"
    ></MapsVectorLayerStyleDialog>

    <DriveVectorLayerExportDialog
        v-if="DriveVectorLayerExportDialog_show"
        :show_dialog.sync="DriveVectorLayerExportDialog_show"
        :layer_id="layer_id"
        :selected_uid_list="selected_uid_list"
        :show_save_warning="true"
    ></DriveVectorLayerExportDialog>

    <DriveVectorLayerDialog
        v-if="DriveVectorLayerDialog_show"
        :show_dialog.sync="DriveVectorLayerDialog_show"
        :action="DriveVectorLayerDialog_action"
        :map_id="map_id"
        :layer_id="layer_id"
        :selected_uid_list="selected_uid_list"
        :refresh_map_function="layers_list_init_function"
        :refresh_qfdrive_function="null"
        :show_save_warning="true"
    ></DriveVectorLayerDialog>

  </v-card>
</template>

<script>

import LGeo from "leaflet-geodesy";
import {
  add_heatmap,
  c_edit_layer, json_arrays_to_featureGroup
} from '@/services/gis_utils';

import MapsVectorLayerTools from "@/components/Maps/MapsVectorLayerTools";
import MapsConfirmDeleteLayerFromMapDialog from "@/components/Maps/MapsConfirmDeleteLayerFromMapDialog";
import DriveVectorLayerDialog from "@/components/Drive/DriveVectorLayerDialog";
import DriveVectorLayerExportDialog from "@/components/Drive/DriveVectorLayerExportDialog";
import DriveAllocationWarning from "@/components/Drive/DriveAllocationWarning";
import MapsVectorLayerQueryDialog from "@/components/Maps/MapsVectorLayerQueryDialog";
import MapsVectorLayerStyleDialog from "@/components/Maps/MapsVectorLayerStyleDialog";
import MapsVectorLayerMergeTableDialog from "@/components/Maps/MapsVectorLayerMergeTableDialog";
import MapsVectorLayerLinkTableDialog from "@/components/Maps/MapsVectorLayerLinkTableDialog";
import DriveSchemaDialog from "@/components/Drive/DriveSchemaDialog";
import MapsVectorLayerPlots from "@/components/Maps/MapsVectorLayerPlots";
import MapsVectorLayerBottomSheet from "@/components/Maps/MapsVectorLayerBottomSheet";
import {deepClone} from "@/services/generic";


import {
  file_to_zip_blob,
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
  idb_unzip_blob,
} from "@/services/app_utils";
import {
  d_qfDocument,
  d_qfDocument_from_qfCollection,
  u_qfDocument
} from "@/services/app_api";

import {
  add_row_to_json_arrays,
  col_name_2_col_uid_mapper,
  get_rec_for_row_uid,
  remove_row_ids_from_json_arrays
} from "@/services/json_arrays_api";
import {
  append_polygons,
  crop_polygons,
  cut_polygons, select_by_geometry,
  split_polygons,
  union_polygons
} from "@/services/geoprocessing_api";
import {idb_c, idb_u_qfCollections_history, idb_u_qfDocuments_history} from "@/services/idb_api";
import {get_utc_dt_iso} from "@/services/datetime_api";

export default {
  name: "MapsVectorLayer",
  components: {
    MapsVectorLayerTools,
    MapsConfirmDeleteLayerFromMapDialog,
    DriveVectorLayerDialog,
    DriveVectorLayerExportDialog,
    DriveAllocationWarning,
    MapsVectorLayerQueryDialog,
    MapsVectorLayerStyleDialog,
    MapsVectorLayerMergeTableDialog,
    MapsVectorLayerLinkTableDialog,
    DriveSchemaDialog,
    MapsVectorLayerPlots,
    MapsVectorLayerBottomSheet
  },
  props: {
    map_id: {type: String},
    layer_id: {type: String},
    z_index: {type: Number},
    layers_list_init_function: {type: Function},
    update_map_layer_dict: {type: Function},

  },
  data() {
    return {

      layer_obj: null,  // layer object
      map_layer_dict: null, // = maps[this.map_id].layers.dict[this.layer_id]
      json_arrays: [],
      col_name_2_col_uid: {},

      fg_obj: null, // feature group obj for leaflet map
      fg_type: null, // markerClusterGroup, vectorGrid, FeatureGroup
      bbox: null,
      heatmap_obj: null,
      fg_edit: null,

      MapsVectorLayerQueryDialog_show: false,
      filt_action: null,
      filtered_fid_list: [],
      is_filtered: false,

      MapsVectorLayerMergeTableDialog_show: false,
      MapsVectorLayerLinkTableDialog_show: false,

      MapsVectorLayerStyleDialog_show: false,
      MapsConfirmDeleteLayerFromMapDialog_show: false,
      layer_tools_show_mode: 'all', // all, none, top_only
      show_select_edit_tools: true,
      edit_draw_features_mode: false,

      DriveSchemaDialog_show: false,

      DriveAllocationWarning_show: false,
      show_blob_missing_msg: false,
      is_loading: false,

      DriveVectorLayerExportDialog_show: false,

      draw_feature_shape: 'Line',
      edit_mode: false,

      edit_features_started: false,

      active_edit_tool: null,

      select_tools: {
        select_polygon: null,
        select_rectangle: null,
        select_circle: null,
      },
      select_tool: null,

      select_mode: false,
      selected_uid_list: [],

      MapsVectorLayerBottomSheet_show: false,

      history: [],
      cur_history_idx: null,

      DriveVectorLayerDialog_show: false,
      DriveVectorLayerDialog_action: 'Edit',

      table_data_is_editable: false,


    }
  },
  methods: {

    // to organize
    async update_json_arrays_in_map(json_arrays) {
      this.json_arrays = json_arrays
      await this.json_arrays_to_fg()
    },
    async MapsVectorLayerMergeTableDialog_update_current_layer_callback(json_arrays, schema) {
      this.json_arrays = json_arrays
      this.layer_obj.config.schema = schema
      await this.json_arrays_to_fg()
    },
    // layer dialog
    async edit_layer() {
      this.DriveVectorLayerDialog_action = 'Edit'
      this.DriveVectorLayerDialog_show = true
    },
    async duplicate_layer() {
      this.DriveVectorLayerDialog_action = 'Duplicate'
      this.DriveVectorLayerDialog_show = true
    },
    async switch_marker_type() {
      this.is_loading = true
      if (this.map_layer_dict.style.marker_type === 'marker') {
        this.map_layer_dict.style.marker_type = 'circleMarker'
      } else {
        this.map_layer_dict.style.marker_type = 'marker'
      }
      await this.do_update_map_layer_dict(true)
      await this.init_function()
      this.is_loading = false

    },
    async switch_markerCluster() {
      this.is_loading = true
      this.map_layer_dict.style.cluster.is_cluster = !this.map_layer_dict.style.cluster.is_cluster
      await this.do_update_map_layer_dict(true)
      await this.init_function()
      this.is_loading = false

    },
    async select_by_draw_shape() {

      let shape = this.select_tool

      this.set_draw_off()

      this.$store.state.map.pm.enableDraw(shape);
      this.select_mode = false
      this.$store.state.map.on('pm:create', async (e) => {
        let selected_area_geojson
        if (shape === 'Circle') {
          selected_area_geojson = LGeo.circle(e.layer._latlng, e.layer.getRadius()).toGeoJSON()
        } else {
          selected_area_geojson = e.layer.toGeoJSON()
        }
        this.selected_uid_list = await select_by_geometry(this.json_arrays, selected_area_geojson, this.selected_uid_list)
        await this.highlight_selected_items()
        e.layer.removeFrom(this.$store.state.map)
        this.set_draw_off()
        this.select_tool = null
        this.select_mode = true

      })


    },

    // GEO PROCESSING TOOLS
    async union_polygons() {
      this.json_arrays = await union_polygons(this.json_arrays, this.selected_uid_list)
      this.selected_uid_list = []
      await this.json_arrays_to_fg()
      await this.highlight_selected_items()
    },
    async append_polygons() {
      this.select_mode = false
      this.on_select_mode_off()
      this.edit_draw_features_mode = true

      this.active_edit_tool = 'append_polygons'
      this.set_draw_off()
      this.$store.state.map.pm.enableDraw('Polygon');

      this.$store.state.map.on('pm:create', async (e) => {

        this.json_arrays = await append_polygons(e.layer.toGeoJSON(), this.json_arrays)
        e.layer.removeFrom(this.$store.state.map)
        this.cancel_append_polygons()

        await this.json_arrays_to_fg()
      })


    },
    cancel_append_polygons() {
      this.set_draw_off()
      this.active_edit_tool = null
      this.edit_draw_features_mode = false
    },
    async cut_polygons() {
      this.select_mode = false
      this.on_select_mode_off()
      this.edit_draw_features_mode = true

      this.active_edit_tool = 'cut_polygons'
      this.set_draw_off()
      this.$store.state.map.pm.enableDraw('Polygon');
      this.$store.state.map.on('pm:create', async (e) => {

        this.json_arrays = await cut_polygons(e.layer.toGeoJSON(), this.json_arrays)
        e.layer.removeFrom(this.$store.state.map)
        this.cancel_cut_polygons()
        await this.json_arrays_to_fg()
      })


    },
    cancel_cut_polygons() {
      this.set_draw_off()
      this.active_edit_tool = null
      this.edit_draw_features_mode = false
    },
    async crop_polygons() {
      this.select_mode = false
      this.on_select_mode_off()
      this.edit_draw_features_mode = true

      this.active_edit_tool = 'crop_polygons'
      this.set_draw_off()
      this.$store.state.map.pm.enableDraw('Polygon');
      this.$store.state.map.on('pm:create', async (e) => {
        this.json_arrays = await crop_polygons(e.layer.toGeoJSON(), this.json_arrays)
        e.layer.removeFrom(this.$store.state.map)
        this.cancel_crop_polygons()
        await this.json_arrays_to_fg()
      })

    },
    cancel_crop_polygons() {
      this.set_draw_off()
      this.active_edit_tool = null
      this.edit_draw_features_mode = false
    },
    async split_polygons() {
      this.select_mode = false
      this.on_select_mode_off()
      this.edit_draw_features_mode = true

      this.active_edit_tool = 'split_polygons'
      this.set_draw_off()
      this.$store.state.map.pm.enableDraw('Line');
      this.$store.state.map.on('pm:create', async (e) => {

        this.json_arrays = await split_polygons(e.layer.toGeoJSON(), this.json_arrays)
        e.layer.removeFrom(this.$store.state.map)
        this.cancel_split_polygons()
        await this.json_arrays_to_fg()

      })
    },
    cancel_split_polygons() {
      this.set_draw_off()
      this.active_edit_tool = null
      this.edit_draw_features_mode = false
    },

    // Tools
    async on_select_tool(action, row_uid = null) {
      switch (action) {
        case 'undo':
          await this.undo()
          break;
        case 'redo':
          await this.redo()
          break;
        case 'edit_layer_info':
          await this.edit_layer()
          break;
        case 'toggle_select_mode':
          this.toggle_select_mode()
          break;
        case 'start_draw_mode':
          this.layer_tools_show_mode = 'top_only' // all, none, top_only
          await this.start_draw_mode()
          break;
        case 'start_edit_features_mode':
          this.layer_tools_show_mode = 'top_only' // all, none, top_only
          this.start_edit_features_mode()
          break;
        case 'start_edit_table_mode':
          this.start_edit_table_mode()
          break;
        case 'edit_layer_schema':
          this.DriveSchemaDialog_show = true
          break;
        case 'change_style':
          this.layer_tools_show_mode = 'top_only' // all, none, top_only
          this.MapsVectorLayerStyleDialog_show = true;
          break;
        case 'switch_marker_type':
          await this.switch_marker_type()
          break;
        case 'switch_markerCluster':
          await this.switch_markerCluster()
          break;
        case 'switch_heatmap':
          await this.switch_heatmap()
          break;
        case 'delete':
          this.MapsConfirmDeleteLayerFromMapDialog_show = true;
          break;
        case 'zoom':
          this.zoom_to_layer()
          break;
        case 'upload_layer':
          await this.upload_layer()
          break;
        case 'save_layer':
          await this.save_layer()
          break;
        case 'show_bottom_sheet':
          this.MapsVectorLayerBottomSheet_show = true
          break;
        case 'hide_bottom_sheet':
          this.MapsVectorLayerBottomSheet_show = false
          break;
        case 'export_layer':
          this.DriveVectorLayerExportDialog_show = true;
          break;
        case 'duplicate_layer':
          await this.duplicate_layer()
          break;
        case 'query_layer':
          this.MapsVectorLayerQueryDialog_show = true;
          break;
        case 'merge_with_tables':
          this.MapsVectorLayerMergeTableDialog_show = true;
          break;
        case 'link_with_tables':
          this.MapsVectorLayerLinkTableDialog_show = true;
          break;
        default:
          break;
      }
    },

    // Schema
    async update_schema_callback(new_schema, json_arrays, layer_idx) {
      this.MapsVectorLayerBottomSheet_show = false
      this.set_off_click_feature(this.fg_obj)
      this.json_arrays = json_arrays
      this.layer_obj.config.schema = new_schema

    },


    // Select & Filter
    async apply_layer_query(query_id, query_fid_list, query_action, save_in_map = false) {
      if (query_fid_list.length > 0) {
        // if (save_in_map) {
        //   this.map_layer_dict.query_config.query_id = query_id
        //   this.map_layer_dict.query_config.query_action = query_action
        //   this.map_layer_dict.query_config.fid_list = query_fid_list
        //   this.map_layer_dict.query_config.is_applied = true
        //    // not applied. to apply this we need to save and reload map
        // }

        if (query_action === 'Select') {
          this.selected_uid_list = query_fid_list
          await this.highlight_selected_items()
        } else {
          this.filtered_fid_list = query_fid_list
          await this.init_function()
          this.filtered_fid_list = []
          this.is_filtered = true
        }
      }
      this.$forceUpdate()

    },
    async remove_filter() {
      this.filtered_fid_list = []
      this.is_filtered = false
      await this.init_function()
    },


    // Upload
    async upload_layer() {

      this.stop_draw_mode()
      await this.stop_edit_features_mode()

      this.is_loading = true
      await u_qfDocument(this.layer_obj, this.json_arrays, true)
      this.is_loading = false
    },
    async save_layer() {

      await this.stop_draw_mode()
      await this.stop_edit_features_mode()

      this.is_loading = true
      await u_qfDocument(this.layer_obj, this.json_arrays, this.layer_obj.docBlob.is_uploaded)
      this.is_loading = false
    },


    // Draw
    set_draw_off() {
      this.$store.state.map.pm.disableDraw(this.layer_obj.config.feature_type)
      this.$store.state.map.off('pm:drawstart')
      this.$store.state.map.off('pm:drawend')
      this.$store.state.map.off('pm:create')
    },
    async start_draw_mode(draw_feature_shape = null) {

      await this.stop_edit_features_mode()
      this.edit_draw_features_mode = true

      this.select_mode = false
      this.on_select_mode_off()

      this.$store.state.draw_edit_flag = {
        mode: 'draw',
        layer_id: this.layer_obj.id
      }
      this.set_draw_off()

      let style = this.map_layer_dict.style

      let draw_options = {
        snappable: true,
        snapDistance: 20,
        continueDrawing: true,
      }
      let feature_type = this.layer_obj.config.feature_type
      switch (feature_type) {
        case 'Point':

          if (this.map_layer_dict.style.marker_type === 'marker') {
            this.draw_feature_shape = 'Marker'
            draw_options.markerStyle = {
              icon: new this.$store.state.L.MakiMarkers.icon({
                icon: style.icon_name,
                color: style.color,
                size: style.icon_size
              }),
            }
          } else {
            this.draw_feature_shape = 'CircleMarker'
            draw_options.markerStyle = {
              radius: this.map_layer_dict.style.radius
            }
          }
          break;
        case 'Line':
          this.draw_feature_shape = 'Line'
          draw_options.pathOptions = {color: style.color}
          break;
        case 'Polygon':
          if (draw_feature_shape === null) {
            this.draw_feature_shape = 'Polygon'
          } else {
            this.draw_feature_shape = draw_feature_shape
          }
          draw_options.pathOptions = {color: style.color}
          break;
        default:
          break;
      }

      this.$store.state.map.pm.enableDraw(this.draw_feature_shape, draw_options);

      this.$store.state.map.on('pm:create', async (e) => {

        e.layer.removeFrom(this.$store.state.map)
        let feature = e.layer


        let feature_geoJson = e.layer.toGeoJSON()
        if (feature_geoJson.geometry.type === 'Point' && this.layer_obj.config.feature_type === 'Polygon') {
          feature_geoJson = LGeo.circle(feature._latlng, feature.getRadius(), this.map_layer_dict.style).toGeoJSON()
        }

        this.json_arrays = await add_row_to_json_arrays(this.json_arrays, feature_geoJson.geometry)
        await this.json_arrays_to_fg()

      });

    },
    stop_draw_mode() {
      this.set_draw_off()
      this.$store.state.draw_edit_flag = {
        mode: null,
        layer_id: null
      }
      this.layer_obj.config.current_state = null
      this.edit_draw_features_mode = false
    },

    // Edit
    async start_edit_table_mode() {
      await this.stop_edit_features_mode()
      this.table_data_is_editable = true
      this.MapsVectorLayerBottomSheet_show = true
    },
    start_edit_features_mode() {
      this.table_data_is_editable = false
      this.$store.state.draw_edit_flag = {
        mode: 'edit',
        layer_id: this.layer_obj.id
      }
      this.$store.state.map.pm.disableDraw(this.layer_obj.config.feature_type)
      this.$store.state.map.off('pm:drawstart')
      this.$store.state.map.off('pm:drawend')
      this.$store.state.map.off('pm:create')
      this.edit_mode = true
    },
    async stop_edit_features_mode() {
      await this.stop_draw_mode()
      this.$store.state.draw_edit_flag = {
        mode: null,
        layer_id: null
      }
      this.edit_mode = false

      if (this.fg_edit !== null) {
        await this.stop_edit_features()
      }
      this.active_edit_tool = null
      this.layer_tools_show_mode = 'all'
      this.edit_draw_features_mode = false
    },
    async start_edit_features() {

      this.edit_features_started = true
      this.edit_draw_features_mode = true

      this.fg_edit = await c_edit_layer(this.json_arrays,
          this.selected_uid_list, this.layer_id, this.map_layer_dict.style)

      let highlight_style = await this.get_highlight_style(this.map_layer_dict.style)
      this.fg_edit.setStyle(highlight_style)

      this.fg_edit.addTo(this.$store.state.map)
      this.set_on_click_feature(this.fg_edit)

      this.fg_edit.eachLayer((feature) => {
        feature.pm.enable()
      })

      this.fg_obj.removeFrom(this.$store.state.map)
      this.is_loading = true

      let gj_out = await json_arrays_to_featureGroup(this.json_arrays, this.layer_obj.config.feature_type,
          this.map_layer_dict.style, this.layer_id, this.z_index, true, this.selected_uid_list, [])

      this.fg_obj = gj_out.fg
      this.$store.state.map.getPane(this.layer_id).style.zIndex = this.z_index
      this.fg_obj.addTo(this.$store.state.map)
      this.set_style(this.map_layer_dict.style)
      this.set_on_click_feature(this.fg_obj)
      this.is_loading = false

      this.select_mode = false
      this.on_select_mode_off()
    },
    async stop_edit_features() {
      this.edit_features_started = false

      this.fg_edit.eachLayer((feature) => {
        feature.pm.disable()
        let idx = this.json_arrays.row_uid.indexOf(feature.row_uid)
        this.json_arrays.geometry[idx] = feature.toGeoJSON().geometry
      })

      this.selected_uid_list = []
      await this.json_arrays_to_fg()

      this.fg_edit.removeFrom(this.$store.state.map)
      this.fg_edit = null

      this.layer_tools_show_mode = 'top_only'
      this.edit_draw_features_mode = false
    },
    async after_update_layer_info(layer_obj) {
      this.layer_obj = layer_obj
    },

    // Delete
    async delete_features() {

      this.set_off_click_feature(this.fg_obj)

      if (this.fg_edit !== null) {
        this.fg_edit.removeFrom(this.$store.state.map)
        this.fg_edit = null
      }

      this.json_arrays = await remove_row_ids_from_json_arrays(this.json_arrays, this.selected_uid_list)
      this.selected_uid_list = []
      await this.json_arrays_to_fg()

      this.layer_tools_show_mode = 'all'

    },
    async submit_delete(permanent_delete = false) {

      this.remove_layer_from_map()
      if (permanent_delete) {
        await d_qfDocument(this.layer_id)
      } else {
        await d_qfDocument_from_qfCollection(this.map_id, this.layer_obj)
      }
      this.layers_list_init_function(this.map_id)
    },

    // Selection
    toggle_select_mode() {
      this.select_mode = !this.select_mode
      if (this.select_mode) {
        this.on_select_mode_on()
      } else {
        this.on_select_mode_off()
      }
    },
    on_select_mode_on() {
      this.set_on_click_feature(this.fg_obj)
    },
    on_select_mode_off() {
      this.clear_selected_features()
      this.set_off_click_feature(this.fg_obj)
    },
    clear_selected_features() {
      this.selected_uid_list = []
      this.highlight_selected_items()
    },
    invert_selected_features() {
      let inverted_selected_fid_list = []

      for (let row_uid of this.json_arrays.row_uid) {
        if (!this.selected_uid_list.includes(row_uid)) {
          inverted_selected_fid_list.push(row_uid)
        }
      }
      this.selected_uid_list = inverted_selected_fid_list
      this.highlight_selected_items()
    },

    // Table Links
    async submit_change_link() {

      await this.do_update_map_layer_dict(true)
    },

    // STYLE
    async submit_change_style() {

      this.is_loading = true
      this.set_style(this.map_layer_dict.style)
      this.layer_tools_show_mode = 'all' // all, none, top_only
      this.is_loading = false
      setTimeout(async () => {
        await this.do_update_map_layer_dict(true)
      }, 500)

    },
    cancel_change_style() {
      this.layer_tools_show_mode = 'all' // all, none, top_only
    },


    get_graduated_color(row_uid, style) {
      const getColor = (d, range_vals, range_colors, d_color = null) => {
        d = parseFloat(d)
        for (let i = 0; i < range_vals.length; i++) {
          if (d <= range_vals[i]) {
            d_color = range_colors[i];
            break;
          }
        }
        return d_color;
      }

      let range_vals = []
      let range_colors = []
      for (let style_item of style.style_rule.style_obj.colors_list) {
        range_vals.push(parseFloat(style_item.val))
        range_colors.push(style_item.color)
      }


      let d_color = deepClone(range_colors[range_colors.length - 1])
      let col_name = this.map_layer_dict.style.style_rule.col_name
      let col_uid = this.col_name_2_col_uid[col_name]
      let row_idx = this.json_arrays.row_uid.indexOf(row_uid)

      let d = this.json_arrays[col_uid][row_idx]

      return getColor(d, range_vals, range_colors, d_color)
    },
    get_graduated_style(row_uid, style) {
      style.fillColor = this.get_graduated_color(row_uid, style)
      return style
    },

    get_icon(row_uid, style) {

      if (style.style_type === 'simple') {
        return new this.$store.state.L.MakiMarkers.icon({
          icon: style.icon_name,
          color: style.color,
          size: style.icon_size
        })
      } else {
        return new this.$store.state.L.MakiMarkers.icon({
          icon: style.icon_name,
          color: this.get_graduated_color(row_uid, style),
          size: style.icon_size
        })
      }

    },
    style_function(row_uid, style) {
      let the_style = deepClone(style)
      if (the_style.style_type === 'simple') {
        return the_style
      } else {
        return this.get_graduated_style(row_uid, the_style)
      }
    },
    async on_update_schema() {
      this.col_name_2_col_uid = await col_name_2_col_uid_mapper(this.layer_obj.config.schema)
    },
    set_style(the_style) {

      the_style = deepClone(the_style)
      if (this.fg_type === 'vectorGrid') {
        for (let row_uid of this.json_arrays.row_uid) {
          let this_style = this.style_function(row_uid, the_style)
          if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
            this_style.icon = this.get_icon(row_uid, the_style)
          }
          this.fg_obj.setFeatureStyle(row_uid, this_style)
        }
      } else {
        this.fg_obj.eachLayer((feature) => {
          let row_uid = feature.row_uid
          if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
            feature.setIcon(this.get_icon(row_uid, the_style))
          } else {
            feature.setStyle(this.style_function(row_uid, the_style))
          }
        })
      }
    },

    async get_highlight_style(the_style) {

      let highlight_style = deepClone(the_style)
      highlight_style.color = "#b10d46"
      highlight_style.weight = 5
      highlight_style.opacity = 0.8
      if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
        highlight_style.icon = new this.$store.state.L.MakiMarkers.icon({
          icon: highlight_style.icon_name,
          color: highlight_style.color,
          size: highlight_style.icon_size
        })
      }
      return highlight_style
    },

    async highlight_selected_items() {

      this.set_style(this.map_layer_dict.style)

      let highlight_style = await this.get_highlight_style(this.map_layer_dict.style)
      if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
        highlight_style.icon = new this.$store.state.L.MakiMarkers.icon({
          icon: highlight_style.icon_name,
          color: highlight_style.color,
          size: highlight_style.icon_size
        })
      }
      for (let row_uid of this.selected_uid_list) {
        if (this.fg_type === 'vectorGrid') {
          if (this.map_layer_dict.style.style_type === 'simple') {
            this.fg_obj.setFeatureStyle(row_uid, highlight_style)
          } else {
            this.fg_obj.setFeatureStyle(row_uid, this.get_graduated_style(row_uid, highlight_style))
          }
        } else {
          this.fg_obj.eachLayer((feature) => {
            if (feature.row_uid === row_uid) {
              if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
                feature.setIcon(highlight_style.icon)
              } else {
                if (this.map_layer_dict.style.style_type === 'simple') {
                  feature.setStyle(highlight_style)
                } else {
                  feature.setStyle(this.get_graduated_style(row_uid, highlight_style))
                }
              }
            }
          })
        }
      }

      // check if we have identity feature
      if (this.$store.state.feature_info_drawer.layer_id === this.layer_id && this.$store.state.feature_info_drawer.show) {
        this.highlight_identity_item()
      }

    },

    highlight_identity_item() {

      let row_uid = this.$store.state.feature_info_drawer.row_uid

      let identity_style = deepClone(this.map_layer_dict.style)
      identity_style.color = "#e3c31f"
      identity_style.weight = 5
      identity_style.opacity = 0.8

      if (this.fg_type === 'vectorGrid') {
        if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
          identity_style.icon = this.get_icon(row_uid, identity_style)
        }
        this.fg_obj.setFeatureStyle(row_uid, identity_style)
      } else {
        this.fg_obj.eachLayer((feature) => {
          if (feature.row_uid === row_uid) {
            if (this.layer_obj.config.feature_type === 'Point' && this.map_layer_dict.style.marker_type === 'marker') {
              feature.setIcon(new this.$store.state.L.MakiMarkers.icon({
                icon: identity_style.icon_name,
                color: identity_style.color,
                size: identity_style.icon_size
              }))
            } else {
              feature.setStyle(identity_style)
            }
          }
        })
      }
    },

    set_on_click_feature() {
      if (this.fg_obj !== null) {
        this.fg_obj.on('click',
            async (e) => {

              let row_uid
              if ('row_uid' in e.layer) {
                row_uid = e.layer.row_uid
              } else {
                row_uid = e.layer.properties.row_uid
              }

              if (this.select_mode) {
                if (this.selected_uid_list.includes(row_uid)) {
                  this.selected_uid_list = this.selected_uid_list.filter(item => item !== row_uid)
                } else {
                  this.selected_uid_list.push(row_uid)
                }
              }

              if (this.$store.state.identify_flag) {
                this.$store.state.feature_info_drawer.show = true;
                this.$store.state.feature_info_drawer.row_uid = row_uid;
                this.$store.state.feature_info_drawer.rec = await get_rec_for_row_uid(this.json_arrays, row_uid, [])
                this.$store.state.feature_info_drawer.schema = this.layer_obj.config.schema
                this.$store.state.feature_info_drawer.feature_style = this.map_layer_dict.style
                this.$store.state.feature_info_drawer.feature_type = this.layer_obj.config.feature_type
                this.$store.state.feature_info_drawer.layer_name = this.layer_obj.name
                this.$store.state.feature_info_drawer.layer_id = this.layer_id

              }

              await this.highlight_selected_items()

            }
        )
      }

    },
    set_off_click_feature(fg_obj) {
      if (fg_obj !== null && fg_obj !== undefined) {
        fg_obj.off('click')
        if (this.$store.state.feature_info_drawer.layer_id === this.layer_id) {
          this.$store.state.feature_info_drawer = {
            show: false,
            row_uid: null,
            rec: {},
            schema: {},
            feature_style: {},
            feature_type: 'Polygon',
            layer_name: null,
            layer_id: null,
          }
          this.highlight_selected_items()
        }
      }

    },

    async switch_heatmap() {
      if (this.map_layer_dict.style.heatmap.has_heatmap) {
        this.heatmap_obj.removeFrom(this.$store.state.map)
        this.map_layer_dict.style.heatmap.has_heatmap = false
      } else {
        this.remove_layer_from_map()
        this.heatmap_obj.addTo(this.$store.state.map)
        this.add_layer_to_map()
        this.map_layer_dict.style.heatmap.has_heatmap = true
      }
    },

    // layer on-off
    async on_click_layer_checkbox() {
      if (!this.map_layer_dict.config.show_layer) {
        this.remove_layer_from_map()
      } else {
        this.add_layer_to_map()
      }
      await this.do_update_map_layer_dict()
    },
    async do_update_map_layer_dict(save_map = false) {
      await this.update_map_layer_dict(this.layer_id, this.map_layer_dict, save_map)
    },
    async switch_show_details() {
      this.map_layer_dict.config.show_details = !this.map_layer_dict.config.show_details
      await this.do_update_map_layer_dict()
    },
    zoom_to_layer() {
      if (this.bbox !== null) {
        if (this.bbox.isValid()) {
          this.$store.state.map.fitBounds(this.bbox, {paddingTopLeft: [200, 50]})
        }
      }
    },
    remove_layer_from_map() {
      if (this.fg_obj !== null) {
        this.fg_obj.removeFrom(this.$store.state.map)
      }
      if (this.heatmap_obj !== null) {
        this.heatmap_obj.removeFrom(this.$store.state.map)
      }
    },
    add_layer_to_map() {
      if (this.fg_obj !== null) {
        this.fg_obj.addTo(this.$store.state.map)
      }
    },


    // Undo-Redo
    async create_history(rev_type = null, rev_action = null, rev_comments = null) {
      // for layer editing only for now.
      // to add it for styles, schema, etc later, if users need it

      let iso_datetime = get_utc_dt_iso()
      let rec = {
        version: this.layer_obj.docBlob.version,
        rev_type: rev_type,
        rev_action: rev_action,
        rev_comments: rev_comments,
        blob_obj: await file_to_zip_blob(this.json_arrays, this.layer_obj.docBlob.type)
      }
      await idb_c(`${this.layer_id}__${iso_datetime}__history_blob`, rec, false)

      // await idb_u_qfDocuments_history(this.layer_id, rev_type, rev_action, rev_comments, this.layer_obj, null)
      // await idb_u_qfCollections_history(this.map_id, rev_type, rev_action, rev_comments, null)
      // this.history.push({
      //   type: rev_type,
      //   command: rev_command,
      //   comment: rev_comment,
      //   qfDocument: deepClone(qfDocument),
      //   qfBlob: deepClone(this.json_arrays)
      // })
      // need to save the history in the idb in two parts. history object and history blob
      // history_obj = [{history_id, type, command, comment, history_id, datetime, user_id}]
      // {layer_id}_history_list = []
      // {layer_id}_history_blob_{history_id} = zip_obj_of_geojson
      // then we show the history to the user and we remove the old history


    },
    async undo() {
      let hist_len = this.history.length
      if (this.cur_history_idx === null) {
        await this.create_history('undo_layer', 'undo', 'backup current layer')
        this.cur_history_idx = hist_len - 1
      } else {
        this.cur_history_idx -= 1
      }

      if (this.cur_history_idx > hist_len) {
        this.cur_history_idx = hist_len - 1
      }
      if (this.cur_history_idx < 0) {
        this.cur_history_idx = hist_len - 1
      }

      this.json_arrays = deepClone(this.history[this.cur_history_idx].json_arrays)
      await this.json_arrays_to_fg()

    },
    async redo() {
      let hist_len = this.history.length
      if (this.cur_history_idx === null) {
        this.cur_history_idx = hist_len - 1
      } else {
        this.cur_history_idx += 1
      }

      if (this.cur_history_idx >= hist_len) {
        this.cur_history_idx = hist_len - 1
      }

      this.json_arrays = deepClone(this.history[this.cur_history_idx].json_arrays)
      await this.json_arrays_to_fg()

    },


    // fg & geojson
    async reset_data() {

      this.selected_uid_list = []
      this.MapsVectorLayerBottomSheet_show = false

      if (this.fg_obj !== null) {
        this.set_off_click_feature(this.fg_obj)
        this.fg_obj.removeFrom(this.$store.state.map)
        this.fg_obj = null
      }
    },
    async json_arrays_to_fg() {

      this.is_loading = true

      await this.reset_data()

      let gj_out = await json_arrays_to_featureGroup(this.json_arrays, this.layer_obj.config.feature_type,
          this.map_layer_dict.style, this.layer_id, this.z_index, true, [], this.filtered_fid_list)

      if (this.layer_obj.config.feature_type === 'Point') {
        this.heatmap_obj = await add_heatmap(this.json_arrays, this.layer_id, this.z_index - 1)
        let heatmap_layer_id = `${this.layer_id}__heatmap`
        this.$store.state.map.getPane(heatmap_layer_id).style.zIndex = this.z_index - 1
        this.heatmap_obj.options.pane = heatmap_layer_id
      }

      this.bbox = gj_out.bbox
      this.fg_type = gj_out.fg_type

      if (this.fg_obj !== null) {
        this.remove_layer_from_map()
        this.fg_obj = null
      }

      this.fg_obj = gj_out.fg
      this.fg_obj.layer_id = this.layer_id

      this.$store.state.map.getPane(this.layer_id).style.zIndex = this.z_index

      if (this.map_layer_dict.config.show_layer) {
        if (this.layer_obj.config.feature_type === 'Point' && this.json_arrays.row_uid.length > 10000) {
          this.map_layer_dict.config.show_layer = false
        } else {
          this.fg_obj.addTo(this.$store.state.map)
        }
      }

      this.set_style(this.map_layer_dict.style)

      if (this.$store.state.identify_flag) {
        this.set_on_click_feature(this.fg_obj)
      }

      if (this.layer_obj.config.current_state === 'Create') {
        await this.start_draw_mode()
      }
      this.is_loading = false

    },

    // INIT
    async init_function() {
      let maps = await get_qfCollection_type_as_dict('map')
      let layers = await get_qfDocument_type_as_dict('layer')

      if (this.layer_id in layers) {
        this.map_layer_dict = maps[this.map_id].layers.dict[this.layer_id]
        this.layer_obj = layers[this.layer_id]
        this.json_arrays = await idb_unzip_blob(this.layer_id)

        if (this.json_arrays !== null) {
          this.col_name_2_col_uid = await col_name_2_col_uid_mapper(this.layer_obj.config.schema)
          await this.json_arrays_to_fg()
          this.show_blob_missing_msg = false
        } else {
          this.show_blob_missing_msg = true
          this.map_layer_dict.config.show_details = true
        }
      } else {
        console.log('NO LAYER')
      }

    },

  },


  beforeDestroy() {
    this.set_off_click_feature(this.fg_obj)

    if (this.fg_obj !== null) {
      this.fg_obj.removeFrom(this.$store.state.map)
      this.fg_obj.remove()
    } else {
      let cnt = 0
      let interval_id = setInterval(() => {
        cnt += 1
        if (this.fg_obj !== null) {
          this.fg_obj.removeFrom(this.$store.state.map)
          this.fg_obj.remove()
          this.fg_obj = null
          clearInterval(interval_id);
        } else if (cnt >= 50) {
          clearInterval(interval_id);
        }
      }, 100);
    }

    // if (this.fg_obj !== null) {
    //   this.fg_obj.removeFrom(this.$store.state.map)
    // }
    if (this.heatmap_obj !== null) {
      this.heatmap_obj.removeFrom(this.$store.state.map)
    }
    if (this.fg_edit !== null) {
      this.fg_edit.removeFrom(this.$store.state.map)
    }

  },
  watch: {
    '$store.state.identify_flag': function () {
      if (this.$store.state.identify_flag) {
        this.set_on_click_feature()
      } else {
        this.set_off_click_feature()
      }
    },
    '$store.state.is_sync_done': async function () {
      let layers = await get_qfDocument_type_as_dict('layer')
      if (this.layer_id in layers) {
        if (layers[this.layer_id].version > this.layer_obj.version || layers[this.layer_id].docBlob.version > this.layer_obj.docBlob.version) {
          // here to inform the user about the refresh when we make data sharing feature
          if (this.$store.state.auto_apply_sync) {
            await this.init_function()
          }
        }
      } else {
        if (this.fg_obj !== null) {
          this.fg_obj.removeFrom(this.$store.state.map)
        }
        if (this.heatmap_obj !== null) {
          this.heatmap_obj.removeFrom(this.$store.state.map)
        }
        if (this.fg_edit !== null) {
          this.fg_edit.removeFrom(this.$store.state.map)
        }
      }

    }
  }
  ,

  mounted() {
    this.init_function()
  }
}
</script>

<style scoped>

.box {
  float: left;
  height: 20px;
  width: 20px;
  clear: both;
}

</style>
