<template>
  <v-card flat :loading="loading">
    <v-card-text>

      <v-row>
        <v-spacer></v-spacer>

        <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense nav>

            <v-list-item @click="DriveTableImportDialog_show=true">
              <v-list-item-icon>
                <v-icon color="orange">mdi-application-import</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Import Table(s)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider v-if="selected_items.length > 0"></v-divider>

            <v-list-item
                v-if="selected_items.length > 0"
                @click="delete_selected">
              <v-list-item-icon>
                <v-icon color="red">mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Delete Selected</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="selected_items.length > 0 && selected_has_on_disk"
                :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"
                @click="upload_selected">
              <v-list-item-icon :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online">
                <v-icon color="blue">mdi-cloud-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Upload Selected</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="selected_items.length > 0"
                @click="export_selected_as_zip">
              <v-list-item-icon>
                <v-icon color="green">mdi-export</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Export Selected (csv in zip)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </v-menu>
      </v-row>

      <p></p>


      <v-col>
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
              style="max-width: 300px;"
              background-color="blue-grey lighten-5"
              v-model="search"
              append-icon="mdi-magnify"
              color="green"
              label="Search table rows"
              outlined
              dense
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-row>

      </v-col>

      <v-data-table
          :headers="tables_table_headers"
          :items="tables_table"
          :items-per-page="-1"
          :search="search"
          item-key="table_id"
          class="elevation-1"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          :hide-default-footer="tables_table.length < 10"

          show-select
          v-model="selected_items"
          @item-selected="on_item_selected"
          @toggle-select-all="on_select_all_items"

      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container style="margin-bottom: 10px; margin-top: 10px;">
              <p class="caption" v-if="table_maps[item.table_id].length > 0">
                <b>Maps:</b><br>
                <span v-for="map in table_maps[item.table_id]" :key="map.map_id">
                  {{ map.map_name }}<br>
                  </span>
              </p>

              <v-row justify="space-around">
                <v-spacer></v-spacer>

                <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense nav>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.table_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item
                        @click="edit_table">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Edit Table's Info</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="duplicate_table">
                      <v-list-item-icon>
                        <v-icon color="purple">mdi-content-save-all</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Duplicate Table (Save As)</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

<!--                    <v-list-item-->
<!--                        :disabled="!$store.state.auth.isLoggedIn || !$store.state.is_online"-->
<!--                        @click="upload_table(item.table_id, false)">-->
<!--                      <v-list-item-icon>-->
<!--                        <v-icon color="blue">mdi-cloud</v-icon>-->
<!--                      </v-list-item-icon>-->
<!--                      <v-list-item-content>-->
<!--                        <v-list-item-title>Upload to cloud</v-list-item-title>-->
<!--                      </v-list-item-content>-->
<!--                    </v-list-item>-->

                    <v-list-item
                        @click="DriveTableExportDialog_show=true">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-file-export</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Export Table</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        @click="delete_table(item.table_id, false)">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Delete Table</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>

              <v-col>
                <v-btn small @click="on_show_table(item.table_id)">
                  <v-icon color="green" left>mdi-table</v-icon>
                  Preview Table
                </v-btn>
              </v-col>

              <DriveTableViewDialog
                  v-if="DriveTableViewDialog_show"
                  :show_dialog.sync="DriveTableViewDialog_show"
                  :callback_function="null"
                  :the_title="active_table_name"
                  :doc_obj="tables[item.table_id]"
                  :json_arrays="active_table_json_arrays"
                  :schema="active_table_schema"
                  :editable="true"
              ></DriveTableViewDialog>


            </v-container>
          </td>

          <DriveTableExportDialog
              v-if="DriveTableExportDialog_show"
              :show_dialog.sync="DriveTableExportDialog_show"
              :table_id="item.table_id"
              :show_save_warning="false"
              :selected_uid_list="[]"
          ></DriveTableExportDialog>

          <DriveTableDialog
              v-if="DriveTableDialog_show"
              :show_dialog.sync="DriveTableDialog_show"
              :action="DriveTableDialog_action"
              :map_id="null"
              :table_id="item.table_id"
              :selected_uid_list="[]"
              :refresh_map_function="null"
              :refresh_qfdrive_function="init_list_data"
              :show_save_warning="false"
          ></DriveTableDialog>

        </template>
      </v-data-table>

    </v-card-text>
    <DriveTableImportDialog
        v-if="DriveTableImportDialog_show"
        :show_dialog.sync="DriveTableImportDialog_show"
        :map_id="null"
        :callback_function="init_list_data"
    ></DriveTableImportDialog>

    <DriveAllocationWarning
        v-if="DriveAllocationWarning_show"
        :show_dialog.sync="DriveAllocationWarning_show"
    ></DriveAllocationWarning>

    <DriveConfirmDeleteDialog
        v-if="DriveConfirmDeleteDialog_show"
        :show_dialog.sync="DriveConfirmDeleteDialog_show"
        :callback_function="after_delete_table"
        :dialog_title="'Delete Table'"
        :item_type_name="'Table'"
        :item_type="'qfDocument'"
        :item_name="delete_item.name"
        :item_id="delete_item.id"
    ></DriveConfirmDeleteDialog>


  </v-card>
</template>

<script>

import DriveTableImportDialog from "@/components/Drive/DriveTableImportDialog";
import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";
import DriveTableExportDialog from "@/components/Drive/DriveTableExportDialog";
import DriveTableDialog from "@/components/Drive/DriveTableDialog";
import DriveAllocationWarning from "@/components/Drive/DriveAllocationWarning";
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";

import prettyBytes from 'pretty-bytes';
import {
  r_user_data,
  u_qfDocument, d_qfDocument,
} from "@/services/app_api";
import {idb_r} from "@/services/idb_api";

import {
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
  idb_unzip_blob,
} from "@/services/app_utils";
import {zip_pack_csv_list} from "@/services/gis_utils";

export default {
  name: "DriveTablesList",
  props: {
    callback_function: {type: Function, default: null},
    map_id: {type: String, default: null},
  },
  components: {
    DriveTableImportDialog,
    DriveConfirmDeleteDialog,
    DriveTableExportDialog,
    DriveTableDialog,
    DriveAllocationWarning,
    DriveTableViewDialog,

  },
  data() {
    return {
      loading: false,
      maps: {},
      tables: {},
      tables_table: [],
      search: null,
      tables_table_headers: [
        {text: 'Name', value: 'table_name'},
        // {text: 'Type', value: 'table_type'},
        {text: 'Size', value: 'table_size'},
        // {text: 'No. Maps', value: 'num_maps'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],
      table_maps: {},

      selected_items: [],
      selected_has_on_disk: false,

      DriveTableImportDialog_show: false,

      DriveAllocationWarning_show: false,

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,
      },
      DriveTableExportDialog_show: false,

      DriveTableDialog_show: false,
      DriveTableDialog_action: 'Edit',

      DriveTableViewDialog_show: false,
      active_table_json_arrays: {},
      active_table_schema: {},
      active_table_name: null,

    }
  },
  methods: {

    async on_show_table(table_id) {
      this.active_table_json_arrays = await idb_unzip_blob(table_id)
      this.active_table_schema = this.tables[table_id].config.schema
      this.active_table_name = this.tables[table_id].name
      this.DriveTableViewDialog_show = true
    },

    async edit_table() {
      this.DriveTableDialog_action = 'Edit'
      this.DriveTableDialog_show = true
    },

    async duplicate_table() {
      this.DriveTableDialog_action = 'Duplicate'
      this.DriveTableDialog_show = true
    },

    async export_selected_as_zip() {

      this.loading = true

      let files_list = []

      for (let item of this.selected_items) {


        let json_list = await idb_unzip_blob(item.table_id)
        const file_name = item.table_name.replace('.csv', '').replace('.CSV', '') + '.csv'
        files_list.push({
          name: file_name,
          json_list: json_list
        })


      }

      if (files_list.length > 0) {
        await zip_pack_csv_list(files_list, 'selected_files_csv.zip')
      }

      this.loading = false

    },

    async remove_from_selected(table_id) {
      let selected_items = []
      for (let item of this.selected_items) {
        if (item.table_id !== table_id) {
          selected_items.push(item)
        }
      }
      this.selected_items = selected_items
    },

    async process_selected_rows() {

    },

    async on_item_selected() {
      await this.process_selected_rows()
    },
    async on_select_all_items() {
      await this.process_selected_rows()
    },
    async delete_selected() {
      // TO CHECK
      this.loading = true
      for (let item of this.selected_items) {
        await this.remove_from_selected(item.table_id)
        await this.delete_table(item.table_id, true)
      }
      await r_user_data(null)
      this.loading = false
      await this.init_list_data()
      await this.process_selected_rows()

    },

    async upload_selected() {
      // TO CHECK
      this.loading = true
      for (let item of this.selected_items) {
        await this.remove_from_selected(item.table_id)

        let json_arrays = await idb_unzip_blob(item.table_id)
        await u_qfDocument(this.tables[item.table_id], json_arrays, true)

      }
      await r_user_data(null)
      this.loading = false
      await this.init_list_data()
      await this.process_selected_rows()
    },

    async upload_table(table_id) {
      // TO CHECK
      this.loading = true
      let json_arrays = await idb_unzip_blob(table_id)
      await u_qfDocument(this.tables[table_id], json_arrays, true)

      this.loading = false
      // await this.remove_from_selected(table_id)
      await this.init_list_data()
      await this.process_selected_rows()
    },

    async delete_table(table_id, is_multi = false) {

      if (is_multi) {
        this.loading = true
        await d_qfDocument(table_id)
        await this.init_list_data()
        if (this.callback_function !== null && this.map_id !== null) {
          await this.callback_function(this.map_id)
        }
        await this.after_delete_table(table_id)
      } else {
        this.delete_item.name = this.tables[table_id].name
        this.delete_item.id = table_id
        this.DriveConfirmDeleteDialog_show = true
      }
    },
    async after_delete_table(table_id) {
      this.delete_item.name = null
      this.delete_item.id = null

      this.loading = false
      await this.remove_from_selected(table_id)
      await this.init_list_data()
      await this.process_selected_rows()

    },


    async get_table_use_in_maps(table_id) {
      let maps_list = []

      for (let map_id in this.maps) {
        let map_obj = this.maps[map_id]

        if (table_id in map_obj.tables.dict) {
          maps_list.push({
            map_id: map_id,
            map_name: map_obj.name
          })
        }

      }
      return maps_list
    },
    async init_list_data() {

      this.tables = await get_qfDocument_type_as_dict('table')
      this.maps = await get_qfCollection_type_as_dict('map')

      let tables_table = []
      let table_maps = {}
      for (let table_id in this.tables) {
        let table_obj = this.tables[table_id]

        table_maps[table_id] = await this.get_table_use_in_maps(table_id)

        tables_table.push({
          table_id: table_id,
          table_name: table_obj.name,
          table_type: table_obj.config.table_type,
          table_size: this.do_prettyBytes(table_obj.docBlob.size),
          num_maps: table_maps[table_id].length
        })
      }
      this.$set(this, 'tables_table', tables_table)
      this.$set(this, 'table_maps', table_maps)
      this.$forceUpdate()
    },

    do_prettyBytes(num) {
      if (num === null || num === undefined) {
        return null
      } else {
        return prettyBytes(num)
      }
    },
    the_prettyBytes(num_byte) {
      return prettyBytes(num_byte)
    },
  },
  watch: {
    '$store.state.is_sync_done': async function () {
      if (this.$store.state.is_sync_done) {
        await this.init_list_data()
      }

    }
  },
  mounted() {
    this.init_list_data()
  },
}
</script>

<style scoped>

</style>
