<template>
  <v-main id="map" v-bind:style="mapStyle"></v-main>
</template>

<script>

import {init_map} from "@/services/leaflet_api";

export default {
  name: "MapsMainMap",
  data() {
    return {
      mapStyle: {
        padding: 0,
        cursor: 'grab',
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 0,
        margin: 0,
      },
    }
  },
  methods: {
    async init() {
      // if (this.$store.state.auth.isLoggedIn){
      //   await init_map('map', 22, 45, 6)
      // } else {
      //   await init_map('map', -25, 133, 5)
      // }

      await init_map('map', 22, 45, 6)


    },

  },
  mounted() {
    let interval_id = setInterval(() => {
      if (this.$store.state.L !== null) {
        this.init()
        clearInterval(interval_id);
      }
    }, 100);
  }
}
</script>

<style scoped>

</style>
