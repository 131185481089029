<template>
  <div ref="the_dialog" style="height: 100%; min-height: 100%;" v-if="!is_loading">
    <ag-grid-vue
        v-bind:style="{width: table_width + 'px', height: table_height + 'px', padding: 0, margin: 0}"
        class="ag-theme-alpine"
        :columnDefs="table_headers"
        :rowData="table_data"
        :animateRows="true"
        @grid-ready="onGridReady"
        :rowDragManaged="true"
        :editType="'fullRow'"
        :rowMultiSelectWithClick="!table_data_is_editable"
        :rowSelection="'multiple'"
        @selection-changed="onSelectionChanged"
    ></ag-grid-vue>
  </div>

</template>

<script>

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue"
import {deepClone} from "@/services/generic";


export default {
  name: "MapsVectorLayerTable",
  props: {
    json_arrays: {type: Object},
    schema: {type: Object},
    highlight_selected_items: {type: Function},
    update_json_arrays_in_map: {type: Function},
    selected_uid_list: {type: Array},
    update_selected_uid_list: {type: Function},
    doc_obj: {type: Object, default: null},
    table_data_is_editable: {type: Boolean, default: false},
    show_selected_on_tob: {type: Boolean, default: false},
    bottom_sheet_resized: {type: Boolean},
  },
  components: {AgGridVue},
  data() {
    return {
      is_loading: false,
      table_width: 800,
      table_height: 500,

      the_selected_uid_list: this.selected_uid_list,

      table_data: [],
      table_headers: [],
      selected_items: [],

      gridApi: null,
      columnApi: null,
      gridColumnApi: null,

    }
  },
  methods: {

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      this.set_selected_rows()
    },
    resize_cols() {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    },
    async set_table_size() {
      console.log('table resized')
      setTimeout(() => {
        this.table_width = this.$refs.the_dialog.clientWidth
        this.table_height = this.$refs.the_dialog.clientHeight - 20
        console.log(this.table_height)
        this.resize_cols()
      }, 100)
    },
    async json_arrays_to_json_list(json_arrays) {
      let col_uids = Object.keys(json_arrays)
      let table_data = []
      for (let idx in json_arrays.row_uid) {
        let the_dict = {}
        for (let col_uid of col_uids) {
          the_dict[col_uid] = json_arrays[col_uid][idx]
        }
        table_data.push(the_dict)
      }
      return table_data
    },
    async get_table_headers(json_arrays, schema) {

      let isFirstColumn = async (params) => {
        let displayedColumns = params.columnApi.getAllDisplayedColumns();
        return displayedColumns[0] === params.column;
      }

      let col_uids = Object.keys(json_arrays)
      let table_headers = []

      for (let col_uid of col_uids) {
        if (!schema[col_uid].is_system) {
          let header_item = {
            field: col_uid,
            headerName: schema[col_uid].label,
            editable: this.table_data_is_editable,
            resizable: true,
            sortable: true,
            filter: true,
            // headerCheckboxSelection: await isFirstColumn
          }

          table_headers.push(header_item)
        }
      }
      table_headers[0].rowDrag = true
      table_headers[0].checkboxSelection = true
      table_headers[0].headerCheckboxSelection = true
      return table_headers
    },
    set_selected_rows() {
      this.gridApi.forEachNode((node) => {
        node.setSelected(this.the_selected_uid_list.includes(node.data.row_uid), false, true);
      });
    },
    async onSelectionChanged() {
      const selected_rows = this.gridApi.getSelectedRows();
      let selected_uid_list = []
      for (let selected_row of selected_rows) {
        let row_uid = selected_row.row_uid
        selected_uid_list.push(row_uid)
      }
      await this.update_selected_uid_list(selected_uid_list)
      this.highlight_selected_items()

    },
    async init() {
      this.is_loading = true
      this.table_data = await this.json_arrays_to_json_list(this.json_arrays)
      this.table_headers = await this.get_table_headers(this.json_arrays, this.schema)
      await this.set_table_size()
      this.is_loading = false
    },
    async save_table_edits() {
      let col_uids = Object.keys(this.table_data[0])
      let json_arrays = {}
      for (let col_uid of col_uids) {
        json_arrays[col_uid] = []
      }
      for (let row_item of this.table_data) {
        for (let col_uid of col_uids) {
          json_arrays[col_uid].push(row_item[col_uid])
        }
      }
      this.update_json_arrays_in_map(json_arrays)
    },

    async do_show_selected_on_top() {
      let selected_rows = []
      let non_selected_rows = []
      for (let this_row of this.table_data) {
        if (this.selected_uid_list.includes(this_row.row_uid)) {
          selected_rows.push(this_row)
        } else {
          non_selected_rows.push(this_row)
        }
      }
      console.log(this.table_data)
      console.log(this.selected_uid_list)
      console.log(selected_rows)
      console.log(selected_rows.concat(non_selected_rows))
      this.table_data = selected_rows.concat(non_selected_rows)
    },

  },
  watch: {
    async bottom_sheet_resized(new_val, old_val) {
      console.log('in watch')
      await this.set_table_size()
      this.$emit('update:bottom_sheet_resized', false)
    },
    async show_selected_on_tob() {
      if (this.show_selected_on_tob) {
        await this.do_show_selected_on_top()
        this.set_selected_rows()
      } else {
        console.log('show_selected_on_tob NOT')
      }
    },
    async table_data_is_editable() {
      if (this.table_data_is_editable) {
        await this.init()
        await this.set_selected_rows()
      } else {
        await this.save_table_edits()
      }
    },
    async json_arrays() {
      await this.init()
      await this.set_selected_rows()
    },
    async schema() {
      await this.init()
      await this.set_selected_rows()
    },
    selected_uid_list() {
      this.the_selected_uid_list = deepClone(this.selected_uid_list)
      this.set_selected_rows()
    },
  },
  beforeDestroy() {
    if (this.table_data_is_editable) {
      this.save_table_edits()
    }
  },

  mounted() {
    this.init()
  },
}
</script>

<style scoped>


</style>
