<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card>
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <v-icon left :color="'#00ff00'" left>mdi-rename-box</v-icon>
          <span v-if="action==='Edit'" class="body-1">
            Edit Layer Info
          </span>
          <span v-else class="body-1">
            Duplicate (Save As) Layer
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-col style="max-width: 400px;">
          <v-text-field
              v-model="layer_obj.name"
              label="Name"
              :hide-details="true"
          ></v-text-field>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-textarea
              outlined
              rows="1"
              clearable
              auto-grow
              v-model="layer_obj.config.description"
              label="Description"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-select
              outlined
              dense
              v-model="layer_obj.config.stage.stage"
              :items="stages"
              item-text="stage"
              item-value="stage"
              label="Stage"
              :hide-details="true"
          >
            <template v-slot:selection="{item}">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" dense>
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.stage }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col style="max-width: 400px;">
          <v-textarea
              outlined
              rows="1"
              clearable
              auto-grow
              v-model="layer_obj.config.comments"
              label="Comments"
              :hide-details="true"
          ></v-textarea>
        </v-col>

        <v-divider></v-divider>

        <div v-if="action === 'Duplicate'">
          <v-divider></v-divider>
          <v-switch
              v-if="map_id !== null"
              v-model="add_to_map"
              :hide-details="true"
              label="Add layer to current map"
          ></v-switch>
<!--          <v-switch-->
<!--              :disabled="!($store.state.auth.isLoggedIn && $store.state.is_online)"-->
<!--              v-model="upload_blob"-->
<!--              :hide-details="true"-->
<!--              label="Upload to cloud"-->
<!--          ></v-switch>-->
        </div>


        <v-alert
            v-if="show_save_warning"
            style="margin-top: 10px;"
            max-width="400"
            border="left"
            color="orange"
            text
            dense
            type="warning"
        >
          <span v-if="action==='Edit'">
            Please <b>save</b> the layer before changing the layer info.
            Any <b>unsaved geometry changes</b> will be discarded
          </span>
          <span v-else>
            Only <b>saved</b> changes will be exported.
          </span>
        </v-alert>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Cancel</v-btn>
        <v-btn small color="purple" class="white--text"
               @click="submit"
        >Submit
        </v-btn>

      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import {
  get_qfCollection_type_as_dict,
  get_qfDocument_type_as_dict,
  idb_unzip_blob, set_random_style
} from "@/services/app_utils";
import {c_qfDocument, u_qfCollection, u_qfDocument} from "@/services/app_api";
import {idb_r} from "@/services/idb_api";
import {deepClone} from "@/services/generic";
import {templates} from "@/store";

export default {
  name: "DriveRasterLayerDialog",
  props: {
    show_dialog: {type: Boolean},
    action: {type: String, default: 'Edit'}, // Edit, Duplicate
    map_id: {type: String},
    layer_id: {type: String},
    refresh_map_function: {type: Function, default: null},
    refresh_qfdrive_function: {type: Function, default: null},
    show_save_warning: {type: Boolean, default: false},

  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 400
      },
      layer_obj: {
        config: {
          stage: {}
        },
      },

      add_to_map: false,
      selected_only: false,

      upload_blob: true,

      stages: [
        {stage: 'Development', icon: 'mdi-test-tube', color: 'green'},
        {stage: 'Testing', icon: 'mdi-truck-delivery', color: 'purple'},
        {stage: 'Production', icon: 'mdi-bullseye-arrow', color: 'red'},
      ],
    }
  },
  methods: {

    async submit() {

      switch (this.action) {
        case 'Edit':
          await u_qfDocument(this.layer_obj, null, this.layer_obj.docBlob.is_uploaded)
          break;
        case 'Duplicate':
          let rec = await idb_r(`${this.layer_obj.id}__blob`)
          let layer_blob = rec.blob_obj
          this.layer_obj.id = null
          this.layer_obj.is_uploaded = false
          this.layer_obj.docBlob.is_uploaded = false

          let resp = await c_qfDocument(
              this.layer_obj,
              layer_blob,
              this.upload_blob)

          let layer_id = resp.doc_id

          if (this.map_id !== null) {
            let maps = await get_qfCollection_type_as_dict('map')
            let map = maps[this.map_id]

            map.layers.dict[layer_id] = deepClone(templates.qfCollection__qfDocumentType__dict_templates.layers)
            map.layers.dict[layer_id].id = layer_id
            map.layers.list.push(layer_id)

            await u_qfCollection(map)
          }

          break;
        default:
          break;
      }

      if (this.refresh_map_function !== null) {
        this.refresh_map_function(this.map_id)
      }

      if (this.refresh_qfdrive_function !== null) {
        this.refresh_qfdrive_function()
      }

      this.cancel()

    },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    async init_load() {

      let layers = await get_qfDocument_type_as_dict('layer')

      this.layer_obj = layers[this.layer_id]
      console.log(this.layer_obj)
      console.log('-------------------')
      if (this.action === 'Duplicate') {
        this.layer_obj.name += ' -Copy'
      }

      if (this.selected_only) {
        this.layer_obj.name += '-Selected'
      }
    },

  },
  mounted() {
    this.init_load()

  },
}
</script>

<style scoped>

</style>
