<template>
  <v-dialog
      v-model="show_dialog"
      persistent
      :max-width="dialog_size.max_width"
      :fullscreen="dialog_size.fullscreen"
  >
    <v-card :loading="loading">
      <v-app-bar dense color="white">
        <v-toolbar-title>
          <span class="body-1">
            MEWA Water Use Tasks
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width-=50">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.max_width+=50">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon v-if="dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=false">mdi-fullscreen-exit</v-icon>
        </v-btn>
        <v-btn icon v-if="!dialog_size.fullscreen">
          <v-icon @click="dialog_size.fullscreen=true">mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon @click="cancel">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>

        <v-toolbar dense>
          <v-btn small color="purple" class="white--text" style="margin-right: 10px; text-transform: none;"
                 @click="MewaGeeNewTaskDialog_show=true">
            <v-icon left>mdi-google-earth</v-icon>
            New Water Use Task
          </v-btn>

          <v-btn small color="orange" class="white--text" style="text-transform: none;"
                 @click="init">
            <v-icon left>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </v-toolbar>

        <v-data-table
            :headers="tasks_table_headers"
            :items="tasks_table"
            :items-per-page="-1"
            :search="search"
            item-key="task_id"
            class="elevation-1"
            fixed-header

            show-expand
            :expanded.sync="expanded"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container style="margin-bottom: 10px; margin-top: 10px;">

                <pre v-if="tasks[item.task_id].gee_dict !== undefined">
                  <v-subheader>GEE Result</v-subheader>
                  <p class="caption">
                  {{ tasks[item.task_id].gee_dict }}
                  </p>
                </pre>
                <p>
                  Name: {{ tasks[item.task_id].name }}<br>
                  Description: {{ tasks[item.task_id].description }}<br>
                  Task Created: {{ get_datetime(tasks[item.task_id].datetime.created) }}
                </p>
                <p>
                  {{ item.task.task_type }}
                </p>


                <v-row>

                  <div v-if="item.status=== 'Data Transferred'">
                    <v-btn v-if="item.task.task_type === 'export_asfields'" x-small color="green" class="white--text" style="margin-right: 5px; margin-bottom: 5px;"
                           v-for="pfx in ['M', 'Q', 'Y', 'RM', 'RQ', 'RY']" :key="pfx"
                           :disabled="loading"
                           @click="import_file(item.task_id, pfx)">
                      <v-icon x-small left>mdi-import</v-icon>
                      Import {{ pfx }}
                    </v-btn>
                    <v-btn v-if="item.task.task_type === 'export_asgrid'" x-small color="green" class="white--text" style="margin-right: 5px; margin-bottom: 5px;"
                           v-for="pfx in ['RM', 'RQ', 'RY']" :key="pfx"
                           :disabled="loading"
                           @click="import_file(item.task_id, pfx)">
                      <v-icon x-small left>mdi-import</v-icon>
                      Import {{ pfx }}
                    </v-btn>
                  </div>

                  <v-btn x-small color="red" class="white--text"
                         :disabled="loading"
                         @click="delete_task(item.task_id)">
                    <v-icon x-small left>mdi-delete</v-icon>
                    Delete Task
                  </v-btn>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>

        <MewaGeeNewTaskDialog
            v-if="MewaGeeNewTaskDialog_show"
            :show_dialog.sync="MewaGeeNewTaskDialog_show"
            :callback_function="init"
        ></MewaGeeNewTaskDialog>

        <DriveVectorLayerImportDialog
            v-if="DriveVectorLayerImportDialog_show"
            :show_dialog.sync="DriveVectorLayerImportDialog_show"
            :input_file="DriveVectorLayerImportDialog_input_file"
            :callback_function="null"
        ></DriveVectorLayerImportDialog>

        <DriveTableImportDialog
            v-if="DriveTableImportDialog_show"
            :show_dialog.sync="DriveTableImportDialog_show"
            :input_file="DriveTableImportDialog_input_file"
            :callback_function="null"
        ></DriveTableImportDialog>

      </v-card-text>
      <v-card-actions>
        <v-btn small text @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>

import MewaGeeNewTaskDialog from "@/components/MEWA/MewaGeeNewTaskDialog";
import DriveVectorLayerImportDialog from "@/components/Drive/DriveVectorLayerImportDialog";
import DriveTableImportDialog from "@/components/Drive/DriveTableImportDialog";
import {d_task, import_gee_file, r_tasks} from "@/components/MEWA/services/mewa_api";
import {idb_r} from "@/services/idb_api";
import {DateTime} from "luxon";

export default {
  name: "MewaGeeTasksListDialog",
  props: {
    show_dialog: {type: Boolean},
    callback_function: {type: Function},
  },
  components: {
    DriveTableImportDialog,
    MewaGeeNewTaskDialog,
    DriveVectorLayerImportDialog,
  },
  data() {
    return {
      dialog_size: {
        fullscreen: false,
        max_width: 800
      },
      loading: false,

      search: null,
      tasks_table: [],
      tasks_table_headers: [
        {text: 'Task Name', value: 'task_name'},
        {text: 'Created', value: 'created'},
        {text: 'Analysis Type', value: 'task_type'},
        {text: 'Start Date', value: 'start_date'},
        {text: 'End Date', value: 'end_date'},
        {text: 'Status', value: 'status'},
        {text: '', value: 'data-table-expand'},
      ],

      MewaGeeNewTaskDialog_show: false,

      expanded: [],
      tasks: {},

      DriveVectorLayerImportDialog_show: false,
      DriveVectorLayerImportDialog_input_file: null,

      DriveTableImportDialog_show: false,
      DriveTableImportDialog_input_file: null,

    }
  },
  methods: {
    // async submit() {
    //   this.cancel()
    // },
    cancel() {
      this.$emit('update:show_dialog', false)
    },
    get_datetime(iso_dt) {
      return DateTime.fromISO(iso_dt).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    },

    async import_file(task_id, pfx) {
      this.loading = true
      console.log('importing file', task_id)

      let tasks_map = {
        'export_asfields': 'Fields',
        'export_asgrid': 'Regional',
      }

      let blob_obj = await import_gee_file(`${task_id}_${pfx}.csv`)
      let file_name = `${this.tasks[task_id].name}_${tasks_map[this.tasks[task_id].task_type]}_${pfx}.csv`
      this.DriveTableImportDialog_input_file = new File([blob_obj], file_name, {type: "text/csv"});
      this.DriveTableImportDialog_show = true

      // if (task_type === 'Delineate fields') {
      //   file_name = this.tasks[task_id].name + '.geojson'
      //   this.DriveVectorLayerImportDialog_input_file = new File([blob_obj], file_name, {type: "text/plain"});
      //   this.DriveVectorLayerImportDialog_show = true
      // }
      // if (task_type === 'Water Use') {
      //   file_name = this.tasks[task_id].name + '.csv'
      //   this.DriveTableImportDialog_input_file = new File([blob_obj], file_name, {type: "text/csv"});
      //   this.DriveTableImportDialog_show = true
      // }
      this.loading = false

    },

    async delete_task(task_id) {
      await d_task(task_id)
      await this.init()
    },
    async init() {
      this.loading = true
      console.log('in init TasksList')
      this.tasks = await r_tasks()
      // console.log(1)
      // this.tasks = await idb_r('mewa_gee_task')
      console.log('tasks', this.tasks)

      let tasks_map = {
        'export_asfields': 'Fields',
        'export_asgrid': 'Regional',
      }
      let status_map = {
        db_init: 'Initiated',
        gee_submitted: 'GEE Submitted',
        gee_failed: 'GEE Failed',
        gee_in_progress: 'GEE In Progress',
        gee_done: 'GEE Done',
        fb_bucket_transfer: 'Data Transferred',
      }

      this.tasks_table = []
      for (let task_id in this.tasks) {
        delete this.tasks[task_id].boundary_geojson
        let task = this.tasks[task_id]
        let row = {
          task_id: task_id,
          task_type: tasks_map[task.task_type],
          task_name: task.name,
          created: DateTime.fromISO(task.datetime.created).toLocaleString(DateTime.DATETIME_SHORT),
          start_date: task.start_date,
          end_date: task.end_date,
          status: status_map[task.status],
          task: task,
        }
        this.tasks_table.push(row)
      }
      this.loading = false

    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
