<template>
  <div>
    <v-btn text small color="orange"
        @click="apply_sync"
        v-if="$store.state.auth.isLoggedIn">
      <v-icon left>mdi-sync</v-icon>
      Apply Sync
    </v-btn>

  </div>
</template>

<script>
import {
  reload_firestore
} from "@/services/app_api";

export default {
  name: "SyncApplySync",
  props: {},
  data() {
    return {}
  },
  methods: {
    async apply_sync(){
      await reload_firestore()
    },

  },
  watch: {},
  mounted() {
  },
}
</script>

<style scoped>

</style>
