<template>
  <div>

    <v-col style="max-width: 400px;">
      <v-select
          label="Plot Type"
          @change="do_plot"
          v-model="plot_type"
          :items="plot_types"
          item-text="title"
          item-value="val"
          :hide-details="true"
      ></v-select>
    </v-col>

    <v-col style="max-width: 400px;">
      <v-autocomplete
          v-model="selected_table_id"
          :items="input_tables_list"
          @change="on_select_table"
          dense
          small-chips
          deletable-chips
          clearable
          label="Select Link Table"
          item-text="table_name"
          item-value="table_id"
          :hide-details="true"
      ></v-autocomplete>
    </v-col>

    <v-col v-if="selected_table_id !== null">
      <v-btn x-small @click="on_show_link_table()">
        <v-icon small color="green" left>mdi-table</v-icon>
        Preview Link Table
      </v-btn>
    </v-col>

    <v-col>

      <v-autocomplete
          style="max-width: 400px;"
          v-model="plot_col_x_uid"
          @change="do_plot"
          :items="col_list"
          dense
          label="Select X Column"
          item-text="col_name"
          item-value="col_uid"
      ></v-autocomplete>

      <v-autocomplete
          style="max-width: 400px;"
          v-if="plot_type !== 'histogram'"
          v-model="plot_col_y_uid"
          @change="do_plot"
          :items="col_list"
          dense
          label="Select Y Column"
          item-text="col_name"
          item-value="col_uid"
      ></v-autocomplete>

      <v-text-field
          v-if="plot_type === 'line_with_bounds'"
          @change="do_plot"
          @wheel="do_plot"
          style="max-width: 100px;"
          v-model="lower_pct"
          label="Lower Pct"
          type="number"
          step="0.01" min="0" max="1"
          dense
      ></v-text-field>

      <v-text-field
          v-if="plot_type === 'line_with_bounds'"
          @change="do_plot"
          @wheel="do_plot"
          style="max-width: 100px;"
          v-model="upper_pct"
          label="Upper Pct"
          type="number"
          step="0.01" min="0" max="1"
          dense
      ></v-text-field>


      <v-switch
          v-if="plot_type !== 'line_with_bounds'"
          style="max-width: 400px;"
          @change="do_plot"
          v-model="include_selected_uids"
          :disabled="selected_uid_list.length === 0"
          dense
          :hide-details="true"
          label="Include selected features"
      ></v-switch>

      <PlotsLine
          v-if="plot_type=== 'line' && line_plot_data.show"
          :plot_data.sync="line_plot_data.plot_data"
      ></PlotsLine>

      <PlotsLineWithBounds
          v-if="plot_type=== 'line_with_bounds' && line_with_bounds_data.show"
          :plot_data.sync="line_with_bounds_data.plot_data"
      ></PlotsLineWithBounds>

      <PlotsHistogram
          v-if="plot_type=== 'histogram' && histogram_plot_data.show"
          :plot_data.sync="histogram_plot_data.plot_data"
      ></PlotsHistogram>


      <v-switch
          style="max-width: 400px;"
          v-model="show_plot_data_table"
          dense
          :hide-details="true"
          label="Show Plot Data Table"
      ></v-switch>


      <TablesSimpleTable
          v-if="TablesSimpleTable__show && show_plot_data_table"
          :header_pairs_list.sync="TablesSimpleTable__header_pairs_list"
          :table_data.sync="TablesSimpleTable__table_data"
      ></TablesSimpleTable>

      <DriveTableViewDialog
          v-if="DriveTableViewDialog_show"
          :show_dialog.sync="DriveTableViewDialog_show"
          :callback_function="null"
          :the_title="DriveTableViewDialog_name"
          :doc_obj="DriveTableViewDialog_doc_obj"
          :json_arrays="DriveTableViewDialog_json_arrays"
          :schema="DriveTableViewDialog_schema"
          :editable="false"
      ></DriveTableViewDialog>

    </v-col>

  </div>
</template>

<script>

import PlotsLine from "@/components/Plots/PlotsLine";
import PlotsLineWithBounds from "@/components/Plots/PlotsLineWithBounds";
import PlotsHistogram from "@/components/Plots/PlotsHistogram";
import TablesSimpleTable from "@/components/Tables/TablesSimpleTable";
import DriveTableViewDialog from "@/components/Drive/DriveTableViewDialog";

import {deepClone, unique_array} from "@/services/generic";
import * as aq from "arquero";
import * as ss from 'simple-statistics'
import {get_qfDocument_type_as_dict, idb_unzip_blob} from "@/services/app_utils";

export default {
  name: "PlotsLinkedTableVariables",
  props: {
    json_arrays: {type: Object},
    schema: {type: Object},
    selected_uid_list: {type: Array},
    map_layer_dict: {type: Object},
  },
  components: {
    PlotsLine,
    PlotsLineWithBounds,
    PlotsHistogram,
    TablesSimpleTable,
    DriveTableViewDialog,
  },
  data() {
    return {
      td: null,

      tables: {},

      fid_list: this.selected_uid_list,
      json_arrays_selected: {},

      table_json_arrays: {},

      plot_col_x_uid: null,
      plot_col_y_uid: null,

      col_list: [],
      plot_type: 'line',
      plot_types: [
        {val: 'line', title: 'Line Plot (Single Variable)'},
        {val: 'line_with_bounds', title: 'Line Plot with uncertainty bounds'},
        {val: 'histogram', title: 'Histogram'},
        // {val: 'pie', title: 'Pie Chart'},
      ],

      line_plot_data: {show: false, plot_data: null},
      line_with_bounds_data: {show: false, plot_data: null},
      histogram_plot_data: {show: false, plot_data: null},

      include_all_uids: false,
      include_selected_uids: false,
      include_unselected_uids: false,

      lower_pct: 0.05,
      upper_pct: 0.95,

      input_tables_list: [],
      selected_table_id: null,

      TablesSimpleTable__show: false,
      TablesSimpleTable__table_data: [],
      TablesSimpleTable__header_pairs_list: [],
      show_plot_data_table: false,

      DriveTableViewDialog_show: false,
      DriveTableViewDialog_doc_obj: null,
      DriveTableViewDialog_json_arrays: {},
      DriveTableViewDialog_schema: {},
      DriveTableViewDialog_name: null,

    }
  },
  methods: {
    async on_show_link_table() {
      this.DriveTableViewDialog_json_arrays = await idb_unzip_blob(this.selected_table_id)
      this.DriveTableViewDialog_doc_obj = this.tables[this.selected_table_id]
      this.DriveTableViewDialog_schema = this.tables[this.selected_table_id].config.schema
      this.DriveTableViewDialog_name = this.tables[this.selected_table_id].name
      this.DriveTableViewDialog_show = true
    },
    do_plot() {
      this.reset_plot_table_data()
      setTimeout(() => {
        if (this.plot_type !== null) {
          switch (this.plot_type) {
            case 'line':
              if (this.plot_col_y_uid !== null) {
                this.plot_line()
              }
              break;
            case 'line_with_bounds':
              if (this.plot_col_x_uid !== null && this.plot_col_y_uid !== null) {
                this.plot_line_with_bounds()
              }
              break;
            case 'histogram':
              if (this.plot_col_x_uid !== null) {
                this.plot_histogram()
              }
              break;
            default:
              break;
          }
        }
      }, 100)

    },
    async set_json_arrays_selected() {
      let cols_list = Object.keys(this.json_arrays)

      let json_arrays_selected = deepClone(this.json_arrays)
      for (let idx in this.json_arrays.row_uid) {
        let row_uid = this.json_arrays.row_uid[idx]
        if (!this.selected_uid_list.includes(row_uid)) {
          for (let col_uid of cols_list) {
            json_arrays_selected[col_uid][idx] = null
          }
        }
      }
      this.json_arrays_selected = json_arrays_selected
    },


    async plot_line_with_bounds() {
      console.log('plot_line_with_bounds')
      this.TablesSimpleTable__show = false
      let data = await this.get_plot_data()
      let data_bounds = await this.calc_bounds(data.x, data.y)

      this.TablesSimpleTable__header_pairs_list = [
        {field: 'x', headerName: 'X Axis'},
        {field: 'lower', headerName: 'Lower'},
        {field: 'median', headerName: 'Median'},

        {field: 'upper', headerName: 'Upper'},
        {field: 'rec_count', headerName: 'Rec Count'},
      ]
      this.TablesSimpleTable__table_data = []
      for (let idx in data_bounds.x_median) {
        let row = {
          'x': data_bounds.x_median[idx],
          'median': data_bounds.y_median[idx],
          'lower': data_bounds.y_lower[idx],
          'upper': data_bounds.y_upper[idx],
          'rec_count': data_bounds.rec_length[idx],
        }
        this.TablesSimpleTable__table_data.push(row)
      }
      this.TablesSimpleTable__show = true
      console.log('🚀', data)
      console.log(data_bounds)
      let plot_data = [
        {
          x: data_bounds.x_median,
          y: data_bounds.y_median,
          mode: 'lines',
          type: 'scatter',
          line: {color: "red"},
          name: 'Median'
        },
        {
          x: data_bounds.x_bounds,
          y: data_bounds.y_bounds,
          fill: "tozerox",
          fillcolor: "rgba(0,100,80,0.2)",
          line: {color: "transparent"},
          name: "Bounds",
          showlegend: false,
          type: "scatter"
        }
      ]
      // if (this.include_selected_uids) {
      //   plot_data.push({
      //     x: data.x_selected,
      //     y: data.y_selected,
      //     color: 'green', width: 4, name: 'Selected data'
      //   })
      // }

      this.line_with_bounds_data.plot_data = plot_data
      this.line_with_bounds_data.show = true
    },
    async calc_bounds(x = [], y = []) {

      let x_unique = await unique_array(x).sort()

      let y_upper = []
      let y_median = []
      let y_lower = []
      let rec_length = []

      for (let x_val of x_unique) {
        let y_filt = []
        for (let i in x) {
          if (x_val === x[i]) {
            let the_val = parseFloat(y[i])
            if (!isNaN(the_val)) {
              y_filt.push(the_val)
            }

          }
        }

        y_upper.push(ss.quantile(y_filt, this.upper_pct))
        y_median.push(ss.quantile(y_filt, 0.5))
        y_lower.push(ss.quantile(y_filt, this.lower_pct))
        rec_length.push(y_filt.length)
      }

      let x_median = deepClone(x_unique)
      let x_bounds = [...deepClone(x_unique), ...deepClone(x_unique.reverse())]
      let y_bounds = [...deepClone(y_upper), ...deepClone(y_lower).reverse()]
      return {x_median, y_median, x_bounds, y_bounds, y_lower, y_upper, rec_length}

    },
    async get_plot_data() {
      let x = null
      let y = null
      let x_selected = null
      let y_selected = null

      if (this.plot_col_x_uid !== null) {
        if (this.plot_col_x_uid in this.schema) {
          x = this.json_arrays[this.plot_col_x_uid]
        } else {
          x = this.table_json_arrays[this.plot_col_x_uid]
        }
      }
      if (this.plot_col_y_uid !== null) {
        if (this.plot_col_y_uid in this.schema) {
          y = this.json_arrays[this.plot_col_y_uid]
        } else {
          y = this.table_json_arrays[this.plot_col_y_uid]
        }
      }

      if (this.include_selected_uids) {
        if (this.plot_col_x_uid !== null) {
          x_selected = this.json_arrays_selected[this.plot_col_x_uid]
        }
        if (this.plot_col_y_uid !== null) {
          y_selected = this.json_arrays_selected[this.plot_col_y_uid]
        }
      }
      // let db = await this.calc_bounds(x, y)
      // console.log('😋', db)
      return {x, y, x_selected, y_selected}
    },

    async plot_line() {
      let data = await this.get_plot_data()
      console.log('🚀', data)
      this.line_plot_data.show = false
      let plot_data = [
        {
          x: data.x,
          y: data.y,
          color: 'orange', width: 3, name: 'All data'
        }
      ]
      if (this.include_selected_uids) {
        plot_data.push({
          x: data.x_selected,
          y: data.y_selected,
          color: 'green', width: 4, name: 'Selected data'
        })
      }

      this.line_plot_data.plot_data = plot_data
      this.line_plot_data.show = true

      this.TablesSimpleTable__show = false
      this.TablesSimpleTable__header_pairs_list = [
        {field: 'x', headerName: 'X Axis'},
        {field: 'y', headerName: 'Y Axis'},
      ]
      this.TablesSimpleTable__table_data = []
      for (let idx in data.x) {
        let row = {
          'x': data.x[idx],
          'y': data.y[idx],
        }
        this.TablesSimpleTable__table_data.push(row)
      }
      this.TablesSimpleTable__show = true

    },

    async plot_histogram() {
      this.histogram_plot_data.show = false
      let data = await this.get_plot_data()
      console.log('🚀', data)
      let plot_data = [
        {
          x: data.x,
          y: null,
          color: 'orange', width: 3, name: 'All data',
        }
      ]
      if (this.include_selected_uids) {
        plot_data.push({
          x: data.x_selected,
          y: null,
          color: 'green', width: 4, name: 'Selected data'
        })
      }
      console.log('plot data for histogram', plot_data)
      this.histogram_plot_data.plot_data = plot_data
      this.histogram_plot_data.show = true

      this.TablesSimpleTable__show = false
      this.TablesSimpleTable__header_pairs_list = [
        {field: 'x', headerName: 'Data'},
      ]
      this.TablesSimpleTable__table_data = []
      for (let idx in data.x) {
        let row = {
          'x': data.x[idx],
        }
        this.TablesSimpleTable__table_data.push(row)
      }
      this.TablesSimpleTable__show = true

    },
    reset_plot_table_data() {
      this.histogram_plot_data.show = false
      this.TablesSimpleTable__table_data = []
      this.TablesSimpleTable__header_pairs_list = []
    },
    async on_select_table() {
      console.log('on_select_table')
      console.log(this.selected_table_id)
      // load table
      this.table_json_arrays = await idb_unzip_blob(this.selected_table_id)

      await this.set_col_list()

    },
    async set_col_list() {
      this.col_list = []

      this.col_list.push({
        col_uid: null,
        col_name: 'Row Number'
      })
      for (let col_uid in this.schema) {
        if (!this.schema[col_uid].is_system) {
          this.col_list.push({
            col_uid: col_uid,
            col_name: this.schema[col_uid].label
          })
        }
      }

      if (this.selected_table_id !== null) {
        let table_schema = this.tables[this.selected_table_id].config.schema

        for (let col_uid in table_schema) {
          if (!table_schema[col_uid].is_system) {
            this.col_list.push({
              col_uid: col_uid,
              col_name: '🔗 ' + table_schema[col_uid].label
            })
          }
        }


      }

    },

    async init() {
      this.is_loading = true

      this.tables = await get_qfDocument_type_as_dict('table')

      for (let table_id in this.map_layer_dict.link) {
        this.input_tables_list.push({
          table_id: this.tables[table_id].id,
          table_name: this.tables[table_id].name,
        })
      }
      console.log(this.input_tables_list)

      this.td = aq.table(this.json_arrays)

      await this.set_col_list()

      await this.set_json_arrays_selected()

      setTimeout(() => {
        this.do_plot()
      }, 500)

      this.is_loading = false
    },
  },
  watch: {

    async json_arrays() {
      await this.init()
    },
    async schema() {
      await this.init()
    },
    selected_uid_list() {
      this.do_plot()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
