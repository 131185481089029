<template>
  <div>

    <v-col style="max-width: 400px;">
      <v-select
          label="Plot Type"
          @change="do_plot"
          v-model="plot_type"
          :items="plot_types"
          item-text="title"
          item-value="val"
          :hide-details="true"
      ></v-select>
    </v-col>

    <v-col>

      <v-autocomplete
          style="max-width: 400px;"
          v-if="plot_type !== 'histogram'"
          v-model="plot_col_x_uid"
          @change="do_plot"
          :items="col_lists"
          dense
          label="Select X Column"
          item-text="col_name"
          item-value="col_uid"
      ></v-autocomplete>

      <v-autocomplete
          style="max-width: 400px;"
          v-model="plot_col_y_uid"
          @change="do_plot"
          :items="col_lists"
          dense
          label="Select Y Column"
          item-text="col_name"
          item-value="col_uid"
      ></v-autocomplete>

      <v-switch
          style="max-width: 400px;"
          @change="do_plot"
          v-model="include_selected_uids"
          :disabled="selected_uid_list.length === 0"
          dense
          :hide-details="true"
          label="Include selected features"
      ></v-switch>


      <PlotsLine
          v-if="plot_type=== 'line' && line_plot_data.show"
          :plot_data.sync="line_plot_data.plot_data"
      ></PlotsLine>

      <PlotsHistogram
          v-if="plot_type=== 'histogram' && histogram_plot_data.show"
          :plot_data.sync="histogram_plot_data.plot_data"
      ></PlotsHistogram>


    </v-col>

  </div>
</template>

<script>

import PlotsLine from "@/components/Plots/PlotsLine";
import PlotsHistogram from "@/components/Plots/PlotsHistogram";

import {deepClone, unique_array} from "@/services/generic";
import * as aq from "arquero";
import * as ss from 'simple-statistics'

export default {
  name: "PlotsTableVariables",
  props: {
    json_arrays: {type: Object},
    schema: {type: Object},
    selected_uid_list: {type: Array},
  },
  components: {
    PlotsLine,
    PlotsHistogram,
  },
  data() {
    return {
      td: null,

      fid_list: this.selected_uid_list,
      json_arrays_selected: {},

      plot_col_x_uid: null,
      plot_col_y_uid: null,

      col_lists: [],
      plot_type: 'line',
      plot_types: [
        {val: 'line', title: 'Line Plot'},
        {val: 'histogram', title: 'Histogram'},
        // {val: 'pie', title: 'Pie Chart'},
      ],


      line_plot_data: {show: false, plot_data: null},
      histogram_plot_data: {show: false, plot_data: null},

      include_all_uids: false,
      include_selected_uids: false,
      include_unselected_uids: false,

      lower_pct: 0.05,
      upper_pct: 0.95,

    }
  },
  methods: {
    do_plot() {
      setTimeout(() => {
        if (this.plot_type !== null && this.plot_col_y_uid !== null) {
          switch (this.plot_type) {
            case 'line':
              this.plot_line()
              break;
            case 'histogram':
              this.plot_histogram()
              break;
            default:
              break;
          }
        }
      }, 100)

    },
    async set_json_arrays_selected() {
      let cols_list = Object.keys(this.json_arrays)

      let json_arrays_selected = deepClone(this.json_arrays)
      for (let idx in this.json_arrays.row_uid) {
        let row_uid = this.json_arrays.row_uid[idx]
        if (!this.selected_uid_list.includes(row_uid)) {
          for (let col_uid of cols_list) {
            json_arrays_selected[col_uid][idx] = null
          }
        }
      }
      this.json_arrays_selected = json_arrays_selected
    },
    async get_plot_data() {
      let x = null
      let y = null
      let x_selected = null
      let y_selected = null

      if (this.plot_col_x_uid !== null) x = this.json_arrays[this.plot_col_x_uid]
      if (this.plot_col_y_uid !== null) y = this.json_arrays[this.plot_col_y_uid]

      if (this.include_selected_uids) {
        if (this.plot_col_x_uid !== null) x_selected = this.json_arrays_selected[this.plot_col_x_uid]
        if (this.plot_col_y_uid !== null) y_selected = this.json_arrays_selected[this.plot_col_y_uid]
      }
      return {x, y, x_selected, y_selected}
    },

    async plot_line() {
      let data = await this.get_plot_data()
      this.line_plot_data.show = false
      let plot_data = [
        {
          x: data.x,
          y: data.y,
          color: 'orange', width: 3, name: 'All data'
        }
      ]
      if (this.include_selected_uids) {
        plot_data.push({
          x: data.x_selected,
          y: data.y_selected,
          color: 'green', width: 4, name: 'Selected data'
        })
      }

      this.line_plot_data.plot_data = plot_data
      this.line_plot_data.show = true
    },

    async plot_histogram() {

      this.histogram_plot_data.show = false
      let plot_data = [
        {
          x: this.json_arrays[this.plot_col_y_uid],
          y: null,
          color: 'orange', width: 3, name: 'All data',
        }
      ]
      if (this.include_selected_uids) {
        plot_data.push({
          x: this.json_arrays_selected[this.plot_col_y_uid],
          y: null,
          color: 'green', width: 4, name: 'Selected data'
        })
      }

      this.histogram_plot_data.plot_data = plot_data
      this.histogram_plot_data.show = true

    },
    async init() {
      this.is_loading = true
      this.td = aq.table(this.json_arrays)
      this.col_lists = []

      this.col_lists.push({
        col_uid: null,
        col_name: 'Row Number'
      })

      for (let col_uid in this.schema) {
        if (!this.schema[col_uid].is_system) {
          this.col_lists.push({
            col_uid: col_uid,
            col_name: this.schema[col_uid].label
          })
        }
      }

      await this.set_json_arrays_selected()

      setTimeout(() => {
        this.do_plot()
      }, 500)

      this.is_loading = false
    },
  },
  watch: {

    async json_arrays() {
      await this.init()
    },
    async schema() {
      await this.init()
    },
    selected_uid_list() {
      this.do_plot()
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>

</style>
