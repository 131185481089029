<template>
  <v-card flat :loading="loading">
    <v-card-text>
      <v-col v-if="queries_table.length > 2">
        <v-row>
          <v-spacer></v-spacer>
          <v-text-field
              style="max-width: 300px;"
              background-color="blue-grey lighten-5"
              v-model="search"
              append-icon="mdi-magnify"
              color="green"
              label="Search for a query"
              outlined
              dense
              single-line
              hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-row>

      </v-col>

      <v-data-table
          :headers="queries_table_headers"
          :items="queries_table"
          :items-per-page="-1"
          :search="search"
          item-key="query_id"
          class="elevation-1"
          fixed-header
          show-expand
          :expanded.sync="expanded"
          :hide-default-footer="queries_table.length < 10"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container style="margin-bottom: 10px; margin-top: 10px;">
              <p class="caption">
                <b>Query Statement:</b><br>
                {{ query_to_text(queries[item.query_id]) }}
              </p>

              <v-row justify="space-around">
<!--                <v-btn @click="edit_query(item.query_id)" small style="margin-right: 10px;">-->
<!--                  <v-icon small left color="green">mdi-pencil</v-icon>-->
<!--                  Edit Query-->
<!--                </v-btn>-->

                <v-btn @click="delete_query(item.query_id)" small style="margin-right: 10px;">
                  <v-icon small left color="red">mdi-delete</v-icon>
                  Delete Query
                </v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </td>

        </template>
      </v-data-table>
      <DriveConfirmDeleteDialog
          v-if="DriveConfirmDeleteDialog_show"
          :show_dialog.sync="DriveConfirmDeleteDialog_show"
          :callback_function="after_delete_query"
          :dialog_title="'Delete Query'"
          :item_type_name="'Query'"
          :item_type="'qfDocument'"
          :item_name="delete_item.name"
          :item_id="delete_item.id"
      ></DriveConfirmDeleteDialog>
    </v-card-text>
  </v-card>

</template>

<script>

import DriveConfirmDeleteDialog from "@/components/Drive/DriveConfirmDeleteDialog";

import {deepClone} from "@/services/generic";
import {get_qfCollection_type_as_dict, get_qfDocument_type_as_dict} from "@/services/app_utils";

export default {
  name: "DriveQueriesTable",
  props: {
    callback_function: {type: Function},
  },
  components: {DriveConfirmDeleteDialog},
  data() {
    return {

      loading: false,
      layers: {},
      maps: {},
      queries: {},

      queries_table: [],
      search: null,
      queries_table_headers: [
        {text: 'Name', value: 'query_name'},
        {text: 'Description', value: 'query_description'},
        {text: '', value: 'data-table-expand'},
      ],
      expanded: [],

      selected_items: [],

      DriveConfirmDeleteDialog_show: false,
      delete_item: {
        id: null,
        name: null,
      }
    }
  },
  methods: {

    async remove_from_selected(query_id) {
      let selected_items = []
      for (let item of this.selected_items) {
        if (item.query_id !== query_id) {
          selected_items.push(item)
        }
      }
      this.selected_items = selected_items
    },

    async delete_query(query_id) {

      this.delete_item.name = this.queries[query_id].name
      this.delete_item.id = query_id
      this.DriveConfirmDeleteDialog_show = true

    },
    async after_delete_query(query_id){
      this.delete_item.name = null
      this.delete_item.id = null
      await this.init_list_queries()
      await this.remove_from_selected(query_id)
    },

    query_to_text(the_query) {

      if (the_query === null || the_query === undefined) {
        the_query = deepClone(this.query)
      }

      let txt = ``
      for (let idx_main in the_query.config.query_groups) {
        txt += `(`
        let query_operator_sub = the_query.config.query_groups[idx_main].operator
        let query_list_sub = the_query.config.query_groups[idx_main].query_list
        for (let flt_idx in query_list_sub) {
          let query_item = query_list_sub[flt_idx]
          if (query_item.column_name !== null) {
            txt += `${query_item.column_name} ${query_item.criteria.toLowerCase()} ${query_item.value}`
            if (flt_idx < query_list_sub.length - 1) {
              txt += ` ${query_list_sub[parseInt(flt_idx) + 1].operator.toUpperCase()} `
            }
          }

        }
        txt += `)`
        if (idx_main < the_query.config.query_groups.length - 1) {
          txt += ` ${query_operator_sub.toUpperCase()} `
        }

      }

      return txt
    },
    async init_list_queries() {

      this.queries_table = []

      this.layers = await get_qfDocument_type_as_dict('layer')
      this.queries = await get_qfDocument_type_as_dict('query')
      this.maps = await get_qfCollection_type_as_dict('map')

      console.log(this.queries)
      for (let query_id in this.queries) {
        let query_obj = this.queries[query_id]
        this.queries_table.push({
          query_id: query_id,
          query_name: query_obj.name,
          query_description: query_obj.config.description,
        })
      }

    },

  },
  watch: {},
  mounted() {
    this.init_list_queries()
  },
}
</script>

<style scoped>

</style>
