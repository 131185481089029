<template>
  <v-btn text small @click="signout">
    <v-icon small left>mdi-logout</v-icon>
    Log out
  </v-btn>
</template>

<script>
import {auth} from "@/firebase/firebase";
import {signOut} from "firebase/auth";
import {templates} from "@/store";
import {deepClone} from "@/services/generic";

export default {
  name: "SignOut",
  methods: {
    signout() {
      signOut(auth)
      this.$store.state.auth.user = deepClone(templates.anonymous_user)
      this.$store.state.auth.isLoggedIn = false
    },
  },
}
</script>

<style scoped>

</style>
